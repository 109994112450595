import qs from 'qs';
import { CancelToken } from 'axios';

import AbstractService from 'src/services/AbstractService';
import config from 'src/lib/config';
import { ServiceError } from 'src/lib/errors';
import { logServiceError } from 'src/utils/logger';

/**
 *
 */
class GalleryContentService extends AbstractService {
    constructor() {
        super(config.services.content);
    }

    /**
     * Gets content for the gallery
     *
     * @param input
     * @throws ServiceError
     */
    public async getContent(
        name: string,
        culture: string,
        input: Services.GalleryContent.Search,
        cancelToken?: CancelToken,
    ): Promise<Services.GalleryContent.Content[]> {
        const requestUrl = `api/v2/Galleries/${name}/Culture/${culture}/Content`;

        try {
            const response = await this.api.get<Services.GalleryContent.Response>(requestUrl, {
                cancelToken,
                params: {
                    ...input,
                    requestor: config.appName,
                    noCache: true,
                },
                paramsSerializer: (params) => qs.stringify(params),
            });

            return response.data.results.content;
        } catch (e: any) {
            const error = new ServiceError(e);

            logServiceError(error);
            throw error;
        }
    }
}

export default new GalleryContentService();
