import config from 'src/lib/config';

const imageAspectRatioUpdate = (sceneUri: string): Promise<number> => {
    if (!sceneUri) {
        return Promise.resolve(0);
    }

    const img = new Image();

    const promise = new Promise<number>((resolve) => {
        img.onload = (): void => {
            const height = img.naturalHeight;
            const width = img.naturalWidth;
            const newAspectRatio = width / height;

            resolve(newAspectRatio);
        };

        img.src = `${config.defaultScenePreview}&scene=${sceneUri}`;
    });

    return promise;
};

export default imageAspectRatioUpdate;
