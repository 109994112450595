import { useContext } from 'react';
import {
    DragDropContext,
    Droppable,
    Draggable,
    DropResult,
} from 'react-beautiful-dnd';
import { Grid } from '@mui/material';

import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from 'src/components/common/CardTable';
import ManageGalleryContext from 'src/contexts/manageGalleryContext';
import Card, { CardHeader, CardContent } from 'src/components/common/Card';
import { findProductOptionForFilter } from 'src/utils/attributeMapping';
import {
    removeAttributeFilterOption,
    updateAttributeFilterOption,
    updateGlobalFilter,
    updateProductFilterOptionOrder,
} from 'src/store/galleryData/filterStore';
import { EditContext } from 'src/contexts/EditContext';
import ProductFilterOptionsListItem from 'src/components/ManageGallery/Filters/ProductFilter/ProductFilterOptionsListItem';
import AddFilterOptions from 'src/components/ManageGallery/Filters/ProductFilter/AddFilterOptions';
import Header from 'src/components/common/Header';
import { isAutoFilter } from 'src/store/galleryData/utils';

interface PropTypes {
    filterId: string;
}

const getAvailableFilters = (
    productOption: string,
    product: Services.Product.Product,
    globalFilter: Services.Filters.GlobalFilter,
    msxAttributes: Services.Msx.MsxOptionsByName | null | undefined,
): Services.Filters.GlobalFilterOption[] => {
    let options = product.options.list.filter((o) => o.name === productOption)
        .flatMap(((o) => o.values.map((v) => ({
            name: o.name,
            value: v,
        }))));

    if (msxAttributes && isAutoFilter(globalFilter)) {
        const sortingArray = Object.keys(msxAttributes[globalFilter.attributeType].optionValues);

        options = options.sort((a, b) => sortingArray.indexOf(a.value) - sortingArray.indexOf(b.value));
    }

    const variantFilterOptions = globalFilter.options.filter((gf) => gf.productOptionName === productOption);

    return options.reduce((memo, option) => {
        // This is unlikely, but if someone removes a variant option, we need to let them add it back
        const existingFilterOption = variantFilterOptions.find((o) => o.productOptionValue === option.value);

        if (!existingFilterOption) {
            const globalFilterOption: Services.Filters.GlobalFilterOption = {
                value: '',
                productOptionName: option.name,
                productOptionValue: option.value,
                title: '',
                isDefaultValue: false,
                otherProperties: {},
            };

            memo.push(globalFilterOption);
        }

        return memo;
    }, [] as Services.Filters.GlobalFilterOption[]);
};

const ProductFilterOptionsList = (props: PropTypes): JSX.Element => {
    const { filterId } = props;
    const { galleryData, dispatch } = useContext(ManageGalleryContext);
    const { editing } = useContext(EditContext);
    const { allFiltersById, coreProduct, msxAttributes } = galleryData;
    const { globalFilter } = allFiltersById.byId.get(filterId) as App.AttributeFilter;
    const productOption = findProductOptionForFilter(galleryData, globalFilter);
    const availableFilters = coreProduct
        ? getAvailableFilters(productOption, coreProduct, globalFilter, msxAttributes)
        : [];

    const handleRemoveFilter = (
        globalFilterOption: Services.Filters.GlobalFilterOption,
    ) => (): void => {
        dispatch(removeAttributeFilterOption(globalFilter.id, globalFilterOption));
    };

    const handleIsDefaultValueChange = (
        globalFilterOption?: Services.Filters.GlobalFilterOption,
    ) => (
        event: React.MouseEvent,
        value: boolean,
    ): void => {
        event.preventDefault();

        if (globalFilterOption) {
            if (isAutoFilter(globalFilter)) {
                const newDefault = value
                    ? globalFilterOption.productOptionValue
                    : '';

                dispatch(updateGlobalFilter({
                    ...globalFilter,
                    defaultPropertyValue: newDefault,
                }, globalFilter.id));
            } else {
                dispatch(updateAttributeFilterOption(globalFilter.id, {
                    ...globalFilterOption,
                    isDefaultValue: value,
                }));
            }
        }
    };

    const handleTranslationKeyChange = (
        globalFilterOption: Services.Filters.GlobalFilterOption,
    ) => (key: string): void => {
        const newGlobalFilterOption = {
            ...globalFilterOption,
            title: key,
        };

        dispatch(updateAttributeFilterOption(globalFilter.id, newGlobalFilterOption));
    };

    const onDragEndFunc = (result: DropResult): void => {
        const { destination, source } = result;

        if (!destination) {
            return;
        }
        if (destination.index === source.index) {
            return;
        }
        dispatch(updateProductFilterOptionOrder(globalFilter.id, source.index, destination.index));
    };

    const getOptionValueTranslations = (
        productOptionValue: string,
    ): App.ById<Services.Translations.TranslationByCulture, string> => {
        if (msxAttributes) {
            const msxAttribute = msxAttributes[globalFilter.attributeType];

            return msxAttribute.optionValues[productOptionValue].translations;
        }

        return { byId: new Map<string, Services.Translations.TranslationByCulture>(), allIds: [] };
    };

    return (
        <Card>
            <CardHeader>
                <Grid container justifyContent="space-between">
                    <Grid item>
                        Filter Options
                    </Grid>
                </Grid>
            </CardHeader>
            <CardContent>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <TableCell align="center"><Header>Product Option Value</Header></TableCell>
                            <TableCell align="center"><Header>Is Default</Header></TableCell>
                            {
                                isAutoFilter(globalFilter)
                                    ? <TableCell align="center"><Header>Option Values And Translations</Header></TableCell>
                                    : <TableCell align="center"><Header>Option Value Translation Key</Header></TableCell>
                            }

                            {/* Added to accomodate the buttons in each row when editing */}
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <DragDropContext onDragEnd={onDragEndFunc}>
                        <Droppable key={`droppable${filterId}`} droppableId={filterId}>
                            {(provided): JSX.Element => (
                                <TableBody ref={provided.innerRef} {...provided.droppableProps}>
                                    {
                                        (isAutoFilter(globalFilter)
                                            ? availableFilters
                                            : globalFilter.options).map((filterOption, index) => {
                                            const id = `${filterOption.productOptionName}_${filterOption.productOptionValue}`;
                                            const translations = getOptionValueTranslations(
                                                filterOption.productOptionValue,
                                            );

                                            return (
                                                <Draggable
                                                    key={`draggable${id}`}
                                                    draggableId={id}
                                                    index={index}
                                                    isDragDisabled={
                                                        isAutoFilter(globalFilter)
                                                            || (!editing && !isAutoFilter(globalFilter)
                                                            )
                                                    }
                                                >
                                                    {(draggable): JSX.Element => (
                                                        <ProductFilterOptionsListItem
                                                            draggableProvided={draggable}
                                                            editing={editing}
                                                            filterOption={filterOption}
                                                            handleIsDefaultValueChange={
                                                                handleIsDefaultValueChange(filterOption)
                                                            }
                                                            handleTranslationKeyChange={
                                                                handleTranslationKeyChange(filterOption)
                                                            }
                                                            globalFilter={globalFilter}
                                                            overrideTranslations={translations}
                                                            handleRemoveFilter={handleRemoveFilter(filterOption)}
                                                        />
                                                    )}
                                                </Draggable>
                                            );
                                        })
                                    }
                                    {provided.placeholder}
                                </TableBody>
                            )}
                        </Droppable>
                    </DragDropContext>
                    {
                        editing && !isAutoFilter(globalFilter) && (
                            <TableBody>
                                <AddFilterOptions
                                    filterId={filterId}
                                    dispatch={dispatch}
                                    availableFilters={availableFilters}
                                />
                            </TableBody>
                        )
                    }
                </Table>
            </CardContent>
        </Card>
    );
};

export default ProductFilterOptionsList;
