import { HTMLProps, useContext } from 'react';

import EditController from 'src/components/common/EditController';
import SearchScenesContext from 'src/contexts/SearchScenesContext';
import { searchScenes } from 'src/hooks/useSearchedScenes';
import { setSearchResults } from 'src/store/searchedScenes';

const SearchScenesController = (props: HTMLProps<{}>): JSX.Element => {
    const { children } = props;
    const {
        dispatch,
        saveScenes,
        searchState: {
            scenes,
            search,
        },
    } = useContext(SearchScenesContext);

    const refreshSearch = async (): Promise<void> => {
        if (search) {
            const refreshedSearchState = await searchScenes(search);

            dispatch(setSearchResults(refreshedSearchState));
        }
    };

    const handleSave = async (): Promise<void> => {
        if (scenes) {
            await saveScenes(scenes);
            await refreshSearch();
        }
    };

    const handleCancel = async (): Promise<void> => {
        await refreshSearch();
    };

    return (
        <EditController
            onCancel={handleCancel}
            onSave={handleSave}
        >
            {children}
        </EditController>
    );
};

export default SearchScenesController;
