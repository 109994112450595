import { SET_SCENE_DESCRIPTION } from 'src/store/sceneData/constants';

export const setSceneDescriptionAction = (
    description: string,
): Actions.SceneDescriptionAction => ({
    type: SET_SCENE_DESCRIPTION,
    payload: {
        description,
    },
});

export const descriptionReducer = (
    description: string,
    action: Actions.Action,
): string => {
    switch (action.type) {
        case (SET_SCENE_DESCRIPTION):
            return (action as Actions.SceneDescriptionAction).payload.description;
        default:
            return description;
    }
};
