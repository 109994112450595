import AbstractService from 'src/services/AbstractService';
import config from 'src/lib/config';
import { ServiceError } from 'src/lib/errors';
import { logServiceError } from 'src/utils/logger';

/**
 *
 */
class TranslationService extends AbstractService {
    constructor() {
        super(config.services.config);
    }

    /**
     * Retrieves the translation keys with the translated values for the given culture
     *
     * @param culture
     */
    // TODO This is deprecated - delete
    public async getTranslationKeys(culture: string): Promise<Services.Translations.TranslationKey[]> {
        const url = `api/v2/translations/culture/${culture}`;

        try {
            const response = await this.api.get<Services.Translations.TranslationKey[]>(url, {
                params: {
                    requestor: config.appName,
                    noCache: true,
                },
            });

            return response.data;
        } catch (e: any) {
            const error = new ServiceError(e);

            logServiceError(error);
            throw error;
        }
    }

    /**
     * Retrieves the translation value for the given key for the given culture
     *
     * @param key
     * @param culture
     */
    // TODO This is deprecated - delete
    public async getTranslationValue(key: string, culture: string): Promise<Services.Translations.TranslationKey> {
        const url = `api/v2/translations/culture/${culture}/${key}`;

        try {
            const response = await this.api.get<Services.Translations.TranslationKey>(url, {
                params: {
                    requestor: config.appName,
                    noCache: true,
                },
            });

            return response.data;
        } catch (e: any) {
            const error = new ServiceError(e);

            logServiceError(error);
            throw error;
        }
    }

    /**
     * Retrieves all translation values for the given key
     *
     * @param key
     */
    // TODO This is deprecated - delete
    public async getTranslationValues(key: string): Promise<Services.Translations.TranslationByCulture[] | null> {
        const url = `api/v2/translations/${key}`;

        try {
            const response = await this.api.get<Services.Translations.TranslationByCulture[]>(url, {
                params: {
                    requestor: config.appName,
                    noCache: true,
                },
            });

            return response.data;
        } catch (e: any) {
            const error = new ServiceError(e);

            if (error.status === 404) {
                return null;
            }

            logServiceError(error);
            throw error;
        }
    }

    /**
     * Retrieves all the Translations for the given Translation Group
     *
     * @param translationGroupId
     */
    public async getTranslations(translationGroupId: number): Promise<Services.Translations.V2.Translations> {
        const url = `api/v2/TranslationGroup/${translationGroupId}/Translation`;

        try {
            const response = await this.api.get<Services.Translations.V2.TranslationsResponse>(url, {
                params: {
                    requestor: config.appName,
                    noCache: true,
                },
            });

            return response.data.data;
        } catch (e: any) {
            const error = new ServiceError(e);

            logServiceError(error);
            throw error;
        }
    }

    /**
     * Updates the value for a given translation group and culture
     *
     * @param translationGroupId
     * @param translations
     * @param bearerToken
     */
    public async putTranslation(
        translationGroupId: number,
        translation: Services.Translations.V2.Translation,
        bearerToken: string,
    ): Promise<void> {
        const url = `api/v2/TranslationGroup/${translationGroupId}/Translation/${translation.culture}`;

        try {
            return await this.api.put(
                url,
                translation,
                {
                    params: {
                        requestor: config.appName,
                    },
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                },
            );
        } catch (e: any) {
            const error = new ServiceError(e);

            logServiceError(error);
            throw error;
        }
    }

    /**
     * Creates a translation for the given translation group
     *
     * @param translationGroupId
     * @param translation
     * @param bearerToken
     */
    public async postTranslation(
        translationGroupId: number,
        translation: Services.Translations.V2.Translation,
        bearerToken: string,
    ): Promise<void> {
        const url = `api/v2/TranslationGroup/${translationGroupId}/Translation`;

        try {
            return await this.api.post(
                url,
                translation,
                {
                    params: {
                        requestor: config.appName,
                    },
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                },
            );
        } catch (e: any) {
            const error = new ServiceError(e);

            logServiceError(error);
            throw error;
        }
    }

    /**
     * Updates the value for a given translation key and culture
     *
     * @param culture
     * @param key
     * @param bearerToken
     */
    public async deleteTranslation(
        translationGroupId: number,
        culture: string,
        bearerToken: string,
    ): Promise<void> {
        const url = `api/v2/TranslationGroup/${translationGroupId}/Translation/${culture}`;

        try {
            return await this.api.delete(
                url,
                {
                    params: {
                        requestor: config.appName,
                    },
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                },
            );
        } catch (e: any) {
            const error = new ServiceError(e);

            logServiceError(error);
            throw error;
        }
    }

    public postTranslations(
        translationGroupId: number,
        translations: Services.Translations.V2.Translation[],
        bearerToken: string,
    ): Promise<void>[] {
        return translations.map((translation) => this.postTranslation(translationGroupId, translation, bearerToken));
    }

    public putTranslations(
        translationGroupId: number,
        translations: Services.Translations.V2.Translation[],
        bearerToken: string,
    ): Promise<void>[] {
        return translations.map((translation) => this.putTranslation(translationGroupId, translation, bearerToken));
    }

    public deleteTranslations(
        translationGroupId: number,
        translations: Services.Translations.V2.Translation[],
        bearerToken: string,
    ): Promise<void>[] {
        return translations.map(
            (translation) => this.deleteTranslation(translationGroupId, translation.culture, bearerToken),
        );
    }
}

export default new TranslationService();
