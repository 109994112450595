import { HTMLProps, useContext } from 'react';

import EditController from 'src/components/common/EditController';
import ManageGalleryNotificationChannelsContext from 'src/contexts/ManageGalleryNotificationChannelContext';
import { fetchGalleryNotificationChannelData } from 'src/hooks/useGalleryNotificationChannelData';
import { initializeGalleryNotificationChannels } from 'src/store/GalleryNotificationChannelData';
import { IdentityContext } from 'src/contexts/IdentityContext';

const ManageGalleryNotificationChannelController = (props: HTMLProps<{}>): JSX.Element => {
    const { children } = props;
    const {
        dispatch, saveGalleryNotificationChannel, galleryNotificationChannelData,
    } = useContext(ManageGalleryNotificationChannelsContext);
    const { accessToken } = useContext(IdentityContext);

    const resetGalleryNotificationChannels = async (): Promise<void> => {
        if (galleryNotificationChannelData) {
            const newGalleryNotificationChannelData = await fetchGalleryNotificationChannelData(
                galleryNotificationChannelData.galleryId,
                accessToken,
            );

            dispatch(initializeGalleryNotificationChannels(newGalleryNotificationChannelData));
        }
    };

    const handleSave = async (): Promise<void> => {
        await saveGalleryNotificationChannel(galleryNotificationChannelData);
        await resetGalleryNotificationChannels();
    };

    const handleCancel = async (): Promise<void> => {
        await resetGalleryNotificationChannels();
    };

    return (
        <EditController
            onCancel={handleCancel}
            onSave={handleSave}
        >
            {children}
        </EditController>
    );
};

export default ManageGalleryNotificationChannelController;
