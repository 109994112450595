import classnames from 'classnames';
import { ReactNode } from 'react';
import { Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

import GhostSvg from 'src/components/common/GhostSvg';

const useStyles = makeStyles()((theme: Theme) => ({
    centered: {
        textAlign: 'center',
    },
    ghost: {
        height: theme.spacing(8),
    },
    offset: {
        paddingTop: theme.spacing(4),
    },
}));

interface ZeroStateProps {
    text: ReactNode;
    icon?: JSX.Element;
}

const ZeroState = (props: ZeroStateProps): JSX.Element => {
    const { text, icon } = props;
    const { classes } = useStyles();

    return (
        <div className={classnames(classes.centered, classes.offset)}>
            {icon}
            {!icon && (<GhostSvg className={classes.ghost} />)}
            <Typography
                gutterBottom
                align="center"
                variant="h6"
            >
                {text}
            </Typography>
        </div>
    );
};

export default ZeroState;
