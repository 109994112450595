import { useState, useContext } from 'react';
import { addTaxonomyFilter } from 'src/store/galleryData/filterStore';
import CreateFilter from 'src/components/ManageGallery/Filters/CreateFilter';
import GalleryGlobalValueContext from 'src/contexts/GalleryGlobalValueContext';
import ManageGalleryContext from 'src/contexts/manageGalleryContext';

interface PropTypes {
    backTo: string;
}

const CreateTaxonomyFilter = (props: PropTypes): JSX.Element => {
    const { backTo } = props;
    const { globalGalleryData: { allTaxonomiesById } } = useContext(GalleryGlobalValueContext);
    const [filter, setFilter] = useState<string | undefined>(undefined);
    const { galleryData: { allFiltersById } } = useContext(ManageGalleryContext);
    const allTaxonomies = [...allTaxonomiesById.byId.values()];

    const unAssociatedFilters = allTaxonomies.filter((t) => !allFiltersById.byId.get(t.id));

    return (
        <CreateFilter
            backTo={backTo}
            redirectTo={`${backTo}/taxonomy/${filter}`}
            filter={filter}
            setFilter={setFilter}
            backBarTitle="Create Taxonomy Filter"
            filters={unAssociatedFilters}
            addFilterAction={addTaxonomyFilter}
            description="Select the name of the Taxonomy Root to use for this new Taxonomy filter"
            SelectProps={{
                id: 'select-root-taxonomy',
                label: 'Select a root taxonomy',
            }}
        />
    );
};

export default CreateTaxonomyFilter;
