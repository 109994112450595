import { useContext } from 'react';
import {
    Grid,
    Chip,
    Box,
    Typography,
} from '@mui/material';

import Card, { CardHeader, CardContent } from 'src/components/common/Card';
import ManageSceneContext from 'src/contexts/ManageSceneContext';
import { EditContext } from 'src/contexts/EditContext';
import { setProductOptionAction, deleteProductOptionAction } from 'src/store/sceneData/productOptions';

const ManageSceneProductOptions = (): JSX.Element => {
    const { sceneData: { coreProductOptions, sceneDefinition }, dispatch } = useContext(ManageSceneContext);
    const { editing } = useContext(EditContext);
    const sceneProductOptionMap = new Map(sceneDefinition.productOptions.map((i) => [i.optionName, i.optionValue]));

    const optionOnClick = (optionName: string, optionValue: string, selected: boolean) => (): void => {
        const sceneProductOption = {
            optionName,
            optionValue,
        };

        if (selected) {
            dispatch(deleteProductOptionAction(sceneProductOption));
        } else {
            dispatch(setProductOptionAction(sceneProductOption));
        }
    };

    return (

        <Card>
            <CardHeader>
                Product Options
            </CardHeader>
            <CardContent>
                <Grid container direction="column" spacing={2}>
                    {coreProductOptions && coreProductOptions.list.map((productOption) => (
                        <Grid item xs={12} key={productOption.name}>
                            <Typography variant="overline">
                                <Box component="span" fontWeight="bold">
                                    {productOption.name}
                                </Box>
                            </Typography>
                            <Grid container direction="row" spacing={2}>
                                {productOption.values.length && productOption.values.map((value) => {
                                    const optionValue = sceneProductOptionMap.get(productOption.name);
                                    const optionSelected = (optionValue === value);

                                    return (
                                        <Grid item key={`${productOption.name}-${value}`}>
                                            <Chip
                                                color={optionSelected ? 'primary' : undefined}
                                                label={value}
                                                clickable={editing}
                                                onClick={editing
                                                    ? optionOnClick(productOption.name, value, optionSelected)
                                                    : undefined}
                                            />
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </Grid>

                    ))}
                </Grid>
            </CardContent>
        </Card>
    );
};

export default ManageSceneProductOptions;
