import { Grid } from '@mui/material';

import ContentBackgroundColor from 'src/components/ManageGallery/GalleryRenderProperties/ContentBackgroundColor';
import ComparativeNameFormat from 'src/components/ManageGallery/GalleryRenderProperties/ComparativeNameFormat';
import RenderProperties from 'src/components/ManageGallery/GalleryRenderProperties/RenderProperties';
import useCompatibleBreakpoint from 'src/hooks/useCompatibleBreakpoint';

const GalleryRenderProperties = (): JSX.Element => {
    const leftSplitScreenProp = { [useCompatibleBreakpoint('md')]: 4 };
    const rightSplitScreenProp = { [useCompatibleBreakpoint('md')]: 8 };

    return (
        <Grid container spacing={2} direction="row">
            <Grid item xs={12} {...leftSplitScreenProp}>
                <Grid container spacing={2} direction="row">
                    <Grid item xs={12}>
                        <ComparativeNameFormat />
                    </Grid>
                    <Grid item xs={12}>
                        <ContentBackgroundColor />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} {...rightSplitScreenProp}>
                <RenderProperties />
            </Grid>
        </Grid>
    );
};

export default GalleryRenderProperties;
