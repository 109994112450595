import { useState } from 'react';
import { Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

import Adornment from 'src/components/common/TranslationKeySelect/Adornment';
import config from 'src/lib/config';
import SearchKeysDialog from 'src/components/common/TranslationKeySelect/SearchKeysDialog';
import TranslationTooltip from 'src/components/common/TranslationTooltip';
import TextField, { TextFieldProps } from 'src/components/common/inputs/TextField';
import useTranslationValues from 'src/hooks/useTranslationValues';

type PropTypes = Omit<TextFieldProps, 'helperText' | 'InputProps' | 'value' | 'variant'> & {
    handleTranslationKeyChange: (key: string) => void;
    helperText?: boolean;
    value: App.AllowedTranslationKey;
    defaultPurpose: TRANSLATION_PURPOSE;
};

const useStyles = makeStyles()((theme: Theme) => ({
    helperTextInset: {
        marginLeft: theme.typography.body1.fontSize,
    },
    reposition: {
        top: -2,
    },
    input: {
        '& .MuiInput-input': {
            color: theme.palette.text.primary,
        },
    },
}));

const TranslationKeySelect = (props: PropTypes): JSX.Element => {
    const {
        editing,
        label,
        handleTranslationKeyChange,
        helperText = true,
        value,
        defaultPurpose,
        ...rest
    } = props;
    const { classes } = useStyles();
    const [translations, , loading, error] = useTranslationValues(value);
    const [open, setOpen] = useState(false);

    let culture = config.defaultCulture;
    let localizedValue = 'N/A';

    if (translations) {
        if (!translations.allIds.includes(config.defaultCulture)) {
            // eslint-disable-next-line prefer-destructuring
            culture = translations.allIds[0];
        }

        const translation = translations.byId.get(culture);

        if (translation) {
            localizedValue = translation.value;
        }
    }

    const handleSelectTranslationKey = (): void => setOpen(true);

    const handleTranslationKeyChangeForModal = (key: string) => (): void => {
        handleTranslationKeyChange(key);
        if (setOpen) {
            setOpen(false);
        }
    };

    return (
        <>
            <TextField
                disabled
                editing={editing}
                label={label && (
                    <TranslationTooltip icon={!editing} translationKey={value}>
                        <Typography
                            display="inline"
                            variant="inherit"
                        >
                            {label}
                        </Typography>
                    </TranslationTooltip>
                )}
                InputLabelProps={{
                    className: classes.reposition,
                }}
                helperText={helperText && (
                    translations ? `${culture.toUpperCase()} value: ${localizedValue}` : undefined
                )}
                InputProps={{
                    className: classes.input,
                    endAdornment: (
                        <Adornment
                            editing={editing}
                            error={error}
                            loading={loading}
                            onClick={handleSelectTranslationKey}
                        />
                    ),
                }}
                value={value}
                variant="standard"
                {...rest}
            />
            <SearchKeysDialog
                open={open}
                handleTranslationKeyChange={handleTranslationKeyChangeForModal}
                defaultPurpose={defaultPurpose}
                onClose={(): void => setOpen(false)}
            />
        </>
    );
};

export default TranslationKeySelect;
