import { galleryReducer } from 'src/store/galleryData/galleryStore';
import { renderPropertiesReducer } from 'src/store/galleryData/renderPropertiesStore';
import { filterReducer } from 'src/store/galleryData/filterStore';
import { categoryRestrictionsReducer } from './categoryRestrictionsStore';

export const INITIALIZE_GALLERY_DATA = 'INITIALIZE_GALLERY_DATA';

export const initializeGalleryDataAction = (galleryData: App.GalleryData): Actions.GalleryDataAction => ({
    type: INITIALIZE_GALLERY_DATA,
    payload: {
        galleryData,
    },
});

export const reducer = (state: App.GalleryData | null, action: Actions.Action): App.GalleryData | null => {
    switch (action.type) {
        case (INITIALIZE_GALLERY_DATA):
            return (action as Actions.GalleryDataAction).payload.galleryData;
        default:
            if (!state) {
                return state;
            }
            return {
                ...state,
                gallery: galleryReducer(state.gallery, action),
                renderProperties: renderPropertiesReducer(state.renderProperties, action),
                allFiltersById: filterReducer(state.allFiltersById, action),
                categoryRestrictions: categoryRestrictionsReducer(state.categoryRestrictions, action),
            };
    }
};
