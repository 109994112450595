import merge from 'merge-anything';

import files from 'config';

declare const ENVIRONMENT: App.AllowedEnvironments;

declare const DEPLOYMENT: string;

// TODO add support for local configuration override
const config = merge(
    files.default,
    files[ENVIRONMENT],
    files[`${ENVIRONMENT}-${DEPLOYMENT}`],
    {
        // eslint-disable-next-line object-shorthand
        ENVIRONMENT: ENVIRONMENT,
        // eslint-disable-next-line object-shorthand
        DEPLOYMENT: DEPLOYMENT,
    },
) as App.Config;

export default config;
