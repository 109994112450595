import { useEffect, useState } from 'react';

import productService from 'src/services/ProductService';
import { getLogger } from 'src/utils/logger';
import { AppError } from 'src/lib/errors';

type ProductOptionsValuesPairsTuple = [
    Services.Product.ProductOptionValuePair[],
    boolean,
    AppError | null,
];

/**
 * Simple utility to take the name of a Product Option and one of it's values
 * and create a ProductOptionValuePair object
 *
 * @param name
 * @param value
 */
function createProductOptionValuePair(name: string, value: string): Services.Product.ProductOptionValuePair {
    return ({
        name,
        value,
        label: `${name}: ${value}`,
    });
}

const useProductOptionsValuePairs = (productKey?: string | null): ProductOptionsValuesPairsTuple => {
    const [error, setError] = useState<AppError | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<Services.Product.ProductOptionValuePair[]>([]);

    useEffect(() => {
        const runEffect = async (query: string): Promise<void> => {
            try {
                setLoading(true);
                const product = await productService.getProduct(query);

                const result = product.options.list.reduce((accum, option) => {
                    const { name, values } = option;
                    const pairs = values.map((value) => createProductOptionValuePair(name, value));

                    return accum.concat(pairs);
                }, [] as Services.Product.ProductOptionValuePair[]);

                setData(result);
            } catch (e: any) {
                getLogger().error(e);
                setError(new AppError(e));
            } finally {
                setLoading(false);
            }
        };

        if (productKey) {
            runEffect(productKey);
        }
    }, [productKey]);

    return [data, loading, error];
};

export default useProductOptionsValuePairs;
