import { Card, CardContent } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import CategoryRestrictionPicker from 'src/components/ManageGallery/GalleryCategoryRestrictions/CategoryRestrictionPicker';
import Description from 'src/components/common/Description';

const GalleryCategoryRestrictions = (): JSX.Element => (
    <Grid container spacing={3} direction="column">
        <Grid>
            <Card>
                <CardContent>
                    <Description>
                        Category restrictions are useful when you want to include/exclude content
                        from a specific category. Once one category has been selected with the include
                        option, only content from that category and its children and other categories selected
                        as include will be shown. You can also exclude content selecting a category as exclude.
                    </Description>
                    <Description>
                        To add a restriction, use the input below to choose a root category, click any of the
                        rows in the tree and then select the type of restriction in the left side of the screen.
                    </Description>
                </CardContent>
            </Card>
        </Grid>
        <Grid>
            <CategoryRestrictionPicker />
        </Grid>
    </Grid>
);

export default GalleryCategoryRestrictions;
