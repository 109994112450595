import { AxiosResponse } from 'axios';

import AbstractService from 'src/services/AbstractService';
import config from 'src/lib/config';
import { ServiceError } from 'src/lib/errors';
import { logServiceError } from 'src/utils/logger';

/**
 * Despite the typing saying the IDs are strings, they currently come back
 * as numbers. Convert them to strings.
 * @param response
 */
function replaceWithTaxonomyIds(response: AxiosResponse<Services.GalleryTaxonomies.GalleryTaxonomy[]>): AxiosResponse {
    if (Array.isArray(response.data)) {
        return {
            ...response,
            data: response.data.map((result) => ({
                ...result,
                id: result.taxonomyId,
            })),
        };
    }

    return response;
}

/**
 * Convert the GalleryTaxonomy model into a new one without the ID
 *
 * @param galleryTaxonomy
 */
function toGalleryTaxonomyPutRequest(
    galleryTaxonomy: Services.Filters.GalleryTaxonomy,
): Services.GalleryTaxonomies.GalleryTaxonomy {
    return {
        taxonomyId: galleryTaxonomy.id,
        bestSellingCount: galleryTaxonomy.bestSellingCount,
        collapsed: galleryTaxonomy.collapsed,
        sortOrder: galleryTaxonomy.sortOrder,
        type: galleryTaxonomy.type,
    };
}

/**
 * Gets and sets category filters for a gallery
 */
class GalleryTaxonomiesService extends AbstractService {
    constructor() {
        super(config.services.config);

        this.api.interceptors.response.use(replaceWithTaxonomyIds);
    }

    public async getGalleryTaxonomies(
        galleryId: number,
    ): Promise<Services.Filters.GalleryTaxonomy[]> {
        const requestUrl = `api/v3/Galleries/${galleryId}/Config/Taxonomies`;

        try {
            const response = await this.api.get<Services.Filters.GalleryTaxonomy[]>(requestUrl, {
                params: {
                    requestor: config.appName,
                    noCache: true,
                },
            });

            return response.data;
        } catch (e: any) {
            const error = new ServiceError(e);

            logServiceError(error);
            throw error;
        }
    }

    /**
     * Updates gallery taxonomy link
     *
     * @param filter
     * @param galleryId
     * @throws ServiceError
     */
    public async putGalleryTaxonomy(
        taxonomy: Services.Filters.GalleryTaxonomy,
        galleryId: number,
        bearerToken: string,
    ): Promise<void> {
        const requestUrl = `api/v3/Galleries/${galleryId}/Config/Taxonomies`;

        try {
            return await this.api.put(
                requestUrl,
                toGalleryTaxonomyPutRequest(taxonomy),
                {
                    params: {
                        requestor: config.appName,
                    },
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                },
            );
        } catch (e: any) {
            const error = new ServiceError(e);

            logServiceError(error);
            throw error;
        }
    }

    public async deleteGalleryTaxonomy(
        taxonomy: Services.Filters.GalleryTaxonomy,
        galleryId: number,
        bearerToken: string,
    ): Promise<void> {
        const requestUrl = `api/v3/Galleries/${galleryId}/Config/Taxonomies/${taxonomy.id}`;

        try {
            return await this.api.delete(
                requestUrl,
                {
                    params: {
                        requestor: config.appName,
                    },
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                },
            );
        } catch (e: any) {
            const error = new ServiceError(e);

            logServiceError(error);
            throw error;
        }
    }
}

export default new GalleryTaxonomiesService();
