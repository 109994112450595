import {
    Dispatch,
    useContext,
    useEffect,
    useReducer,
    useState,
} from 'react';

import getGalleryName from 'src/utils/getGalleryName';
import ProductService from 'src/services/ProductService';
import saveScenes from 'src/lib/saveScenes';
import scenesService from 'src/services/ScenesService';
import { AppError } from 'src/lib/errors';
import { reducer, setSearchResults } from 'src/store/searchedScenes';
import { IdentityContext } from 'src/contexts/IdentityContext';
import { SEARCH_SCENES_TYPE } from 'src/constants';

type SearchScenesTuple = [
    App.SearchScenesContext | null,
    boolean,
    AppError | null,
];

const INITIAL_STATE: Services.Scenes.V2.SearchState = {
    galleryName: null,
    hasMore: false,
    product: null,
    scenes: null,
    search: null,
};

export const searchScenes = async (search: Services.Scenes.V2.Search): Promise<Services.Scenes.V2.SearchState> => {
    const { basicProduct: { productKey }, ...rest } = search;

    const response = await scenesService.getScenes({
        productKey,
        ...rest,
    });
    const galleryName = await getGalleryName(productKey);
    const product = await ProductService.getProduct(productKey);

    return {
        galleryName,
        product,
        search,
        hasMore: (response.offset + response.length) < response.total,
        scenes: response.results,
    };
};

const useSearchedScenes = (search: Services.Scenes.V2.Search | null): SearchScenesTuple => {
    const [
        searchState,
        dispatch,
    ] = useReducer(reducer, INITIAL_STATE) as [Services.Scenes.V2.SearchState, Dispatch<Actions.Action>];
    const [error, setError] = useState<AppError | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const { accessToken } = useContext(IdentityContext);

    useEffect(() => {
        const runEffect = async (): Promise<void> => {
            try {
                if (search) {
                    setLoading(search.type === SEARCH_SCENES_TYPE.SEARCHING);

                    const newSearchState = await searchScenes(search);

                    dispatch(setSearchResults(newSearchState));
                }
            } catch (e: any) {
                setError(new AppError(e));
            } finally {
                setLoading(false);
            }
        };

        runEffect();
    }, [search, dispatch]);

    const state = (accessToken && searchState)
        ? {
            searchState,
            dispatch,
            saveScenes: saveScenes(accessToken),
        }
        : null;

    return [state, loading, error];
};

export default useSearchedScenes;
