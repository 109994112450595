import {
    ChangeEvent, useContext, useEffect, useMemo, useState,
} from 'react';
import {
    Grid,
    Typography,
} from '@mui/material';

import AvailableFiltersList from 'src/components/ManageGallery/GalleryRenderProperties/ComparativeNameFormat/AvailableFiltersList';
import ManageGalleryContext from 'src/contexts/manageGalleryContext';
import RenderPropertyEnable from 'src/components/ManageGallery/GalleryRenderProperties/RenderPropertyEnable';
import TextField from 'src/components/common/inputs/TextField';
import Card, { CardHeader, CardContent } from 'src/components/common/Card';
import { EditContext } from 'src/contexts/EditContext';
import { updateGallery } from 'src/store/galleryData/galleryStore';
import {
    generateComparativeNamePreview,
    findUniqueOptionNamesFromFilters,
    getRenderProperty,
} from 'src/components/ManageGallery/GalleryRenderProperties/utils';
import GalleryGlobalValueContext from 'src/contexts/GalleryGlobalValueContext';
import { BASE_EXPERIENCE_TYPE } from 'src/constants';

const COMPARATIVE_NAME_KEY = 'ShowComparativeNames';

const ComparativeNameFormat = (): JSX.Element => {
    const { editing } = useContext(EditContext);
    const {
        galleryData: {
            allFiltersById,
            gallery,
            renderProperties,
            msxAttributes,
        },
        dispatch,
    } = useContext(ManageGalleryContext);
    const { comparativeNameFormat } = gallery;
    const { globalGalleryData: { defaultRenderProperties } } = useContext(GalleryGlobalValueContext);
    const filters = useMemo(() => [...allFiltersById.byId.values()], [allFiltersById.byId]);
    const [preview, setPreview] = useState<string>('');
    const availableFilters = findUniqueOptionNamesFromFilters(filters);

    useEffect(() => {
        const getPreview = async (): Promise<void> => {
            const newPreview = await generateComparativeNamePreview(comparativeNameFormat, filters, msxAttributes);

            setPreview(newPreview);
        };

        getPreview();
    }, [comparativeNameFormat, filters, msxAttributes]);

    const handleComparativeNameFormatChange = (event: ChangeEvent<HTMLInputElement>): void => {
        dispatch(updateGallery({
            ...gallery,
            comparativeNameFormat: event.currentTarget.value,
        }));
    };

    const addFilterToFormat = (key: string): void => {
        dispatch(updateGallery({
            ...gallery,
            comparativeNameFormat: comparativeNameFormat ? `${comparativeNameFormat} {${key}}` : `{${key}}`,
        }));
    };

    const showComparativeName = !!getRenderProperty(
        defaultRenderProperties,
        renderProperties,
        COMPARATIVE_NAME_KEY,
        BASE_EXPERIENCE_TYPE,
    );

    return (
        <Card>
            <CardHeader>
                <Grid container spacing={3} justifyContent="space-between">
                    <Grid item>
                        Comparative Name Format
                    </Grid>
                    <Grid item>
                        <RenderPropertyEnable
                            editing={editing}
                            id="comparative-name-key-render-prop-enable"
                            propKey={COMPARATIVE_NAME_KEY}
                            experienceType={BASE_EXPERIENCE_TYPE}
                        />
                    </Grid>
                </Grid>
            </CardHeader>
            <CardContent>
                {showComparativeName && (
                    <Grid container spacing={3} justifyContent="space-between">
                        <Grid item xs={12}>
                            <Typography variant="subtitle2">
                                Enter the filter names used to build the Comparative Name Format.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                editing={editing}
                                id="comparative-name-key-render-prop-format"
                                value={comparativeNameFormat || ''}
                                label="Format"
                                helperText="The Comparative Name Format is a string of at least one filter name, referenced as {filter}, along with any other characters that should appear in the Comparative Name."
                                onChange={handleComparativeNameFormatChange}
                            />
                        </Grid>
                        {editing && (
                            <Grid item xs={12}>
                                <Typography paragraph variant="body2">
                                    Available filters:
                                </Typography>
                                <AvailableFiltersList filters={availableFilters} onClick={addFilterToFormat} />
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                editing={false}
                                id="comparative-name-key-render-prop-preview"
                                value={preview}
                                label="Preview"
                                helperText="Preview of what the comparative name might look like"
                                variant="filled"
                            />
                        </Grid>

                    </Grid>
                )}
                {!showComparativeName && (
                    <Typography variant="subtitle2">
                        Enable to show and edit the Comparative Name of the Gallery
                    </Typography>
                )}
            </CardContent>
        </Card>
    );
};

export default ComparativeNameFormat;
