import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

import AppError from 'src/lib/errors/AppError';
import parseValidationDetails from 'src/lib/errors/parseValidationDetails';
import {
    ErrorPayload, IServiceError, ValidationDetails, ErrorInfo,
} from 'src/lib/errors/types';
import * as utils from 'src/lib/errors/utils';

export default class ServiceError extends AppError implements IServiceError {
    details: ValidationDetails | undefined;

    isAxiosError: boolean;

    request: AxiosRequestConfig | undefined;

    response: AxiosResponse | undefined;

    status: number;

    payload: ErrorPayload;

    constructor(payload: ErrorPayload) {
        super(payload);
        const info = payload as ErrorInfo;
        const data = (payload as AxiosError)?.response?.data;

        this.payload = payload;
        this.details = parseValidationDetails(typeof data === 'string' ? { errors: [data] } : data);
        this.isAxiosError = utils.resolveIsAxiosError(info);
        this.name = 'ServiceError';
        this.request = utils.resolveRequest(info);
        this.response = utils.resolveResponse(info);
        this.status = utils.resolveStatus(payload);
    }
}
