import { useEffect, useState } from 'react';

import { getLogger } from 'src/utils/logger';
import { AppError } from 'src/lib/errors';
import ProductSearchService from 'src/services/ProductSearchService';

type ProductsTuple = [
    Services.Product.BasicProduct[],
    boolean,
    AppError | null,
];

const useProducts = (accessToken: string | undefined, searchInput: string): ProductsTuple => {
    const [error, setError] = useState<AppError | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<Services.Product.BasicProduct[]>([]);

    useEffect(() => {
        const runEffect = async (at: string, si: string): Promise<void> => {
            try {
                setLoading(true);
                const result = await ProductSearchService.getProductsBy(at, si);

                setData(result);
            } catch (e: any) {
                getLogger().error(e);
                setError(new AppError(e));
            } finally {
                setLoading(false);
            }
        };

        if (accessToken && searchInput) {
            runEffect(accessToken, searchInput);
        } else {
            // If we clear out the productKey in a search,
            // this prevents the original product from being used in the next search
            setData([]);
        }
    }, [accessToken, searchInput]);

    return [data, loading, error];
};

export default useProducts;
