import { ChangeEvent, useContext } from 'react';
import {
    Grid, SelectChangeEvent, TextField, Typography,
} from '@mui/material';

import Checkbox from 'src/components/common/inputs/Checkbox';
import FilterTypeHelperText from 'src/components/ManageGallery/Filters/ProductFilter/FilterTypeHelperText';
import FilterInitialisationTypeHelperText from 'src/components/ManageGallery/Filters/ProductFilter/FilterInitialisationTypeHelperText';

import ManageGalleryContext from 'src/contexts/manageGalleryContext';
import ReadOnlyField from 'src/components/common/inputs/ReadOnlyField';
import Select from 'src/components/common/inputs/Select';
import TranslationKeySelect from 'src/components/common/TranslationKeySelect';
import Card, { CardHeader, CardContent } from 'src/components/common/Card';
import { EditContext } from 'src/contexts/EditContext';
import { updateGlobalFilter, updateGalleryFilter } from 'src/store/galleryData/filterStore';
import {
    GLOBAL_FILTER_TYPES, TRANSLATION_PURPOSE, QUICKVIEW_FILTER_TYPE, FILTER_PROPERTY_INIT_TYPE,
} from 'src/constants';
import TranslationTooltip from 'src/components/common/TranslationTooltip';
import { isAutoFilter } from 'src/store/galleryData/utils';

export interface PropTypes {
    filterId: string;
}

const FilterForm = (props: PropTypes): JSX.Element => {
    const { filterId } = props;
    const { galleryData, dispatch } = useContext(ManageGalleryContext);
    const { editing } = useContext(EditContext);
    const { allFiltersById, msxAttributes } = galleryData;
    const { filter, globalFilter } = allFiltersById.byId.get(filterId) as App.AttributeFilter;
    const { sortOrder } = filter;

    const handleTranslationKeyChange = (key: string): void => {
        dispatch(updateGlobalFilter({
            ...globalFilter,
            title: key,
        }, globalFilter.id));
    };

    const handleCollapsedChange = (
        _: ChangeEvent<HTMLInputElement>,
        value: boolean,
    ): void => {
        dispatch(updateGalleryFilter({
            ...filter,
            isCollapsed: value,
        }, globalFilter.id));
    };

    const handleTypeChange = (event: SelectChangeEvent<unknown>): void => {
        dispatch(updateGlobalFilter({
            ...globalFilter,
            type: event.target.value as string,
            defaultPropertyValue: '',
        }, globalFilter.id));
    };

    const handleQuickviewFilterChange = (
        _: ChangeEvent<HTMLInputElement>,
        value: boolean,
    ): void => {
        dispatch(updateGalleryFilter({
            ...filter,
            quickViewFilter: (value) ? {
                quickViewSortOrder: sortOrder,
                quickViewFilterType: QUICKVIEW_FILTER_TYPE.RADIO,
            } as Services.Filters.QuickViewFilter : null,
        }, globalFilter.id));
    };

    const handleAutoFilterChange = (event: SelectChangeEvent<unknown>): void => {
        if (event.target.value === FILTER_PROPERTY_INIT_TYPE.MANUAL) {
            dispatch(updateGlobalFilter({
                ...globalFilter,
                propertyInitialisationType: event.target.value as string,
                defaultPropertyValue: '',
            }, globalFilter.id));
        } else {
            dispatch(updateGlobalFilter({
                ...globalFilter,
                propertyInitialisationType: event.target.value as string,
                title: '',
                options: [],
            }, globalFilter.id));
        }
    };

    const optionNameTranslations = !!msxAttributes && msxAttributes[globalFilter.attributeType]
        ? msxAttributes[globalFilter.attributeType].optionName.translations
        : { byId: new Map<string, Services.Translations.TranslationByCulture>(), allIds: [] };

    return (
        <Card>
            <CardHeader>
                Filter Details
            </CardHeader>
            <CardContent>
                <Grid container spacing={3} justifyContent="space-between">
                    <Grid item xs={6}>
                        <Select
                            fullWidth
                            editing={editing}
                            value={globalFilter.type}
                            id="selection-type-select"
                            label="Selection Type"
                            helperText={(<FilterTypeHelperText type={globalFilter.type} />)}
                            onChange={handleTypeChange}
                        >
                            {Object.values(GLOBAL_FILTER_TYPES).map((filterType) => (
                                <option key={filterType} value={filterType}>
                                    {filterType}
                                </option>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={6}>
                        <Select
                            fullWidth
                            editing={editing}
                            value={globalFilter.propertyInitialisationType}
                            id="product-option-initialisation-type-select"
                            label="Product Options Initialisation Type"
                            helperText={(
                                <FilterInitialisationTypeHelperText type={globalFilter.propertyInitialisationType} />
                            )}
                            onChange={handleAutoFilterChange}
                        >
                            {Object.values(FILTER_PROPERTY_INIT_TYPE).map((filterType) => (
                                <option key={filterType} value={filterType}>
                                    {filterType}
                                </option>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={6}>
                        {globalFilter.attributeType && (
                            <ReadOnlyField
                                label="Product Option"
                                value={globalFilter.attributeType}
                                helperText="The Product Option associated to this filter and attribute mapping"
                            />
                        )}
                    </Grid>
                    <Grid item xs={6}>
                        {isAutoFilter(globalFilter) && (
                            <TextField
                                fullWidth
                                disabled
                                id="display-name-text"
                                label={(
                                    <TranslationTooltip icon overrideTranslations={optionNameTranslations}>
                                        <Typography
                                            display="inline"
                                            variant="inherit"
                                        >
                                            Display Name
                                        </Typography>
                                    </TranslationTooltip>
                                )}
                                value={globalFilter.attributeType}
                            />
                        )}
                        {!isAutoFilter(globalFilter) && (
                            <TranslationKeySelect
                                fullWidth
                                editing={editing}
                                id="display-name-input"
                                label="Display Name"
                                handleTranslationKeyChange={handleTranslationKeyChange}
                                defaultPurpose={TRANSLATION_PURPOSE.FilterTitle}
                                value={globalFilter.title}
                            />
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <Checkbox
                            editing={editing}
                            checked={filter.isCollapsed}
                            id="is-collapsed-input"
                            label="Collapsed"
                            onChange={handleCollapsedChange}
                        />
                        <Checkbox
                            editing={editing}
                            checked={!!filter.quickViewFilter}
                            id="quickview-filter-input"
                            label="Show in Quickview"
                            onChange={handleQuickviewFilterChange}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default FilterForm;
