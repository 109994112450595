import {
    DELETE_TRANSLATION,
    UPDATE_TRANSLATION,
    UPDATE_TRANSLATIONS,
} from 'src/store/translationsData/constants';

import { deleteFromTranslations, updateTranslationValue } from 'src/store/translationsData/utils';

export const deleteTranslation = (culture: string): Actions.DeleteTranslationAction => ({
    type: DELETE_TRANSLATION,
    payload: {
        culture,
    },
});

export const updateTranslation = (culture: string, value: string): Actions.UpdateTranslationAction => ({
    type: UPDATE_TRANSLATION,
    payload: {
        culture,
        value,
    },
});

export const updateTranslations = (
    translations: Services.Translations.V2.Translations,
): Actions.UpdateTranslationsAction => ({
    type: UPDATE_TRANSLATIONS,
    payload: {
        translations,
    },
});

export const translationsReducer = (
    translations: Services.Translations.V2.Translations,
    action: Actions.Action,
): Services.Translations.V2.Translations => {
    switch (action.type) {
        case DELETE_TRANSLATION:
            return deleteFromTranslations((action as Actions.DeleteTranslationAction).payload.culture, translations);
        case UPDATE_TRANSLATION:
            return updateTranslationValue(
                (action as Actions.UpdateTranslationAction).payload.culture,
                (action as Actions.UpdateTranslationAction).payload.value,
                translations,
            );
        case UPDATE_TRANSLATIONS:
            return (action as Actions.UpdateTranslationsAction).payload.translations;
        default:
            return translations;
    }
};
