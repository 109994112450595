import {
    HTMLProps, useContext, useEffect, useState,
} from 'react';
import {
    Tooltip,
    IconButton,
    Zoom,
    Typography,
} from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';

import imageAspectRatioUpdate from 'src/components/ManageScene/imageAspectRatioUpdate';
import ManageSceneContext from 'src/contexts/ManageSceneContext';
import { EditContext } from 'src/contexts/EditContext';
import { SAVE_CLICKED_EVENT } from 'src/constants';
import { setSceneAspectRatioAction } from 'src/store/sceneData/scenes';

interface PropTypes extends HTMLProps<{}> {
    sceneType: string;
    uri: string;
    currentAspectRatio: number;
}

const AspectRatioCheck = (props: PropTypes): JSX.Element => {
    const {
        sceneType,
        uri,
        currentAspectRatio,
    } = props;
    const { dispatch } = useContext(ManageSceneContext);
    const { editing } = useContext(EditContext);
    const [aspectRatioError, displayAspectRatioError] = useState<boolean>(false);
    const [calculatedAspectRatio, setCalculatedAspectRatio] = useState<number | undefined>(undefined);

    const warningMessage = 'The preview image Aspect Ratio does not match the recorded Aspect Ratio. ';
    const warningFix = 'Click the warning icon to update the Aspect Ratio to match the preview image.';
    const warningFixEdit = 'Saving will update the Aspect Ratio to its correct value. Be mindful of this when making changes.';

    const onFix = (): void => {
        const saveEvent = new Event(SAVE_CLICKED_EVENT);

        window.dispatchEvent(saveEvent);
    };

    useEffect(() => {
        imageAspectRatioUpdate(uri).then((aspectRatio) => {
            setCalculatedAspectRatio(aspectRatio);
        });
    }, [uri]);

    useEffect(() => {
        if (uri && calculatedAspectRatio && (currentAspectRatio.toFixed(3) !== calculatedAspectRatio?.toFixed(3))) {
            displayAspectRatioError(true);

            dispatch(setSceneAspectRatioAction(
                sceneType,
                calculatedAspectRatio.toString(),
            ));
        } else {
            displayAspectRatioError(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [calculatedAspectRatio, currentAspectRatio]);

    return (
        <>
            {aspectRatioError && !editing && (
                <Tooltip
                    TransitionComponent={Zoom}
                    title={(
                        <Typography variant="body1">
                            {`${warningMessage}${warningFix}`}
                        </Typography>
                    )}
                >
                    <IconButton disabled={editing} onClick={onFix}>
                        <WarningIcon color="error" />
                    </IconButton>
                </Tooltip>
            )}
            {aspectRatioError && editing && (
                <Tooltip
                    TransitionComponent={Zoom}
                    title={(
                        <Typography variant="body1">
                            {`${warningMessage}${warningFixEdit}`}
                        </Typography>
                    )}
                >
                    <WarningIcon color="error" />
                </Tooltip>
            )}
        </>
    );
};

export default AspectRatioCheck;
