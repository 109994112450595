import byId from 'src/utils/byId';
import { CONTENT_FILTERS_LIST, FILTER_TYPE } from 'src/constants';

function getFilters(
    attributeFilters: Services.Filters.GalleryFilter[],
    taxonomyFilters: Services.Filters.GalleryTaxonomy[],
    globalFilters: Services.Filters.GlobalFilter[],
    taxonomies: Services.Filters.Taxonomy[],
): App.Filter[] {
    let allFilters: App.Filter[] = [];

    allFilters = attributeFilters.reduce(
        (memo, attributeFilter) => {
            const filterType = CONTENT_FILTERS_LIST.includes(attributeFilter.id)
                ? FILTER_TYPE.global
                : FILTER_TYPE.private;
            const globalFilter = globalFilters.find((g) => g.id === attributeFilter.id);

            if (!globalFilter) {
                throw Error(`Could not find globalFilter for attribute filter ${attributeFilter.id}`);
            }

            memo.push({
                globalFilter,
                id: attributeFilter.id,
                type: filterType,
                filter: attributeFilter,
            });

            return memo;
        },
        allFilters,
    );

    allFilters = taxonomyFilters.reduce(
        (memo, taxonomyFilter) => {
            let globalFilter = taxonomies.find((taxonomy) => taxonomy.id === taxonomyFilter.id);

            if (!globalFilter) {
                globalFilter = {
                    id: taxonomyFilter.id,
                    name: 'No Taxonomy Found',
                    category: {
                        categoryKey: -1,
                        children: [],
                        name: 'No Taxonomy Found',
                        localizedTitle: {
                            'en-ie': 'No Taxonomy Found',
                        },
                    } as Services.Filters.Category,
                } as Services.Filters.Taxonomy;
            }

            memo.push({
                globalFilter,
                id: taxonomyFilter.id,
                type: FILTER_TYPE.taxonomy,
                filter: taxonomyFilter,
            });

            return memo;
        },
        allFilters,
    );

    return allFilters;
}

export default function mergeGalleryFilters(
    galleryFilters: Services.Filters.GalleryFilter[],
    galleryTaxonomies: Services.Filters.GalleryTaxonomy[],
    globalFilters: Services.Filters.GlobalFilter[],
    taxonomies: Services.Filters.Taxonomy[],
): App.FiltersById {
    const allFilters = getFilters(galleryFilters, galleryTaxonomies, globalFilters, taxonomies);
    const allFiltersById = byId(allFilters);

    allFiltersById.allIds = allFiltersById.allIds.sort((a, b) => {
        const filterA = allFiltersById.byId.get(a);
        const filterB = allFiltersById.byId.get(b);

        if (!filterA || !filterB) {
            return 0;
        }

        return filterA.filter.sortOrder - filterB.filter.sortOrder;
    });

    return allFiltersById;
}
