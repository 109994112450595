/* eslint-disable max-len */
import config from 'src/lib/config';
import AbstractService from 'src/services/AbstractService';
import { getLogger } from 'src/utils/logger';
import { ServiceError } from 'src/lib/errors';
import byId from 'src/utils/byId';

type OptionTranslationsByKey = Record<string, {
    optionKey: string,
    translations: Services.Translations.TranslationByCulture[],
}>

type TranslationsByOptionValue = Record<string, {
    optionNameKey: string,
    optionNameTranslations: Services.Translations.TranslationByCulture[],
    optionValues: OptionTranslationsByKey
}>;

class MsxService extends AbstractService {
    constructor() {
        super(config.services.msx);
    }

    public async getMsxAttributes(
        productKey: string,
        productVersion: number,
        cultures: string[],
    ): Promise<Services.Msx.MsxOptionsByName> {
        const url = `/api/v2/tenant/vistaprint/productKey/${productKey}/version/${productVersion}/experienceandordering/attributesandvalues?`;
        const urlWithCultures = cultures.reduce((result, current) => `${result}cultures=${current}&`, url);

        try {
            const response = await this.api.get<Services.Msx.MsxAttributesByCulture>(urlWithCultures, {
                params: {
                    requestor: config.appName,
                },
            });

            return this.projectToMsxOptionsByName(response.data);
        } catch (e: any) {
            getLogger().error(e);
            throw new ServiceError(e);
        }
    }

    projectToMsxOptionsByName(
        msxAttributesByCulture: Services.Msx.MsxAttributesByCulture,
    ): Services.Msx.MsxOptionsByName {
        const translationsByOptionName = Object.keys(msxAttributesByCulture).reduce((accum, culture) => {
            const productOptionsForCulture = msxAttributesByCulture[culture].list;

            for (const productOption of productOptionsForCulture) {
                if (!accum[productOption.key.key]) {
                    const productOptionValues = productOption.values.reduce((optionValuesAccum, optionValue) => {
                        // eslint-disable-next-line no-param-reassign
                        optionValuesAccum[optionValue.key] = {
                            optionKey: optionValue.key,
                            translations: [{ culture, value: optionValue.merchandisingName }],
                        };

                        return optionValuesAccum;
                    }, {} as OptionTranslationsByKey);

                    // eslint-disable-next-line no-param-reassign
                    accum[productOption.key.key] = {
                        optionNameKey: productOption.key.key,
                        optionNameTranslations: [{ culture, value: productOption.key.merchandisingName }],
                        optionValues: productOptionValues,
                    };
                } else {
                    accum[productOption.key.key].optionNameTranslations.push({
                        culture,
                        value: productOption.key.merchandisingName,
                    });

                    for (const optionValue of productOption.values) {
                        accum[productOption.key.key].optionValues[optionValue.key].translations.push({
                            culture,
                            value: optionValue.merchandisingName,
                        });
                    }
                }
            }

            return accum;
        }, {} as TranslationsByOptionValue);

        return Object.values(translationsByOptionName).reduce((msxOptionsByOptionName, productOption) => {
            const optionNameTranslations = byId<Services.Translations.TranslationByCulture>(productOption.optionNameTranslations, 'culture');

            optionNameTranslations.allIds = optionNameTranslations.allIds.sort();

            const msxOptionsWithTranslations: Services.Msx.MsxOptionsWithTranslations = {
                optionName: {
                    value: productOption.optionNameKey,
                    translations: optionNameTranslations,
                },
                optionValues: Object.values(productOption.optionValues).reduce((msxOptionByValue, productOptionValue) => {
                    const optionValueTranslations = byId<Services.Translations.TranslationByCulture>(productOptionValue.translations, 'culture');

                    optionValueTranslations.allIds = optionNameTranslations.allIds.sort();

                    // eslint-disable-next-line no-param-reassign
                    msxOptionByValue[productOptionValue.optionKey] = {
                        value: productOptionValue.optionKey,
                        translations: optionValueTranslations,
                    };
                    return msxOptionByValue;
                }, {} as Services.Msx.MsxOptionsByValue),
            };

            // eslint-disable-next-line no-param-reassign
            msxOptionsByOptionName[productOption.optionNameKey] = msxOptionsWithTranslations;

            return msxOptionsByOptionName;
        }, {} as Services.Msx.MsxOptionsByName);
    }
}

export default new MsxService();
