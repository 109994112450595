import config from 'src/lib/config';
import { FILTER_TYPE } from 'src/constants';

function filterTypeEnumToString(type: FILTER_TYPE): string {
    switch (type) {
        case FILTER_TYPE.taxonomy:
            return 'taxonomy';
        case FILTER_TYPE.global:
        case FILTER_TYPE.private:
            return 'attribute';
        default:
            return 'unkown';
    }
}

function getTaxonomyData(filter: App.TaxonomyFilter): App.FilterData {
    return {
        sortOrder: filter.filter.sortOrder,
        type: filterTypeEnumToString(filter.type),
        name: filter.globalFilter.name,
        collapsed: filter.filter.collapsed,
        title: filter.globalFilter.category.localizedTitle[config.defaultCulture],
        detailsUrl: `/taxonomy/${filter.id}`,
        quickViewFilter: null,
    };
}

function getAttributeData(filter: App.AttributeFilter): App.FilterData {
    return {
        sortOrder: filter.filter.sortOrder,
        type: filterTypeEnumToString(filter.type),
        name: filter.globalFilter.name,
        collapsed: filter.filter.isCollapsed,
        title: filter.globalFilter.title,
        detailsUrl: filter.type === FILTER_TYPE.private ? `/attribute/${filter.id}` : undefined,
        quickViewFilter: filter.filter.quickViewFilter,
    };
}

export function getFilterData(filter: App.Filter): App.FilterData {
    if (filter.type === FILTER_TYPE.taxonomy) {
        return getTaxonomyData(filter as App.TaxonomyFilter);
    }

    return getAttributeData(filter as App.AttributeFilter);
}
