import { useContext, useEffect, useState } from 'react';

import globalFiltersService from 'src/services/GlobalFiltersService';
import taxonomyService from 'src/services/TaxonomyService';
import renderPropertiesService from 'src/services/RenderPropertiesService';
import byId from 'src/utils/byId';
import { AppError } from 'src/lib/errors';
import { IdentityContext } from 'src/contexts/IdentityContext';

const contentFiltersList = [
    'AllColors', 'logoHasPhoto', 'PhotoNumber',
];

/* const globalFiltersList = [
    'AllColors',
    'logoHasPhoto',
    'orientation',
    'HCFold',
    'BusinessCardCorners',
    'PostcardShape',
    'BusinessCardSize',
    'PhotoNumber',
]; */

type GlobalGalleryValueTuple = [
    App.GlobalGalleryData | null,
    AppError | null,
];

async function getContentFilters(): Promise<Services.Filters.GlobalFilter[]> {
    return Promise.all(contentFiltersList.map((f) => globalFiltersService.getGlobalFilter(f)));
}

export const fetchGlobalGalleryData = async (accessToken: string): Promise<App.GlobalGalleryData> => {
    const taxonomiesTask = taxonomyService.getTaxonomies(accessToken);
    const contentFiltersTask = getContentFilters();
    const defaultRenderPropertiesTask = renderPropertiesService.getDefaultRenderProperties(accessToken);

    const [
        taxonomies,
        contentFilters,
        defaultRenderProperties,
    ] = await Promise.all([taxonomiesTask, contentFiltersTask, defaultRenderPropertiesTask]);

    const allTaxonomiesById = byId<Services.Filters.Taxonomy>(taxonomies);
    const allContentFiltersById = byId<Services.Filters.GlobalFilter>(contentFilters);

    return {
        allTaxonomiesById,
        allContentFiltersById,
        defaultRenderProperties,
    };
};

const useGlobalGalleryValues = (): GlobalGalleryValueTuple => {
    const [sharedGalleryData, setSharedGalleryContext] = useState<App.GlobalGalleryData | null>(null);
    const [error, setError] = useState<AppError | null>(null);
    const { accessToken } = useContext(IdentityContext);

    useEffect(() => {
        const runEffect = async (): Promise<void> => {
            if (accessToken) {
                try {
                    setSharedGalleryContext(await fetchGlobalGalleryData(accessToken));
                } catch (e: any) {
                    setError(new AppError(e));
                }
            }
        };

        runEffect();
        // eslint-disable-next-line
    }, [accessToken]);

    return [sharedGalleryData, error];
};

export default useGlobalGalleryValues;
