import AbstractService from 'src/services/AbstractService';
import config from 'src/lib/config';
import { ServiceError } from 'src/lib/errors';
import { logServiceError } from 'src/utils/logger';

/**
 *
 */
class GalleryValidationService extends AbstractService {
    constructor() {
        super(config.services.config);
    }

    /**
     * Gets gallery validation result for given a gallery id
     *
     * @param id
     * @throws ServiceError
     */
    public async getGalleryValidations(id: number): Promise<Services.GalleryValidation.ValidationResults> {
        const requestUrl = `api/v2/Galleries/${id}/Validation`;

        try {
            const response = await this.api.get<Services.GalleryValidation.ValidationResults>(requestUrl, {
                params: {
                    requestor: config.appName,
                    noCache: true,
                },
            });

            return response.data;
        } catch (e: any) {
            const error = new ServiceError(e);

            logServiceError(error);
            throw error;
        }
    }
}

export default new GalleryValidationService();
