import classnames from 'classnames';

import {
    Button,
    ButtonProps,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

export type ErrorButtonProps = ButtonProps;

const useStyles = makeStyles()((theme: Theme) => ({
    error: {
        backgroundColor: theme.palette.error.main,
        '&:hover': {
            backgroundColor: theme.palette.error.dark,
            boxShadow: 'none',
        },
        color: theme.palette.common.white,
    },
}));

const ErrorButton = (props: ButtonProps): JSX.Element => {
    const {
        children,
        className,
        ...rest
    } = props;
    const { classes } = useStyles();

    return (
        <Button
            variant="contained"
            className={classnames(classes.error, className)}
            {...rest}
        >
            {children}
        </Button>
    );
};

export default ErrorButton;
