import { scenesReducer } from 'src/store/sceneData/scenes';
import { productOptionsReducer } from 'src/store/sceneData/productOptions';
import { descriptionReducer } from 'src/store/sceneData/description';
import { INITIALIZE_SCENE_DATA } from 'src/store/sceneData/constants';

export const initializeSceneDataAction = (
    sceneData: App.SceneData,
): Actions.SceneDataAction => ({
    type: INITIALIZE_SCENE_DATA,
    payload: {
        sceneData,
    },
});

export const reducer = (state: App.SceneData | null, action: Actions.Action): App.SceneData | null => {
    switch (action.type) {
        case (INITIALIZE_SCENE_DATA):
            return (action as Actions.SceneDataAction).payload.sceneData;
        default:
            if (!state) {
                return state;
            }
            return {
                ...state,
                sceneDefinition: {
                    ...state.sceneDefinition,
                    description: descriptionReducer(state.sceneDefinition.description, action),
                    scenes: scenesReducer(state.sceneDefinition.scenes, action),
                    productOptions: productOptionsReducer(state.sceneDefinition.productOptions, action),
                },
            };
    }
};
