import axios, { Canceler } from 'axios';
import { useEffect, useState } from 'react';

import config from 'src/lib/config';
import { getPreviewUrlRoot } from 'src/components/ManageScene/getPreviewUrl';

const useScenePreviewUrlRoot = (
    galleryName: string | null,
    productOptions: Services.Scenes.ProductOption[],
): [string] => {
    const [scenePreviewUrlRoot, setScenePreviewUrlRoot] = useState<string>(config.defaultScenePreview);

    useEffect(() => {
        let active = true;
        let cancel: Canceler | undefined;

        const runEffect = async (): Promise<void> => {
            if (galleryName) {
                const newPreviewUrlRoot = await getPreviewUrlRoot(
                    galleryName,
                    productOptions,
                    new axios.CancelToken((c) => { cancel = c; }),
                );

                if (active && newPreviewUrlRoot) {
                    setScenePreviewUrlRoot(newPreviewUrlRoot);
                }
            }
        };

        runEffect();

        return (): void => {
            active = false;

            if (cancel) {
                cancel();
            }
        };
    }, [productOptions, galleryName]);

    return [scenePreviewUrlRoot];
};

export default useScenePreviewUrlRoot;
