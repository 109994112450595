import { useEffect, JSX } from 'react';
import { CssBaseline, GlobalStyles } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { broadcastQueryClient } from 'react-query/broadcastQueryClient-experimental';

import config from 'src/lib/config';
import ErrorBoundary from 'src/components/ErrorBoundary';
import getTheme from 'src/lib/theme';
import NavDrawer from 'src/components/NavDrawer';
import Routes from 'src/Routes';
import { IdentityProvider } from 'src/contexts/IdentityContext';
import { EditContextProvider } from 'src/contexts/EditContext';
import { NavDrawerContextProvider } from 'src/contexts/NavDrawerContext';
import { AttributePermutationJobsContextProvider } from 'src/contexts/AttributePermutationJobsContext';
import { initialize as rollbarInit } from 'src/utils/logger';
import { GLOBAL_STYLES } from './constants';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            suspense: true,
        },
    },
});

broadcastQueryClient({
    queryClient,
    broadcastChannel: `gallery-management-tool-${config.DEPLOYMENT}`,
});

const App = (): JSX.Element => {
    useEffect(() => {
        rollbarInit(config.rollbarAccessToken);
    }, []);

    return (
        <QueryClientProvider client={queryClient}>
            <ErrorBoundary>
                <IdentityProvider>
                    <ThemeProvider theme={getTheme()}>
                        <CssBaseline />
                        <GlobalStyles styles={GLOBAL_STYLES} />
                        <BrowserRouter>
                            <NavDrawerContextProvider>
                                <EditContextProvider>
                                    <AttributePermutationJobsContextProvider>
                                        <NavDrawer />
                                        <Routes />
                                    </AttributePermutationJobsContextProvider>
                                </EditContextProvider>
                            </NavDrawerContextProvider>
                        </BrowserRouter>
                    </ThemeProvider>
                </IdentityProvider>
            </ErrorBoundary>
        </QueryClientProvider>
    );
};

export default App;
