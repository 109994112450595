import {
    Grid,
    Chip,
    PaperProps,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

import LinkedGalleryIdList from 'src/components/ManageTranslations/LinkedGalleryIdList';
import PurposeSelect from 'src/components/common/inputs/PurposeSelect';
import ReadOnlyField from 'src/components/common/inputs/ReadOnlyField';
import TextField from 'src/components/common/inputs/TextField';
import useCompatibleBreakpoint from 'src/hooks/useCompatibleBreakpoint';
import Card, { CardContent, CardHeader } from 'src/components/common/Card';
import { TRANSLATION_PURPOSE, VALID_TRANSLATION_PURPOSES_FOR_SAVING } from 'src/constants';

interface PropTypes extends PaperProps {
    editing: boolean;
    foundIn: Services.Translations.V2.FoundIn;
    onPurposeChange: (newPurpose: TRANSLATION_PURPOSE) => void;
    translationGroup: Services.Translations.V2.TranslationGroup;
}

function getPopulatedFoundInAttribute(foundIn: Services.Translations.V2.FoundIn): number[] {
    if (foundIn.filterOptionTitle?.length) {
        return foundIn.filterOptionTitle;
    }

    if (foundIn.filterTitle?.length) {
        return foundIn.filterTitle;
    }

    if (foundIn.h1Title?.length) {
        return foundIn.h1Title;
    }

    return [];
}

const useStyles = makeStyles()((theme: Theme) => ({
    content: {
        paddingBottom: theme.spacing(4),
    },
    scrollContainer: {
        maxHeight: theme.spacing(15),
        overflowX: 'auto',
    },
}));

const ManageTranslationGroup = (props: PropTypes): JSX.Element => {
    const {
        editing,
        foundIn,
        onPurposeChange,
        translationGroup,
        ...rest
    } = props;
    const { classes } = useStyles();
    const galleryIds = getPopulatedFoundInAttribute(foundIn);
    const enablePurpose = (editing && !galleryIds);
    const splitScreenProp = { [useCompatibleBreakpoint('md')]: 4 };

    return (
        <Card {...rest}>
            <CardHeader>
                Translation Key Settings
            </CardHeader>
            <CardContent className={classes.content}>
                <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={12} {...splitScreenProp}>
                        <TextField
                            fullWidth
                            label="Key"
                            value={translationGroup.key}
                        />
                    </Grid>
                    <Grid item xs={12} {...splitScreenProp}>
                        <PurposeSelect
                            fullWidth
                            required
                            id="purpose-select"
                            editing={enablePurpose}
                            currentPurpose={translationGroup.purpose}
                            label="Purpose"
                            options={VALID_TRANSLATION_PURPOSES_FOR_SAVING}
                            onChange={onPurposeChange}
                        />
                    </Grid>
                    <Grid item xs={12} {...splitScreenProp}>
                        <ReadOnlyField
                            label="Galleries using this Translation Key"
                            underlined={false}
                        >
                            <Grid container className={classes.scrollContainer} spacing={1}>
                                {!galleryIds.length && (
                                    <Grid item>
                                        <Chip label="None" />
                                    </Grid>
                                )}
                                {!!galleryIds.length && (<LinkedGalleryIdList galleryIds={galleryIds} />)}
                            </Grid>
                        </ReadOnlyField>

                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default ManageTranslationGroup;
