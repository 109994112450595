import TextField from 'src/components/common/inputs/TextField';
import Select from 'src/components/common/inputs/Select';
import { AppError } from 'src/lib/errors';
import { SelectChangeEvent } from '@mui/material';

interface PropTypes {
    editing: boolean;
    strategyType: string;
    currentStrategy: string | null;
    setStrategy: (strategyName: string) => void;
    strategies: string[] | undefined;
    loading: boolean;
    error: AppError | null;
}

const ManageRankingStrategy = (props: PropTypes): JSX.Element => {
    const {
        editing,
        strategyType,
        currentStrategy,
        setStrategy,
        strategies,
        loading,
        error,
    } = props;

    const handleStrategyChange = (event: SelectChangeEvent<unknown>): void => {
        event.preventDefault();

        setStrategy(event.target.value as string);
    };

    return (
        <>
            {editing && strategies && (
                <Select
                    editing
                    value={currentStrategy}
                    label={`${strategyType} Ranking Strategy`}
                    onChange={handleStrategyChange}
                >
                    {strategies?.map((strategy) => (
                        <option value={strategy} key={strategy}>{strategy}</option>
                    ))}
                </Select>
            )}
            {(!editing || loading || error) && (
                <TextField
                    fullWidth
                    id={`${strategyType}-ranking-strategy-input`}
                    value={currentStrategy}
                    label={`${strategyType} Ranking Strategy`}
                />
            )}
        </>
    );
};

export default ManageRankingStrategy;
