import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

type ReducerCallback<T> = (search: string, initialState: T) => T;

const useSavedSearch = <T>(initialState: T, reducer: ReducerCallback<T>): T => {
    const location = useLocation();

    return useMemo(() => reducer(location.search, initialState), [location, initialState, reducer]);
};

export default useSavedSearch;
