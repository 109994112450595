import { Button, ButtonProps } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CancelButtonPropTypes extends ButtonProps {}

const CancelButton = (props: CancelButtonPropTypes): JSX.Element => {
    const {
        onClick,
        className,
        ...rest
    } = props;

    return (
        <Button
            {...rest}
            variant="contained"
            type="submit"
            startIcon={<CancelIcon />}
            className={className}
            onClick={onClick}
        >
            Cancel
        </Button>
    );
};

export default CancelButton;
