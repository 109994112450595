/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable react/no-unstable-nested-components */
import { useContext } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Grid } from '@mui/material';

import AppBar from 'src/components/common/AppBar';
import Content from 'src/components/Content';
import EditActions from 'src/components/common/AppBar/EditActions';
import GallerySharedValuesContext from 'src/contexts/GalleryGlobalValueContext';
import Main from 'src/components/Main';
import ManageGallery from 'src/components/ManageGallery';
import ManageGalleryContext from 'src/contexts/manageGalleryContext';
import ManageGalleryController from 'src/components/ManageGallery/ManageGalleryController';
import PreviewGalleryButton from 'src/components/ManageGallery/PreviewGalleryButton';
import Splash from 'src/components/common/Splash';
import useManagableGallery from 'src/hooks/useManageableGallery';
import useGlobalGalleryValues from 'src/hooks/useGlobalGalleryValues';
import { IdentityContext } from 'src/contexts/IdentityContext';
import { EditContext } from 'src/contexts/EditContext';
import { ContainsSecurityBoundary } from 'src/utils/securityBoundary';
import DisabledEditButton from 'src/components/common/inputs/DisabledEditButton';
import DisabledSaveButton from 'src/components/common/inputs/DisabledSaveButton';
import DeleteButton from 'src/components/common/inputs/DeleteButton';
import ManageGalleryStatus from 'src/components/ManageGallery/GalleryDetails/ManageGalleryStatus';
import { GALLERY_STATUS } from 'src/constants';
import { updateGallery } from 'src/store/galleryData/galleryStore';

const DISABLED_MESSAGE = 'You do not have permission to edit this gallery';

function getGalleryName(galleryData: App.GalleryData | null | undefined): string {
    let galleryName = 'Gallery: ';
    const names = galleryData?.gallery.galleryNames;

    if (names) {
        const irelandName = names.find((name) => name.culture === 'en-ie');

        galleryName += (irelandName) ? irelandName.name : names[0].name;
    } else {
        galleryName += 'Loading...';
    }

    return galleryName;
}

const ManageGalleryPage = (): JSX.Element => {
    const { params: { galleryId } } = useRouteMatch<{ galleryId: string }>();
    const [state, error] = useManagableGallery(galleryId);
    const [globalGalleryData, globalError] = useGlobalGalleryValues();
    const { securityBoundaries } = useContext(IdentityContext);
    const { editing } = useContext(EditContext);

    if (error || globalError) {
        // TODO better error handling?
        throw error;
    }

    const handleStatusChange = (): void => {
        if (state?.galleryData && editing) {
            const { status } = state.galleryData.gallery;
            let newStatus = GALLERY_STATUS.ACTIVE;

            if (status === GALLERY_STATUS.ACTIVE) {
                newStatus = GALLERY_STATUS.INACTIVE;
            }

            state.dispatch(updateGallery({
                ...state.galleryData.gallery,
                status: newStatus,
            }));
        }
    };

    const securityBoundary = state?.galleryData?.gallery.securityBoundary;
    const disableEdit = !ContainsSecurityBoundary(securityBoundaries, securityBoundary);

    return (
        <Main
            AppBar={(
                <AppBar
                    actions={(
                        <EditActions
                            DeleteButton={DeleteButton}
                            EditButton={disableEdit && (
                                (editButtonProps): JSX.Element => (
                                    <DisabledEditButton
                                        tooltipProps={({
                                            title: DISABLED_MESSAGE,
                                        })}
                                        {...editButtonProps}
                                    />
                                )
                            )}
                            SaveButton={disableEdit && (
                                (editButtonProps): JSX.Element => (
                                    <DisabledSaveButton
                                        tooltipProps={({
                                            title: DISABLED_MESSAGE,
                                        })}
                                        {...editButtonProps}
                                    />
                                )
                            )}
                        />
                    )}
                    title={getGalleryName(state?.galleryData)}
                >
                    {(state?.galleryData?.gallery) && (
                        <Grid container spacing={1} alignItems="center">
                            <Grid item>
                                <PreviewGalleryButton gallery={state.galleryData.gallery} />
                            </Grid>
                            <Grid item>
                                <ManageGalleryStatus
                                    editing={editing}
                                    status={state.galleryData.gallery.status}
                                    handleStatusChange={handleStatusChange}
                                />
                            </Grid>
                        </Grid>
                    )}
                </AppBar>
            )}
        >
            <Content>
                {(!state || !globalGalleryData) && (<Splash />)}
                {(state && globalGalleryData) && (
                    <ManageGalleryContext.Provider value={state}>
                        <GallerySharedValuesContext.Provider value={{ globalGalleryData }}>
                            <ManageGalleryController>
                                <ManageGallery />
                            </ManageGalleryController>
                        </GallerySharedValuesContext.Provider>
                    </ManageGalleryContext.Provider>
                )}
            </Content>
        </Main>
    );
};

export default ManageGalleryPage;
