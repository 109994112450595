import { HTMLProps } from 'react';
import {
    Grid,
    Typography,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

import { AppError } from 'src/lib/errors';

export interface PropTypes extends HTMLProps<HTMLDivElement> {
    error: AppError;
}

const useStyles = makeStyles()((theme: Theme) => ({
    errorPage: {
        margin: 0,
        paddingTop: theme.spacing(4),
        width: '100%',
    },
    message: {
        whiteSpace: 'normal',
    },
}));

const ErrorPage = (props: PropTypes): JSX.Element => {
    const { error } = props;
    const { classes } = useStyles();

    return (
        <Grid
            container
            alignContent="center"
            className={classes.errorPage}
            direction="column"
            spacing={10}
        >
            <Grid item xs>
                <Typography gutterBottom align="center" className="header" variant="h2">
                    Whoops!
                </Typography>
                <Typography gutterBottom align="center" className="subheader" variant="h3">
                    There&apos;s a slight problem on this page.
                </Typography>
            </Grid>
            <Grid item>
                <Typography
                    paragraph
                    align="center"
                    variant="body1"
                >
                    {error.guid}
                </Typography>
                <Typography
                    align="center"
                    className={classes.message}
                    component="pre"
                    variant="body1"
                >
                    {error.message}
                </Typography>
            </Grid>
        </Grid>
    );
};

export default ErrorPage;
