import { useContext, useState, MouseEvent } from 'react';
import {
    Grid, Card, CardHeader, Box, Button,
} from '@mui/material';

import ManageSceneTypeCard from 'src/components/ManageScene/ManageSceneTypeCard';
import ManageSceneContext from 'src/contexts/ManageSceneContext';
import NewSceneTypeCard from 'src/components/ManageScene/NewSceneTypeCard';
import { EditContext } from 'src/contexts/EditContext';
import { Add } from '@mui/icons-material';
import { addSceneTypeAction } from 'src/store/sceneData/scenes';
import { SCENE_TYPES } from 'src/constants';

const ManageSceneTypes = (): JSX.Element => {
    const { dispatch, sceneData: { sceneDefinition, galleryName } } = useContext(ManageSceneContext);
    const { editing } = useContext(EditContext);
    const [newScenes, setNewScenes] = useState<number[]>([]);
    // Only allow the creation of type gallery for now
    const hasAvailableSceneTypes = !Object.keys(sceneDefinition.scenes).includes(SCENE_TYPES.GALLERY);

    const createNewSceneType = (event: MouseEvent<{}>): void => {
        event.preventDefault();

        const id = (newScenes[newScenes.length - 1] || -1) + 1;

        setNewScenes([
            id,
            ...newScenes,
        ]);
    };

    const deleteNewSceneType = (id: number) => (): void => {
        const index = newScenes.findIndex((item) => (item === id));

        const updatedNewScenes = newScenes.slice();

        updatedNewScenes.splice(index, 1);

        setNewScenes(updatedNewScenes);
    };

    const saveSceneType = (sceneType: App.SceneType): void => (
        dispatch(addSceneTypeAction(
            sceneType.type,
            sceneType.uri,
        ))
    );

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Card>
                    <CardHeader
                        action={(editing && hasAvailableSceneTypes) && (
                            <Button
                                color="primary"
                                startIcon={(<Add />)}
                                variant="contained"
                                onClick={createNewSceneType}
                            >
                                Add Scene Type
                            </Button>
                        )}
                        title={(<Box fontWeight="bold">Scene Types</Box>)}
                        titleTypographyProps={{
                            variant: 'subtitle1',
                        }}
                    />
                </Card>
            </Grid>
            {editing && (
                newScenes.map((id) => (
                    <Grid item xs={12}>
                        <NewSceneTypeCard
                            key={id}
                            galleryName={galleryName}
                            productOptions={sceneDefinition.productOptions}
                            onDelete={deleteNewSceneType(id)}
                            onDone={saveSceneType}
                        />
                    </Grid>
                ))
            )}
            {sceneDefinition && Object.entries(sceneDefinition.scenes).map(([sceneType, sceneUri]) => (
                <Grid item key={sceneType} xs={12}>
                    <ManageSceneTypeCard
                        dispatch={dispatch}
                        editing={editing}
                        sceneType={sceneType}
                        sceneUri={sceneUri}
                        galleryName={galleryName}
                        productOptions={sceneDefinition.productOptions}
                    />
                </Grid>
            ))}
        </Grid>
    );
};

export default ManageSceneTypes;
