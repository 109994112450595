import { ChangeEvent } from 'react';
import {
    TableRow,
    TableCell,
} from '@mui/material';

import CultureSelect from 'src/components/common/inputs/CultureSelect';
import TextField from 'src/components/common/inputs/TextField';
import AddActionButtons, { AddActionButtonsProps } from 'src/components/common/AddToTable/AddActionButtons';
import { AddToTableRenderProps } from 'src/components/common/AddToTable';

interface PropTypes extends
    AddActionButtonsProps,
    Omit<AddToTableRenderProps<Services.Translations.V2.Translation>, 'row'>
{
    translation: Services.Translations.V2.Translation;
    index: number;
    updateRow: (index: number, newRow: Services.Translations.V2.Translation) => void;
    availableCultures: string[];
}

const AddTranslationRow = (props: PropTypes): JSX.Element => {
    const {
        translation,
        index,
        updateRow,
        availableCultures,
        onDone,
        onDelete,
    } = props;
    const { culture, value } = translation;

    const handleCultureChange = (newCulture: string): void => {
        const newTranslation = {
            ...translation,
            culture: newCulture,
        };

        updateRow(index, newTranslation);
    };

    const handleTranslationChange = (event: ChangeEvent<HTMLTextAreaElement>): void => {
        event.preventDefault();

        const newTranslation = {
            ...translation,
            value: event.target.value,
        };

        updateRow(index, newTranslation);
    };

    return (
        <TableRow>
            <TableCell>
                <CultureSelect
                    currentCulture={culture}
                    setCurrentCulture={handleCultureChange}
                    options={availableCultures}
                />
            </TableCell>
            <TableCell>
                <TextField
                    editing
                    fullWidth
                    underlined={false}
                    value={value}
                    onChange={handleTranslationChange}
                />
            </TableCell>
            <TableCell align="right">
                <AddActionButtons
                    doneButtonDisabled={!(!!culture && !!value)}
                    onDone={onDone}
                    onDelete={onDelete}
                />
            </TableCell>
        </TableRow>
    );
};

export default AddTranslationRow;
