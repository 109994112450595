import { HTMLProps } from 'react';
import {
    Button,
    Grid,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

export interface AddActionButtonsProps extends HTMLProps<{}> {
    onDelete: () => void;
    onDone: () => void;
    doneButtonDisabled?: boolean;
}

const useStyles = makeStyles()((theme: Theme) => ({
    cancelButton: {
        margingRight: theme.spacing(1),
    },
}));

const AddActionButtons = (props: AddActionButtonsProps): JSX.Element => {
    const {
        onDelete,
        onDone,
        doneButtonDisabled,
        className,
    } = props;
    const { classes } = useStyles();

    return (
        <Grid container direction="row" spacing={1} justifyContent="flex-end" className={className}>
            <Grid item>
                <Button
                    className={classes.cancelButton}
                    color="secondary"
                    variant="contained"
                    type="submit"
                    onClick={onDelete}
                >
                    Cancel
                </Button>
            </Grid>
            <Grid item>
                <Button
                    color="primary"
                    variant="contained"
                    disabled={doneButtonDisabled}
                    onClick={onDone}
                >
                    Done
                </Button>
            </Grid>
        </Grid>
    );
};

export default AddActionButtons;
