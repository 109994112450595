import { useReducer } from 'react';

export const UPDATE_ATTRIBUTE_PERMUTATION_JOB = 'UPDATE_ATTRIBUTE_PERMUTATION_JOB';
export const ADD_ATTRIBUTE_PERMUTATION_JOB = 'ADD_ATTRIBUTE_PERMUTATION_JOB';
export const REMOVE_ATTRIBUTE_PERMUTATION_JOB = 'REMOVE_ATTRIBUTE_PERMUTATION_JOB';

export const updateAttributePermutationJobAction = (
    job: AttributePermutations.AttributePermutationJob,
): AttributePermutations.UpdateAction => ({
    type: UPDATE_ATTRIBUTE_PERMUTATION_JOB,
    payload: {
        job,
    },
});

export const addAttributePermutationJobAction = (
    job: AttributePermutations.AttributePermutationJob,
): AttributePermutations.AddAction => ({
    type: ADD_ATTRIBUTE_PERMUTATION_JOB,
    payload: {
        job,
    },
});

export const removeAttributePermutationJobAction = (
    job: AttributePermutations.AttributePermutationJob,
): AttributePermutations.RemoveAction => ({
    type: REMOVE_ATTRIBUTE_PERMUTATION_JOB,
    payload: {
        job,
    },
});

const removeAttributePermutationJob = (
    jobs: AttributePermutations.AttributePermutationJob[],
    action: AttributePermutations.RemoveAction,
): AttributePermutations.AttributePermutationJob[] => {
    const { payload: { job } } = action;

    let newJobs = [...jobs];

    newJobs = newJobs.filter((newJob) => job.id !== newJob.id);
    return newJobs;
};

const addAttributePermutationJob = (
    jobs: AttributePermutations.AttributePermutationJob[],
    action: AttributePermutations.AddAction,
): AttributePermutations.AttributePermutationJob[] => [
    ...jobs,
    action.payload.job,
];

const updateAttributePermutationJob = (
    jobs: AttributePermutations.AttributePermutationJob[],
    action: AttributePermutations.UpdateAction,
): AttributePermutations.AttributePermutationJob[] => {
    const { payload: { job } } = action;
    const newJobs = [...jobs];

    // don't put the index in the action because setTimeout means the correct index may change by the time it gets here
    const index = newJobs.findIndex((newJob) => job.id === newJob.id);

    newJobs[index] = job;
    return newJobs;
};

const attributePermutationJobReducer = (
    jobs: AttributePermutations.AttributePermutationJob[],
    action: AttributePermutations.Action,
): AttributePermutations.AttributePermutationJob[] => {
    switch (action.type) {
        case (REMOVE_ATTRIBUTE_PERMUTATION_JOB):
            return removeAttributePermutationJob(jobs, action as AttributePermutations.RemoveAction);
        case (ADD_ATTRIBUTE_PERMUTATION_JOB):
            return addAttributePermutationJob(jobs, action as AttributePermutations.AddAction);
        case (UPDATE_ATTRIBUTE_PERMUTATION_JOB):
            return updateAttributePermutationJob(jobs, action as AttributePermutations.UpdateAction);
        default:
            return jobs;
    }
};

const useAttributePermutationJob = (): AttributePermutations.AttributePermutationJobsContext => {
    const [jobs, dispatchJobs] = useReducer(
        attributePermutationJobReducer,
        [] as AttributePermutations.AttributePermutationJob[],
    );

    return {
        jobs,
        dispatchJobs,
    };
};

export default useAttributePermutationJob;
