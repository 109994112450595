import { HTMLProps, useContext } from 'react';

import EditController from 'src/components/common/EditController';
import ManageSceneContext from 'src/contexts/ManageSceneContext';
import { fetchSceneData } from 'src/hooks/useSceneData';
import { initializeSceneDataAction } from 'src/store/sceneData';

const ManageSceneController = (props: HTMLProps<{}>): JSX.Element => {
    const { children } = props;
    const { dispatch, saveScene, sceneData: { sceneDefinition } } = useContext(ManageSceneContext);

    const resetScene = async (): Promise<void> => {
        const { id } = sceneDefinition;

        if (id) {
            const newSceneData = await fetchSceneData(id);

            dispatch(initializeSceneDataAction(newSceneData));
        }
    };

    const handleSave = async (): Promise<void> => {
        await saveScene(sceneDefinition);
        await resetScene();
    };

    const handleCancel = async (): Promise<void> => {
        await resetScene();
    };

    return (
        <EditController
            onCancel={handleCancel}
            onSave={handleSave}
        >
            {children}
        </EditController>
    );
};

export default ManageSceneController;
