import { useRouteMatch } from 'react-router-dom';

import AppBar from 'src/components/common/AppBar';
import Content from 'src/components/Content';
import EditActions from 'src/components/common/AppBar/EditActions';
import Main from 'src/components/Main';
import ManageTranslations from 'src/components/ManageTranslations';
import ManageTranslationsContext from 'src/contexts/ManageTranslationsContext';
import ManageTranslationsController from 'src/components/ManageTranslations/ManageTranslationController';
import Splash from 'src/components/common/Splash';
import useTranslationsData from 'src/hooks/useTranslationsData';
import ZeroState from 'src/components/common/ZeroState';

function getTranslationKey(translationsData: App.TranslationsData | null | undefined): string {
    return (`Translation Key: ${translationsData?.translationGroup ? translationsData?.translationGroup.key : 'Loading...'}`);
}

const ManageTranslationsPage = (): JSX.Element => {
    const { params: { translationGroupId } } = useRouteMatch<{ translationGroupId: string }>();
    const [state, loading, error] = useTranslationsData(parseInt(translationGroupId, 10));

    if (error) {
        // TODO better error handling?
        throw error;
    }

    return (
        <Main
            AppBar={(
                <AppBar
                    actions={<EditActions />}
                    title={getTranslationKey(state?.translationsData)}
                />
            )}
        >
            <Content>
                {loading && (<Splash />)}
                {(!loading && !state) && (
                    <ZeroState text="Could not load the translations for that translation group" />
                )}
                {(!loading && state) && (
                    <ManageTranslationsContext.Provider value={state}>
                        <ManageTranslationsController>
                            <ManageTranslations />
                        </ManageTranslationsController>
                    </ManageTranslationsContext.Provider>
                )}
            </Content>
        </Main>
    );
};

export default ManageTranslationsPage;
