import { useState, useContext } from 'react';
import { addProductFilter } from 'src/store/galleryData/filterStore';
import CreateFilter from 'src/components/ManageGallery/Filters/CreateFilter';
import ManageGalleryContext from 'src/contexts/manageGalleryContext';
import { FILTER_PROPERTY_INIT_TYPE, GLOBAL_FILTER_TYPES } from 'src/constants';

interface PropTypes {
    backTo: string;
}

const DESCRIPTION = `
Select the Product Option to associate with this new filter.
From this product option, you will be able to choose which option values are included in this filter.
`;

function createProductFiltersById(
    galleryId: number,
    productOptions: Services.Product.ProductOptions,
    allFiltersById: App.FiltersById,
): App.ProductFiltersById {
    return productOptions.list.reduce(
        (memo, productOption) => {
            const { name } = productOption;

            // We can't assume format of filter id, so look for an existing one first
            const existingFilter = allFiltersById.allIds.find((filterId) => {
                const filter = allFiltersById.byId.get(filterId) as App.AttributeFilter;
                const { globalFilter } = filter;

                if (!filter || !globalFilter.options) {
                    return false;
                }

                const { propertyInitialisationType, attributeType } = globalFilter;

                return propertyInitialisationType === FILTER_PROPERTY_INIT_TYPE.AUTO && attributeType === name;
            });

            const id = existingFilter || `${galleryId}_${name}`;

            if (!memo.byId.get(id)) {
                memo.allIds.push(id);
                memo.byId.set(id, {
                    id,
                    name,
                    title: '',
                    attribute: 0,
                    type: GLOBAL_FILTER_TYPES.Radio,
                    attributeType: name,
                    options: [],
                    propertyInitialisationType: FILTER_PROPERTY_INIT_TYPE.AUTO,
                    defaultPropertyValue: '',
                });
            }

            return memo;
        },
        { byId: new Map(), allIds: [] } as App.ProductFiltersById,
    );
}

const CreateProductFilter = (props: PropTypes): JSX.Element => {
    const { backTo } = props;
    const { galleryData } = useContext(ManageGalleryContext);
    const { coreProduct } = galleryData;
    const [filter, setFilter] = useState<string | undefined>(undefined);
    const { galleryData: { allFiltersById } } = useContext(ManageGalleryContext);

    const productFiltersById = coreProduct
        ? createProductFiltersById(
            galleryData.gallery.id,
            coreProduct.options,
            galleryData.allFiltersById,
        )
        : { byId: new Map(), allIds: [] } as App.ProductFiltersById;
    const productFilters = [...productFiltersById.byId.values()];

    const unAssociatedFilters = productFilters.filter((f) => !allFiltersById.byId.get(f.id));

    return (
        <CreateFilter
            backTo={backTo}
            redirectTo={`${backTo}/product/${filter}`}
            filter={filter}
            setFilter={setFilter}
            backBarTitle="Create Product Filter"
            filters={unAssociatedFilters}
            addFilterAction={addProductFilter}
            description={DESCRIPTION}
            SelectProps={{
                id: 'select-product-option',
                label: 'Select a Product Option',
            }}
        />
    );
};

export default CreateProductFilter;
