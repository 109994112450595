import classnames from 'classnames';

import Collapse, { CollapseProps } from '@mui/material/Collapse';
import { makeStyles } from 'tss-react/mui';

interface PropTypes extends CollapseProps{
    expanded: boolean;
}

const useStyles = makeStyles()({
    collapsedContent: {
        width: '100%',
    },
});

const Collapsable = (props: PropTypes): JSX.Element => {
    const {
        children,
        className,
        expanded,
        ...rest
    } = props;
    const { classes } = useStyles();

    return (
        <Collapse
            unmountOnExit
            className={classnames(classes.collapsedContent, className)}
            in={expanded}
            timeout="auto"
            {...rest}
        >
            {children}
        </Collapse>
    );
};

export default Collapsable;
