import { useEffect, useState } from 'react';

import translationGroupService from 'src/services/TranslationGroupService';
import { TRANSLATION_PURPOSE } from 'src/constants';
import { AppError } from 'src/lib/errors';

type useTranslationGroupsTuple = {
    translations: Services.Translations.V2.TranslationGroup[];
    translationsLoading: boolean;
    translationsError: AppError | null;
};

const useTranslationGroups = (purpose?: TRANSLATION_PURPOSE): useTranslationGroupsTuple => {
    const [translations, setTranslations] = useState<Services.Translations.V2.TranslationGroup[]>([]);
    const [translationsError, setTranslationsError] = useState<AppError | null>(null);
    const [translationsLoading, setTranslationsLoading] = useState<boolean>(false);

    useEffect(() => {
        // Don't try to update options if component is unmounted
        let active = true;

        const fetchData = async (): Promise<void> => {
            try {
                setTranslationsLoading(true);
                const translationGroups = await translationGroupService.getTranslationGroups({
                    purpose: purpose === TRANSLATION_PURPOSE.All ? undefined : purpose,
                });

                if (active) {
                    setTranslations(translationGroups);
                }
            } catch (e: any) {
                setTranslationsError(new AppError(e));
            } finally {
                setTranslationsLoading(false);
            }
        };

        fetchData();

        return (): void => {
            active = false;
        };
    }, [purpose]);

    return {
        translations,
        translationsLoading,
        translationsError,
    };
};

export default useTranslationGroups;
