import { HTMLProps, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import EditController from 'src/components/common/EditController';
import { IdentityContext } from 'src/contexts/IdentityContext';
import ManageGalleryContext from 'src/contexts/manageGalleryContext';
import { fetchGalleryData } from 'src/hooks/useManageableGallery';
import { initializeGalleryDataAction } from 'src/store/galleryData';
import { SEARCH_GALLERIES } from 'src/utils/routes';
import { GALLERY_STATUS } from 'src/constants';

const ManageGalleryController = (props: HTMLProps<{}>): JSX.Element => {
    const { children } = props;
    const history = useHistory();
    const { galleryData, dispatch, saveGallery } = useContext(ManageGalleryContext);
    const { accessToken } = useContext(IdentityContext);

    const resetGallery = async (): Promise<void> => {
        if (accessToken) {
            const newGalleryData = await fetchGalleryData(galleryData.gallery.id.toString(), 'en-ie', accessToken);

            dispatch(initializeGalleryDataAction(newGalleryData));
        }
    };

    const handleSave = async (): Promise<void> => {
        await saveGallery(galleryData);
        await resetGallery(); // Fetch the gallery from the API again to confirm the save succeeded
    };

    const handleCancel = async (): Promise<void> => {
        await resetGallery();
    };

    const handleDelete = async (): Promise<void> => {
        const newGalleryData = {
            ...galleryData,
            gallery: {
                ...galleryData.gallery,
                status: GALLERY_STATUS.DELETED,
            },
        };

        await saveGallery(newGalleryData);
    };

    const handleDeleted = (): void => {
        history.replace(SEARCH_GALLERIES);
    };

    return (
        <EditController
            deletePrompt="Are you sure you want to delete this gallery?"
            deleteDescription="This will prevent users from accessing this gallery. Only delete this gallery if there is already a replacement for it or if the product key is no longer in use. It will have significant implications across other applications and services."
            onCancel={handleCancel}
            onSave={handleSave}
            onDelete={handleDelete}
            onDeleted={handleDeleted}
        >
            {children}
        </EditController>
    );
};

export default ManageGalleryController;
