import { useContext } from 'react';

import Select, { SelectProps } from 'src/components/common/inputs/Select';
import ManageGalleryContext from 'src/contexts/manageGalleryContext';
import { RenderPropertiesSchema } from 'src/constants';
import { updateRenderProperty } from 'src/store/galleryData/renderPropertiesStore';
import {
    getRenderProperty,
    getRenderPropertyDisplay,
} from 'src/components/ManageGallery/GalleryRenderProperties/utils';
import GalleryGlobalValueContext from 'src/contexts/GalleryGlobalValueContext';
import { SelectChangeEvent } from '@mui/material';

interface PropTypes extends SelectProps {
    editing?: boolean;
    propKey: string;
    experienceType: string;
}

const RenderPropertySelect = (props: PropTypes): JSX.Element => {
    const {
        editing,
        propKey,
        experienceType,
        ...rest
    } = props;
    const { galleryData, dispatch } = useContext(ManageGalleryContext);
    const { renderProperties } = galleryData;
    const { globalGalleryData: { defaultRenderProperties } } = useContext(GalleryGlobalValueContext);

    const updateGalleryRenderPropValue = (key: string): ((event: SelectChangeEvent<unknown>) => void) => (
        event: SelectChangeEvent<unknown>,
    ): void => {
        const renderProperty = getRenderProperty(defaultRenderProperties, renderProperties, key, experienceType);
        const renderPropValues = RenderPropertiesSchema[key].values;

        if (renderProperty && renderPropValues) {
            const value = event.target.value as string;

            for (let i = 0; i < renderPropValues.length; i++) {
                if (renderPropValues?.[i].displayValue === value) {
                    renderProperty.value = renderPropValues[i].dbValue;
                }
            }

            dispatch(updateRenderProperty(
                renderProperty,
            ));
        }
    };

    const renderPropertyDisplay = getRenderPropertyDisplay(
        defaultRenderProperties,
        renderProperties,
        propKey,
        experienceType,
    );

    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
            {renderPropertyDisplay && (
                <Select
                    {...rest}
                    value={renderPropertyDisplay}
                    editing={editing}
                    helperText={editing ? RenderPropertiesSchema[propKey].helperText : undefined}
                    onChange={updateGalleryRenderPropValue(propKey)}
                >
                    {RenderPropertiesSchema[propKey].values?.map((value) => (
                        <option key={value.displayValue} value={value.displayValue}>
                            {value.displayValue}
                        </option>
                    ))}
                </Select>
            )}
        </>
    );
};

export default RenderPropertySelect;
