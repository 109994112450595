import AbstractService from 'src/services/AbstractService';
import config from 'src/lib/config';
import { ServiceError } from 'src/lib/errors';
import { logServiceError } from 'src/utils/logger';

/**
 * Gets Ranking Strategies
 */
class RankingStrategyService extends AbstractService {
    constructor() {
        super(config.services.contentStore);
    }

    public async getRankingStrategies(bearerToken: string, strategyType: string):
    Promise<Services.RankingStrategies.RankingStrategy[]> {
        const requestUrl = 'api/v3/rankingstrategies';

        try {
            const response = await this.api.get<Services.RankingStrategies.PagedResponse>(
                requestUrl,
                {
                    params: {
                        requestor: config.appName,
                        noCache: true,
                        type: strategyType,
                        limit: 1000,
                    },
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                },
            );

            return response.data.results;
        } catch (e: any) {
            const error = new ServiceError(e);

            logServiceError(error);
            throw error;
        }
    }
}

export default new RankingStrategyService();
