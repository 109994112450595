// Logout
export const LOGOUT = '/logout';

// Galleries
export const CREATE_GALLERIES = '/gallery/create';
export const MANAGE_GALLERIES = '/gallery/:galleryId';
export const SEARCH_GALLERIES = '/gallery';

// Scenes
export const CREATE_SCENE_DEFINITION = '/scenes/create';
export const MANAGE_SCENES = '/scenes/:sceneId';
export const SEARCH_SCENES = '/scenes';
export const SEARCH_SCENES_V2 = '/v2/scenes';

// Translations
export const CREATE_TRANSLATIONS = '/translations/create';
export const MANAGE_TRANSLATIONS = '/translations/:translationGroupId';
export const SEARCH_TRANSLATIONS = '/translations';

// Notification Channels
export const CREATE_NOTIFICATION_CHANNELS = '/notificationChannels/create';
export const MANAGE_NOTIFICATION_CHANNELS = '/notificationChannels/:notificationChannelId';
export const SEARCH_NOTIFICATION_CHANNELS = '/notificationChannels';
