import { Fragment } from 'react';
import {
    TableBody,
} from '@mui/material';

import Header from 'src/components/common/Header';
import {
    Table,
    TableRow,
    TableCell,
    TableHead,
    CardCell,
    CardRow,
} from 'src/components/common/CardTable';
import Checkbox from 'src/components/common/inputs/Checkbox';

interface PropTypes {
    handleNotificationChannelChange?: (label: string) => () => void;
    page: number;
    pageSize: number;
    notificationChannels: Services.NotificationChannels.NotificationChannel[];
}

const NotificationChannelTable = (props: PropTypes): JSX.Element => {
    const {
        handleNotificationChannelChange,
        page,
        pageSize,
        notificationChannels,
    } = props;

    return (
        <Table size="medium">
            <TableHead>
                <TableRow>
                    <TableCell>
                        <Header>Label</Header>
                    </TableCell>
                    <TableCell>
                        <Header>Channel Name</Header>
                    </TableCell>
                    <TableCell>
                        <Header>Security Boundary</Header>
                    </TableCell>
                    <TableCell>
                        <Header>Active</Header>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {notificationChannels.slice(page * pageSize, (page * pageSize) + pageSize).map((option) => (
                    <Fragment key={option.label}>
                        {!handleNotificationChannelChange && (
                            <CardRow hover to={`/notificationChannels/${option.id}`}>
                                <CardCell>
                                    {option.label}
                                </CardCell>
                                <CardCell>
                                    {option.channelName}
                                </CardCell>
                                <CardCell>
                                    {option.defaultFor}
                                </CardCell>
                                <Checkbox
                                    checked={option.active}
                                    name={option.label}
                                />
                            </CardRow>
                        )}
                        {handleNotificationChannelChange && (
                            <CardRow hover onClick={handleNotificationChannelChange(option.label)}>
                                <CardCell>
                                    {option.label}
                                </CardCell>
                                <CardCell>
                                    {option.channelName}
                                </CardCell>
                                <CardCell>
                                    {option.defaultFor}
                                </CardCell>
                                <Checkbox
                                    checked={option.active}
                                    name={option.label}
                                />
                            </CardRow>
                        )}
                    </Fragment>
                ))}
            </TableBody>
        </Table>
    );
};

export default NotificationChannelTable;
