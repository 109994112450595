import scenesService from 'src/services/ScenesService';

const saveScene = (bearerToken: string) => async (
    scene: Services.Scenes.SceneDefinition,
): Promise<Services.Scenes.SceneDefinition> => {
    const sceneClone = { ...scene };

    for (const [key, value] of Object.entries(sceneClone.scenes)) {
        const { newAspectRatio } = value;

        if (newAspectRatio) {
            sceneClone.scenes[key].aspectRatio = newAspectRatio;
        }
    }

    if (sceneClone.id) {
        await scenesService.putScene(sceneClone, sceneClone.id, bearerToken);
        return sceneClone;
    }

    return scenesService.postScene(sceneClone, bearerToken);
};

export default saveScene;
