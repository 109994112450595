import classnames from 'classnames';

import Typography, { TypographyProps } from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
    description: {
        maxWidth: theme.breakpoints.values.lg,
    },
}));

const Description = (props: TypographyProps): JSX.Element => {
    const { children, className, ...rest } = props;
    const { classes } = useStyles();

    return (
        <Typography
            paragraph
            className={classnames(classes.description, className)}
            variant="subtitle1"
            {...rest}
        >
            {children}
        </Typography>
    );
};

export default Description;
