import AbstractService from 'src/services/AbstractService';
import config from 'src/lib/config';
import { ServiceError } from 'src/lib/errors';
import { logServiceError } from 'src/utils/logger';

/**
 *
 */
class TranslationGroupService extends AbstractService {
    constructor() {
        super(config.services.config);
    }

    public async getFoundIn(
        translationGroupId: number,
        bearerToken: string,
    ): Promise<Services.Translations.V2.FoundIn> {
        const url = `api/v2/TranslationGroup/${translationGroupId}/foundIn`;

        try {
            const response = await this.api.get<Services.Translations.V2.FoundIn>(url, {
                params: {
                    requestor: config.appName,
                    noCache: true,
                },
                headers: {
                    Authorization: `Bearer ${bearerToken}`,
                },
            });

            return response.data;
        } catch (e: any) {
            const error = new ServiceError(e);

            logServiceError(error);
            throw error;
        }
    }

    public async getTranslationGroup(translationGroupId: number): Promise<Services.Translations.V2.TranslationGroup> {
        const url = `api/v2/TranslationGroup/${translationGroupId}`;

        try {
            const response = await this.api.get<Services.Translations.V2.TranslationGroup>(url, {
                params: {
                    requestor: config.appName,
                    noCache: true,
                },
            });

            return response.data;
        } catch (e: any) {
            const error = new ServiceError(e);

            logServiceError(error);
            throw error;
        }
    }

    /**
     * Retrieves the translation groups for the given purpose
     *
     * @param purpose
     */
    public async getTranslationGroups(
        translationGroupSearch: Services.Translations.V2.TranslationGroupSearch,
    ): Promise<Services.Translations.V2.TranslationGroup[]> {
        const url = 'api/v2/TranslationGroup';

        try {
            const response = await this.api.get<Services.Translations.V2.TranslationGroup[]>(url, {
                params: {
                    ...translationGroupSearch,
                    requestor: config.appName,
                    noCache: true,
                },
            });

            return response.data;
        } catch (e: any) {
            const error = new ServiceError(e);

            logServiceError(error);
            throw error;
        }
    }

    public async createTranslationGroup(
        translationGroup: Services.Translations.V2.CreateTranslationGroup,
        bearerToken: string,
    ): Promise<Services.Translations.V2.TranslationGroup> {
        const url = 'api/v2/TranslationGroup';

        try {
            const response = await this.api.post<Services.Translations.V2.TranslationGroup>(
                url,
                translationGroup,
                {
                    params: {
                        requestor: config.appName,
                        noCache: true,
                    },
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                },
            );

            return response.data;
        } catch (e: any) {
            const error = new ServiceError(e);

            logServiceError(error);
            throw error;
        }
    }

    public async updateTranslationGroup(
        bearerToken: string,
        translationGroup: Services.Translations.V2.TranslationGroup,
    ): Promise<void> {
        const url = `api/v2/TranslationGroup/${translationGroup.id}`;

        try {
            await this.api.put(
                url,
                { purpose: translationGroup.purpose },
                {
                    params: {
                        requestor: config.appName,
                    },
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                },
            );
        } catch (e: any) {
            const error = new ServiceError(e);

            logServiceError(error);
            throw error;
        }
    }
}

export default new TranslationGroupService();
