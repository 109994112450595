import { Dispatch, HTMLProps, useState } from 'react';

import AddFilterOptionRow from 'src/components/ManageGallery/Filters/ProductFilter/AddFilterOptionRow';
import AddToTable from 'src/components/common/AddToTable';
import { addAttributeFilterOption } from 'src/store/galleryData/filterStore';

interface PropTypes extends HTMLProps<{}> {
    filterId: string;
    dispatch: Dispatch<Actions.Action>;
    availableFilters: Services.Filters.GlobalFilterOption[];
}

const AddFilterOptions = (props: PropTypes): JSX.Element => {
    const { filterId, dispatch, availableFilters } = props;
    const [newOptions, setNewOptions] = useState([] as App.MappedFilterOption[]);

    const newProductOption = (): void => {
        const newOption = {
            productOptionValue: '',
            filterOption: {
                value: '',
                productOptionName: '',
                productOptionValue: '',
                title: '',
                isDefaultValue: false,
                otherProperties: {},
            },
        };

        setNewOptions([
            ...newOptions,
            newOption,
        ]);
    };

    const isRowIncomplete = (option: App.MappedFilterOption): boolean => !(
        option.productOptionValue
        && option.filterOption
        && option.filterOption.title
        && (option.filterOption.value
            || option.filterOption.productOptionName && option.filterOption.productOptionValue)
    );

    const saveFilter = (option: App.MappedFilterOption): void => {
        if (!isRowIncomplete(option)) {
            dispatch(addAttributeFilterOption(filterId, option.filterOption));
        }
    };

    return (
        <AddToTable
            addedRows={newOptions}
            setAddedRows={setNewOptions}
            createNewRow={newProductOption}
            saveRow={saveFilter}
            addButtonText="Add Filter Option"
        >
            {({ row, ...rest }): JSX.Element => (
                <AddFilterOptionRow
                    mappedFilterOption={row}
                    availableFilters={availableFilters}
                    doneButtonDisabled={isRowIncomplete(row)}
                    {...rest}
                />
            )}
        </AddToTable>
    );
};

export default AddFilterOptions;
