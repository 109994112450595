import { HTMLProps } from 'react';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
    content: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
}));

const Content = ({ children }: HTMLProps<{}>): JSX.Element => {
    const { classes } = useStyles();

    return (
        <div className={classes.content}>
            {children}
        </div>
    );
};

export default Content;
