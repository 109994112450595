import saveScene from 'src/lib/saveScene';

const saveScenes = (bearerToken: string) => async (
    scenes: Services.Scenes.V2.SceneDefinition[],
): Promise<Services.Scenes.V2.SceneDefinition[]> => {
    const tasks: Promise<Services.Scenes.V2.SceneDefinition>[] = [];

    for (let i = 0; i < scenes.length; i++) {
        const saveSceneTask = (saveScene(bearerToken)(scenes[i]) as Promise<Services.Scenes.V2.SceneDefinition>);

        tasks.push(saveSceneTask);
    }

    const updatedScenes = await Promise.all(tasks);

    return updatedScenes;
};

export default saveScenes;
