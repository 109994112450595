import { useContext } from 'react';
import {
    Chip, Grid, Typography, Alert,
} from '@mui/material';
import { AlertTitle } from '@mui/lab';

import ManageSceneContext from 'src/contexts/ManageSceneContext';

const ObsoleteProductOptionsAlert = (): JSX.Element => {
    const { sceneData: { sceneDefinition: { obsoleteProductOptions } } } = useContext(ManageSceneContext);

    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
            { !!obsoleteProductOptions && !!obsoleteProductOptions.length && (
            <Alert severity="warning">
                <AlertTitle>
                    Obsolete Product Options
                </AlertTitle>
                <Grid container direction="column" spacing={1}>
                    <Grid item>
                        <Typography>
                            These product options are no longer valid and will be
                            <strong> deleted </strong>
                            in the next save:
                        </Typography>
                    </Grid>
                    <Grid item container direction="row" spacing={2}>
                        {obsoleteProductOptions.map((ob) => (
                            <Grid item>
                                <Chip
                                    color="secondary"
                                    label={`${ob.optionName} - ${ob.optionValue}`}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Alert>
            )}
        </>
    );
};

export default ObsoleteProductOptionsAlert;
