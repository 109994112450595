import { DeleteForever } from '@mui/icons-material';

import ErrorButton, { ErrorButtonProps } from 'src/components/common/inputs/ErrorButton';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface DeleteButtonPropTypes extends ErrorButtonProps {}

const DeleteButton = (props: DeleteButtonPropTypes): JSX.Element => (
    <ErrorButton
        startIcon={<DeleteForever />}
        {...props}
    >
        Delete
    </ErrorButton>
);

export default DeleteButton;
