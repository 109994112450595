import { Typography, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import GhostSvg from 'src/components/common/GhostSvg';

const useStyles = makeStyles()((theme: Theme) => ({
    ghost: {
        height: theme.spacing(8),
    },
    container: {
        paddingTop: theme.spacing(4),
        textAlign: 'center',
    },
}));

const NoSearchResults = (): JSX.Element => {
    const { classes } = useStyles();

    return (
        <div className={classes.container}>
            <GhostSvg className={classes.ghost} />
            <Typography
                gutterBottom
                align="center"
                variant="h6"
            >
                No Results
            </Typography>
            <Typography
                align="center"
                variant="body1"
            >
                Check for any spelling mistakes or try refining your search further
            </Typography>
        </div>
    );
};

export default NoSearchResults;
