import { Grid } from '@mui/material';

import ManageSceneProductOptions from 'src/components/ManageScene/ManageSceneProductOptions';
import ManageSceneTypes from 'src/components/ManageScene/ManageSceneTypes';
import ManageSceneDescription from 'src/components/ManageScene/ManageSceneDescription';
import ObsoleteProductOptionsAlert from './obsoleteProductOptionsAlert';

const ManageScene = (): JSX.Element => (
    <Grid container direction="column" spacing={2}>
        <Grid item>
            <ObsoleteProductOptionsAlert />
        </Grid>
        <Grid item container direction="row" spacing={2}>
            <Grid
                container
                item
                direction="column"
                spacing={2}
                wrap="nowrap"
                xs={6}
            >
                <Grid item>
                    <ManageSceneDescription />
                </Grid>
                <Grid item>
                    <ManageSceneProductOptions />
                </Grid>
            </Grid>
            <Grid item xs={6}>
                <ManageSceneTypes />
            </Grid>
        </Grid>
    </Grid>
);

export default ManageScene;
