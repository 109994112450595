import { HTMLProps, ReactNode } from 'react';
import classnames from 'classnames';

import {
    Box,
    Divider,
    Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { TypographyProps } from '@mui/material/Typography';

interface PropTypes extends Omit<HTMLProps<HTMLDivElement>, 'label' | 'value'> {
    helperText?: ReactNode;
    helperTextClassName?: string;
    helperTextProps?: TypographyProps;
    label?: ReactNode;
    labelClassName?: string;
    labelProps?: TypographyProps;
    underlined?: boolean;
    value?: ReactNode;
    valueClassName?: string;
}

const useStyles = makeStyles()({
    invisible: {
        visibility: 'hidden',
    },
    nonInteractive: {
        cursor: 'default',
    },
});

const renderHelperText = (
    helperText: ReactNode,
    className: string,
    helperTextProps: TypographyProps,
): JSX.Element => (
    <Typography
        className={className}
        variant="caption"
        {...helperTextProps}
    >
        {helperText}
    </Typography>
);

const renderLabel = (
    label: ReactNode,
    className: string,
    labelProps: TypographyProps,
): JSX.Element => (
    <Typography
        gutterBottom
        className={className}
        variant="overline"
        {...labelProps}
    >
        <Box component="span" fontWeight="bold">
            {label}
        </Box>
    </Typography>
);

const ReadOnlyField = (props: PropTypes): JSX.Element => {
    const {
        children,
        helperText,
        helperTextClassName = '',
        helperTextProps = {},
        label,
        labelClassName = '',
        labelProps = {},
        underlined = true,
        value,
        valueClassName = '',
        ...rest
    } = props;
    const { classes } = useStyles();

    if (!value && !children) {
        // eslint-disable-next-line
        console.error('In ReadOnlyField: The prop "value" or "children" must be supplied');
    }

    const dividerClassNames = classnames({
        [classes.invisible]: !underlined,
    });

    const ValueComponent = value
        ? <Typography className={valueClassName} variant="body1">{value}</Typography>
        : children;

    return (
        <div {...rest}>
            {label && renderLabel(label, classnames(classes.nonInteractive, labelClassName), labelProps)}
            {ValueComponent}
            <Divider className={dividerClassNames} />
            {helperText && renderHelperText(helperText, helperTextClassName, helperTextProps)}
        </div>
    );
};

export default ReadOnlyField;
