import { ChangeEvent, Dispatch } from 'react';
import { CardProps } from '@mui/material/Card';
import { DeleteForever } from '@mui/icons-material';
import {
    Card,
    CardContent,
    CardHeader,
    IconButton,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

import AspectRatioCheck from 'src/components/ManageScene/AspectRatioCheck';
import CopyTextButton from 'src/components/common/CopyTextButton';
import SceneUriTextField from 'src/components/ManageScene/SceneUriTextField';
import ScenePreview from 'src/components/common/ScenePreview';
import { setSceneUriAction, deleteSceneTypeAction } from 'src/store/sceneData/scenes';

interface PropTypes extends CardProps {
    dispatch: Dispatch<Actions.Action<unknown>>;
    editing: boolean;
    sceneType: string;
    sceneUri: Services.Scenes.SceneUri;
    galleryName: string | null;
    productOptions: Services.Scenes.ProductOption[];
}

const useStyles = makeStyles()((theme: Theme) => ({
    copy: {
        position: 'absolute',
        right: theme.spacing(0.5),
        top: -theme.spacing(0.5),
    },
    container: {
        paddingRight: theme.spacing(6),
        position: 'relative',
    },
    media: {
        height: theme.spacing(20),
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
    },
    subtitle: {
        marginRight: theme.spacing(0.5),
        verticalAlign: 'middle',
    },
}));

const ManageSceneTypeCard = (props: PropTypes): JSX.Element => {
    const {
        editing,
        dispatch,
        sceneType,
        sceneUri: {
            aspectRatio,
            uri,
        },
        galleryName,
        productOptions,
        ...rest
    } = props;
    const { classes } = useStyles();

    const handleUriChange = (type: string) => (event: ChangeEvent<HTMLInputElement>): void => {
        event.preventDefault();

        dispatch(setSceneUriAction(type, event.currentTarget.value));
    };

    const handleTypeDelete = (type: string) => (): void => {
        dispatch(deleteSceneTypeAction(type));
    };

    return (
        <Card {...rest}>
            <CardHeader
                action={editing && (
                    <IconButton onClick={handleTypeDelete(sceneType)}>
                        <DeleteForever />
                    </IconButton>
                )}
                title={`Scene Type: ${sceneType}`}
                subheader={(
                    <>
                        <span className={classes.subtitle}>
                            {`Aspect Ratio: ${aspectRatio}`}
                        </span>
                        <AspectRatioCheck
                            sceneType={sceneType}
                            uri={uri}
                            currentAspectRatio={aspectRatio}
                        />
                    </>
                )}
            />
            {uri && (
                <ScenePreview
                    galleryName={galleryName}
                    sceneUri={uri}
                    productOptions={productOptions}
                    className={classes.media}
                />
            )}
            <CardContent className={classes.container}>
                <CopyTextButton
                    className={classes.copy}
                    text={uri}
                    title="Click to copy the Scene URI"
                />
                <SceneUriTextField
                    fullWidth
                    multiline
                    required
                    editing={editing}
                    id={`${sceneType}-scene-uri-input`}
                    label="Scene URI"
                    value={uri}
                    variant="outlined"
                    onChange={handleUriChange(sceneType)}
                />
            </CardContent>
        </Card>
    );
};

export default ManageSceneTypeCard;
