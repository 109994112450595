import { useEffect, useState, forwardRef } from 'react';
import { CircularProgress, TableBodyProps } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroller';
import { TableProps } from '@mui/material/Table';

import getGalleryName from 'src/utils/getGalleryName';
import Header from 'src/components/common/Header';
import NoSearchResults from 'src/components/common/NoSearchResults';
import ResultRow from 'src/components/SearchScenes/ResultRow';
import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from 'src/components/common/CardTable';

interface PropTypes extends Omit<TableProps, 'results'> {
    results?: Services.Scenes.SceneDefinition[];
    hasMore: boolean;
    onPage: (nextPage: number) => void;
    productKey?: string;
    scrollParent: () => HTMLElement | null;
}

const ResultsList = (props: PropTypes): JSX.Element => {
    const {
        results,
        hasMore,
        productKey,
        onPage,
        scrollParent,
        ...rest
    } = props;
    const [galleryName, setGalleryName] = useState<string | null>(null);

    useEffect(() => {
        const runEffect = async (): Promise<void> => {
            if (productKey) {
                const fetchedGalleryName = await getGalleryName(productKey);

                setGalleryName(fetchedGalleryName);
            }
        };

        runEffect();
    }, [productKey, setGalleryName]);

    return (
        <Table stickyHeader {...rest}>
            <TableHead>
                <TableRow>
                    <TableCell align="center"><Header>Preview</Header></TableCell>
                    <TableCell align="center"><Header>Product Key</Header></TableCell>
                    <TableCell align="center"><Header>Description</Header></TableCell>
                    <TableCell align="center"><Header>Product Options</Header></TableCell>
                </TableRow>
            </TableHead>
            <InfiniteScroll
                // @ts-ignore
                // eslint-disable-next-line react/no-unstable-nested-components
                element={forwardRef<HTMLTableSectionElement, TableBodyProps>(
                    ({ children, ...other }, ref): JSX.Element => (
                        <TableBody {...other} ref={ref}>
                            {children}
                        </TableBody>
                    ),
                )}
                hasMore={hasMore}
                initialLoad={false}
                loader={(
                    <TableRow key="loader">
                        <TableCell align="center" colSpan={6}>
                            <CircularProgress color="primary" />
                        </TableCell>
                    </TableRow>
                )}
                loadMore={onPage}
                pageStart={0}
                threshold={1}
                useWindow={false}
                getScrollParent={scrollParent}
            >
                {(results && !results.length) && (
                    <TableRow>
                        <TableCell align="center" colSpan={4}>
                            <NoSearchResults />
                        </TableCell>
                    </TableRow>
                )}
                {(!!results?.length) && results.map((scene: Services.Scenes.SceneDefinition): JSX.Element => (
                    <ResultRow key={scene.id} galleryName={galleryName} scene={scene} />
                ))}
            </InfiniteScroll>
        </Table>
    );
};

export default ResultsList;
