import {
    useState, useEffect, ChangeEvent, FormEvent, MouseEvent,
} from 'react';
import qs from 'query-string';
import { PaperProps } from '@mui/material/Paper';
import { Breakpoint } from '@mui/material/styles';
import {
    TextField,
    Button,
    GridSize,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { Search, Clear } from '@mui/icons-material';

import useCompatibleBreakpoint from 'src/hooks/useCompatibleBreakpoint';
import useSavedSearch from 'src/hooks/useSavedSearch';
import Card, {
    CardActionBar,
    CardContent,
} from 'src/components/common/Card';

interface PropTypes extends Omit<PaperProps, 'onSubmit'> {
    onSubmit: (search: Services.Galleries.Search) => void;
}

const INITIAL_STATE = {
    galleryName: '',
    productKey: '',
    mpv: '',
    securityBoundary: '',
    id: '',
    culture: '',
    rankingStrategy: '',
};

function getValueFromStringArray(source: string | string[] | null | undefined): string | null | undefined {
    return Array.isArray(source) ? source[0] : source;
}

function getSavedSelection(search: string, initialState: Services.Galleries.Search): Services.Galleries.Search {
    const query = qs.parse(search);

    return Object.keys(initialState).reduce((accum: Services.Galleries.Search, key: string) => {
        if (key in query) {
            // eslint-disable-next-line no-param-reassign
            accum[key] = getValueFromStringArray(query[key]) || '';
        }

        return accum;
    }, initialState);
}

const SearchForm = (props: PropTypes): JSX.Element => {
    const {
        onSubmit,
        ...rest
    } = props;
    const initialState = useSavedSearch(INITIAL_STATE, getSavedSelection);
    const [search, updateSearch] = useState<Services.Galleries.Search>(initialState);
    const formFlowProp: Partial<Record<Breakpoint, boolean | GridSize>> = {
        [useCompatibleBreakpoint('md')]: 3,
        xs: 6,
    };

    const submitSearch = (newSearch: Services.Galleries.Search): void => {
        updateSearch(newSearch);
        // Filter out null/empty string and convert to undefined since qs treats undefined as "not present"
        onSubmit(Object.entries(newSearch).reduce((accum, [key, value]) => {
            // eslint-disable-next-line no-param-reassign
            accum[key] = (value === null || value === '') ? undefined : value;

            return accum;
        }, {} as Services.Galleries.Search));
    };

    // Trigger a search on component mount (submitSearch)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => submitSearch(search), []);

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        event.preventDefault();
        const { name, value } = event.currentTarget;

        updateSearch({
            ...search,
            [name]: value === '' ? undefined : value,
        });
    };

    const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        submitSearch(search);
    };

    const handleClear = (event: MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();
        submitSearch({
            galleryName: '',
            productKey: '',
            mpv: '',
            securityBoundary: '',
            id: '',
            culture: '',
            rankingStrategy: '',
        });
    };

    return (
        <form onSubmit={handleSubmit}>
            <Card {...rest}>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid {...formFlowProp}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                label="Gallery Name / Slug"
                                placeholder="Gallery Name / Slug"
                                helperText="Search for Galleries by a name / slug"
                                margin="normal"
                                size="small"
                                value={search.galleryName}
                                name="galleryName"
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid {...formFlowProp}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                label="Gallery ID"
                                placeholder="Gallery ID"
                                helperText="Search for a Gallery by its ID"
                                margin="normal"
                                size="small"
                                value={search.id}
                                name="id"
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid {...formFlowProp}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                label="Product Key (Core Product ID)"
                                placeholder="Product Key (Core Product ID)"
                                helperText="Search for Galleries using a product key (PRD-XXXXXXXX)"
                                margin="normal"
                                size="small"
                                value={search.productKey}
                                name="productKey"
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid {...formFlowProp}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                label="MPV"
                                placeholder="MPV"
                                helperText="Search for a Gallery by a MPV"
                                margin="normal"
                                size="small"
                                value={search.mpv}
                                name="mpv"
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid {...formFlowProp}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                label="Culture"
                                placeholder="Culture"
                                helperText="Search for Galleries containing a culture"
                                margin="normal"
                                size="small"
                                value={search.culture}
                                name="culture"
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid {...formFlowProp}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                label="LOB"
                                placeholder="LOB"
                                helperText="Search for Galleries owned by a line of business (LOB)"
                                margin="normal"
                                size="small"
                                value={search.securityBoundary}
                                name="securityBoundary"
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid {...formFlowProp}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                label="Ranking Strategy"
                                placeholder="Ranking Strategy"
                                helperText="Search for Galleries using a ranking strategy"
                                margin="normal"
                                size="small"
                                value={search.rankingStrategy}
                                name="rankingStrategy"
                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
                <CardActionBar>
                    <Grid container justifyContent="flex-end" spacing={2}>
                        <Grid>
                            <Button
                                variant="contained"
                                color="secondary"
                                startIcon={(<Clear />)}
                                onClick={handleClear}
                            >
                                Clear All
                            </Button>
                        </Grid>
                        <Grid>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                startIcon={(<Search />)}
                            >
                                Search
                            </Button>
                        </Grid>
                    </Grid>
                </CardActionBar>
            </Card>
        </form>
    );
};

export default SearchForm;
