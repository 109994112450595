import AbstractService from 'src/services/AbstractService';
import config from 'src/lib/config';
import { getLogger } from 'src/utils/logger';
import { ServiceError } from 'src/lib/errors';

class CoamService extends AbstractService {
    constructor() {
        super(config.services.coam);
    }

    public async getSecurityBoundaries(principal: string, accessToken: string): Promise<Services.Coam.Resource[]> {
        const url = `principals/${principal}/permissions/lines_of_business`;

        try {
            const response = await this.api.get<Services.Coam.Resource[]>(
                url,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                },
            );

            return response.data;
        } catch (e: any) {
            getLogger().error(e);
            throw new ServiceError(e);
        }
    }
}

export default new CoamService();
