import {
    Dispatch, useContext, useEffect, useReducer, useState,
} from 'react';

import config from 'src/lib/config';
import galleryContentAttributeService from 'src/services/GalleryContentAttributeService';
import galleryFiltersService from 'src/services/GalleryFiltersService';
import galleryTaxonomiesService from 'src/services/GalleryTaxonomiesService';
import galleryValidationService from 'src/services/GalleryValidationService';
import globalFiltersService from 'src/services/GlobalFiltersService';
import taxonomyService from 'src/services/TaxonomyService';
import galleriesService from 'src/services/GalleriesService';
import mergeGalleryFilters from 'src/utils/mergeGalleryFilters';
import productService from 'src/services/ProductService';
import msxService from 'src/services/MsxService';
import renderPropertiesService from 'src/services/RenderPropertiesService';
import galleryCategoryRestrictionsService from 'src/services/GalleryCategoryRestrictionsService';
import saveGallery from 'src/lib/saveGallery';
import { reducer, initializeGalleryDataAction } from 'src/store/galleryData';
import { IdentityContext } from 'src/contexts/IdentityContext';
import { AppError } from 'src/lib/errors';
import { filterRenderProperties } from 'src/utils/renderProperties';
import { CULTURES } from 'src/constants';

type ManageableGalleryTuple = [
    App.ManageGalleryContext | null,
    AppError | null,
];

function mergeGalleryData(
    allFiltersById: App.FiltersById,
    gallery: Services.Galleries.Gallery,
    galleryContentAttributes: Services.GalleryContentAttribute.GalleryContentAttribute,
    coreProduct: Services.Product.Product | undefined | null,
    msxAttributes: Services.Msx.MsxOptionsByName | undefined | null,
    renderProperties: App.RenderPropertiesByExperienceType,
    categoryRestrictions: Services.CategoryRestrictions.CategoryRestriction[],
    validations: Services.GalleryValidation.ValidationResult[],
): App.GalleryData {
    return {
        gallery,
        allFiltersById,
        galleryContentAttributes,
        coreProduct,
        msxAttributes,
        renderProperties,
        categoryRestrictions,
        validations,
    };
}

async function getGlobalFilters(
    galleryFilters: Services.Filters.GalleryFilter[],
): Promise<Services.Filters.GlobalFilter[]> {
    return Promise.all(galleryFilters.map((filter) => globalFiltersService.getGlobalFilter(filter.id)));
}

const findCategory = (category: Services.Filters.Category, categoryKey: string): Services.Filters.Category | null => {
    if (category.categoryKey.toString() === categoryKey) {
        return category;
    }

    for (const child of category.children) {
        const childTaxonomy = findCategory(child, categoryKey);

        if (childTaxonomy) {
            return childTaxonomy;
        }
    }

    return null;
};

function getCategoryRestrictions(
    taxonomies: Services.Filters.Taxonomy[],
    categoryRestrictionResponses: Services.CategoryRestrictions.CategoryRestrictionDto[],
): Services.CategoryRestrictions.CategoryRestriction[] {
    const categoryRestrictions: Services.CategoryRestrictions.CategoryRestriction[] = [];

    for (const categoryRestrictionResponse of categoryRestrictionResponses) {
        const categoryKey = categoryRestrictionResponse.categoryKey.toString();

        taxonomies.some((taxonomy) => {
            const category = findCategory(taxonomy.category, categoryKey);

            if (category !== null) {
                categoryRestrictions.push({
                    categoryKey: category.categoryKey.toString(),
                    include: categoryRestrictionResponse.include,
                    name: category.name,
                });

                return true;
            }

            return false;
        });
    }

    return categoryRestrictions;
}

export const fetchGalleryData = async (id: string, culture: string, bearerToken: string): Promise<App.GalleryData> => {
    const gallery = await galleriesService.getGallery(id);

    const galleryCategoryRestrictions = galleryCategoryRestrictionsService.getCategoryRestrictions(gallery.id);
    const galleryFiltersTask = galleryFiltersService.getGalleryFilters(gallery.id);
    const galleryTaxonomiesTask = galleryTaxonomiesService.getGalleryTaxonomies(gallery.id);
    const taxonomiesTask = taxonomyService.getTaxonomies(bearerToken);
    const renderPropertiesTask = renderPropertiesService.getRenderProperties(gallery.id);
    const contentAttributesTask = galleryContentAttributeService.getContentAttributes(
        culture,
    );
    const validationsTask = galleryValidationService.getGalleryValidations(gallery.id);
    const coreProduct = gallery.productKey === null
        ? undefined
        : await productService.getNullableProduct(gallery.productKey);

    const [galleryFilters, galleryTaxonomies] = await Promise.all([galleryFiltersTask, galleryTaxonomiesTask]);

    const globalFiltersTask = getGlobalFilters(galleryFilters);

    const [
        globalFilters,
        taxonomies,
        categoryRestrictionResponse,
    ] = await Promise.all([globalFiltersTask, taxonomiesTask, galleryCategoryRestrictions]);

    const categoryRestrictions = getCategoryRestrictions(taxonomies, categoryRestrictionResponse);

    const allFiltersById = mergeGalleryFilters(
        galleryFilters,
        galleryTaxonomies,
        // Temporary filter to remove it from the filter global list so we can add it to the DB
        // without risking it actually getting used. Created as enum in later MR.
        globalFilters.filter((gf) => gf.id !== 'PhotoNumberAll'),
        taxonomies,
    );

    const msxAttributesByCultureTask = coreProduct
        ? await msxService.getMsxAttributes(coreProduct.key, coreProduct.version, CULTURES)
        : {} as Services.Msx.MsxOptionsByName;

    const [
        renderProperties,
        contentAttributes,
        validations,
        msxAttributesByCulture,
    ] = await Promise.all([renderPropertiesTask, contentAttributesTask, validationsTask, msxAttributesByCultureTask]);

    return mergeGalleryData(
        allFiltersById,
        gallery,
        contentAttributes,
        coreProduct,
        msxAttributesByCulture,
        filterRenderProperties(renderProperties),
        categoryRestrictions,
        validations.results,
    );
};

const useManageableGallery = (galleryId: string | undefined): ManageableGalleryTuple => {
    const [
        galleryData,
        dispatch,
    ] = useReducer(reducer, null) as [App.GalleryData | null, Dispatch<Actions.Action>];
    const [error, setError] = useState<AppError | null>(null);
    const { accessToken } = useContext(IdentityContext);

    useEffect(() => {
        const runEffect = async (): Promise<void> => {
            try {
                if (galleryId && accessToken) {
                    dispatch(initializeGalleryDataAction(
                        await fetchGalleryData(galleryId, config.defaultCulture, accessToken),
                    ));
                }
            } catch (e: any) {
                setError(new AppError(e));
            }
        };

        runEffect();
    }, [galleryId, dispatch, accessToken]);

    const state = (galleryData && accessToken)
        ? { galleryData, dispatch, saveGallery: saveGallery(accessToken) }
        : null;

    return [state, error];
};

export default useManageableGallery;
