import classnames from 'classnames';

import Paper, { PaperProps } from '@mui/material/Paper';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
    card: {
        paddingTop: theme.spacing(2),
    },
}));

const Card = (props: PaperProps): JSX.Element => {
    const {
        children,
        className = '',
        elevation = 1,
        ...rest
    } = props;

    const { classes } = useStyles();

    return (
        <Paper
            className={classnames(classes.card, className)}
            elevation={elevation}
            {...rest}
        >
            {children}
        </Paper>
    );
};

export { default as CardHeader } from 'src/components/common/Card/Header';
export { default as CardContent } from 'src/components/common/Card/Content';
export { default as CardActionBar } from 'src/components/common/Card/ActionBar';

export default Card;
