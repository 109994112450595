import {
    ReactNode, ReactText,
} from 'react';
import classnames from 'classnames';

import {
    Checkbox as MuiCheckbox,
    FormControlLabel,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { CheckboxProps } from '@mui/material/Checkbox';
import { v4 } from 'uuid';

interface PropTypes extends CheckboxProps {
    editing?: boolean;
    label?: ReactNode;
}

const useStyles = makeStyles()({
    nonInteractive: {
        cursor: 'default',
    },
});

const generateId = (): ReactText => `${v4}-input`;

const generateKey = (id: ReactText, value: boolean): string => `${id}-${value}`;

const Checkbox = (props: PropTypes): JSX.Element => {
    const {
        id = generateId(),
        checked = false,
        className,
        editing = false,
        key = generateKey(id, checked),
        label,
        color = 'primary',
        ...rest
    } = props;
    const { classes } = useStyles();

    return label ? (
        <FormControlLabel
            control={(
                <MuiCheckbox
                    key={key}
                    checked={checked}
                    className={classnames(className, {
                        [classes.nonInteractive]: !editing,
                    })}
                    disabled={!editing}
                    color={color}
                    {...rest}
                />
              )}
            label={label}
        />
    ) : (
        <MuiCheckbox
            key={key}
            checked={checked}
            className={classnames(className, {
                [classes.nonInteractive]: !editing,
            })}
            disabled={!editing}
            color={color}
            {...rest}
        />
    );
};

export default Checkbox;
