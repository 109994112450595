import {
    Dispatch, useContext, useEffect, useReducer, useState,
} from 'react';

import notificationChannelService from 'src/services/NotificationChannelService';
import { reducer, initializeNotificationChannelDataAction } from 'src/store/notificationChannelData';
import { IdentityContext } from 'src/contexts/IdentityContext';
import { AppError } from 'src/lib/errors';
import saveNotificationChannel from 'src/lib/saveNotificationChannel';

type NotificationChannelTuple = [
    App.ManageNotificationChannelsContext | null,
    boolean,
    AppError | null,
];

export const fetchNotificationChannelData = async (
    notificationChannelId: number,
    accessToken: string | undefined,
): Promise<App.NotificationChannelData> => {
    const notificationChannelTask = accessToken
        ? notificationChannelService.getNotificationChannel(notificationChannelId)
        : Promise.resolve({} as Services.NotificationChannels.NotificationChannel);

    const [notificationChannel] = await Promise.all([
        notificationChannelTask,
    ]);

    return {
        ...notificationChannel,
    };
};

const useNotificationChannelData = (notificationChannelId: number): NotificationChannelTuple => {
    const [error, setError] = useState<AppError | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const { accessToken } = useContext(IdentityContext);
    const [
        notificationChannelData,
        dispatch,
    ] = useReducer(reducer, null) as [
        App.NotificationChannelData | null,
        Dispatch<Actions.Action>,
    ];

    useEffect(() => {
        const runEffect = async (): Promise<void> => {
            try {
                setLoading(true);
                const data = await fetchNotificationChannelData(notificationChannelId, accessToken);

                if (!data) {
                    throw Error('Notification Channel data could not be found');
                }

                dispatch(initializeNotificationChannelDataAction(data));
            } catch (e: any) {
                setError(new AppError(e as any));
            } finally {
                setLoading(false);
            }
        };

        runEffect();
    }, [accessToken, dispatch, notificationChannelId]);

    const state = (notificationChannelData && accessToken)
        ? {
            dispatch,
            notificationChannelData,
            saveNotificationChannel: saveNotificationChannel(accessToken),
        }
        : null;

    return [state, loading, error];
};

export default useNotificationChannelData;
