import { HTMLProps, createContext, useState } from 'react';

const initialState = {
    editing: false,
    setEditing: (): void => {
        // Do nothing
    },
};
const EditContext = createContext<App.EditContext>(initialState);

const EditContextProvider = (props: HTMLProps<{}>): JSX.Element => {
    const { children } = props;
    const [editing, setEditing] = useState<boolean>(false);

    return (
        // eslint-disable-next-line react/jsx-no-constructed-context-values
        <EditContext.Provider value={{
            editing,
            setEditing,
        }}
        >
            { children }
        </EditContext.Provider>
    );
};

export { EditContext, EditContextProvider };
