import {
    Typography,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

import { ValidationDetails } from 'src/lib/errors/types';

interface PropTypes {
    validationErrorList: ValidationDetails;
}

const renderMessages = (messages: string[]): JSX.Element => (
    <List>
        {messages.map(
            (errorMessage: string): JSX.Element => (
                <ListItem>
                    <ListItemIcon>
                        <FiberManualRecordIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>
                        {errorMessage}
                    </ListItemText>
                </ListItem>
            ),
        )}
    </List>
);

const ValidationErrors = (props: PropTypes): JSX.Element => {
    const { validationErrorList } = props;

    return (
        <>
            {Object.entries(validationErrorList).map(
                ([field, value]) => (
                    <>
                        <Typography variant="h6">
                            {(field.charAt(0).toUpperCase() + field.slice(1))
                                .split(/(?=[A-Z])/)
                                .join(' ')}
                        </Typography>
                        {!Array.isArray(value)
                        && value.message
                        && renderMessages(value.message as string[])}
                        {Array.isArray(value) && Object.entries(value).map(
                            ([idx, messages]) => (
                                <>
                                    <Typography>
                                        {`Index ${idx}`}
                                    </Typography>
                                    {messages.message
                                    && renderMessages(messages.message as string[])}
                                </>
                            ),
                        )}
                    </>
                ),
            )}
        </>
    );
};

export default ValidationErrors;
