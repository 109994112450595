import { HTMLProps, createContext } from 'react';
import useAttributePermutationJob from 'src/hooks/useAttributePermutationJobs';

export const AttributePermutationJobsContext = createContext<AttributePermutations.AttributePermutationJobsContext>(
    {} as AttributePermutations.AttributePermutationJobsContext,
);

export const AttributePermutationJobsContextProvider = (props: HTMLProps<{}>): JSX.Element => {
    const { children } = props;
    const { jobs, dispatchJobs } = useAttributePermutationJob();

    return (
        // eslint-disable-next-line react/jsx-no-constructed-context-values
        <AttributePermutationJobsContext.Provider value={{
            jobs,
            dispatchJobs,
        }}
        >
            { children }
        </AttributePermutationJobsContext.Provider>
    );
};
