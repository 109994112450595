import { ReactNode } from 'react';
import classnames from 'classnames';

import {
    Switch as MuiSwitch,
    FormControlLabel,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { SwitchProps } from '@mui/material/Switch';

export interface PropTypes extends SwitchProps {
    editing?: boolean;
    label?: ReactNode;
}

const useStyles = makeStyles()({
    nonInteractive: {
        cursor: 'default',
    },
});

const Switch = (props: PropTypes): JSX.Element => {
    const {
        checked = false,
        className = '',
        editing = false,
        label,
        color = 'primary',
        ...rest
    } = props;
    const { classes } = useStyles();

    return label ? (
        <FormControlLabel
            control={(
                <MuiSwitch
                    checked={checked}
                    className={classnames(className, {
                        [classes.nonInteractive]: !editing,
                    })}
                    disabled={!editing}
                    color={color}
                    {...rest}
                />
              )}
            label={label}
        />
    ) : (
        <MuiSwitch
            checked={checked}
            className={classnames(className, {
                [classes.nonInteractive]: !editing,
            })}
            disabled={!editing}
            color={color}
            {...rest}
        />
    );
};

export default Switch;
