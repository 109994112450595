import { ChangeEvent, MouseEvent } from 'react';

import {
    Grid,
    TextField,
    Button,
} from '@mui/material';

import Card, { CardContent, CardActionBar } from 'src/components/common/Card';
import useCompatibleBreakpoint from 'src/hooks/useCompatibleBreakpoint';
import { Clear } from '@mui/icons-material';

interface PropTypes {
    filterByLabel: (event: ChangeEvent<{ value: unknown }>) => void;
    filterByChannelName: (event: ChangeEvent<{ value: unknown }>) => void;
    filterByDefaultFor: (event: ChangeEvent<{ value: unknown }>) => void;
    labelSearch: string;
    channelNameSearch: string;
    defaultForSearch: string;
    clearAll: (event: MouseEvent<HTMLButtonElement>) => void;
}

const SearchForm = (props: PropTypes): JSX.Element => {
    const {
        filterByLabel,
        filterByChannelName,
        filterByDefaultFor,
        labelSearch,
        channelNameSearch,
        defaultForSearch,
        clearAll,
    } = props;
    const splitScreenProp = { [useCompatibleBreakpoint('md')]: 4 };

    return (
        <Card>
            <CardContent>
                <Grid container alignItems="center" spacing={3}>
                    <Grid item xs={12} {...splitScreenProp}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Label"
                            placeholder="Label"
                            helperText="Filter notifications by label"
                            margin="normal"
                            value={labelSearch}
                            name="value"
                            onChange={filterByLabel}
                        />
                    </Grid>
                    <Grid item xs={12} {...splitScreenProp}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Channel Name"
                            placeholder="Channel Name"
                            helperText="Filter notification channels by Channel Name"
                            margin="normal"
                            value={channelNameSearch}
                            name="key"
                            onChange={filterByChannelName}
                        />
                    </Grid>
                    <Grid item xs={12} {...splitScreenProp}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Security Boundary"
                            placeholder="Default For"
                            helperText="Filter notifications by Security Boundary"
                            margin="normal"
                            value={defaultForSearch}
                            name="key"
                            onChange={filterByDefaultFor}
                        />
                    </Grid>
                </Grid>
            </CardContent>
            <CardActionBar>
                <Button
                    variant="contained"
                    color="secondary"
                    startIcon={(<Clear />)}
                    onClick={clearAll}
                >
                    Clear All
                </Button>
            </CardActionBar>
        </Card>
    );
};

export default SearchForm;
