import { Switch, Route, useRouteMatch } from 'react-router-dom';

import ProductFilter from 'src/components/ManageGallery/Filters/ProductFilter';
import TaxonomyFilter from 'src/components/ManageGallery/Filters/TaxonomyFilter';
import FilterList from 'src/components/ManageGallery/Filters/FilterList';
import CreateTaxonomyFilter from 'src/components/ManageGallery/Filters/TaxonomyFilter/CreateTaxonomyFilter';
import CreateContentFilter from 'src/components/ManageGallery/Filters/ContentFilter/CreateContentFilter';
import CreateProductFilter from 'src/components/ManageGallery/Filters/ProductFilter/CreateProductFilter';

const Filters = (): JSX.Element => {
    const { path, url } = useRouteMatch();

    return (
        <Switch>
            <Route
                exact
                path={`${path}/attribute/create`}
                render={(): JSX.Element => <CreateContentFilter backTo={url} />}
            />
            <Route
                path={`${path}/attribute/:filterId`}
                render={(): JSX.Element => <ProductFilter backTo={url} />}
            />
            <Route
                exact
                path={`${path}/taxonomy/create`}
                render={(): JSX.Element => <CreateTaxonomyFilter backTo={url} />}
            />
            <Route
                path={`${path}/taxonomy/:taxonomyId`}
                render={(): JSX.Element => <TaxonomyFilter backTo={url} />}
            />
            <Route
                exact
                path={`${path}/product/create`}
                render={(): JSX.Element => <CreateProductFilter backTo={url} />}
            />
            <Route
                exact
                path={`${path}/product/:filterId`}
                render={(): JSX.Element => <ProductFilter backTo={url} />}
            />
            <Route path={path} component={FilterList} />
        </Switch>
    );
};

export default Filters;
