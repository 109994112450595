import { ArrowBack, ArrowForward } from '@mui/icons-material';
import {
    Grid,
    IconButton,
    Typography,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

interface PropTypes {
    page: number;
    pageSize: number;
    setPage: (page: number) => void;
    searchedTranslations: Services.Translations.V2.TranslationGroup[];
}

const useStyles = makeStyles()((theme: Theme) => ({
    wideCell: {
        minWidth: theme.spacing(70),
    },
    narrowCell: {
        minWidth: theme.spacing(14),
    },
    rightArrow: {
        textAlign: 'right',
    },
    leftArrow: {
        textAlign: 'left',
    },
    centerText: {
        textAlign: 'center',
    },
}));

const Paging = (props: PropTypes): JSX.Element => {
    const {
        page,
        pageSize,
        setPage,
        searchedTranslations,
    } = props;
    const { classes } = useStyles();

    return (
        <Grid container>
            <Grid item xs={4} className={classes.leftArrow}>
                <IconButton
                    disabled={page <= 0}
                    onClick={(): void => setPage(page - 1)}
                >
                    <ArrowBack />
                </IconButton>
            </Grid>
            <Grid item xs={4} className={classes.centerText}>
                <Typography>
                    {`${(page * pageSize) + 1} - ${(page + 1) * pageSize}  of  ${searchedTranslations.length}`}
                </Typography>
            </Grid>
            <Grid item xs={4} className={classes.rightArrow}>
                <IconButton
                    disabled={(page + 1) >= searchedTranslations.length / pageSize}
                    onClick={(): void => setPage(page + 1)}
                >
                    <ArrowForward />
                </IconButton>
            </Grid>
        </Grid>
    );
};

export default Paging;
