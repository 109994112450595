import {
    useState, useContext, useEffect, ChangeEvent,
} from 'react';
import {
    Grid,
} from '@mui/material';

import AppBar from 'src/components/common/AppBar';
import getGalleryName from 'src/utils/getGalleryName';
import Main from 'src/components/Main';
import Card, { CardContent } from 'src/components/common/Card';
import ProductKeyAutocomplete from 'src/components/common/inputs/ProductKeyAutocomplete';
import useProduct from 'src/hooks/useProduct';
import useSceneData from 'src/hooks/useSceneData';
import CreateSceneDefinitionController from 'src/components/CreateSceneDefinition/CreateSceneDefinitionController';
import ManageSceneContext from 'src/contexts/ManageSceneContext';
import { EditContext } from 'src/contexts/EditContext';
import Splash from 'src/components/common/Splash';
import ZeroState from 'src/components/common/ZeroState';
import ManageScene from 'src/components/ManageScene';
import EditActions from 'src/components/common/AppBar/EditActions';
import Content from 'src/components/Content';

const emptyCoreProductOptions = {
    range: [],
    list: [] as Services.Product.ProductOption[],
};

const CreateSceneDefinition = (): JSX.Element => {
    const [productKey, setProductKey] = useState<string | undefined>(undefined);
    const [newSceneData, setNewSceneData] = useState<App.SceneData | undefined>(undefined);
    const [productKeyData] = useProduct(productKey);
    const [state, loading] = useSceneData(undefined, newSceneData);
    const { setEditing } = useContext(EditContext);

    const handleProductChange = (event: ChangeEvent<{}>, value: unknown): void => {
        event.preventDefault();
        const newProductKey = (value as Services.Product.BasicProduct)?.productKey;

        if (newProductKey) {
            setProductKey(newProductKey);
        }
    };

    useEffect((): void => setEditing(true), [setEditing]);

    useEffect((): void => {
        const runEffect = async (): Promise<void> => {
            const newSceneDefinition = {
                productKey: productKey || '',
                productOptions: [],
                obsoleteProductOptions: [],
                scenes: {
                    gallery: {
                        uri: '',
                        aspectRatio: 0,
                    },
                },
                description: '',
            };

            const galleryName = productKey ? await getGalleryName(productKey) : null;

            const sceneData = {
                sceneDefinition: newSceneDefinition,
                coreProductOptions: productKeyData?.options
                    || emptyCoreProductOptions as Services.Product.ProductOptions,
                galleryName,
            };

            setNewSceneData(sceneData);
        };

        runEffect();
    }, [productKey, productKeyData]);

    return (
        <Main
            AppBar={(
                <AppBar
                    actions={<EditActions />}
                    title="Create Scene"
                />
            )}
        >
            <Content>
                <Grid container spacing={3} direction="column">
                    <Grid item>
                        <Card>
                            <CardContent>
                                <ProductKeyAutocomplete
                                    editing
                                    fullWidth
                                    lockOnSelect
                                    productKey={productKey}
                                    handleProductChange={handleProductChange}
                                    helperText="Select the Product Key for which you would like to create a scene"
                                />
                            </CardContent>
                        </Card>
                    </Grid>
                    {state && (
                        <Grid item>
                            <ManageSceneContext.Provider value={state}>
                                <CreateSceneDefinitionController>
                                    {loading && (<Splash />)}
                                    {!loading && productKey && !state.sceneData.sceneDefinition.productKey && (<ZeroState text="Could not find data for the Product Key" />)}
                                    {!loading && state.sceneData.sceneDefinition.productKey && (
                                        <ManageScene />
                                    )}
                                </CreateSceneDefinitionController>
                            </ManageSceneContext.Provider>
                        </Grid>
                    )}
                </Grid>
            </Content>
        </Main>
    );
};

export default CreateSceneDefinition;
