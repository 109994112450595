import { Visibility } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import generateUrl from 'src/utils/urlGenerator';

import config from 'src/lib/config';

interface PropTypes {
    culture: string;
    name: string;
}

function getPreviewUrl(culture: string, name: string): string {
    return `${culture}/g/${name}?noCache=true&debug=true`;
}

const PreviewGalleryIconButton = (props: PropTypes): JSX.Element => {
    const { culture, name } = props;
    const { DEPLOYMENT } = config;
    const baseUrl = generateUrl(culture, DEPLOYMENT);
    const previewUrl = getPreviewUrl(culture, name);

    return (
        <Tooltip title={`Preview ${name}`}>
            <IconButton
                color="primary"
                component="a"
                href={`${baseUrl}/${previewUrl}`}
                target="_blank"
                size="large"
            >
                <Visibility />
            </IconButton>
        </Tooltip>
    );
};

export default PreviewGalleryIconButton;
