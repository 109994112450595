import AbstractService from 'src/services/AbstractService';
import config from 'src/lib/config';
import { ServiceError } from 'src/lib/errors';
import { logServiceError } from 'src/utils/logger';
import { VISIBILITY_CRITERIA } from 'src/constants';

/**
 *
 */
class GalleriesService extends AbstractService {
    constructor() {
        super(config.services.config);
    }

    /**
     * Gets gallery metadata given a gallery id
     *
     * @param id
     * @throws ServiceError
     */
    public async getGallery(id: string): Promise<Services.Galleries.Gallery> {
        const requestUrl = `api/v3/Galleries/${id}`;

        try {
            const response = await this.api.get<Services.Galleries.Gallery>(requestUrl, {
                params: {
                    requestor: config.appName,
                    noCache: true,
                    visibility: VISIBILITY_CRITERIA.ACTIVE_AND_INACTIVE,
                },
            });

            return response.data;
        } catch (e: any) {
            const error = new ServiceError(e);

            logServiceError(error);
            throw error;
        }
    }

    public async getGalleries(input: Services.Galleries.Search): Promise<Services.Galleries.Gallery[]> {
        const requestUrl = 'api/v3/Galleries';

        try {
            const response = await this.api.get<Services.Galleries.Response>(requestUrl, {
                params: {
                    ...input,
                    useWildCardSearch: true,
                    requestor: config.appName,
                    noCache: true,
                    visibility: VISIBILITY_CRITERIA.ACTIVE_AND_INACTIVE,
                },
            });

            return response.data.results;
        } catch (e: any) {
            const error = new ServiceError(e);

            logServiceError(error);
            throw error;
        }
    }

    public async getPagableGalleries(
        input: Services.Galleries.Search,
        offset: number,
        limit = 50,
    ): Promise<Services.Galleries.PagableGalleries> {
        const requestUrl = 'api/v3/Galleries';

        try {
            const response = await this.api.get<Services.Galleries.PagableGalleries>(requestUrl, {
                params: {
                    ...input,
                    limit,
                    offset,
                    useWildCardSearch: true,
                    requestor: config.appName,
                    noCache: true,
                    visibility: VISIBILITY_CRITERIA.ACTIVE_AND_INACTIVE,
                },
            });

            return response.data;
        } catch (e: any) {
            const error = new ServiceError(e);

            logServiceError(error);
            throw error;
        }
    }

    /**
     * Updates gallery metadata
     *
     * @param gallery
     * @throws ServiceError
     */
    public async putGallery(gallery: Services.Galleries.Gallery, bearerToken: string): Promise<void> {
        const requestUrl = `api/v3/Galleries/${gallery.id}`;

        try {
            return await this.api.put(
                requestUrl,
                gallery,
                {
                    params: {
                        requestor: config.appName,
                    },
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                },
            );
        } catch (e: any) {
            const error = new ServiceError(e);

            logServiceError(error);
            throw error;
        }
    }

    public async postGallery(
        galleryObject: Services.Galleries.CreateGallery,
        bearerToken: string,
    ): Promise<Services.Galleries.Gallery> {
        const requestUrl = 'api/v3/Galleries';

        try {
            const response = await this.api.post(
                requestUrl,
                galleryObject,
                {
                    params: {
                        requestor: config.appName,
                    },
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                },
            );

            return response.data;
        } catch (e: any) {
            const error = new ServiceError(e);

            logServiceError(error);
            throw error;
        }
    }
}

export default new GalleriesService();
