import { useContext, useState } from 'react';
import {
    Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import { useQuery } from 'react-query';

import AuditTableRow from 'src/components/ManageGallery/GalleryAuditLog/AuditTableRow';
import ErrorState from 'src/components/common/ErrorState';
import ManageGalleryContext from 'src/contexts/manageGalleryContext';
import Paging from 'src/components/ManageGallery/GalleryAuditLog/Paging';
import Splash from 'src/components/common/Splash';
import { queryAuditLogs, QUERY_KEY } from 'src/queries/queryAuditLog';
import { DEFAULT_LIMIT } from 'src/constants';
import { IdentityContext } from 'src/contexts/IdentityContext';

type PagedAuditLogs = Models.PageResult<Services.AuditLogs.AuditLog>;

const useStyles = makeStyles()((theme: Theme) => ({
    table: {
        marginBottom: theme.spacing(2),
    },
}));

const GalleryAuditLog = (): JSX.Element => {
    const { galleryData: { gallery: { id } } } = useContext(ManageGalleryContext);
    const [page, setPage] = useState(0);
    const { accessToken } = useContext(IdentityContext);
    const { classes } = useStyles();
    const limit = DEFAULT_LIMIT;

    const {
        isLoading,
        isError,
        error,
        data,
    } = useQuery<PagedAuditLogs, Error, PagedAuditLogs, App.AuditLogs.PagedQueryKey>(
        [QUERY_KEY, {
            limit, galleryId: id, offset: limit * page, accessToken,
        }],
        queryAuditLogs,
        {
            // TODO enable this when the page overall has suspense
            suspense: false,
            enabled: !!accessToken,
        },
    );

    return (
        <>
            {isLoading && (<Splash />)}
            {isError && (
                <ErrorState
                    title="Failed to load audit log"
                    subtitle="Please refresh the page and try is again"
                    message={error?.message}
                />
            )}
            {data && (
                <>
                    <TableContainer className={classes.table} component={Paper}>
                        <Table aria-label="collapsible table">
                            <TableHead>
                                <TableRow>
                                    <TableCell />
                                    <TableCell align="center">Date</TableCell>
                                    <TableCell align="center">Action</TableCell>
                                    <TableCell align="center">User Canonical ID</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data && data.results.map((log) => (
                                    <AuditTableRow key={log.id} auditLog={log} />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Paging
                        page={page}
                        pageSize={limit}
                        total={data.total}
                        setPage={setPage}
                    />
                </>
            )}
        </>
    );
};

export default GalleryAuditLog;
