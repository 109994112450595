import { SelectChangeEvent } from '@mui/material';

import Select, { SelectProps } from 'src/components/common/inputs/Select';

interface PropTypes extends SelectProps {
    currentNotificationChannel?: Services.NotificationChannels.NotificationChannel;
    setCurrentNotificationChannel: (notificationChannel: Services.NotificationChannels.NotificationChannel) => void;
    options?: Services.NotificationChannels.NotificationChannel[];
}

const LabelSelect = (props: PropTypes): JSX.Element => {
    const {
        currentNotificationChannel,
        setCurrentNotificationChannel,
        options,
        ...rest
    } = props;

    const handleLabelChange = (event: SelectChangeEvent<unknown>): void => {
        event.preventDefault();

        const notificationChannels = options?.filter((option) => option.label === (event.target.value as string));

        if (notificationChannels) {
            setCurrentNotificationChannel(notificationChannels[0]);
        }
    };

    return (
        <Select
            editing
            value={currentNotificationChannel?.label}
            onChange={handleLabelChange}
            {...rest}
        >
            {!options?.length && (<option value="" key="">None Available</option>)}
            {options?.map((nc) => (
                <option value={nc.label} key={nc.label}>{nc.label}</option>
            ))}
        </Select>
    );
};

export default LabelSelect;
