import defaultConfig from 'config/default.json';
import development from 'config/development.json';
import developmentLocal from 'config/development-local.json';
import production from 'config/production.json';
import productionProduction from 'config/production-production.json';
import productionQa from 'config/production-qa.json';
import productionTraining from 'config/production-training.json';

type RecursivePartialAppConfig<T> = {
    [P in keyof T]?: RecursivePartialAppConfig<T[P]>;
};

export default {
    development,
    production,
    default: defaultConfig,
    'development-local': developmentLocal,
    'production-production': productionProduction,
    'production-qa': productionQa,
    'production-training': productionTraining,
} as {
    [index: string]: RecursivePartialAppConfig<App.Config>;
};
