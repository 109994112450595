import {
    HTMLProps,
    SyntheticEvent, useContext, useEffect, useState, MouseEvent,
} from 'react';
import { Prompt, useRouteMatch } from 'react-router-dom';
import { Location } from 'history';
import {
    Backdrop,
    Button,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContentText,
    DialogContent,
    DialogActions,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

import ErrorButton from 'src/components/common/inputs/ErrorButton';
import ErrorMessageBar from 'src/components/common/ErrorMessageBar';
import { EditContext } from 'src/contexts/EditContext';
import { DELETE_CLICKED_EVENT, SAVE_CLICKED_EVENT, CANCEL_CLICKED_EVENT } from 'src/constants';
import { AppError } from 'src/lib/errors';
import { getHintForResponseCode } from 'src/lib/errors/utils';

interface PropTypes extends HTMLProps<{}> {
    allowSubRouteNagivation?: boolean;
    deletePrompt?: string;
    deleteDescription?: string;
    onDelete?: () => Promise<void>;
    onDeleted?: () => void;
    onSave: () => Promise<void>;
    onCancel: () => Promise<void>;
}

const DEFAULT_DELETE_PROMPT = 'Are you sure you want to delete?';

const DEFAULT_DELETE_DESCRIPTION = 'This action cannot be undone';

const useStyles = makeStyles()((theme: Theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: theme.palette.common.black,
    },
}));

const EditController = (props: PropTypes): JSX.Element => {
    const {
        allowSubRouteNagivation = true,
        children,
        deletePrompt = DEFAULT_DELETE_PROMPT,
        deleteDescription = DEFAULT_DELETE_DESCRIPTION,
        onDelete,
        onDeleted,
        onCancel,
        onSave,
    } = props;
    const { editing, setEditing } = useContext(EditContext);
    const [deleting, setDeleting] = useState(false);
    const [errorData, setErrorData] = useState<AppError | null>(null);
    const [loading, setLoading] = useState(false);
    const match = useRouteMatch();
    const { classes } = useStyles();

    // Ask the user if they want to leave the page if they are currently editing
    // If subroute navigation is allowed, allow navigation automatically
    const onNavigation = (location: Location): string | boolean => {
        const isSubroute = location.pathname.startsWith(match.url);

        if (allowSubRouteNagivation && isSubroute) {
            return true;
        }

        if (editing) {
            return 'Any changes you may have made will be lost. Are you sure you want to leave?';
        }

        return true;
    };

    // Set editing to false when the controller is unmounted to prevent
    // cross-controller editing
    useEffect(() => (): void => setEditing(false), [setEditing]);

    const showErrorMessage = (error: AppError): void => {
        setErrorData(error);
    };

    const closeErrorMessageBar = (): void => {
        setErrorData(null);
    };

    const cancelDelete = (event: SyntheticEvent): void => {
        event.preventDefault();

        setDeleting(false);
    };

    const handleDeleteEvent = (): void => {
        if (onDelete) {
            setDeleting(true);
        }
    };

    const triggerDelete = async (event: MouseEvent<HTMLButtonElement>): Promise<void> => {
        event.preventDefault();

        if (onDelete) {
            try {
                setLoading(true);
                await onDelete();
                setEditing(false);
                setDeleting(false);
            } catch (e: any) {
                e.title = 'Failed to delete resource';
                e.hint = getHintForResponseCode(e.status);

                showErrorMessage(e);
            } finally {
                setLoading(false);
                if (onDeleted) {
                    onDeleted();
                }
            }
        }
    };

    const handleSaveEvent = async (): Promise<void> => {
        try {
            setLoading(true);
            await onSave();
            setEditing(false);
        } catch (e: any) {
            e.title = 'Failed to save changes';
            e.hint = e.hint || getHintForResponseCode(e.status);

            showErrorMessage(e);
        } finally {
            setLoading(false);
        }
    };

    const handleCancelEvent = async (): Promise<void> => {
        try {
            setLoading(true);
            await onCancel();
            setEditing(false);
        } catch (e: any) {
            e.title = 'Failed to refresh the page';
            e.hint = 'Please try again or refresh the page.';

            showErrorMessage(e);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        window.addEventListener(DELETE_CLICKED_EVENT, handleDeleteEvent);
        window.addEventListener(SAVE_CLICKED_EVENT, handleSaveEvent);
        window.addEventListener(CANCEL_CLICKED_EVENT, handleCancelEvent);
        return (): void => {
            window.removeEventListener(DELETE_CLICKED_EVENT, handleDeleteEvent);
            window.removeEventListener(SAVE_CLICKED_EVENT, handleSaveEvent);
            window.removeEventListener(CANCEL_CLICKED_EVENT, handleCancelEvent);
        };
    });

    return (
        <>
            {children}
            {errorData && (
                <ErrorMessageBar
                    appError={errorData}
                    onClose={closeErrorMessageBar}
                />
            )}
            <Backdrop
                className={classes.backdrop}
                open={loading}
            >
                <CircularProgress color="primary" />
            </Backdrop>
            <Prompt message={onNavigation} />
            <Dialog
                aria-labelledby="delete-verification-dialog-title"
                aria-describedby="delete-verification-dialog-description"
                open={deleting}
                onClose={cancelDelete}
            >
                <DialogTitle id="delete-verification-dialog-title">
                    {deletePrompt}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="delete-verification-dialog-description">
                        {deleteDescription}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <ErrorButton onClick={triggerDelete}>
                        Yes
                    </ErrorButton>
                    <Button
                        autoFocus
                        variant="contained"
                        onClick={cancelDelete}
                    >
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default EditController;
