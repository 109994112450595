import { FILTER_PROPERTY_INIT_TYPE, FILTER_TYPE } from 'src/constants';

function cloneFiltersById(original: App.FiltersById): App.FiltersById {
    return {
        allIds: [...original.allIds],
        byId: new Map(original.byId),
    };
}

export const addFilterToAllFilters = (
    allFiltersById: App.FiltersById,
    id: string,
    newFilter: App.Filter,
): App.FiltersById => {
    const newAllFiltersById = cloneFiltersById(allFiltersById);

    newAllFiltersById.byId.set(id, newFilter);
    newAllFiltersById.allIds.push(id);

    return newAllFiltersById;
};

export const deleteGalleryFilter = (
    allFiltersById: App.FiltersById,
    action: Actions.RemoveGalleryFilterAction,
): App.FiltersById => {
    const { payload: { id } } = action;
    const filter = allFiltersById.byId.get(id);
    const newAllFiltersById = cloneFiltersById(allFiltersById);

    if (filter) {
        newAllFiltersById.byId.delete(id);
        const index = newAllFiltersById.allIds.indexOf(id);

        newAllFiltersById.allIds.splice(index, 1);
    }
    return newAllFiltersById;
};

export const setGalleryFilterOrder = (
    allFiltersById: App.FiltersById,
    action: Actions.GalleryFilterReorderAction,
): App.FiltersById => {
    const newAllFiltersById = cloneFiltersById(allFiltersById);
    const [removed] = newAllFiltersById.allIds.splice(action.payload.sourceIndex, 1);

    newAllFiltersById.allIds.splice(action.payload.destinationIndex, 0, removed);
    newAllFiltersById.allIds.forEach((id, index) => {
        const existing = newAllFiltersById.byId.get(id);
        const newFilter = {
            ...existing || null,
            filter: {
                ...existing?.filter || null,
                sortOrder: index,
            },
        } as App.Filter;

        newAllFiltersById.byId.set(id, newFilter);
    });
    return newAllFiltersById;
};

export const setProductFilterOptionsOrder = (
    allFiltersById: App.FiltersById,
    action: Actions.ProductFilterOptionReorderAction,
): App.FiltersById => {
    const { payload: { filterId, sourceIndex, destinationIndex } } = action;
    const newAllFiltersById = cloneFiltersById(allFiltersById);
    const filter = newAllFiltersById.byId.get(filterId) as App.AttributeFilter;
    const [removed] = filter.globalFilter.options.splice(sourceIndex, 1);

    filter.globalFilter.options.splice(destinationIndex, 0, removed);

    return newAllFiltersById;
};

export const setGalleryFilter = (allFiltersById: App.FiltersById, action: Actions.GalleryFilterAction): App.FiltersById => {
    const { id, galleryFilter } = action.payload;
    const filter = allFiltersById.byId.get(id);
    const newFilter = {
        ...filter,
        filter: galleryFilter,
    } as App.Filter;
    const newAllFiltersById = cloneFiltersById(allFiltersById);

    newAllFiltersById.byId.set(id, newFilter);

    return newAllFiltersById;
};

export const setGlobalFilter = (allFiltersById: App.FiltersById, action: Actions.GlobalFilterAction): App.FiltersById => {
    const { id, globalFilter } = action.payload;
    const filter = allFiltersById.byId.get(id);
    const newFilter = {
        ...filter,
        globalFilter,
    } as App.Filter;
    const newAllFiltersById = cloneFiltersById(allFiltersById);

    newAllFiltersById.byId.set(id, newFilter);

    return newAllFiltersById;
};

export const isAutoFilter = (filter: Services.Filters.GlobalFilter): boolean => filter.propertyInitialisationType === FILTER_PROPERTY_INIT_TYPE.AUTO;

export const getMaxSortOrder = (allFiltersById: App.FiltersById): number => {
    const lastFilterId = allFiltersById.allIds[allFiltersById.allIds.length - 1];
    const lastFilter = allFiltersById.byId.get(lastFilterId);

    return lastFilter ? lastFilter.filter.sortOrder : 0;
};

export const addTaxonomyFilterToFilters = (
    allFiltersById: App.FiltersById,
    action: Actions.AddTaxonomyFilterAction,
): App.FiltersById => {
    const { id, taxonomy } = action.payload;
    const newFilter = {
        id,
        globalFilter: taxonomy,
        type: FILTER_TYPE.taxonomy,
        filter: {
            id,
            sortOrder: getMaxSortOrder(allFiltersById) + 1,
            bestSellingCount: 5,
            collapsed: false,
            type: taxonomy.category.children.some((c) => c.children.length > 0)
                ? 'FacetedTaxonomy'
                : 'FlatTaxonomy',
        },
    } as App.TaxonomyFilter;

    return addFilterToAllFilters(allFiltersById, id, newFilter);
};

export const addGalleryAttributeFilter = (
    allFiltersById: App.FiltersById,
    action: Actions.AddAttributeFilterAction,
    type: FILTER_TYPE,
): App.FiltersById => {
    const { payload: { id, globalFilter } } = action;
    const galleryFilter: Services.Filters.GalleryFilter = {
        id,
        sortOrder: allFiltersById.allIds.length,
        isCollapsed: false,
        quickViewFilter: null,
    };
    const newContentFilter: App.Filter = {
        id,
        type,
        filter: galleryFilter,
        globalFilter,

    };

    return addFilterToAllFilters(allFiltersById, id, newContentFilter);
};

export const setFilterOptions = (
    allFiltersById: App.FiltersById,
    currentFilter: App.AttributeFilter,
    filterOptions: Services.Filters.GlobalFilterOption[],
    id: string,
): App.FiltersById => {
    const newFilter = {
        ...currentFilter,
        globalFilter: {
            ...currentFilter.globalFilter,
            options: filterOptions,
        },
    };
    const newAllFiltersById = cloneFiltersById(allFiltersById);

    newAllFiltersById.byId.set(id, newFilter);

    return newAllFiltersById;
};

export const removeFilterOption = (
    allFiltersById: App.FiltersById,
    action: Actions.GlobalFilterOptionAction,
): App.FiltersById => {
    const { payload: { id, globalFilterOption } } = action;
    const currentFilter = allFiltersById.byId.get(id) as App.AttributeFilter;

    const newFilterOptions = currentFilter.globalFilter.options.filter(
        (option) => (!globalFilterOption.value || option.value !== globalFilterOption.value)
        && (!globalFilterOption.productOptionValue
            || option.productOptionValue !== globalFilterOption.productOptionValue),
    );

    return setFilterOptions(allFiltersById, currentFilter, newFilterOptions, id);
};

export const addFilterOption = (
    allFiltersById: App.FiltersById,
    action: Actions.GlobalFilterOptionAction,
): App.FiltersById => {
    const { payload: { id, globalFilterOption } } = action;
    const currentFilter = allFiltersById.byId.get(id) as App.AttributeFilter;

    const filterOptions = currentFilter.globalFilter.options.slice();

    filterOptions.push(globalFilterOption);

    return setFilterOptions(allFiltersById, currentFilter, filterOptions, id);
};

export const setFilterOption = (
    allFiltersById: App.FiltersById,
    action: Actions.GlobalFilterOptionAction,
): App.FiltersById => {
    const { payload: { id, globalFilterOption } } = action;
    const currentFilter = allFiltersById.byId.get(id) as App.AttributeFilter;

    const filterOptions = currentFilter.globalFilter.options.slice();
    let idx = filterOptions.findIndex(
        (option) => globalFilterOption.productOptionValue
        && option.productOptionValue === globalFilterOption.productOptionValue,
    );

    // old filter options have null productOptionValue
    if (idx === -1) {
        idx = filterOptions.findIndex((option) => option.value === globalFilterOption.value);
    }

    filterOptions[idx] = globalFilterOption;

    return setFilterOptions(allFiltersById, currentFilter, filterOptions, id);
};
