/* eslint-disable max-len */
import AbstractService from 'src/services/AbstractService';
import config from 'src/lib/config';
import { ServiceError } from 'src/lib/errors';
import { logServiceError } from 'src/utils/logger';

/**
 * Gets all render properties configured for a gallery
 */
class RenderPropertiesService extends AbstractService {
    constructor() {
        super(config.services.config);
    }

    private byExperienceType(
        renderProperties: Services.RenderProperties.RenderProperty[],
    ): App.RenderPropertiesByExperienceType {
        return renderProperties.reduce((accum, renderProperty) => {
            const { experienceType, key } = renderProperty;

            return {
                ...accum,
                [experienceType]: {
                    ...accum[experienceType],
                    [key]: renderProperty,
                },
            };
        }, {} as App.RenderPropertiesByExperienceType);
    }

    public async getRenderProperties(
        galleryId: number,
    ): Promise<App.RenderPropertiesByExperienceType> {
        const requestUrl = `api/v3/Galleries/${galleryId}/RenderProperties`;

        try {
            const response = await this.api.get<Services.RenderProperties.RenderProperty[]>(requestUrl, {
                params: {
                    requestor: config.appName,
                    noCache: true,
                },
            });

            return this.byExperienceType(response.data);
        } catch (e: any) {
            const error = new ServiceError(e);

            logServiceError(error);
            throw error;
        }
    }

    public async getDefaultRenderProperties(
        bearerToken: string,
    ): Promise<App.RenderPropertiesByExperienceType> {
        const requestUrl = 'api/v3/RenderPropertyDefaults/defaults';

        try {
            const response = await this.api.get<Services.RenderProperties.RenderProperty[]>(requestUrl, {
                params: {
                    requestor: config.appName,
                    noCache: true,
                },
                headers: {
                    Authorization: `Bearer ${bearerToken}`,
                },
            });

            return this.byExperienceType(response.data);
        } catch (e: any) {
            const error = new ServiceError(e);

            logServiceError(error);
            throw error;
        }
    }

    /**
     * Updates a single gallery render property
     *
     * @param renderProp
     * @param galleryId
     * @throws ServiceError
     */
    public async putRenderProperty(
        renderProp: Services.RenderProperties.RenderProperty,
        galleryId: number,
        bearerToken: string,
    ): Promise<void> {
        const requestUrl = `api/v3/Galleries/${galleryId}/RenderProperties`;

        try {
            return await this.api.put(
                requestUrl,
                renderProp,
                {
                    params: {
                        requestor: config.appName,
                    },
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                },
            );
        } catch (e: any) {
            const error = new ServiceError(e);

            logServiceError(error);
            throw error;
        }
    }

    public async deleteRenderProperty(
        renderProp: Services.RenderProperties.RenderProperty,
        galleryId: number,
        bearerToken: string,
    ): Promise<void> {
        const requestUrl = `api/v3/Galleries/${galleryId}/RenderProperties/${renderProp.key}/${renderProp.experienceType}/`;

        try {
            return await this.api.delete(
                requestUrl,
                {
                    params: {
                        requestor: config.appName,
                    },
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                },
            );
        } catch (e: any) {
            const error = new ServiceError(e);

            logServiceError(error);
            throw error;
        }
    }

    public updateAllRenderProperties(
        oldRenderProperties: App.RenderPropertiesByExperienceType,
        renderProperties: App.RenderPropertiesByExperienceType,
        galleryId: number,
        bearerToken: string,
    ): Promise<void>[] {
        return Object.keys(oldRenderProperties).reduce(
            (accum, experienceType) => {
                const newAccum = Object.keys(oldRenderProperties[experienceType]).reduce((memo, key) => {
                    if (!renderProperties[experienceType][key]) {
                        memo.push(
                            this.deleteRenderProperty(oldRenderProperties[experienceType][key], galleryId, bearerToken),
                        );
                    } else if (renderProperties[experienceType][key] !== oldRenderProperties[experienceType][key]) {
                        memo.push(this.putRenderProperty(renderProperties[experienceType][key], galleryId, bearerToken));
                    }
                    return memo;
                }, accum);

                return newAccum;
            },
            [] as Promise<void>[],
        ).concat(Object.keys(renderProperties).reduce(
            (accum, experienceType) => {
                const newAccum = Object.keys(renderProperties[experienceType]).reduce((memo, key) => {
                    if (!oldRenderProperties[experienceType] || !oldRenderProperties[experienceType][key]) {
                        memo.push(this.putRenderProperty(renderProperties[experienceType][key], galleryId, bearerToken));
                    }
                    return memo;
                }, accum);

                return newAccum;
            },
            [] as Promise<void>[],
        ));
    }
}

export default new RenderPropertiesService();
