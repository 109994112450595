import { ChangeEvent, MouseEvent } from 'react';

import {
    TableRow,
    TableCell,
    IconButton, SelectChangeEvent,
} from '@mui/material';
import { DeleteForever } from '@mui/icons-material';

import Checkbox from 'src/components/common/inputs/Checkbox';
import PreviewGalleryIconButton from 'src/components/ManageGallery/GalleryDetails/GalleryUrls/PreviewGalleryIconButton';
import BuildTlpSelect from 'src/components/ManageGallery/GalleryDetails/GalleryNames/BuildTlpSelect';
import { BUILD_TLP } from 'src/constants';

interface PropTypes {
    culture: string;
    editing: boolean;
    isDefault?: boolean;
    name: string;
    buildTlp?: BUILD_TLP;
    onDefaultChange: (event: ChangeEvent<HTMLInputElement>, value: boolean) => void;
    onBuildTlpChange: (event: SelectChangeEvent<unknown>, value: BUILD_TLP) => void;
    onDelete: (event: MouseEvent<HTMLButtonElement>) => void;
}

const GalleryUrlRow = (props: PropTypes): JSX.Element => {
    const {
        culture,
        editing,
        isDefault,
        name,
        buildTlp,
        onDefaultChange,
        onBuildTlpChange,
        onDelete,
    } = props;

    return (
        <TableRow>
            <TableCell>
                <Checkbox
                    checked={isDefault}
                    editing={editing}
                    name={name}
                    onChange={onDefaultChange}
                />
            </TableCell>
            <TableCell>
                {culture}
            </TableCell>
            <TableCell>
                <BuildTlpSelect
                    buildTlp={buildTlp}
                    onBuildTlpChange={onBuildTlpChange}
                />
            </TableCell>
            <TableCell>
                {name}
            </TableCell>
            <TableCell align="right">
                <IconButton disabled={!editing} size="large" onClick={onDelete}>
                    <DeleteForever color="error" />
                </IconButton>
                <PreviewGalleryIconButton culture={culture} name={name} />
            </TableCell>
        </TableRow>
    );
};

export default GalleryUrlRow;
