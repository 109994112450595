import { useContext } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { makeStyles } from 'tss-react/mui';

import Card, { CardContent } from 'src/components/common/Card';
import TaxonomySelection from 'src/components/ManageGallery/GalleryCategoryRestrictions/CategoryRestrictionPicker/CategorySelector';
import CategoryRestrictionList from 'src/components/ManageGallery/GalleryCategoryRestrictions/CategoryRestrictionPicker/CategoryRestrictionList';
import ManageGalleryContext from 'src/contexts/manageGalleryContext';
import { updateCategoryRestrictions } from 'src/store/galleryData/categoryRestrictionsStore';

const useStyles = makeStyles()({
    noBorder: {
        border: 0,
    },
});

const CategoryRestrictionPicker = (): JSX.Element => {
    const { galleryData, dispatch } = useContext(ManageGalleryContext);
    const { categoryRestrictions } = galleryData;

    const { classes } = useStyles();

    const handleCategoryRestrictionSelected = (category: Services.CategoryRestrictions.CategoryRestriction): void => {
        const categoryRestriction = {
            name: category.name,
            categoryKey: category.categoryKey,
            include: false,
        };

        dispatch(updateCategoryRestrictions(
            [categoryRestriction, ...categoryRestrictions],
        ));
    };

    const handleCategoryRestrictionRemoved = (categoryKey: string): void => {
        const newCategoryRestrictions = categoryRestrictions.filter((c) => c.categoryKey !== categoryKey);

        dispatch(updateCategoryRestrictions(
            newCategoryRestrictions,
        ));
    };

    const handleCategoryRestrictionToggle = (categoryKey: string): void => {
        const updatedCategoryRestrictions = categoryRestrictions;

        const categoryRestrictionIndex = categoryRestrictions.findIndex((c) => c.categoryKey === categoryKey);

        if (categoryRestrictionIndex !== -1) {
            const categoryRestriction = categoryRestrictions[categoryRestrictionIndex];

            categoryRestriction.include = !categoryRestriction.include;

            updatedCategoryRestrictions[categoryRestrictionIndex] = categoryRestriction;

            dispatch(updateCategoryRestrictions(updatedCategoryRestrictions));
        }
    };

    return (
        <Card className={classes.noBorder}>
            <CardContent>
                <Grid container spacing={3} direction="row">
                    <Grid xs={6}>
                        <CategoryRestrictionList
                            categoryRestrictions={categoryRestrictions}
                            onCategoryRestrictionRemoved={handleCategoryRestrictionRemoved}
                            onCategoryRestrictionUpdated={handleCategoryRestrictionToggle}
                        />
                    </Grid>
                    <Grid xs={6}>
                        <TaxonomySelection
                            categoryRestrictions={categoryRestrictions}
                            onCategoryRestrictionSelected={handleCategoryRestrictionSelected}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default CategoryRestrictionPicker;
