import {
    ChangeEvent, Dispatch, SetStateAction, useState,
} from 'react';
import Grid from '@mui/material/Unstable_Grid2';

import Description from 'src/components/common/Description';
import SearchForm from 'src/components/common/TranslationKeySearch/SearchForm';
import Paging from 'src/components/common/TranslationKeySearch/Paging';
import Splash from 'src/components/common/Splash';
import TranslationKeyTable from 'src/components/common/TranslationKeySearch/TranslationKeyTable';
import useTranslationGroupSearch from 'src/hooks/useTranslationGroupSearch';
import Card, { CardContent } from 'src/components/common/Card';
import { TRANSLATION_DEFAULT_PAGE_SIZE } from 'src/constants';

interface PropTypes {
    handleTranslationKeyChange?: (key: string) => () => void;
    pageSize?: number;
    defaultPurpose?: TRANSLATION_PURPOSE;
}

const TranslationKeySearch = (props: PropTypes): JSX.Element => {
    const {
        handleTranslationKeyChange,
        pageSize = TRANSLATION_DEFAULT_PAGE_SIZE,
        defaultPurpose,
    } = props;

    const [purpose, setPurpose] = useState(defaultPurpose);

    const {
        translationsLoading,
        translationsError,
        page,
        setPage,
        keySearch,
        setKeySearch,
        valueSearch,
        setValueSearch,
        searchedTranslations,
    } = useTranslationGroupSearch(purpose);

    if (translationsError) {
        throw translationsError;
    }

    const handleFilterChange = (
        setState: Dispatch<SetStateAction<string>>,
    ) => (event: ChangeEvent<{ value: unknown }>): void => {
        setState(event.target.value as string);
        setPage(0);
    };

    const handleClear = (): void => {
        setKeySearch('');
        setValueSearch('');
        setPurpose(defaultPurpose);
    };

    return (
        <>
            {translationsLoading && (
                // TODO better loading state
                <Splash />
            )}
            {!translationsLoading && (
                <Grid container direction="column" spacing={3}>

                    <Grid xs={12}>
                        <Card>
                            <CardContent>
                                <Description>
                                    All user-facing text in a Gallery is localized. For certain fields, a Gallery must
                                    be configured to use certain localized text. This is achieved with Translation
                                    Keys. Translation keys are IDs that represent all the localized strings for a
                                    particular word or phrase and are shared across Galleries to display the localized
                                    text based on the customer locale.
                                </Description>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid xs={12} sx={{ width: 'auto' }}>
                        <SearchForm
                            filterByKey={handleFilterChange(setKeySearch)}
                            filterByValue={handleFilterChange(setValueSearch)}
                            keySearch={keySearch}
                            valueSearch={valueSearch}
                            translationsPurpose={purpose}
                            setTranslationsPurpose={setPurpose}
                            selectablePurpose={!defaultPurpose}
                            clearAll={handleClear}
                        />
                    </Grid>
                    <Grid xs={12}>
                        <Card>
                            <CardContent>
                                <TranslationKeyTable
                                    handleTranslationKeyChange={handleTranslationKeyChange}
                                    page={page}
                                    pageSize={pageSize}
                                    translations={searchedTranslations}
                                />
                                <Paging
                                    page={page}
                                    pageSize={pageSize}
                                    setPage={setPage}
                                    searchedTranslations={searchedTranslations}
                                />
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export default TranslationKeySearch;
