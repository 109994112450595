import { ChangeEvent, useContext, useState } from 'react';
import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Typography,
    Tabs,
    Tab,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

import Card, { CardContent } from 'src/components/common/Card';
import { EXPERIENCE_TYPES, RenderPropertiesSchema } from 'src/constants';
import { EditContext } from 'src/contexts/EditContext';
import RenderPropertyEnable from 'src/components/ManageGallery/GalleryRenderProperties/RenderPropertyEnable';
import Header from 'src/components/common/Header';
import TabPanel from 'src/components/common/TabPanel';
import { RenderPropertyValue } from 'src/components/ManageGallery/GalleryRenderProperties/RenderPropertyValue';
import { RenderPropertyInheritance } from 'src/components/ManageGallery/GalleryRenderProperties/RenderPropertyInheritance';
import LabelTooltip from 'src/components/common/LabelTooltip';
import { replaceNamingToIGP } from 'src/utils/renderProperties';

const RenderProperties = (): JSX.Element => {
    const { editing } = useContext(EditContext);

    const [value, setValue] = useState(0);

    const handleChange = (event: ChangeEvent<{}>, newValue: number): void => {
        setValue(newValue);
    };

    return (
        <Card>
            <CardContent>
                <Typography variant="body1">

                    Render properties are settings that allow you to configure certain behaviors in a gallery.
                    They can be edited for the entire gallery
                    or for specific use cases of a gallery (experiences).
                    Current experiences include standard gallery,
                    gallery when used as a template landing page, and gallery when used as a product page.
                    To affect all experience types, use the Base tab.
                </Typography>
                <br />
                <Typography variant="body1">
                    If you&apos;re not familiar with experience types,
                    the Base tab functions in the same manner as the original render properties page.
                    For more information, see our
                    {' '}
                    <a href="https://vistaprint.atlassian.net/wiki/spaces/GAL/pages/309396442/Gallery+Configuration+User+Guide#Render-Properties" target="_blank" rel="noreferrer">User Guide</a>
                    .
                </Typography>
                <Tabs
                    indicatorColor="primary"
                    textColor="primary"
                    value={value}
                    variant="fullWidth"
                    scrollButtons="auto"
                    onChange={handleChange}
                >
                    {EXPERIENCE_TYPES.map((exp, index) => (
                        <Tab
                            label={(
                                <LabelTooltip title={exp.tooltip}>
                                    <span>
                                        {replaceNamingToIGP(exp.name)}
                                        <InfoIcon sx={{ fontSize: '16px', marginLeft: '8px' }} />
                                    </span>
                                </LabelTooltip>
                            )}
                            value={index}
                        />
                    ))}
                </Tabs>
                {EXPERIENCE_TYPES.map((exp, index) => (
                    <TabPanel value={value} index={index}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell><Header>Enabled</Header></TableCell>
                                    <TableCell><Header>Name</Header></TableCell>
                                    <TableCell><Header>Value</Header></TableCell>
                                    <TableCell><Header>Inherited From</Header></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Object.keys(RenderPropertiesSchema).map((key: string) => (
                                    !RenderPropertiesSchema[key].hideFromList
                                        ? (
                                            <TableRow key={key}>
                                                <TableCell>
                                                    <RenderPropertyEnable
                                                        editing={editing}
                                                        id={`${key}-${exp.name}-render-prop-enable`}
                                                        propKey={key}
                                                        experienceType={exp.name}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    {key}
                                                </TableCell>
                                                <TableCell>
                                                    <RenderPropertyValue
                                                        experienceType={exp.name}
                                                        propKey={key}
                                                        editing={editing}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <RenderPropertyInheritance
                                                        experienceType={exp.name}
                                                        propKey={key}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        )
                                        : null
                                ))}
                            </TableBody>
                        </Table>
                    </TabPanel>
                ))}
            </CardContent>
        </Card>
    );
};

export default RenderProperties;
