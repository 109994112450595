import { ChangeEvent, useContext } from 'react';
import {
    Grid,
    TableRowProps,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

import TextField from 'src/components/common/inputs/TextField';
import Card, { CardContent, CardHeader } from 'src/components/common/Card';
import Checkbox from 'src/components/common/inputs/Checkbox';
import ManageNotificationChannelsContext from 'src/contexts/ManageNotificationChannelsContext';
import { updateNotificationChannel } from 'src/store/notificationChannelData/utils';

interface PropTypes extends Omit<TableRowProps, 'onChange'> {
    editing: boolean;
}

const useStyles = makeStyles()((theme: Theme) => ({
    content: {
        paddingBottom: theme.spacing(4),
    },
    scrollContainer: {
        maxHeight: theme.spacing(15),
        overflowX: 'auto',
    },
}));

const ManageTranslationGroup = (props: PropTypes): JSX.Element => {
    const {
        editing,
        ...rest
    } = props;
    const { notificationChannelData, dispatch } = useContext(ManageNotificationChannelsContext);
    const { classes } = useStyles();

    const handleChannelNameChange = (event: ChangeEvent<HTMLTextAreaElement>): void => {
        event.preventDefault();

        dispatch(updateNotificationChannel({
            ...notificationChannelData,
            channelName: event.target.value,
        }, notificationChannelData.id));
    };

    const handleDefaultForChange = (event: ChangeEvent<HTMLTextAreaElement>): void => {
        event.preventDefault();

        dispatch(updateNotificationChannel({
            ...notificationChannelData,
            defaultFor: event.target.value,
        }, notificationChannelData.id));
    };

    return (
        <Card {...rest}>
            <CardHeader>
                Translation Key Settings
            </CardHeader>
            <CardContent className={classes.content}>
                <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Label"
                            value={notificationChannelData.label}
                            underlined={false}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Channel Name"
                            editing={editing}
                            value={notificationChannelData.channelName}
                            underlined={false}
                            onChange={handleChannelNameChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Security Boundary"
                            editing={editing}
                            value={notificationChannelData.defaultFor}
                            underlined={false}
                            onChange={handleDefaultForChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Checkbox
                            checked={notificationChannelData.active}
                            name={notificationChannelData.label}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default ManageTranslationGroup;
