import {
    SET_SCENE_URI, DELETE_SCENE_TYPE, SET_SCENE_ASPECT_RATIO, ADD_SCENE_TYPE,
} from 'src/store/sceneData/constants';

export const setSceneUriAction = (
    type: string,
    uri: string,
): Actions.SceneUriAction => ({
    type: SET_SCENE_URI,
    payload: {
        type,
        uri,
    },
});

export const setSceneAspectRatioAction = (
    type: string,
    aspectRatio: string,
): Actions.SceneAspectRatioAction => ({
    type: SET_SCENE_ASPECT_RATIO,
    payload: {
        type,
        aspectRatio,
    },
});

export const deleteSceneTypeAction = (
    type: string,
): Actions.SceneTypeAction => ({
    type: DELETE_SCENE_TYPE,
    payload: {
        type,
    },
});

export const addSceneTypeAction = (
    type: string,
    uri: string,
): Actions.SceneUriAction => ({
    type: ADD_SCENE_TYPE,
    payload: {
        type,
        uri,
    },
});

export const setSceneUri = (
    scenes: Services.Scenes.Scene,
    action: Actions.SceneUriAction,
): Services.Scenes.Scene => {
    const { payload: { type, uri } } = action;
    const scene = scenes[type];

    scene.uri = uri;

    return scenes;
};

export const setSceneAspectRatio = (
    scenes: Services.Scenes.Scene,
    action: Actions.SceneAspectRatioAction,
): Services.Scenes.Scene => {
    const { payload: { type, aspectRatio } } = action;
    const scene = scenes[type];

    scene.newAspectRatio = parseFloat(aspectRatio);

    return scenes;
};

export const deleteSceneType = (
    scenes: Services.Scenes.Scene,
    action: Actions.SceneTypeAction,
): Services.Scenes.Scene => {
    const { payload: { type } } = action;

    const newScenes = {
        ...scenes,
    };

    delete newScenes[type];

    return newScenes;
};

export const addSceneType = (
    scenes: Services.Scenes.Scene,
    action: Actions.SceneUriAction,
): Services.Scenes.Scene => {
    const { payload: { type, uri } } = action;

    const sceneUri = {
        uri,
        aspectRatio: 0,
    };

    const newScenes = {
        ...scenes,
        [type]: sceneUri,
    };

    return newScenes;
};

export const scenesReducer = (
    scenes: Services.Scenes.Scene,
    action: Actions.Action,
): Services.Scenes.Scene => {
    switch (action.type) {
        case (SET_SCENE_URI):
            return setSceneUri(scenes, action as Actions.SceneUriAction);
        case (SET_SCENE_ASPECT_RATIO):
            return setSceneAspectRatio(scenes, action as Actions.SceneAspectRatioAction);
        case (DELETE_SCENE_TYPE):
            return deleteSceneType(scenes, action as Actions.SceneTypeAction);
        case (ADD_SCENE_TYPE):
            return addSceneType(scenes, action as Actions.SceneUriAction);
        default:
            return scenes;
    }
};
