import { HTMLProps, useContext } from 'react';

import EditController from 'src/components/common/EditController';
import ManageTranslationsContext from 'src/contexts/ManageTranslationsContext';
import { fetchTranslationsData } from 'src/hooks/useTranslationsData';
import { initializeTranslationsDataAction } from 'src/store/translationsData';
import { IdentityContext } from 'src/contexts/IdentityContext';

const ManageTranslationsController = (props: HTMLProps<{}>): JSX.Element => {
    const { children } = props;
    const {
        dispatch, saveTranslations, saveTranslationGroup, translationsData,
    } = useContext(ManageTranslationsContext);
    const { translationGroup: { id } } = translationsData;
    const { accessToken } = useContext(IdentityContext);

    const resetTranslations = async (): Promise<void> => {
        if (id) {
            const newTranslationsData = await fetchTranslationsData(id, accessToken);

            dispatch(initializeTranslationsDataAction(newTranslationsData));
        }
    };

    const handleSave = async (): Promise<void> => {
        await saveTranslationGroup(translationsData.translationGroup);
        await saveTranslations(id, translationsData.translations);
        await resetTranslations();
    };

    const handleCancel = async (): Promise<void> => {
        await resetTranslations();
    };

    return (
        <EditController
            onCancel={handleCancel}
            onSave={handleSave}
        >
            {children}
        </EditController>
    );
};

export default ManageTranslationsController;
