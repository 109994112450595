import { v4 } from 'uuid';
import { AxiosError, AxiosResponse, AxiosRequestConfig } from 'axios';

import { ErrorPayload, ErrorInfo } from 'src/lib/errors/types';

export const DEFAULT_STATUS = -1;

function resolveStatusHelper(info: ErrorPayload): number {
    // Use the `in` check to cover typechecking errors
    if ((info as AxiosError).response) {
        return ((info as AxiosError).response as AxiosResponse).status;
    }

    // Use the `in` check to cover typechecking errors
    if ('status' in info && info.status) {
        return info.status;
    }

    return DEFAULT_STATUS;
}

export function getGuid(): string {
    return v4();
}

export function getInfo(info?: ErrorInfo): ErrorInfo | null {
    let flatInfo: ErrorInfo | null = null;

    try {
        // Axios will put the entire config object on the exception. It's large and contains
        // circular references so just removing it.
        if (info) {
            flatInfo = new Error(info.message);
            flatInfo.stack = info.stack;
            Object.assign(flatInfo, info);

            if (flatInfo && 'config' in flatInfo) {
                delete (flatInfo as { config?: AxiosRequestConfig }).config;
            }
        }
    } catch (e: any) {
        try {
            if (!flatInfo) {
                flatInfo = new Error(`Failed to add info: ${e.message}`);
            }
        } catch (e2) {
            // best effort
        }
    }

    return flatInfo;
}

export function resolveMessage(payload: ErrorPayload | null): string {
    if (!payload) {
        return 'An unknown error occurred';
    }

    return ('message' in payload) ? payload.message : JSON.stringify(payload);
}

export function resolveTitle(payload: ErrorPayload | null): string | undefined {
    return (payload && 'title' in payload) ? payload?.title : undefined;
}
export function resolveHint(payload: ErrorPayload | null): string | undefined {
    return (payload && 'hint' in payload) ? payload.hint : undefined;
}

export function resolveStatus(payload: ErrorPayload | null): number {
    if (!payload) {
        return DEFAULT_STATUS;
    }

    if ('status' in payload && payload.status) {
        return payload.status;
    }

    return resolveStatusHelper(payload as ErrorInfo);
}

export function resolveIsAxiosError(info?: ErrorInfo): boolean {
    return !!(info as AxiosError)?.isAxiosError;
}

export function resolveRequest(info?: ErrorInfo): AxiosRequestConfig | undefined {
    return (info && 'request' in info) ? info.request : undefined;
}

export function resolveResponse(info?: ErrorInfo): AxiosResponse | undefined {
    return (info && 'response' in info) ? info.response : undefined;
}

export function getHintForResponseCode(status: number | null): string {
    switch (status) {
        case 400:
            return 'Fix errors and try again';
        case 401:
            return 'Refresh the page to login';
        case 403:
            return 'Request access to this resource in #help-gallery';
        default:
            return 'Please try again or refresh the page.';
    }
}
