import { Button, ButtonProps } from '@mui/material';
import { Edit } from '@mui/icons-material';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface EditButtonPropTypes extends ButtonProps {}

const EditButton = (props: EditButtonPropTypes): JSX.Element => (
    <Button
        {...props}
        variant="contained"
        color="primary"
        type="submit"
        startIcon={<Edit />}
    >
        Edit
    </Button>
);

export default EditButton;
