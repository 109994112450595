import { MouseEvent } from 'react';
import { DragIndicator, Delete } from '@mui/icons-material';
import {
    IconButton,
    Grid,
    TextField,
    Typography,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import { DraggableProvided } from 'react-beautiful-dnd';

import Checkbox from 'src/components/common/inputs/Checkbox';
import {
    CardCell,
    CardRow,
} from 'src/components/common/CardTable';
import TranslationKeySelect from 'src/components/common/TranslationKeySelect';
import TranslationTooltip from 'src/components/common/TranslationTooltip';
import { GLOBAL_FILTER_TYPES, TRANSLATION_PURPOSE } from 'src/constants';
import { isAutoFilter } from 'src/store/galleryData/utils';

interface PropTypes {
    draggableProvided: DraggableProvided;
    editing?: boolean;
    filterOption: Services.Filters.GlobalFilterOption;
    handleIsDefaultValueChange: (
        event: MouseEvent,
        value: boolean,
    ) => void;
    globalFilter: Services.Filters.GlobalFilter;
    overrideTranslations: App.TranslationsByCulture;
    handleTranslationKeyChange: (key: string) => void;
    handleRemoveFilter: (event: MouseEvent<HTMLButtonElement>) => void;
}

const useStyles = makeStyles()((theme: Theme) => ({
    draggable: {
        cursor: 'grab',
        '&:hover': {
            color: theme.palette.primary.main,
        },
    },
    invisible: {
        visibility: 'hidden',
    },
}));

const ProductFilterOptionsListItem = (props: PropTypes): JSX.Element => {
    const {
        draggableProvided,
        editing,
        filterOption,
        globalFilter,
        overrideTranslations,
        handleIsDefaultValueChange,
        handleTranslationKeyChange,
        handleRemoveFilter,
    } = props;
    const { classes } = useStyles();

    const enableCheckbox = (editing && globalFilter.type === GLOBAL_FILTER_TYPES.Radio && isAutoFilter(globalFilter))
    || (editing && !isAutoFilter(globalFilter));
    const isChecked = filterOption.isDefaultValue
        || filterOption.productOptionValue === globalFilter?.defaultPropertyValue;

    return (
        <CardRow
            hover
            key={`row${filterOption.value}_${filterOption.productOptionName}_${filterOption.productOptionValue}`}
            role="button"
            draggableProvided={draggableProvided}
        >
            <CardCell align="center" className={classes.draggable}>
                <DragIndicator />
            </CardCell>
            <CardCell align="center">
                {filterOption.productOptionValue}
            </CardCell>
            <CardCell align="center">
                <Checkbox
                    editing={enableCheckbox}
                    checked={isChecked}
                    onClick={(e) => handleIsDefaultValueChange(e, !isChecked)}
                />
            </CardCell>
            <CardCell align="center">
                <Grid
                    container
                    alignItems="center"
                    direction="row"
                    justifyContent="center"
                    wrap="nowrap"
                >
                    <Grid item>
                        {isAutoFilter(globalFilter) && (
                            <TextField
                                fullWidth
                                disabled
                                id="display-name-input"
                                label={(
                                    <TranslationTooltip icon overrideTranslations={overrideTranslations}>
                                        <Typography
                                            display="inline"
                                            variant="inherit"
                                        >
                                            Display Name
                                        </Typography>
                                    </TranslationTooltip>
                                )}
                                value={filterOption.productOptionValue}
                            />
                        )}
                        {!isAutoFilter(globalFilter) && (
                            <TranslationKeySelect
                                editing={editing}
                                label={false}
                                handleTranslationKeyChange={handleTranslationKeyChange}
                                helperText={false}
                                defaultPurpose={TRANSLATION_PURPOSE.FilterOptionTitle}
                                underlined={false}
                                value={filterOption.title}
                            />
                        )}
                    </Grid>
                    <Grid item>
                        {!editing && !isAutoFilter(globalFilter) && (
                            <TranslationTooltip translationKey={filterOption.title} />
                        )}
                    </Grid>
                </Grid>
            </CardCell>
            {editing && !isAutoFilter(globalFilter) && (
                <CardCell align="right">
                    <IconButton onClick={handleRemoveFilter}>
                        <Delete />
                    </IconButton>
                </CardCell>
            )}
        </CardRow>
    );
};

export default ProductFilterOptionsListItem;
