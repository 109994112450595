import {
    Dispatch, useContext, useEffect, useReducer, useState,
} from 'react';

import galleryNotificationChannelService from 'src/services/GalleryNotificationChannelService';
import {
    reducer,
    initializeGalleryNotificationChannels,
} from 'src/store/GalleryNotificationChannelData';
import { IdentityContext } from 'src/contexts/IdentityContext';
import { AppError } from 'src/lib/errors';
import saveGalleryNotificationChannel from 'src/lib/saveGalleryNotificationChannel';

type GalleryNotificationChannelsTuple = [
    App.ManageGalleryNotificationChannelsContext | null,
    boolean,
    AppError | null,
];

export const fetchGalleryNotificationChannelData = async (
    galleryId: number,
    accessToken: string | undefined,
    notificationChannelLabel?: string,
    defaultFor?: string,
    offset?: number,
    limit = 10000,
): Promise<App.GalleryNotificationChannelData> => {
    const galleryNotificationChannelSearch = {
        galleryId,
        notificationChannelLabel,
        defaultFor,
        offset,
        limit,
    };
    const galleryNotificationChannelTask = accessToken
        ? galleryNotificationChannelService.getPagableGalleryNotificationChannels(galleryNotificationChannelSearch)
        : Promise.resolve({} as Services.NotificationChannels.NotificationChannel[]);

    const [galleryNotificationChannels] = await Promise.all([
        galleryNotificationChannelTask,
    ]);

    const galleryNotificationChannelData = {
        galleryId,
        galleryNotificationChannels,
    };

    return galleryNotificationChannelData;
};

const useGalleryNotificationChannelData = (
    galleryId: number,
    defaultFor?: string,
): GalleryNotificationChannelsTuple => {
    const [error, setError] = useState<AppError | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const { accessToken } = useContext(IdentityContext);
    const [
        galleryNotificationChannelData,
        dispatch,
    ] = useReducer(reducer, null) as [
        App.GalleryNotificationChannelData | null, Dispatch<Actions.Action>
    ];

    useEffect(() => {
        const runEffect = async (): Promise<void> => {
            try {
                setLoading(true);
                const data = await fetchGalleryNotificationChannelData(galleryId, accessToken, defaultFor);

                if (!data) {
                    throw Error('Translations data could not be found');
                }

                dispatch(initializeGalleryNotificationChannels(data));
            } catch (e: any) {
                setError(new AppError(e));
            } finally {
                setLoading(false);
            }
        };

        runEffect();
    }, [accessToken, dispatch, galleryId, defaultFor]);

    const state = (galleryNotificationChannelData && accessToken)
        ? {
            dispatch,
            galleryNotificationChannelData,
            saveGalleryNotificationChannel: saveGalleryNotificationChannel(accessToken),
        }
        : null;

    return [state, loading, error];
};

export default useGalleryNotificationChannelData;
