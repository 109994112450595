import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import {
    Grid,
    Tooltip,
    ListItemIcon,
    ListItemText,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

interface PropTypes {
    href?: string;
    icon: JSX.Element;
    target?: string;
    text: ReactNode;
    to?: string;
    tooltip: string;
}

const useStyles = makeStyles()((theme: Theme) => ({
    link: {
        color: theme.palette.common.white,
        textDecoration: 'none',
        width: '100%',
    },
    iconContainer: {
        alignItems: 'center',
        color: 'inherit',
        height: '100%',
        minWidth: theme.spacing(5),
    },
    textContainer: {
        fontSize: theme.typography.fontSize,
    },
}));

const NavLink = (props: PropTypes): JSX.Element => {
    const {
        href,
        icon,
        target,
        text,
        to,
        tooltip,
    } = props;
    const { classes } = useStyles();

    const renderContent = (): JSX.Element => (
        <Grid container direction="row" wrap="nowrap">
            <Grid item>
                <ListItemIcon className={classes.iconContainer}>
                    {icon}
                </ListItemIcon>
            </Grid>
            <Grid item>
                <ListItemText primaryTypographyProps={{ className: classes.textContainer }}>
                    {text}
                </ListItemText>
            </Grid>
        </Grid>
    );

    return (
        <Tooltip title={tooltip} enterDelay={500}>
            <>
                {href && (
                    <a className={classes.link} target={target} href={href}>
                        {renderContent()}
                    </a>
                )}
                {to && (
                    <Link className={classes.link} to={to}>
                        {renderContent()}
                    </Link>
                )}
            </>
        </Tooltip>
    );
};

export default NavLink;
