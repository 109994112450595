import { Add } from '@mui/icons-material';
import { useRouteMatch } from 'react-router-dom';

import AppBar from 'src/components/common/AppBar';
import Content from 'src/components/Content';
import Main from 'src/components/Main';
import NotificationChannelSearch from 'src/components/common/NotificationChannelSearch';
import LinkButton from 'src/components/common/inputs/LinkButton';

const SearchNotificationChannelsPage = (): JSX.Element => {
    const { path } = useRouteMatch();

    return (
        <Main
            AppBar={(
                <AppBar
                    title="Search Notification Channels"
                    actions={(
                        <LinkButton
                            to={`${path}/create`}
                            variant="contained"
                            color="primary"
                            startIcon={(<Add />)}
                        >
                            Create Notification Channel
                        </LinkButton>
                    )}
                />
            )}
        >
            <Content>
                <NotificationChannelSearch />
            </Content>
        </Main>
    );
};

export default SearchNotificationChannelsPage;
