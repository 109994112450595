import { ChangeEvent, useContext, useState } from 'react';
import { Grid, Button, CircularProgress } from '@mui/material';
import { Add } from '@mui/icons-material';
import { Redirect } from 'react-router-dom';

import AppBar from 'src/components/common/AppBar';
import Content from 'src/components/Content';
import Main from 'src/components/Main';
import PurposeSelect from 'src/components/common/inputs/PurposeSelect';
import TextField from 'src/components/common/inputs/TextField';
import translationGroupService from 'src/services/TranslationGroupService';
import Card, { CardContent } from 'src/components/common/Card';
import { IdentityContext } from 'src/contexts/IdentityContext';
import { TRANSLATION_PURPOSE, VALID_TRANSLATION_PURPOSES_FOR_SAVING } from 'src/constants';
import { EditContext } from 'src/contexts/EditContext';
import { getHintForResponseCode } from 'src/lib/errors/utils';
import { AppError } from 'src/lib/errors';
import ErrorMessageBar from 'src/components/common/ErrorMessageBar';

const CreateTranslationGroup = (): JSX.Element => {
    const { setEditing } = useContext(EditContext);
    const { accessToken } = useContext(IdentityContext);
    const [purpose, setPurpose] = useState<TRANSLATION_PURPOSE>(TRANSLATION_PURPOSE.H1Title);
    const [key, setKey] = useState<string>('');
    const [
        translationGroup,
        setTranslationGroup,
    ] = useState<Services.Translations.V2.TranslationGroup | undefined>(undefined);
    const [errorData, setErrorData] = useState<AppError | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const handleKeyChange = (event: ChangeEvent<HTMLInputElement>): void => {
        event.preventDefault();

        setKey(event.target.value);
    };

    const handlePurposeChange = (newPurpose: TRANSLATION_PURPOSE): void => {
        setPurpose(newPurpose);
    };

    const handleCreateTranslationKey = async (): Promise<void> => {
        if (key && purpose && accessToken) {
            setLoading(true);
            try {
                const newTranslationGroup = await translationGroupService.createTranslationGroup({
                    key,
                    purpose,
                }, accessToken);

                setTranslationGroup(newTranslationGroup);
                setEditing(true);
            } catch (e: any) {
                e.title = 'Failed to create translation group';
                e.hint = getHintForResponseCode(e.status);

                setErrorData(e);
                setLoading(false);
            }
        }
    };

    const closeErrorMessageBar = (): void => {
        setErrorData(null);
    };

    if (translationGroup) {
        return <Redirect to={`/translations/${translationGroup.id}`} />;
    }

    return (
        <Main
            AppBar={(<AppBar title="Create New Translation Group" />)}
        >
            <Content>
                <Card>
                    <CardContent>
                        <Grid container alignItems="center" spacing={2}>
                            <Grid item xs={5}>
                                <TextField
                                    editing
                                    fullWidth
                                    required
                                    id="key-input"
                                    helperText="The key will be used to select a group of translations to be used for a specific purpose"
                                    label="Key"
                                    value={key}
                                    onChange={handleKeyChange}
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <PurposeSelect
                                    editing
                                    fullWidth
                                    required
                                    id="purpose-select"
                                    currentPurpose={purpose}
                                    helperText="Select the purpose the Translation Group will be used for"
                                    label="Select a Purpose"
                                    options={VALID_TRANSLATION_PURPOSES_FOR_SAVING}
                                    onChange={handlePurposeChange}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <Button
                                    fullWidth
                                    disabled={loading ? true : !(key && purpose)}
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    startIcon={loading ? <CircularProgress size={20} /> : (<Add />)}
                                    onClick={handleCreateTranslationKey}
                                >
                                    Create
                                </Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                    {errorData && (
                        <ErrorMessageBar
                            appError={errorData}
                            onClose={closeErrorMessageBar}
                        />
                    )}
                </Card>
            </Content>
        </Main>
    );
};

export default CreateTranslationGroup;
