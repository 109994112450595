import { ChangeEvent, useContext, useState } from 'react';
import { Grid, Button, CircularProgress } from '@mui/material';
import { Add } from '@mui/icons-material';
import { Redirect } from 'react-router-dom';

import AppBar from 'src/components/common/AppBar';
import Content from 'src/components/Content';
import Main from 'src/components/Main';
import TextField from 'src/components/common/inputs/TextField';
import Card, { CardContent } from 'src/components/common/Card';
import { IdentityContext } from 'src/contexts/IdentityContext';
import { EditContext } from 'src/contexts/EditContext';
import { getHintForResponseCode } from 'src/lib/errors/utils';
import { AppError } from 'src/lib/errors';
import ErrorMessageBar from 'src/components/common/ErrorMessageBar';
import NotificationChannelService from 'src/services/NotificationChannelService';

const CreateNotificationChannelPage = (): JSX.Element => {
    const { setEditing } = useContext(EditContext);
    const { accessToken } = useContext(IdentityContext);
    const [label, setLabel] = useState<string>('');
    const [channelName, setChannelName] = useState<string>('');
    const [defaultFor, setDefaultFor] = useState<string>('');
    const [
        notificationChannel,
        setNotificationChannel,
    ] = useState< Services.NotificationChannels.NotificationChannel | undefined>(undefined);

    const [errorData, setErrorData] = useState<AppError | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const handleLabelChange = (event: ChangeEvent<HTMLInputElement>): void => {
        event.preventDefault();

        setLabel(event.target.value);
    };

    const handleChannelNameChange = (event: ChangeEvent<HTMLInputElement>): void => {
        event.preventDefault();

        setChannelName(event.target.value);
    };
    const handleDefaultForChange = (event: ChangeEvent<HTMLInputElement>): void => {
        event.preventDefault();

        setDefaultFor(event.target.value);
    };

    const handleCreateNotificationChannel = async (): Promise<void> => {
        if (label && channelName && accessToken) {
            setLoading(true);
            try {
                const newNotificationChannel = await NotificationChannelService.createNotificationChannel({
                    label,
                    channelName,
                    defaultFor,
                    active: true,
                }, accessToken);

                setNotificationChannel(newNotificationChannel);
                setEditing(true);
            } catch (e: any) {
                e.title = 'Failed to create notification channel';
                e.hint = getHintForResponseCode(e.status);

                setErrorData(e);
                setLoading(false);
            }
        }
    };

    const closeErrorMessageBar = (): void => {
        setErrorData(null);
    };

    if (notificationChannel) {
        return <Redirect to={`/notificationChannels/${notificationChannel.id}`} />;
    }

    return (
        <Main
            AppBar={(<AppBar title="Create New Notification Channel" />)}
        >
            <Content>
                <Card>
                    <CardContent>
                        <Grid container alignItems="center" spacing={2}>
                            <Grid item xs={5}>
                                <TextField
                                    editing
                                    fullWidth
                                    required
                                    id="label-input"
                                    helperText="Helpful Channel Name"
                                    label="Label"
                                    value={label}
                                    onChange={handleLabelChange}
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <TextField
                                    editing
                                    fullWidth
                                    required
                                    id="channel-name-input"
                                    helperText="The Channel Name to send notifications to (ie. Slack Channel Name)"
                                    label="Channel Name"
                                    value={channelName}
                                    onChange={handleChannelNameChange}
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <TextField
                                    editing
                                    fullWidth
                                    id="default-for-input"
                                    helperText="Security Boundary to list this channel as a default for"
                                    label="Security Boundary"
                                    value={defaultFor}
                                    onChange={handleDefaultForChange}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <Button
                                    fullWidth
                                    disabled={loading ? true : !(label && channelName)}
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    startIcon={loading ? <CircularProgress size={20} /> : (<Add />)}
                                    onClick={handleCreateNotificationChannel}
                                >
                                    Create
                                </Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                    {errorData && (
                        <ErrorMessageBar
                            appError={errorData}
                            onClose={closeErrorMessageBar}
                        />
                    )}
                </Card>
            </Content>
        </Main>
    );
};

export default CreateNotificationChannelPage;
