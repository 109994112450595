import { ReactNode } from 'react';
import classnames from 'classnames';

import {
    TextField as MuiTextField,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import {
    StandardTextFieldProps,
    FilledTextFieldProps,
    OutlinedTextFieldProps,
} from '@mui/material/TextField';

export type TextFieldProps = (StandardTextFieldProps | FilledTextFieldProps | OutlinedTextFieldProps) & {
    editing?: boolean;
    underlined?: boolean;
    value?: ReactNode;
}

const useStyles = makeStyles()({
    plain: {
        '& .MuiInput-underline:after, .MuiInput-underline:before': {
            borderBottom: 'none',
        },
    },
    readonlyTextStyle: {
        '& input': {
            color: 'initial',
        },
    },
});

const TextField = (props: TextFieldProps): JSX.Element => {
    const {
        className,
        disabled,
        editing = false,
        label,
        helperText,
        placeholder,
        value,
        variant: variantProp = 'outlined',
        underlined = true,
        ...rest
    } = props;
    const { classes } = useStyles();

    const variant = !editing ? 'standard' : variantProp;

    return (
        <MuiTextField
            className={classnames(className, {
                [classes.plain]: !underlined,
                [classes.readonlyTextStyle]: !editing,
            })}
            disabled={!editing || disabled}
            helperText={helperText}
            label={label}
            placeholder={placeholder}
            value={value}
            variant={variant}
            {...rest}
        />
    );
};

export default TextField;
