import { useEffect, useState } from 'react';

import productService from 'src/services/ProductService';
import { getLogger } from 'src/utils/logger';
import { AppError } from 'src/lib/errors';

type ProductHook = [
    Services.Product.Product | undefined,
    boolean,
    AppError | null,
];

const useProduct = (productKey?: string | null): ProductHook => {
    const [error, setError] = useState<AppError | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<Services.Product.Product>();

    useEffect(() => {
        const runEffect = async (query: string): Promise<void> => {
            try {
                setLoading(true);
                const product = await productService.getProduct(query);

                setData(product);
            } catch (e: any) {
                getLogger().error(e);
                setError(new AppError(e));
            } finally {
                setLoading(false);
            }
        };

        if (productKey) {
            runEffect(productKey);
        } else {
            // If we clear out the productKey in a search,
            // this prevents the original product from being used in the next search
            setData(undefined);
        }
    }, [productKey]);

    return [data, loading, error];
};

export default useProduct;
