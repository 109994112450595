/**
 * A helper function to create an object from a collection with two properties:
 *   byId and allIds.
 *
 * Ids are converted to lower case
 *
 * @param collection { T[] } an array of objects
 * @param idProperty { string } name of the id property on which to index, defaults to 'id'
 * @return object
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function byId<V extends Record<string, any>, K extends string | number = string>(
    collection: V[],
    idProperty = 'id',
): App.ById<V, K> {
    type C = (V & { [index: string]: K })[];

    return (collection as C).reduce(
        (memo, val) => {
            const id = val[idProperty];

            memo.byId.set(id, val);
            (memo.allIds as K[]).push(id);
            return memo;
        },
        { byId: new Map<K, V>(), allIds: [] },
    );
}
