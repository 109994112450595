import {
    DEFAULT_IGP_EXPERIENCE_TYPE,
    DEFAULT_TLP_EXPERIENCE_TYPE,
    IGP_EXPERIENCE_TYPE,
    RenderPropertiesSchema,
    TLP_EXPERIENCE_TYPE,
} from 'src/constants';

// Clean up an render props which do not exist in the current, up-to-date schema
export const filterRenderProperties = (
    renderProperties: App.RenderPropertiesByExperienceType,
): App.RenderPropertiesByExperienceType => {
    Object.keys(renderProperties).forEach((experienceType) => {
        Object.keys(renderProperties[experienceType]).forEach((key) => {
            if (!(key in RenderPropertiesSchema)) {
                // eslint-disable-next-line
                delete renderProperties[experienceType][key];
            }
        });
    });
    return renderProperties;
};

export const replaceNamingToIGP = (experienceType?: string): string | undefined => {
    if (experienceType === TLP_EXPERIENCE_TYPE) {
        return IGP_EXPERIENCE_TYPE;
    }
    if (experienceType === DEFAULT_TLP_EXPERIENCE_TYPE) {
        return DEFAULT_IGP_EXPERIENCE_TYPE;
    }
    return experienceType;
};
