import { ChangeEvent, MouseEvent } from 'react';
import TableRow, { TableRowProps } from '@mui/material/TableRow';
import { DeleteForever } from '@mui/icons-material';
import {
    TableCell,
    IconButton,
} from '@mui/material';

import TextField from 'src/components/common/inputs/TextField';

interface PropTypes extends Omit<TableRowProps, 'onChange'> {
    culture: string;
    editing: boolean;
    onChange: (value: string) => void;
    onDelete: () => void;
    value: string;
}

const TranslationsTableRow = (props: PropTypes): JSX.Element => {
    const {
        culture,
        editing,
        onChange,
        onDelete,
        value,
        ...rest
    } = props;

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        event.preventDefault();

        onChange(event.target.value);
    };

    const handleDelete = (event: MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();

        onDelete();
    };

    return (
        <TableRow {...rest}>
            <TableCell align="center">
                {culture}
            </TableCell>
            <TableCell align="center">
                <TextField
                    fullWidth
                    editing={editing}
                    underlined={false}
                    value={value}
                    onChange={handleChange}
                />
            </TableCell>
            <TableCell align="right">
                <IconButton
                    disabled={!editing}
                    onClick={handleDelete}
                >
                    <DeleteForever />
                </IconButton>
            </TableCell>
        </TableRow>
    );
};

export default TranslationsTableRow;
