import { ChangeEvent } from 'react';

import {
    TableRow,
    TableCell,
    Grid,
    SelectChangeEvent,
} from '@mui/material';

import TextField from 'src/components/common/inputs/TextField';
import Select from 'src/components/common/inputs/Select';
import Checkbox from 'src/components/common/inputs/Checkbox';
import TranslationKeySelect from 'src/components/common/TranslationKeySelect';
import AddActionButtons, { AddActionButtonsProps } from 'src/components/common/AddToTable/AddActionButtons';
import { AddToTableRenderProps } from 'src/components/common/AddToTable';
import { TRANSLATION_PURPOSE } from 'src/constants';

interface PropTypes extends AddActionButtonsProps, Omit<AddToTableRenderProps<App.MappedFilterOption>, 'row'> {
    mappedFilterOption: App.MappedFilterOption;
    availableFilters: Services.Filters.GlobalFilterOption[];
}

const AddFilterOptionRow = (props: PropTypes): JSX.Element => {
    const {
        mappedFilterOption,
        index,
        updateRow,
        availableFilters,
        onDone,
        onDelete,
        doneButtonDisabled,
    } = props;

    //  Change selected product option AND gallery attribute the option is mapped to
    const handleProductOptionChange = (
        event: SelectChangeEvent<unknown>,
    ): void => {
        event.preventDefault();

        const productOption = event.target.value as string;
        const filterOption = availableFilters.find((f) => f.productOptionValue === productOption);

        if (filterOption) {
            const newFilterOption = {
                filterOption: {
                    ...mappedFilterOption.filterOption,
                    value: filterOption.value,
                    productOptionName: filterOption.productOptionName,
                    productOptionValue: filterOption.productOptionValue,
                },
                productOptionValue: filterOption.productOptionValue,
            };

            updateRow(index, newFilterOption);
        }
    };

    const handleDefaultValueChange = (
        event: ChangeEvent<HTMLInputElement>,
        value: boolean,
    ): void => {
        event.preventDefault();

        const newFilterOption = {
            ...mappedFilterOption,
            filterOption: {
                ...mappedFilterOption.filterOption,
                isDefaultValue: value,
            },
        };

        updateRow(index, newFilterOption);
    };

    const handleTranslationKeyChange = (key: string): void => {
        const newFilterOption = {
            ...mappedFilterOption,
            filterOption: {
                ...mappedFilterOption.filterOption,
                title: key,
            },
        };

        updateRow(index, newFilterOption);
    };

    return (
        <TableRow key={index}>
            <TableCell />
            <TableCell align="center">
                <Select
                    editing
                    value={mappedFilterOption.productOptionValue}
                    onChange={handleProductOptionChange}
                >
                    <option key="none" value="">Select product option</option>
                    {availableFilters && availableFilters.map((filter) => (
                        <option key={filter.productOptionValue} value={filter.productOptionValue}>
                            {filter.productOptionValue.toLowerCase()}
                        </option>
                    ))}
                </Select>
            </TableCell>
            <TableCell align="center">
                <TextField
                    fullWidth
                    editing={false}
                    underlined={false}
                    value={mappedFilterOption.filterOption.value}
                />
            </TableCell>
            <TableCell align="center">
                <Checkbox
                    editing
                    checked={mappedFilterOption.filterOption.isDefaultValue}
                    onChange={handleDefaultValueChange}
                />
            </TableCell>
            <TableCell align="center">
                <Grid
                    container
                    alignItems="center"
                    direction="row"
                    justifyContent="center"
                    wrap="nowrap"
                >
                    <Grid item>
                        <TranslationKeySelect
                            editing
                            label={false}
                            handleTranslationKeyChange={handleTranslationKeyChange}
                            helperText={false}
                            defaultPurpose={TRANSLATION_PURPOSE.FilterOptionTitle}
                            underlined={false}
                            value={mappedFilterOption.filterOption.title}
                        />
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell align="right">
                <AddActionButtons
                    doneButtonDisabled={doneButtonDisabled}
                    onDone={onDone}
                    onDelete={onDelete}
                />
            </TableCell>
        </TableRow>
    );
};

export default AddFilterOptionRow;
