import classnames from 'classnames';
import { ElementType } from 'react';
import { ButtonProps, Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Link } from 'react-router-dom';

interface PropTypes extends Omit<ButtonProps, 'component'> {
    buttonClassName?: string;
    component?: ElementType;
    to: string;
}

const useStyles = makeStyles()({
    removeUnderLine: {
        textDecoration: 'none',
    },
});

const LinkButton = (props: PropTypes): JSX.Element => {
    const {
        buttonClassName = '',
        className = '',
        component: Component = Button,
        to,
        ...rest
    } = props;
    const { classes } = useStyles();

    return (
        <Link to={to} className={classnames(classes.removeUnderLine, className)}>
            <Component className={buttonClassName} {...rest} />
        </Link>
    );
};

export default LinkButton;
