import { ValidationDetails } from './types';

// eslint-disable-next-line prefer-regex-literals
const ARRAY_MATCHER = new RegExp(/(.*)\[(\d+)\]/, 'gi');

function* getPathParts(path: string): Generator<string, void, string> {
    const parts = path.split('.');

    for (let i = 0; i < parts.length; i++) {
        yield parts[i];
    }
}

function getArrayInfo(part: string | void): RegExpExecArray | null {
    ARRAY_MATCHER.lastIndex = 0;
    return part ? ARRAY_MATCHER.exec(part) : null;
}

function setObject(state: ValidationDetails, key: string | void): ValidationDetails {
    if (!key) {
        return state;
    }

    if (!(key in state)) {
        // eslint-disable-next-line no-param-reassign
        state[key] = {};
    }

    return state[key] as ValidationDetails;
}

function setArray(state: ValidationDetails, array: RegExpExecArray): ValidationDetails {
    const [, key, indexStr] = array;
    const index = parseInt(indexStr, 10);

    if (!(key in state)) {
        // eslint-disable-next-line no-param-reassign
        state[key] = [] as ValidationDetails[];
    }

    const arrayState = (state[key] as ValidationDetails[]);

    arrayState[index] = {};

    return arrayState[index];
}

function buildPart(
    state: ValidationDetails,
    generator: Generator<string, void, string>,
    message: string[],
): void {
    const { value, done } = generator.next();

    if (done) {
        // eslint-disable-next-line no-param-reassign
        state.message = message;
    } else {
        const array = getArrayInfo(value);
        const nextState = array ? setArray(state, array) : setObject(state, value);

        buildPart(nextState, generator, message);
    }
}

export default function parseValidationDetails(data?: object): ValidationDetails | undefined {
    if (!data) {
        return undefined;
    }

    const state: ValidationDetails = {};

    Object.entries(data).forEach(([field, message]) => {
        const partsGenerator = getPathParts(field);

        buildPart(state, partsGenerator, message);
    });

    return state;
}
