import {
    useContext, useEffect, useReducer, useState,
    Dispatch,
} from 'react';

import getGalleryName from 'src/utils/getGalleryName';
import productService from 'src/services/ProductService';
import saveScene from 'src/lib/saveScene';
import scenceService from 'src/services/ScenesService';
import { reducer, initializeSceneDataAction } from 'src/store/sceneData';
import { IdentityContext } from 'src/contexts/IdentityContext';
import { AppError } from 'src/lib/errors';

type SceneDataTuple = [
    App.ManageSceneContext | null,
    boolean,
    AppError | null,
];

const getSceneDefinition = (
    sceneDef: Services.Scenes.SceneDefinition,
    coreProduct: Services.Product.Product,
): Services.Scenes.SceneDefinition => {
    const validProductOptions: Services.Scenes.ProductOption[] = [];
    const invalidProductOptions: Services.Scenes.ProductOption[] = [];

    sceneDef.productOptions.forEach((spo) => {
        const isValid = coreProduct.options.list.find(
            (po) => po.name === spo.optionName && po.values.includes(spo.optionValue),
        );

        if (isValid) {
            validProductOptions.push(spo);
        } else {
            invalidProductOptions.push(spo);
        }
    });

    return {
        ...sceneDef,
        productOptions: validProductOptions,
        obsoleteProductOptions: invalidProductOptions,
    };
};

export const fetchSceneData = async (sceneId: number): Promise<App.SceneData> => {
    const sceneDef = await scenceService.getScene(sceneId);
    const coreProductTask = productService.getProduct(sceneDef.productKey);
    const galleryNameTask = await getGalleryName(sceneDef.productKey);

    const [coreProduct, galleryName] = await Promise.all([coreProductTask, galleryNameTask]);

    return {
        sceneDefinition: getSceneDefinition(sceneDef, coreProduct),
        coreProductOptions: coreProduct.options,
        galleryName,
    };
};

const useSceneData = (sceneId?: number, initialData?: App.SceneData): SceneDataTuple => {
    const [error, setError] = useState<AppError | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const { accessToken } = useContext(IdentityContext);
    const [
        sceneData,
        dispatch,
    ] = useReducer(reducer, null) as [App.SceneData | null, Dispatch<Actions.Action>];

    useEffect(() => {
        const runEffect = async (): Promise<void> => {
            try {
                setLoading(true);
                const data = sceneId ? await fetchSceneData(sceneId) : initialData;

                if (!data) {
                    throw Error('Scene data could not be found');
                }

                dispatch(initializeSceneDataAction(data));
            } catch (e: any) {
                setError(new AppError(e));
            } finally {
                setLoading(false);
            }
        };

        runEffect();
    }, [dispatch, sceneId, initialData]);

    const state = (sceneData && accessToken)
        ? {
            dispatch,
            sceneData,
            saveScene: saveScene(accessToken),
        }
        : null;

    return [state, loading, error];
};

export default useSceneData;
