import Clipboard from 'clipboard';
import { useEffect, useMemo, useState } from 'react';
import {
    IconButton,
    IconButtonProps,
    Tooltip,
    TooltipProps as MuiTooltipProps,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import {
    FileCopy,
    Error,
    CheckCircle,
} from '@mui/icons-material';
import { makeStyles } from 'tss-react/mui';
import { v4 } from 'uuid';
import { getLogger } from 'src/utils/logger';

enum COPY_STATE {
    FAILED,
    INFO,
    SUCCESS,
}

interface PropTypes extends Omit<IconButtonProps, 'title'> {
    id?: string;
    text: string;
    title?: string;
    options?: Clipboard.Options;
    TooltipProps?: MuiTooltipProps;
}

const DEFAULT_TITLE = 'Click to copy to your clipboard';
const FAILED_TITLE = 'Failed to copy. Please try again';
const SUCCESS_TITLE = 'Copied!';

const useStyles = makeStyles()((theme: Theme) => ({
    copy: {
        cursor: 'pointer',
        '&:hover': {
            color: theme.palette.primary.main,
        },
    },
    success: {
        color: theme.palette.success.main,
    },
}));

function getTooltipTitle(state: COPY_STATE, defaultTitle?: string): string {
    switch (state) {
        case COPY_STATE.FAILED:
            return FAILED_TITLE;
        case COPY_STATE.SUCCESS:
            return SUCCESS_TITLE;
        default:
            return defaultTitle || DEFAULT_TITLE;
    }
}

const CopyTextButton = (props: PropTypes): JSX.Element => {
    const {
        className,
        id = `copy-text-button-${v4()}`,
        text,
        title = DEFAULT_TITLE,
        options = {},
        TooltipProps = {},
        ...rest
    } = props;
    const { classes } = useStyles();
    const [tooltipState, setTooltipState] = useState(COPY_STATE.INFO);
    const clipboard = useMemo(() => new Clipboard(`#${id}`, options), [id, options]);
    const currentTitle = getTooltipTitle(tooltipState, title);

    clipboard.on('success', () => {
        setTooltipState(COPY_STATE.SUCCESS);

        setTimeout(() => setTooltipState(COPY_STATE.INFO), 3000);
    });

    clipboard.on('error', (e) => {
        setTooltipState(COPY_STATE.FAILED);

        setTimeout(() => setTooltipState(COPY_STATE.INFO), 3000);

        getLogger().warning(e, { message: FAILED_TITLE });
    });

    useEffect(() => (): void => {
        if (clipboard) {
            clipboard.destroy();
        }
    }, [clipboard]);

    return (
        <Tooltip title={currentTitle} {...TooltipProps}>
            <IconButton
                aria-label={currentTitle}
                disabled={!COPY_STATE.INFO}
                className={className}
                id={id}
                {...rest}
                data-clipboard-text={text}
            >
                {tooltipState === COPY_STATE.FAILED && (
                    <Error color="error" />
                )}
                {tooltipState === COPY_STATE.INFO && (
                    <FileCopy className={classes.copy} />
                )}
                {tooltipState === COPY_STATE.SUCCESS && (
                    <CheckCircle className={classes.success} />
                )}
            </IconButton>
        </Tooltip>
    );
};

export default CopyTextButton;
