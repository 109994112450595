import { MouseEvent } from 'react';
import classnames from 'classnames';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

interface PropTypes extends IconButtonProps {
    closeOffset: number;
    onClick: () => void;
    open: boolean;
    openOffset: number;
}

interface StyleProps {
    closeOffset: number;
    openOffset: number;
}

const useStyles = makeStyles<StyleProps>()((theme: Theme, { openOffset, closeOffset }) => ({
    button: {
        backgroundColor: theme.palette.common.white,
        boxShadow: theme.shadows[3],
        color: theme.palette.common.black,
        padding: 0,
        position: 'fixed',
        top: theme.spacing(8),
        zIndex: 200,
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
        },
    },
    toggleClose: {
        transform: `translateX(${parseInt(theme.spacing(closeOffset), 10) - parseInt(theme.spacing(1.5), 10)}px)`,
        transition: theme.transitions.create('transform', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    toggleOpen: {
        transform: `translateX(${parseInt(theme.spacing(openOffset), 10) - parseInt(theme.spacing(1.5), 10)}px)`,
        transition: theme.transitions.create('transform', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
}));

const ExpandButton = (props: PropTypes): JSX.Element => {
    const {
        // eslint-disable-next-line
        closeOffset,
        onClick,
        open,
        // eslint-disable-next-line
        openOffset,
        ...rest
    } = props;
    const { classes } = useStyles({ openOffset, closeOffset });

    const handleClick = (event: MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();

        onClick();
    };

    return (
        <IconButton
            className={classnames(classes.button, {
                [classes.toggleClose]: !open,
                [classes.toggleOpen]: open,
            })}
            onClick={handleClick}
            {...rest}
        >
            {open && (<ChevronLeft color="inherit" />)}
            {!open && (<ChevronRight color="inherit" />)}
        </IconButton>
    );
};

export default ExpandButton;
