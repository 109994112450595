import { useRouteMatch } from 'react-router-dom';

import AppBar from 'src/components/common/AppBar';
import Content from 'src/components/Content';
import Main from 'src/components/Main';
import ManageSceneContext from 'src/contexts/ManageSceneContext';
import Splash from 'src/components/common/Splash';
import useSceneData from 'src/hooks/useSceneData';
import ZeroState from 'src/components/common/ZeroState';
import ManageSceneController from 'src/components/ManageScene/ManageSceneController';
import AppBarActions from 'src/components/ManageScene/AppBarActions';
import ManageScene from 'src/components/ManageScene';

const ManageScenePage = (): JSX.Element => {
    const { params: { sceneId: sceneIdMatch } } = useRouteMatch<{ sceneId: string }>();
    const sceneId = parseInt(sceneIdMatch, 10);
    const [state, loading, error] = useSceneData(sceneId);

    if (error) {
        // TODO better error handling?
        throw error;
    }

    return (
        <Main
            AppBar={(
                <AppBar
                    actions={<AppBarActions sceneId={sceneId} />}
                    title={`Manage Scene: ${sceneId}`}
                />
            )}
        >
            <Content>
                {loading && (<Splash />)}
                {!loading && !state && (
                    <ZeroState text="Could not find the Scene Product Options for that SceneId" />
                )}
                {!loading && state && (
                    <ManageSceneContext.Provider value={state}>
                        <ManageSceneController>
                            <ManageScene />
                        </ManageSceneController>
                    </ManageSceneContext.Provider>
                )}
            </Content>
        </Main>
    );
};

export default ManageScenePage;
