import {
    BASE_EXPERIENCE_TYPE, FILTER_PROPERTY_INIT_TYPE, FILTER_TYPE, RenderPropertiesSchema,
} from 'src/constants';
import translationService from 'src/services/TranslationService';
import config from 'src/lib/config';

const FILTER_BLACKLIST: string[] = ['color', 'imageplaceholder'];

function* findUniqueOptionNamesFromFilterOptions(filter: App.Filter): Generator<string, void> {
    const { options } = (filter.globalFilter as Services.Filters.GlobalFilter);

    for (const option of options) {
        const optionName = option.productOptionName?.toLowerCase(); // || attributeType;

        if (optionName) {
            yield optionName;
        }
    }
}

function* findUniqueOptionNamesWithFilterOptionFromFilterOptions(
    filter: App.Filter,
): Generator<[string, Services.Filters.GlobalFilterOption], void> {
    const { options } = (filter.globalFilter as Services.Filters.GlobalFilter);

    for (const option of options) {
        const optionName = option.productOptionName?.toLowerCase();

        if (optionName) {
            yield [optionName, option];
        }
    }
}

function findUniqueOptionNamesWithFilterOptionFromFilters(
    filters: App.Filter[],
): { [key: string]: Services.Filters.GlobalFilterOption } {
    return filters.reduce((accum, filter) => {
        const newAccum = { ...accum };
        const generator = findUniqueOptionNamesWithFilterOptionFromFilterOptions(filter);

        for (const [optionName, option] of generator) {
            if (!(optionName in accum)) {
                newAccum[optionName] = option;
            }
        }

        return newAccum;
    }, {} as { [key: string]: Services.Filters.GlobalFilterOption });
}

function filterOutBlackListedFilters(filters: App.Filter[]): App.Filter[] {
    return filters.filter((filter) => (
        filter.type !== FILTER_TYPE.taxonomy
        && !FILTER_BLACKLIST.includes(filter.globalFilter.name.toLowerCase())
    ));
}

function tokenizeComparativeNameFormat(format: string): string[] {
    // Find all occurrences of text within curly braces
    const matches = format.match(/\{([^}]*)\}/g);

    // Extract the text within curly braces and remove leading/trailing whitespace
    if (matches) {
        return matches.map((match) => match.slice(1, -1).trim());
    }
    return [];
}

function addOptionsToAutoFilters(
    filters: App.Filter[],
    msxAttributes: Services.Msx.MsxOptionsByName | null | undefined,
): App.Filter[] {
    return filters.map((filter) => {
        const globalFilter = filter.globalFilter as Services.Filters.GlobalFilter;

        if (msxAttributes && globalFilter.propertyInitialisationType === FILTER_PROPERTY_INIT_TYPE.AUTO) {
            const msxAttribute = msxAttributes[globalFilter.attributeType];
            const firstOptionValue = Object.values(msxAttribute.optionValues)[0];
            const englishId = firstOptionValue.translations.allIds.find((id) => id.startsWith('en'));
            const firstOptionEntry = firstOptionValue.translations.byId.get(englishId || '');

            const tempOption: Services.Filters.GlobalFilterOption = {
                value: firstOptionEntry?.value || firstOptionValue.value,
                productOptionName: msxAttribute.optionName.value,
                productOptionValue: firstOptionEntry?.value || firstOptionValue.value,
                title: firstOptionValue.value,
                isDefaultValue: true,
                otherProperties: {},
            };

            globalFilter.options = [tempOption];
        }

        return filter;
    });
}

export async function generateComparativeNamePreview(
    format: string,
    filters: App.Filter[],
    msxAttributes: Services.Msx.MsxOptionsByName | null | undefined,
): Promise<string> {
    if (!format) {
        return '';
    }
    let preview = format.slice();
    const tokens = tokenizeComparativeNameFormat(format);
    let validFilters = filterOutBlackListedFilters(filters);

    validFilters = addOptionsToAutoFilters(validFilters, msxAttributes);
    validFilters = validFilters.filter((x) => (
        x.globalFilter as Services.Filters.GlobalFilter).propertyInitialisationType !== FILTER_PROPERTY_INIT_TYPE.AUTO);
    const optionNamesAndOptions = findUniqueOptionNamesWithFilterOptionFromFilters(validFilters);

    for (const [name, option] of Object.entries(optionNamesAndOptions)) {
        if (tokens.includes(name)) {
            // eslint-disable-next-line no-await-in-loop
            const translation = await translationService.getTranslationValue(option.title, config.defaultCulture);

            preview = preview.replace(new RegExp(`{${name}}`, 'ig'), translation.value);
        }
    }

    return preview;
}

export function findUniqueOptionNamesFromFilters(filters: App.Filter[]): string[] {
    const validFilters = filterOutBlackListedFilters(filters);
    const names = validFilters.reduce((accum: Set<string>, filter) => {
        const generator = findUniqueOptionNamesFromFilterOptions(filter);

        for (const optionName of generator) {
            accum.add(optionName);
        }

        return accum;
    }, new Set<string>());

    return Array.from(names);
}

export function getRenderProperty(
    defaultProperties: App.RenderPropertiesByExperienceType,
    properties: App.RenderPropertiesByExperienceType,
    key: string,
    experienceType: string,
): App.RenderPropertyValue | undefined {
    let renderProperty: App.RenderPropertyValue | undefined;

    if (defaultProperties[BASE_EXPERIENCE_TYPE] && defaultProperties[BASE_EXPERIENCE_TYPE][key]) {
        renderProperty = {
            ...defaultProperties[BASE_EXPERIENCE_TYPE][key],
            inheritedFrom: `Default_${BASE_EXPERIENCE_TYPE}`,
        };
    }
    if (defaultProperties[experienceType] && defaultProperties[experienceType][key]) {
        renderProperty = {
            ...defaultProperties[experienceType][key],
            inheritedFrom: `Default_${experienceType}`,
        };
    }
    if (properties[BASE_EXPERIENCE_TYPE] && properties[BASE_EXPERIENCE_TYPE][key]) {
        renderProperty = {
            ...properties[BASE_EXPERIENCE_TYPE][key],
            inheritedFrom: BASE_EXPERIENCE_TYPE,
        };
    }
    if (properties[experienceType] && properties[experienceType][key]) {
        renderProperty = {
            ...properties[experienceType][key],
            inheritedFrom: experienceType,
        };
    }
    return renderProperty;
}

export function getRenderPropertyDisplay(
    defaultProperties: App.RenderPropertiesByExperienceType,
    properties: App.RenderPropertiesByExperienceType,
    key: string,
    experienceType: string,
): string | null | undefined {
    const renderProperty = getRenderProperty(defaultProperties, properties, key, experienceType);
    const renderPropertyValue = RenderPropertiesSchema[key].values?.find(
        (value) => value.dbValue === renderProperty?.value,
    );

    return renderPropertyValue?.displayValue;
}
