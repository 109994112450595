import AppBar from 'src/components/common/AppBar';
import Content from 'src/components/Content';
import CreateGallery from 'src/components/CreateGallery';
import Main from 'src/components/Main';

export type GalleryInitialization = {
    securityBoundary: string;
    culture: string;
    name?: string;
}

const CreateGalleryPage = (): JSX.Element => (
    <Main
        AppBar={(<AppBar title="Create New Gallery" />)}
    >
        <Content>
            <CreateGallery />
        </Content>
    </Main>
);

export default CreateGalleryPage;
