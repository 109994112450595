import { HTMLProps } from 'react';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

import ErrorBoundary from 'src/components/ErrorBoundary';

interface PropTypes extends HTMLProps<HTMLDivElement> {
    AppBar: JSX.Element;
}

const useStyles = makeStyles()((theme: Theme) => ({
    main: {
        display: 'grid',
        gridTemplateRows: `minmax(${theme.spacing(6)}, auto) minmax(0, 1fr)`,
        height: '100vh',
        maxHeight: '100vh',
        width: '100%',
        backgroundColor: theme.palette.grey[50],
    },
}));

const Main = (props: PropTypes): JSX.Element => {
    const { AppBar, children } = props;
    const { classes } = useStyles();

    return (
        <main className={classes.main}>
            {AppBar}
            <ErrorBoundary>
                {children}
            </ErrorBoundary>
        </main>
    );
};

export default Main;
