import { INITIALIZE_NOTIFICATION_CHANNEL_DATA } from 'src/store/notificationChannelData/constants';
import { notificationChannelReducer } from 'src/store/notificationChannelData/utils';

export const initializeNotificationChannelDataAction = (
    notificationChannelData: App.NotificationChannelData,
): Actions.NotificationChannelDataAction => ({
    type: INITIALIZE_NOTIFICATION_CHANNEL_DATA,
    payload: {
        notificationChannelData,
    },
});

export const reducer = (
    notificationChannelData: App.NotificationChannelData | null,
    action: Actions.Action,
): App.NotificationChannelData | null => {
    switch (action.type) {
        case (INITIALIZE_NOTIFICATION_CHANNEL_DATA):
            return (action as Actions.NotificationChannelDataAction).payload.notificationChannelData;
        default:
            if (!notificationChannelData) {
                return notificationChannelData;
            }
            return notificationChannelReducer(notificationChannelData, action);
    }
};
