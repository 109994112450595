import { Typography } from '@mui/material';
import { useContext } from 'react';
import ManageGalleryContext from 'src/contexts/manageGalleryContext';
import GalleryGlobalValueContext from 'src/contexts/GalleryGlobalValueContext';
import { getRenderProperty } from 'src/components/ManageGallery/GalleryRenderProperties/utils';
import { replaceNamingToIGP } from 'src/utils/renderProperties';

type PropTypes = {
    experienceType: string;
    propKey: string;
}

export const RenderPropertyInheritance = (props: PropTypes): JSX.Element => {
    const { experienceType, propKey } = props;
    const { galleryData } = useContext(ManageGalleryContext);
    const { renderProperties } = galleryData;
    const { globalGalleryData: { defaultRenderProperties } } = useContext(GalleryGlobalValueContext);
    const renderProperty = getRenderProperty(defaultRenderProperties, renderProperties, propKey, experienceType);

    return <Typography>{replaceNamingToIGP(renderProperty?.inheritedFrom)}</Typography>;
};
