import { HTMLProps, createContext, useState } from 'react';
import { NAV_DRAWER_OPEN } from 'src/constants';

const NavDrawerContext = createContext<App.NavDrawerState>({} as App.NavDrawerState);

export const NavDrawerContextProvider = (props: HTMLProps<{}>): JSX.Element => {
    const { children } = props;
    const navDrawerOpen = localStorage.getItem(NAV_DRAWER_OPEN);
    const [expanded, setExpanded] = useState<boolean>(!!(navDrawerOpen ?? true));
    const [mobile, setMobile] = useState<boolean>(false);

    return (
        <NavDrawerContext.Provider
            // eslint-disable-next-line react/jsx-no-constructed-context-values
            value={{
                expanded,
                mobile,
                setExpanded,
                setMobile,
            }}
        >
            {children}
        </NavDrawerContext.Provider>
    );
};

export default NavDrawerContext;
