import { Collapse } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { animated, useSpring } from 'react-spring';

const TransitionComponent = (props: TransitionProps): JSX.Element => {
    const { in: transitionIn } = props;
    const style = useSpring({
        from: { opacity: 0, transform: 'translate3d(20px,0,0)' },
        to: { opacity: transitionIn ? 1 : 0, transform: `translate3d(${transitionIn ? 0 : 20}px,0,0)` },
    });

    return (
        <animated.div style={style}>
            <Collapse {...props} />
        </animated.div>
    );
};

export default TransitionComponent;
