import { useEffect, useState } from 'react';
import notificationChannelService from 'src/services/NotificationChannelService';
import { AppError } from 'src/lib/errors';

type useNotificationChannelsTuple = {
    notificationChannels: Services.NotificationChannels.NotificationChannel[];
    notificationChannelsLoading: boolean;
    notificationChannelsError: AppError | null;
};

const useNotificationChannels = (defaultFor?: string): useNotificationChannelsTuple => {
    const [
        notificationChannels,
        setNotificationChannels,
    ] = useState<Services.NotificationChannels.NotificationChannel[]>([]);
    const [notificationChannelsError, setNotificationChannelsError] = useState<AppError | null>(null);
    const [notificationChannelsLoading, setNotificationChannelsLoading] = useState<boolean>(false);

    useEffect(() => {
        // Don't try to update options if component is unmounted
        let active = true;

        const fetchData = async (): Promise<void> => {
            try {
                setNotificationChannelsLoading(true);
                const channels = await notificationChannelService.getPagableNotificationChannels({
                    defaultFor: defaultFor === undefined ? undefined : defaultFor,
                });

                if (active) {
                    const x = channels;

                    setNotificationChannels(x);
                }
            } catch (e: any) {
                setNotificationChannelsError(new AppError(e));
            } finally {
                setNotificationChannelsLoading(false);
            }
        };

        fetchData();

        return (): void => {
            active = false;
        };
    }, [defaultFor]);

    return {
        notificationChannels,
        notificationChannelsLoading,
        notificationChannelsError,
    };
};

export default useNotificationChannels;
