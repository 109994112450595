import {
    Children, HTMLProps, ReactNode, cloneElement, createContext, forwardRef, isValidElement, useContext,
} from 'react';
import {
    useTheme,
    useMediaQuery,
    ListSubheader,
} from '@mui/material';
import {
    VariableSizeList,
    ListChildComponentProps,
    VariableSizeListProps,
} from 'react-window';

interface PropTypes extends HTMLProps<HTMLDivElement> {
    itemSizeSmall?: number;
    itemSizeLarge?: number;
    itemsToShow?: number;
    padding?: number;
    variableSizeListProps?: VariableSizeListProps;
}

const VirtualOuterElementContext = createContext({});

const VirtualOuterElementType = forwardRef<HTMLDivElement>((props, ref) => {
    const outerProps = useContext(VirtualOuterElementContext);

    return <div ref={ref} {...props} {...outerProps} />;
});

// Adapter for react-window
const VirtualListbox = forwardRef<HTMLDivElement, PropTypes>((props, ref): JSX.Element => {
    const theme = useTheme();
    const {
        children,
        itemSizeSmall = parseInt(theme.spacing(6), 10),
        itemSizeLarge = parseInt(theme.spacing(8), 10),
        itemsToShow = 8,
        padding = parseInt(theme.spacing(1), 10),
        variableSizeListProps = {},
        ...other
    } = props;

    const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
    const itemData = Children.toArray(children);
    const itemCount = itemData.length;
    const itemSize = smUp ? itemSizeSmall : itemSizeLarge;

    const getChildSize = (child: ReactNode): number => {
        if (isValidElement(child) && child.type === ListSubheader) {
            return itemSizeLarge;
        }

        return itemSize;
    };

    const getHeight = (): number => {
        if (itemCount > itemsToShow) {
            return itemsToShow * itemSize;
        }

        return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const renderRow = ({ data, index, style }: ListChildComponentProps): JSX.Element => (
        cloneElement(data[index], {
            style: {
                ...style,
                top: (style.top as number) + padding,
            },
        })
    );

    return (
        <div ref={ref}>
            <VirtualOuterElementContext.Provider value={other}>
                <VariableSizeList
                    itemData={itemData}
                    height={getHeight() + 2 * padding}
                    width="100%"
                    key={itemCount}
                    outerElementType={VirtualOuterElementType}
                    innerElementType="ul"
                    itemSize={(index): number => getChildSize(itemData[index])}
                    overscanCount={5}
                    itemCount={itemCount}
                    {...variableSizeListProps}
                >
                    {renderRow}
                </VariableSizeList>
            </VirtualOuterElementContext.Provider>
        </div>
    );
});

export default VirtualListbox;
