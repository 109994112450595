import { useState, useContext } from 'react';
import GalleryGlobalValueContext from 'src/contexts/GalleryGlobalValueContext';
import { addContentFilter } from 'src/store/galleryData/filterStore';
import CreateFilter from 'src/components/ManageGallery/Filters/CreateFilter';
import ManageGalleryContext from 'src/contexts/manageGalleryContext';

interface PropTypes {
    backTo: string;
}

const CreateContentFilter = (props: PropTypes): JSX.Element => {
    const { backTo } = props;
    const { globalGalleryData: { allContentFiltersById } } = useContext(GalleryGlobalValueContext);
    const [filter, setFilter] = useState<string | undefined>(undefined);
    const { galleryData: { allFiltersById } } = useContext(ManageGalleryContext);
    const allContentFiltersValues = [...allContentFiltersById.byId.values()];

    const unAssociatedFilters = allContentFiltersValues.filter((f) => !allFiltersById.byId.get(f.id));

    return (
        <CreateFilter
            backTo={backTo}
            redirectTo={backTo}
            filter={filter}
            setFilter={setFilter}
            backBarTitle="Create Content Filter"
            filters={unAssociatedFilters}
            addFilterAction={addContentFilter}
            description="Select a pre-existing, global filter to add to the gallery"
            SelectProps={{
                id: 'select-content',
                label: 'Select a Filter',
            }}
        />
    );
};

export default CreateContentFilter;
