import { Tooltip, TooltipProps } from '@mui/material';
import { Lock } from '@mui/icons-material';

import EditButton, { EditButtonPropTypes } from 'src/components/common/inputs/EditButton';

export interface DisabledEditPropTypes extends EditButtonPropTypes {
    tooltipProps: Omit<TooltipProps, 'children'>;
}

const DisabledEditButton = (props: DisabledEditPropTypes): JSX.Element => {
    const { tooltipProps, ...rest } = props;

    return (
        <Tooltip
            placement="left"
            {...tooltipProps}
        >
            <span>
                <EditButton
                    disabled
                    startIcon={<Lock />}
                    {...rest}
                >
                    Edit
                </EditButton>
            </span>
        </Tooltip>
    );
};

export default DisabledEditButton;
