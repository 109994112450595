import { HTMLProps } from 'react';
import classnames from 'classnames';

import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
    content: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        '& .MuiTableCell-stickyHeader': {
            backgroundColor: theme.palette.common.white,
        },
    },
}));

const CardContent = (props: HTMLProps<HTMLDivElement>): JSX.Element => {
    const {
        children,
        className = '',
        ...rest
    } = props;

    const { classes } = useStyles();

    return (
        <section className={classnames(classes.content, className)} {...rest}>
            {children}
        </section>
    );
};

export default CardContent;
