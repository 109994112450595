import { useState } from 'react';
import {
    Snackbar,
    SnackbarContent,
    Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

import { Collapsable, CollapsableToggle } from 'src/components/common/Collapsable';
import { AppError, ServiceError } from 'src/lib/errors';
import { ValidationDetails } from 'src/lib/errors/types';
import ValidationErrors from 'src/components/common/ErrorMessageBar/ValidationErrors';

interface PropTypes {
    onClose: () => void;
    appError: AppError | ServiceError | null;
}

const useStyles = makeStyles()((theme: Theme) => ({
    close: {
        padding: theme.spacing(0.5),
    },
    error: {
        backgroundColor: theme.palette.error.main,
        flexDirection: 'column-reverse',
    },
    collapsable: {
        paddingTop: theme.spacing(3),
    },
    collapsableToggle: {
        marginLeft: 'auto',
        marginRight: 'auto',
        color: theme.palette.common.white,
    },
    message: {
        display: 'flex',
        flexDirection: 'column',
    },

}));

const ErrorMessageBar = (props: PropTypes): JSX.Element => {
    const {
        onClose,
        appError,
    } = props;

    let validationErrorList = {} as ValidationDetails;

    if (appError !== null && (appError as ServiceError).details) {
        // eslint-disable-next-line
        validationErrorList = (appError as ServiceError).details!;
    }
    const { classes } = useStyles();
    const [expanded, setExpanded] = useState(false);
    const handleExpandClick = (): void => {
        setExpanded(!expanded);
    };

    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
            {(appError != null)
            && (
                <Snackbar
                    open
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    onClose={onClose}
                >
                    <SnackbarContent
                        className={classes.error}
                        aria-describedby="client-snackbar"
                        action={[
                            <IconButton
                                key="close"
                                aria-label="close"
                                color="inherit"
                                className={classes.close}
                                onClick={onClose}
                            >
                                <CloseIcon />
                            </IconButton>,
                        ]}
                        message={
                            (
                                <div className={classes.message}>
                                    <Typography variant="h5">
                                        {appError.title}
                                    </Typography>
                                    <Typography variant="body1">
                                        {appError.message}
                                    </Typography>
                                    {(appError as ServiceError).status === 400 && (
                                        <>
                                            <Typography>
                                                Validation Failures:
                                            </Typography>
                                            <ValidationErrors validationErrorList={validationErrorList} />
                                        </>
                                    )}
                                    {(appError as ServiceError).status === 401 && (
                                        <Typography>
                                            Authentication failure, you are not logged in.
                                        </Typography>
                                    )}
                                    {(appError as ServiceError).status === 403 && (
                                        <Typography>
                                            You are not authorized to make this change.
                                        </Typography>
                                    )}
                                    <Typography variant="body1">
                                        {appError.hint}
                                    </Typography>
                                    <Collapsable expanded={expanded}>
                                        <Typography variant="body1">
                                            {appError.stack}
                                        </Typography>
                                    </Collapsable>
                                    <CollapsableToggle
                                        className={classes.collapsableToggle}
                                        edge="end"
                                        expanded={expanded}
                                        Icon={ExpandMoreIcon}
                                        showLessText="Hide stack trace"
                                        showMoreText="Show stack trace"
                                        onClick={handleExpandClick}
                                    />
                                </div>
                            )
                        }
                    />
                </Snackbar>
            )}
        </>
    );
};

export default ErrorMessageBar;
