import { ChangeEvent, useContext } from 'react';
import {
    SelectChangeEvent, Table, TableBody, TableCell, TableHead, TableRow,
} from '@mui/material';
import AddGalleryUrls from 'src/components/ManageGallery/GalleryDetails/GalleryUrls/AddGalleryUrls';
import GalleryUrlRow from 'src/components/ManageGallery/GalleryDetails/GalleryUrls/GalleryUrlRow';
import Header from 'src/components/common/Header';
import ManageGalleryContext from 'src/contexts/manageGalleryContext';
import Card, { CardContent, CardHeader } from 'src/components/common/Card';
import { EditContext } from 'src/contexts/EditContext';
import { updateGallery } from 'src/store/galleryData/galleryStore';
import { BUILD_TLP } from 'src/constants';

type GalleryUrlDefaultChangeEvent = (event: ChangeEvent<HTMLInputElement>, value: boolean) => void;
type GalleryUrlBuildTlpChangeEvent = (event: SelectChangeEvent<unknown>, value: BUILD_TLP) => void;
type DeleteGalleryUrlEvent = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;

const GalleryUrls = (): JSX.Element => {
    const { galleryData, dispatch } = useContext(ManageGalleryContext);
    const { gallery } = galleryData;
    const { galleryNames } = gallery;
    const { editing } = useContext(EditContext);

    const updateGalleryUrl = (index: number, newGalleryUrl: Services.Galleries.GalleryUrl): void => {
        const newGalleryUrls = galleryNames.slice();

        newGalleryUrls[index] = newGalleryUrl;
        dispatch(updateGallery({
            ...gallery,
            galleryNames: newGalleryUrls,
        }));
    };

    const handleIsDefaultChange = (index: number): GalleryUrlDefaultChangeEvent => (
        event: ChangeEvent<HTMLInputElement>,
        value: boolean,
    ): void => {
        event.preventDefault();

        const newGalleryUrl = {
            ...galleryNames[index],
            isDefault: value,
        };

        updateGalleryUrl(index, newGalleryUrl);
    };

    const handleBuildTlpChange = (index: number): GalleryUrlBuildTlpChangeEvent => (
        event: SelectChangeEvent<unknown>,
        value: BUILD_TLP,
    ): void => {
        event.preventDefault();

        const newGalleryUrl = {
            ...galleryNames[index],
            buildTlp: value,
        };

        updateGalleryUrl(index, newGalleryUrl);
    };

    const deleteGalleryName = (index: number): DeleteGalleryUrlEvent => (
        event: React.MouseEvent<HTMLButtonElement>,
    ): void => {
        event.preventDefault();

        // slice first to clone
        const newGalleryUrls = galleryNames.slice();

        newGalleryUrls.splice(index, 1);

        dispatch(updateGallery({
            ...gallery,
            galleryNames: newGalleryUrls,
        }));
    };

    return (
        <Card>
            <CardHeader>
                Gallery URLs
            </CardHeader>
            <CardContent>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Header>Is Default</Header>
                            </TableCell>
                            <TableCell>
                                <Header>Culture</Header>
                            </TableCell>
                            <TableCell>
                                <Header>Build IGP</Header>
                            </TableCell>
                            <TableCell colSpan={3}>
                                <Header>Gallery URL</Header>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {galleryNames.map((url: Services.Galleries.GalleryUrl, index: number): JSX.Element => (
                            <GalleryUrlRow
                                {...url}
                                editing={editing}
                                key={`${url.culture}-${url.name}`}
                                onDefaultChange={handleIsDefaultChange(index)}
                                onBuildTlpChange={handleBuildTlpChange(index)}
                                onDelete={deleteGalleryName(index)}
                            />
                        ))}
                        {editing && (<AddGalleryUrls />)}
                    </TableBody>
                </Table>
            </CardContent>
        </Card>
    );
};

export default GalleryUrls;
