import { SyntheticEvent, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import CardMedia, { CardMediaProps } from '@mui/material/CardMedia';
import {
    CircularProgress,
    useTheme,
    Grid,
    Tooltip,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import useScenePreviewUrlRoot from 'src/hooks/useScenePreviewUrlRoot';
import { Warning } from '@mui/icons-material';
import { getLogger } from 'src/utils/logger';

export interface PropTypes extends CardMediaProps {
    galleryName: string | null;
    productOptions: Services.Scenes.ProductOption[];
    sceneUri: string;
}

const DEFAULT_MESSAGE = 'An error occurred loading the preview. Refresh to try again';

const useStyles = makeStyles()({
    contain: {
        height: '100%',
    },
    image: {
        height: '100%',
        objectFit: 'contain',
    },
});

const ScenePreview = (props: PropTypes): JSX.Element => {
    const {
        className,
        galleryName,
        productOptions,
        sceneUri,
    } = props;
    const { classes } = useStyles();
    const theme = useTheme();
    const [isErrored, setIsErrored] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [scenePreviewUrlRoot] = useScenePreviewUrlRoot(galleryName, productOptions);
    const [ref, inView] = useInView({
        triggerOnce: true,
        rootMargin: `${theme.spacing(20)} 0px`,
    });

    const handleError = (event: SyntheticEvent<HTMLImageElement>): void => {
        setIsErrored(true);
        setIsLoading(false);
        getLogger().error(event);
    };

    if (inView) {
        // Load this image from network as soon as it's visible
        // Once loaded, the loading state will be removed and
        // the CardMedia component will load the image from the cache
        const preloader = new Image();

        preloader.onload = (): void => setIsLoading(false);
        preloader.src = `${scenePreviewUrlRoot}&scene=${encodeURIComponent(sceneUri)}`;
    }

    return (
        <Grid
            container
            alignItems="center"
            className={className}
            justifyContent="center"
            ref={ref}
        >
            {isLoading && (
                <Grid item>
                    <CircularProgress />
                </Grid>
            )}
            {(!isLoading && isErrored) && (
                <Grid item>
                    <Tooltip title={DEFAULT_MESSAGE}>
                        <Warning color="error" />
                    </Tooltip>
                </Grid>
            )}
            {(!isLoading && !isErrored) && (
                <Grid item className={classes.contain}>
                    <CardMedia
                        alt="Scene Preview"
                        className={classes.image}
                        component="img"
                        src={`${scenePreviewUrlRoot}&scene=${encodeURIComponent(sceneUri)}`}
                        title="Scene Preview"
                        onError={handleError}
                    />
                </Grid>
            )}
        </Grid>
    );
};

export default ScenePreview;
