import config from 'src/lib/config';
import AbstractService from 'src/services/AbstractService';
import { getLogger } from 'src/utils/logger';
import { ServiceError } from 'src/lib/errors';

class ProductService extends AbstractService {
    constructor() {
        super(config.services.product);
    }

    public async getProduct(productKey: string): Promise<Services.Product.Product> {
        const url = `api/products/${productKey}`;

        try {
            const response = await this.api.get<Services.Product.Product>(url, {
                params: {
                    requestor: config.appName,
                },
            });

            return response.data;
        } catch (e: any) {
            getLogger().error(e);
            throw new ServiceError(e);
        }
    }

    public async getNullableProduct(productKey: string): Promise<Services.Product.Product | null> {
        const url = `api/products/${productKey}`;

        try {
            const response = await this.api.get<Services.Product.Product>(url, {
                params: {
                    requestor: config.appName,
                },
            });

            return response.data;
        } catch (e: any) {
            getLogger().error(e);
            return null;
        }
    }
}

export default new ProductService();
