import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import {
    SimpleTreeView, TreeItemProps, TreeItem, SingleSelectTreeViewProps,
} from '@mui/x-tree-view';
import { ExpandMore, ChevronRight } from '@mui/icons-material';
import {
    alpha,
    createStyles,
    Theme,
} from '@mui/material/styles';
import { makeStyles, withStyles } from 'tss-react/mui';
import { useSpring, animated } from 'react-spring';
import { TransitionProps } from '@mui/material/transitions';

interface PropTypes extends SingleSelectTreeViewProps {
    taxonomy: App.TaxonomyFilter;
    maxDepth: number;
}

const EndIcon = (): JSX.Element => <span />;

const TransitionComponent = (props: TransitionProps): JSX.Element => {
    const { in: transitionIn } = props;
    const style = useSpring({
        from: { opacity: 0, transform: 'translate3d(20px,0,0)' },
        to: { opacity: transitionIn ? 1 : 0, transform: `translate3d(${transitionIn ? 0 : 20}px,0,0)` },
    });

    return (
        <animated.div style={style}>
            <Collapse {...props} />
        </animated.div>
    );
};

const StyledTreeItem = withStyles((props: TreeItemProps) => (
    <TreeItem {...props} slots={{ groupTransition: TransitionComponent }} />
), (theme: Theme) => createStyles({
    iconContainer: {
        '& .close': {
            opacity: 0.3,
        },
    },
    group: {
        marginLeft: 12,
        paddingLeft: 12,
        borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
    },
}));

function renderTreeItems(maxDepth: number): (
    item: Services.Filters.Category,
    depth: number,
) => JSX.Element {
    let id = 0;

    return function renderTreeItem(category: Services.Filters.Category, depth: number): JSX.Element {
        id += 1;

        return (
            <StyledTreeItem key={category.name} itemId={id.toString()} label={category.name}>
                { depth < maxDepth
                    ? category.children.map((child) => renderTreeItem(child, depth + 1))
                    : null }
            </StyledTreeItem>
        );
    };
}

const useStyles = makeStyles()(
    createStyles({
        root: {
            flexGrow: 1,
            width: '100%',
        },
    }),
);

const SubCategoryTree = (props: PropTypes): JSX.Element | null => {
    const { taxonomy, maxDepth } = props;
    const { classes } = useStyles();

    return taxonomy
        ? (
            <Grid container alignItems="center" direction="column">
                <SimpleTreeView
                    className={classes.root}
                    defaultExpandedItems={['1']}
                    slots={{ collapseIcon: ExpandMore, expandIcon: ChevronRight, endIcon: EndIcon }}
                >
                    {renderTreeItems(maxDepth)(taxonomy.globalFilter.category, 0)}
                </SimpleTreeView>
            </Grid>
        )
        : null;
};

export default SubCategoryTree;
