import galleriesService from 'src/services/GalleriesService';
import galleryFiltersService from 'src/services/GalleryFiltersService';
import galleryTaxonomiesService from 'src/services/GalleryTaxonomiesService';
import renderPropertiesService from 'src/services/RenderPropertiesService';
import globalFiltersService from 'src/services/GlobalFiltersService';
import { FILTER_TYPE } from 'src/constants';
import galleryCategoryRestrictionsService from 'src/services/GalleryCategoryRestrictionsService';
import { filterRenderProperties } from 'src/utils/renderProperties';

const saveGallery = (bearerToken: string) => async (galleryData: App.GalleryData): Promise<void> => {
    const { allFiltersById, gallery: { id } } = galleryData;
    const { categoryRestrictions } = galleryData;

    // Gather existing data to know what we need to remove
    const oldGalleryFiltersTask = galleryFiltersService.getGalleryFilters(id);
    const oldGalleryTaxonomiesTask = galleryTaxonomiesService.getGalleryTaxonomies(id);
    const oldRenderPropertiesTask = renderPropertiesService.getRenderProperties(id);
    const oldCategoryRestrictionsTask = galleryCategoryRestrictionsService.getCategoryRestrictions(id);
    const [
        oldGalleryFilters,
        oldGalleryTaxonomies,
        oldRenderProperties,
        oldCategoryRestrictions,
    ] = await Promise.all([
        oldGalleryFiltersTask,
        oldGalleryTaxonomiesTask,
        oldRenderPropertiesTask,
        oldCategoryRestrictionsTask,
    ]);

    const putOrPostPrivateFilter = async (currentFilter: App.Filter): Promise<void> => {
        const { id: filterId } = currentFilter;

        let oldFilter: Services.Filters.GlobalFilter|null = null;

        try {
            oldFilter = await globalFiltersService.getGlobalFilter(filterId);
        } catch (e: any) {
            if (e.status !== 404) {
                throw e;
            }
        }

        // if this filter already existed do a PUT, otherwise a POST
        if (oldFilter) {
            return globalFiltersService.putGlobalFilter(
                currentFilter.globalFilter as Services.Filters.GlobalFilter,
                bearerToken,
            );
        }

        return globalFiltersService.postGlobalFilter(
            currentFilter.globalFilter as Services.Filters.GlobalFilter,
            bearerToken,
        );
    };

    const putOrPostPrivateFiltersTasks = allFiltersById.allIds.reduce(
        (memo, filterId) => {
            const currentFilter = allFiltersById.byId.get(filterId);

            // Don't attempt to write Global filters to the DB
            // aka color/photologo/etc.
            if (currentFilter && currentFilter.type === FILTER_TYPE.private) {
                // if this filter already existed do a PUT, otherwise a POST
                memo.push(putOrPostPrivateFilter(currentFilter as App.Filter));
            }
            return memo;
        },
        [] as Promise<void>[],
    );

    // Update values through the content API
    const deleteGalleryTaxonomiesTasks = oldGalleryTaxonomies.reduce(
        (memo, taxonomy) => {
            const currentFilter = allFiltersById.byId.get(taxonomy.id);

            if (!currentFilter) {
                memo.push(galleryTaxonomiesService.deleteGalleryTaxonomy(taxonomy, id, bearerToken));
            }
            return memo;
        },
        [] as Promise<void>[],
    );

    const deleteGalleryFiltersTasks = oldGalleryFilters.reduce(
        (memo, filter) => {
            const currentFilter = allFiltersById.byId.get(filter.id);

            if (!currentFilter) {
                memo.push(galleryFiltersService.deleteGalleryFilter(filter, id, bearerToken));
            }
            return memo;
        },
        [] as Promise<void>[],
    );

    const putConfigTask = galleriesService.putGallery(galleryData.gallery, bearerToken);
    const putRenderPropsTask = renderPropertiesService.updateAllRenderProperties(
        filterRenderProperties(oldRenderProperties),
        galleryData.renderProperties,
        id,
        bearerToken,
    );

    // We can't write the gallery filters until we are sure the global filters have been written
    // Those ids are necessary to exist in the DB first
    await Promise.all(putOrPostPrivateFiltersTasks);

    const putGalleryFiltersTask = allFiltersById.allIds.reduce(
        (memo, filterId) => {
            const currentFilter = allFiltersById.byId.get(filterId);

            if (currentFilter) {
                if (currentFilter.type === FILTER_TYPE.taxonomy) {
                    const taxonomyFilter = currentFilter as App.TaxonomyFilter;

                    // Never update global taxonomy, only gallery taxonomy
                    memo.push(galleryTaxonomiesService.putGalleryTaxonomy(taxonomyFilter.filter, id, bearerToken));
                } else {
                    const attributeFilter = currentFilter as App.AttributeFilter;
                    const putGalleryFilterTask = galleryFiltersService.putGalleryFilter(
                        attributeFilter.filter,
                        id,
                        bearerToken,
                    );

                    memo.push(putGalleryFilterTask);
                }
            }

            return memo;
        },
        [] as Promise<void>[],
    );

    const updateCategoryRestrictionsTask = galleryCategoryRestrictionsService.updateCategoryRestrictions(
        id,
        oldCategoryRestrictions,
        categoryRestrictions.map((cr) => ({ categoryKey: parseInt(cr.categoryKey, 10), include: cr.include })),
        bearerToken,
    );

    await Promise.all([
        ...putGalleryFiltersTask,
        putConfigTask,
        ...putRenderPropsTask,
        ...deleteGalleryFiltersTasks,
        ...deleteGalleryTaxonomiesTasks,
        ...updateCategoryRestrictionsTask,
    ]);
};

export default saveGallery;
