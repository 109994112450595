import {
    Link,
    TableRow,
    TableCell,
    Typography,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import { Check, Error, Warning } from '@mui/icons-material';

import { Link as RouterLink } from 'react-router-dom';

import { VALIDATION_RESULT } from 'src/constants';
import { MANAGE_TRANSLATIONS, MANAGE_SCENES } from 'src/utils/routes';

const useStyles = makeStyles()((theme: Theme) => ({
    success: {
        color: theme.palette.success.main,
    },
    error: {
        color: theme.palette.error.main,
    },
    warning: {
        color: theme.palette.warning.main,
    },
}));

const translationGroupRegex = /Translation Group Id: (\d+)/;
const sceneRegex = /Scene Id: (\d+)/;

interface PropTypes {
    validation: Services.GalleryValidation.ValidationResult;
    message: string;
}

const ValidationRow = (props: PropTypes): JSX.Element => {
    const { validation, message } = props;
    const { classes } = useStyles();

    const BuildTranslationGroupLink = (m: string): string => {
        const regexResult = translationGroupRegex.exec(m);

        return regexResult?.length === 2
            ? `${MANAGE_TRANSLATIONS
                .replace(':translationGroupId', regexResult[1])
            }` : '';
    };

    const BuildSceneLink = (m: string): string => {
        const regexResult = sceneRegex.exec(m);

        return regexResult?.length === 2
            ? `${MANAGE_SCENES
                .replace(':sceneId', regexResult[1])
            }` : '';
    };

    return (
        <TableRow>
            <TableCell>
                {validation.result === VALIDATION_RESULT.PASSED
                    && (<Check className={classes.success} />)}
                {validation.result === VALIDATION_RESULT.FAILED
                    && (<Error className={classes.error} />)}
                {validation.result === VALIDATION_RESULT.WARNING
                    && (<Warning className={classes.warning} />)}
            </TableCell>
            <TableCell>
                {validation.name}
            </TableCell>
            <TableCell>
                <Typography component="span">
                    {message}
                </Typography>
                {validation.name === 'Translations' && translationGroupRegex.test(message) && (
                    <>
                        <Typography component="span">
                            {'. '}
                        </Typography>
                        <Typography component="span">
                            <RouterLink
                                target="_blank"
                                to={BuildTranslationGroupLink(message)}
                            >
                                Click to fix
                            </RouterLink>
                        </Typography>
                    </>
                )}
                {validation.name === 'Scene Definition Options' && sceneRegex.test(message) && (
                    <>
                        <Typography component="span">
                            {'. '}
                        </Typography>
                        <Typography component="span">
                            <RouterLink
                                target="_blank"
                                to={BuildSceneLink(message)}
                            >
                                Click to fix
                            </RouterLink>
                        </Typography>
                    </>
                )}
            </TableCell>
            <TableCell>
                <Link target="_blank" href={validation.documentation}>
                    {validation.documentation}
                </Link>
            </TableCell>
        </TableRow>
    );
};

export default ValidationRow;
