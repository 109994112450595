import Select, { SelectProps } from 'src/components/common/inputs/Select';
import { useContext } from 'react';
import { EditContext } from 'src/contexts/EditContext';
import { BUILD_TLP } from 'src/constants';
import { SelectChangeEvent } from '@mui/material';

interface PropTypes extends SelectProps {
    editing?: boolean;
    buildTlp?: BUILD_TLP;
    onBuildTlpChange: (event: SelectChangeEvent<unknown>, value: BUILD_TLP) => void;
}

const BuildTlpSelect = (props: PropTypes): JSX.Element => {
    const {
        buildTlp,
        onBuildTlpChange,
        ...rest
    } = props;

    const { editing } = useContext(EditContext);

    const handleBuildTlpChange = (event: SelectChangeEvent<unknown>): void => {
        event.preventDefault();

        let newValue;

        switch (event.target.value) {
            case 'On':
                newValue = BUILD_TLP.On;
                break;
            case 'OnlyL0':
                newValue = BUILD_TLP.OnlyL0;
                break;
            default:
                newValue = BUILD_TLP.Off;
        }

        onBuildTlpChange(event, newValue);
    };

    return (
        <Select
            {...rest}
            value={buildTlp}
            editing={editing}
            onChange={handleBuildTlpChange}
        >
            {Object.values(BUILD_TLP).map((value) => (
                <option key={value} value={value}>
                    {value}
                </option>
            ))}
        </Select>
    );
};

export default BuildTlpSelect;
