import classnames from 'classnames';

import CloseIcon from '@mui/icons-material/Close';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
    Dialog,
    DialogProps,
    DialogContent,
    DialogTitle,
    Typography,
    IconButton,
    useTheme,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

import TranslationKeySearch from 'src/components/common/TranslationKeySearch';

interface PropTypes extends DialogProps {
    handleTranslationKeyChange: (key: string) => () => void;
    onClose: () => void;
    defaultPurpose: TRANSLATION_PURPOSE;
}

const useStyles = makeStyles()((theme: Theme) => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    justALittleTaller: {
        height: `calc(100% - ${theme.spacing(14)})`,
    },
}));

const SearchKeysDialog = (props: PropTypes): JSX.Element => {
    const {
        handleTranslationKeyChange,
        onClose,
        defaultPurpose,
        ...rest
    } = props;
    const { classes } = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Dialog
            fullWidth
            fullScreen={fullScreen}
            maxWidth="lg"
            PaperProps={{
                className: classnames({
                    [classes.justALittleTaller]: !fullScreen,
                }),
            }}
            onClose={onClose}
            {...rest}
        >
            <DialogTitle id="alert-dialog-title">
                <Typography variant="h6">
                    Find a Translation Key
                </Typography>
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        className={classes.closeButton}
                        onClick={onClose}
                    >
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </DialogTitle>
            <DialogContent>
                <TranslationKeySearch
                    handleTranslationKeyChange={handleTranslationKeyChange}
                    pageSize={6}
                    defaultPurpose={defaultPurpose}
                />
            </DialogContent>
        </Dialog>
    );
};

export default SearchKeysDialog;
