import { SET_PRODUCT_OPTION, DELETE_PRODUCT_OPTION } from 'src/store/sceneData/constants';

export const setProductOptionAction = (
    productOption: Services.Scenes.ProductOption,
): Actions.SceneProductOptionAction => ({
    type: SET_PRODUCT_OPTION,
    payload: {
        productOption,
    },
});

export const deleteProductOptionAction = (
    productOption: Services.Scenes.ProductOption,
): Actions.SceneProductOptionAction => ({
    type: DELETE_PRODUCT_OPTION,
    payload: {
        productOption,
    },
});

export const setProductOption = (
    productOptions: Services.Scenes.ProductOption[],
    action: Actions.SceneProductOptionAction,
): Services.Scenes.ProductOption[] => {
    const { payload: { productOption } } = action;
    const newProductOptions = productOptions.slice();
    const optionToUpdateIndex = newProductOptions.findIndex((po) => po.optionName === productOption.optionName);

    if (optionToUpdateIndex >= 0) {
        newProductOptions[optionToUpdateIndex] = productOption;
    } else {
        newProductOptions.push(productOption);
    }

    return newProductOptions;
};

export const deleteProductOption = (
    productOptions: Services.Scenes.ProductOption[],
    action: Actions.SceneProductOptionAction,
): Services.Scenes.ProductOption[] => {
    const { payload: { productOption } } = action;
    const newProductOptions = productOptions.slice();
    const optionToDeleteIndex = newProductOptions.findIndex((po) => po.optionName === productOption.optionName);

    if (optionToDeleteIndex >= 0) {
        newProductOptions.splice(optionToDeleteIndex, 1);
    }

    return newProductOptions;
};

export const productOptionsReducer = (
    productOptions: Services.Scenes.ProductOption[],
    action: Actions.Action,
): Services.Scenes.ProductOption[] => {
    switch (action.type) {
        case (SET_PRODUCT_OPTION):
            return setProductOption(productOptions, action as Actions.SceneProductOptionAction);
        case (DELETE_PRODUCT_OPTION):
            return deleteProductOption(productOptions, action as Actions.SceneProductOptionAction);
        default:
            return productOptions;
    }
};
