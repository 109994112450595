import { HTMLProps, useContext } from 'react';

import EditController from 'src/components/common/EditController';
import ManageNotificationChannelsContext from 'src/contexts/ManageNotificationChannelsContext';
import { fetchNotificationChannelData } from 'src/hooks/useNotificationChannelData';
import { initializeNotificationChannelDataAction } from 'src/store/notificationChannelData';
import { IdentityContext } from 'src/contexts/IdentityContext';

const ManageNotificationChannelController = (props: HTMLProps<{}>): JSX.Element => {
    const { children } = props;
    const {
        dispatch, saveNotificationChannel, notificationChannelData,
    } = useContext(ManageNotificationChannelsContext);
    const { accessToken } = useContext(IdentityContext);

    const resetNotificationChannel = async (): Promise<void> => {
        if (notificationChannelData) {
            const newNotificationChannelData = await fetchNotificationChannelData(
                notificationChannelData.id,
                accessToken,
            );

            dispatch(initializeNotificationChannelDataAction(newNotificationChannelData));
        }
    };

    const handleSave = async (): Promise<void> => {
        await saveNotificationChannel(notificationChannelData);
        await resetNotificationChannel();
    };

    const handleCancel = async (): Promise<void> => {
        await resetNotificationChannel();
    };

    return (
        <EditController
            onCancel={handleCancel}
            onSave={handleSave}
        >
            {children}
        </EditController>
    );
};

export default ManageNotificationChannelController;
