import {
    UPDATE_GALLERY_NOTIFICATION_CHANNEL,
} from 'src/store/GalleryNotificationChannelData/constants';

export const saveGalleryNotificationChannels = (
    galleryNotificationChannelData: App.GalleryNotificationChannelData,
): Actions.SaveGalleryNotificationChannelAction => ({
    type: UPDATE_GALLERY_NOTIFICATION_CHANNEL,
    payload: {
        galleryNotificationChannelData,
    },
});

export const UpdateGalleryNotifcationChannels = (
    galleryNotificationChannelData: App.GalleryNotificationChannelData,
): Services.NotificationChannels.NotificationChannel[] => galleryNotificationChannelData.galleryNotificationChannels;

export const galleryNotificationChannelReducer = (
    galleryNotificationChannelData: App.GalleryNotificationChannelData,
    action: Actions.Action,
): Services.NotificationChannels.NotificationChannel[] => {
    switch (action.type) {
        case UPDATE_GALLERY_NOTIFICATION_CHANNEL:
            return UpdateGalleryNotifcationChannels(
                (action as Actions.SaveGalleryNotificationChannelAction).payload.galleryNotificationChannelData,
            );
        default:
            return galleryNotificationChannelData.galleryNotificationChannels;
    }
};
