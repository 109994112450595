import {
    useContext, useEffect, useMemo, useState,
} from 'react';
import {
    Grid,
    Box,
    useTheme,
    SelectChangeEvent,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

import Card, { CardContent } from 'src/components/common/Card';
import { CollapsableToggle, Collapsable } from 'src/components/common/Collapsable';
import Select from 'src/components/common/inputs/Select';
import SubCategoryTree, { Category } from 'src/components/ManageGallery/GalleryCategoryRestrictions/CategoryRestrictionPicker/CategoryTree';
import GalleryGlobalValueContext from 'src/contexts/GalleryGlobalValueContext';
import { EditContext } from 'src/contexts/EditContext';

interface PropTypes {
    categoryRestrictions: Services.CategoryRestrictions.CategoryRestriction[];
    onCategoryRestrictionSelected: (category: Services.CategoryRestrictions.CategoryRestriction) => void;
}

const useStyles = makeStyles()((theme: Theme) => ({
    fullWidth: {
        width: '100%',
    },
    padded: {
        paddingTop: theme.spacing(2),
    },
}));

const CategorySelector = (props: PropTypes): JSX.Element => {
    const {
        categoryRestrictions, onCategoryRestrictionSelected,
    } = props;

    const { classes } = useStyles();
    const theme = useTheme();

    const { editing } = useContext(EditContext);
    const { globalGalleryData: { allTaxonomiesById } } = useContext(GalleryGlobalValueContext);

    const [taxonomy, setTaxonomy] = useState<string | undefined>();
    const [expanded, setExpanded] = useState(true);
    const categoryRestrictionIds = useMemo(() => categoryRestrictions.map(
        (c) => c.categoryKey,
    ), [categoryRestrictions]);

    useEffect(() => {
        const initTaxonomy = [...allTaxonomiesById.byId.values()][0].id;

        setTaxonomy(initTaxonomy);
    }, [allTaxonomiesById.byId]);

    const handleTaxonomyChange = (event: SelectChangeEvent<unknown>): void => {
        event.preventDefault();
        setTaxonomy(event.target.value as string);
    };

    const handleOnCategoryRowSelected = (categoryTreeRows: Category[]): void => {
        if (categoryTreeRows.length > 0) {
            const newRow = categoryTreeRows[0];

            if (!categoryRestrictionIds.includes(newRow.categoryKey)) {
                const newCategory = {
                    name: newRow.name,
                    categoryKey: newRow.categoryKey,
                    include: false,
                };

                onCategoryRestrictionSelected(newCategory);
            }
        }
    };

    const handleExpandClick = (): void => {
        if (editing) {
            setExpanded(!expanded);
        }
    };

    return (
        <Card>
            <CardContent>
                <Grid
                    container
                    direction="column"
                    spacing={3}
                    justifyContent="space-between"
                >
                    <Grid item xs={12}>
                        <Select
                            disabled={!editing}
                            label="Taxonomy"
                            helperText="Select the Taxonomy for this filter"
                            id="taxonomy-filter-name-select"
                            value={taxonomy}
                            inputProps={{
                                name: 'name',
                            }}
                            onChange={handleTaxonomyChange}
                        >
                            {
                                [...allTaxonomiesById.byId.values()].map((t) => (
                                    <option key={t.id} value={t.id}>
                                        {t.name}
                                    </option>
                                ))
                            }
                        </Select>
                    </Grid>
                    <Grid item xs={12}>
                        <CollapsableToggle
                            className={classes.fullWidth}
                            expanded={expanded}
                            showLessText="Hide sub cateogries"
                            showMoreText="Show sub cateogries"
                            onClick={handleExpandClick}
                        />
                        <Collapsable className={classes.padded} expanded={expanded}>
                            <Box
                                bgcolor={theme.palette.grey[50]}
                                padding={parseInt(theme.spacing(1), 10) / 2}
                                marginBottom={parseInt(theme.spacing(1), 10) / 2}
                            >
                                {!!taxonomy && (
                                    <SubCategoryTree
                                        selected={categoryRestrictions.map((c) => c.categoryKey)}
                                        taxonomyId={taxonomy}
                                        maxDepth={10}
                                        onRowSelected={handleOnCategoryRowSelected}
                                    />
                                )}
                            </Box>
                        </Collapsable>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default CategorySelector;
