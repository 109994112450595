import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import { Warning } from '@mui/icons-material';

import LabelTooltip from 'src/components/common/LabelTooltip';
import { GLOBAL_FILTER_TYPES } from 'src/constants';

interface PropTypes {
    type: string;
}

const HELPER_TEXT = `
Type controls how many filters a user is able to select and how that selection looks in a Gallery.
Radio is single-select and Checkbox is multi-select.
`;

const useStyles = makeStyles()((theme: Theme) => ({
    // Set the class to the icon parent in order to override MUI's styling of svg in helper texts
    helperText: {
        '& > span': {
            lineHeight: '1rem',
        },
        '& > svg': {
            color: theme.palette.warning.main,
            marginLeft: theme.spacing(0),
            marginRight: theme.spacing(0),
            verticalAlign: 'middle',
        },
    },
}));

const FilterTypeHelperText = (props: PropTypes): JSX.Element => {
    const { type } = props;
    const { classes } = useStyles();
    const isRadioType = (type === GLOBAL_FILTER_TYPES.Radio);

    return (
        <LabelTooltip title={isRadioType ? 'Radio filter types require a default option' : ''}>
            <span className={classes.helperText}>
                {isRadioType && (
                    <Warning />
                )}
                <span>{HELPER_TEXT}</span>
            </span>
        </LabelTooltip>
    );
};

export default FilterTypeHelperText;
