import { useContext, useState } from 'react';

import AddTranslationRow from 'src/components/ManageTranslations/AddTranslationRow';
import AddToTable from 'src/components/common/AddToTable';
import ManageTranslationsContext from 'src/contexts/ManageTranslationsContext';
import { updateTranslations } from 'src/store/translationsData/translations';
import { CULTURES } from 'src/constants';

const AddTranslations = (): JSX.Element => {
    const { translationsData: { translations }, dispatch } = useContext(ManageTranslationsContext);
    const [newTranslations, setNewTranslations] = useState([] as Services.Translations.V2.Translation[]);
    const existingCultures = Object.keys(translations);
    const availableCultures = existingCultures
        ? CULTURES.filter((culture) => !existingCultures.includes(culture))
        : CULTURES;

    const initializeNewTranslation = (): void => {
        const newTranslation = {
            culture: availableCultures[0],
            value: '',
        };

        setNewTranslations([
            ...newTranslations,
            newTranslation,
        ]);
    };

    const saveNewTranslation = (translation: Services.Translations.V2.Translation): void => {
        const updatedTranslations = { ...translations };

        updatedTranslations[translation.culture] = translation.value;

        dispatch(updateTranslations(updatedTranslations));
    };

    return (
        <AddToTable
            addedRows={newTranslations}
            setAddedRows={setNewTranslations}
            createNewRow={initializeNewTranslation}
            saveRow={saveNewTranslation}
            addButtonText="Add Translation"
        >
            {({ row, ...rest }): JSX.Element => (
                <AddTranslationRow
                    availableCultures={availableCultures}
                    key={`add-translation-row-${row.culture}`}
                    translation={row}
                    {...rest}
                />
            )}
        </AddToTable>
    );
};

export default AddTranslations;
