import { useRef, useState } from 'react';
import qs from 'query-string';
import {
    useHistory,
    useLocation,
} from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { makeStyles } from 'tss-react/mui';

import Content from 'src/components/Content';
import galleriesService from 'src/services/GalleriesService';
import SearchForm from 'src/components/SearchGalleries/SearchForm';
import ResultsList from 'src/components/SearchGalleries/ResultsList';
import SearchErrorMessage from 'src/components/common/SearchErrorMessage';
import Main from 'src/components/Main';
import AppBar from 'src/components/common/AppBar';
import CreateActions from 'src/components/common/AppBar/CreateActions';
import { CREATE_GALLERIES } from 'src/utils/routes';
import { AppError } from 'src/lib/errors';

const LIMIT = 50;

const useStyles = makeStyles()({
    container: {
        height: '100%',
        maxHeight: '100%',
    },
    scrollTable: {
        maxHeight: '100%',
        overflow: 'auto',
        textAlign: 'center',
        paddingTop: 0,
    },
});

const SearchGalleriesPage = (): JSX.Element => {
    const { classes } = useStyles();
    const history = useHistory();
    const location = useLocation();
    const scrollTableRef = useRef<HTMLDivElement>(null);
    const [error, setError] = useState<AppError | null>(null);
    const [galleries, updateGalleries] = useState<Services.Galleries.Gallery[]>([]);
    const [hasMore, setHasMore] = useState(false);
    const [isSearching, setIsSearching] = useState(false);
    const [search, setSearch] = useState<Services.Galleries.Search | null>(null);

    const shouldShowErrorMessage = !isSearching && error;
    const shouldShowResultsList = !isSearching && !error;

    const onSearch = async (newSearch: Services.Galleries.Search): Promise<void> => {
        if (JSON.stringify(newSearch) !== JSON.stringify(search)) {
            history.push({
                ...location,
                search: qs.stringify(newSearch),
            });

            setIsSearching(true);
            setSearch(newSearch);
            setError(null);
            setHasMore(false);

            try {
                if (newSearch.id) {
                    const result = await galleriesService.getGallery(newSearch.id);

                    updateGalleries([result]);
                    setHasMore(false);
                    return;
                }
                const response = await galleriesService.getPagableGalleries(newSearch, 0, LIMIT);

                setHasMore((response.offset + response.length) < response.total);
                updateGalleries(response.results);
            } catch (e: any) {
                if (e.status === 404) {
                    updateGalleries([]);
                    return;
                }

                setError(new AppError(e));
            } finally {
                setIsSearching(false);
            }
        }
    };

    const pager = async (nextPage: number): Promise<void> => {
        if (search) {
            try {
                const offset = nextPage * LIMIT;
                const response = await galleriesService.getPagableGalleries(search, offset, LIMIT);

                setHasMore((response.offset + response.length) < response.total);

                const currentGalleries = galleries || [];
                const updatedGalleries = [...currentGalleries];

                for (const gallery of response.results) {
                    const exists = currentGalleries.some((old) => old.id === gallery.id);

                    if (!exists) {
                        updatedGalleries.push(gallery);
                    }
                }

                updateGalleries(updatedGalleries);
            } catch (e: any) {
                setError(new AppError(e));
            }
        }
    };

    return (
        <Main
            AppBar={(
                <AppBar
                    actions={<CreateActions title="Create Gallery" to={CREATE_GALLERIES} />}
                    title="Search Galleries"
                />
            )}
        >
            <Content>
                <Grid
                    container
                    className={classes.container}
                    direction="column"
                    spacing={4}
                    wrap="nowrap"
                >
                    <Grid>
                        <SearchForm onSubmit={onSearch} />
                    </Grid>
                    <Grid className={classes.scrollTable} ref={scrollTableRef}>
                        {isSearching && <CircularProgress color="primary" />}
                        {shouldShowErrorMessage && <SearchErrorMessage />}
                        {shouldShowResultsList && (
                            <ResultsList
                                hasMore={hasMore}
                                results={galleries}
                                scrollParent={(): HTMLDivElement | null => (scrollTableRef.current)}
                                onPage={pager}
                            />
                        )}
                    </Grid>
                </Grid>
            </Content>
        </Main>
    );
};

export default SearchGalleriesPage;
