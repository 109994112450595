import {
    Chip,
    Grid,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

import SearchScenePreview from 'src/components/SearchScenesV2/SearchScenePreview';
import {
    CardRow,
    CardCell,
} from 'src/components/common/CardTable';

interface PropTypes {
    galleryName: string | null;
    scene: Services.Scenes.V2.SceneDefinition;
}

const useStyles = makeStyles()((theme: Theme) => ({
    maxWidth: {
        maxWidth: theme.spacing(48),
    },
    center: {
        textAlign: 'center',
    },
}));

const ResultRow = (props: PropTypes): JSX.Element => {
    const { galleryName, scene } = props;
    const { classes } = useStyles();

    return (
        <CardRow hover to={`/scenes/${scene.id}`}>
            <CardCell align="center" className={classes.center}>
                <SearchScenePreview
                    galleryName={galleryName}
                    productOptions={scene.productOptions}
                    sceneUri={scene.scenes.gallery.uri}
                />
            </CardCell>
            <CardCell align="center">
                {scene.productKey}
            </CardCell>
            <CardCell align="center">
                {scene.description}
            </CardCell>
            <CardCell align="center" className={classes.maxWidth}>
                <Grid container spacing={1}>
                    {scene.productOptions && scene.productOptions.map(
                        (productOption: Services.Scenes.V2.ProductOption): JSX.Element => (
                            <Grid item key={`${productOption.optionName}: ${productOption.optionValue}`}>
                                <Chip label={`${productOption.optionName}: ${productOption.optionValue}`} />
                            </Grid>
                        ),
                    )}
                </Grid>
            </CardCell>
        </CardRow>
    );
};

export default ResultRow;
