export const findProductOptionForFilter = (
    galleryData: App.GalleryData,
    filterData: Services.Filters.GlobalFilter,
): string => {
    if (galleryData) {
        const option = !!filterData.options.length && filterData.options[0].productOptionName;

        return option || filterData.attributeType || '';
    }

    return '';
};

export const findContentAttributeForFilter = (
    galleryData: App.GalleryData,
    filterData: Services.Filters.GlobalFilter,
): string => {
    if (!galleryData) {
        return '';
    }

    const { attributeTypes } = galleryData.galleryContentAttributes;

    for (const [contentAttributeKey, contentAttributeValues] of Object.entries(attributeTypes)) {
        if (
            Object
                .keys(contentAttributeValues)
                .some((cav) => filterData.options.some(
                    (fdo) => fdo.value.toLowerCase() === cav,
                ))
        ) {
            return contentAttributeKey;
        }
    }

    return '';
};
