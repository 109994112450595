import { UPDATE_SCENES } from 'src/store/searchedScenes/constants';

export const updateScenes = (scenes: Services.Scenes.V2.SceneDefinition[]): Actions.UpdateSearchedScenesAction => ({
    type: UPDATE_SCENES,
    payload: {
        scenes,
    },
});

const scenesReducer = (
    scenes: Services.Scenes.V2.SceneDefinition[] | null,
    action: Actions.Action,
): Services.Scenes.V2.SceneDefinition[] | null => {
    switch (action.type) {
        case (UPDATE_SCENES):
            return (action as Actions.UpdateSearchedScenesAction).payload.scenes;
        default:
            return scenes;
    }
};

export default scenesReducer;
