import { ReactNode } from 'react';

import {
    CircularProgress, TextField, Autocomplete, AutocompleteProps,
} from '@mui/material';

import VirtualListbox from 'src/components/common/inputs/AsyncAutocomplete/VirtualListbox';

export interface AsyncAutocompleteProps<T,
    Multiple extends boolean | undefined = false,
    DisableClearable extends boolean | undefined = false,
    FreeSolo extends boolean | undefined = false
> extends Omit<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, 'options' | 'renderInput'> {
    helperText?: string;
    label: ReactNode;
    loading: boolean;
    options: T[];
    optionKey: string;
    virtualize?: boolean;
}

const AsyncAutocomplete = <T extends Record<string, any>,
    Multiple extends boolean | undefined = false,
    DisableClearable extends boolean | undefined = false,
    FreeSolo extends boolean | undefined = false
>(props: AsyncAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>): JSX.Element => {
    const {
        helperText,
        label,
        loading,
        optionKey,
        virtualize = false,
        ...rest
    } = props;

    return (
        <Autocomplete
            isOptionEqualToValue={(option, value): boolean => option[optionKey] === value[optionKey]}
            getOptionLabel={(option): string => (option as any)[optionKey]}
            ListboxComponent={virtualize ? VirtualListbox : undefined}
            loading={loading}
            renderInput={(params): JSX.Element => (
                <TextField
                    {...params}
                    fullWidth
                    helperText={helperText}
                    label={label}
                    variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
            {...rest}
        />
    );
};

export default AsyncAutocomplete;
