import { ReactElement } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import InfoIcon from '@mui/icons-material/Info';

import useTranslationValues from 'src/hooks/useTranslationValues';
import { getLogger } from 'src/utils/logger';
import LabelTooltip from 'src/components/common/LabelTooltip';

export interface TranslationProps {
    children?: ReactElement;
    icon?: boolean;
    translationKey?: App.AllowedTranslationKey;
    overrideTranslations?: App.TranslationsByCulture;
}

const useStyles = makeStyles()((theme) => ({
    tooltipTableCell: {
        borderBottom: 'none',
        whiteSpace: 'nowrap',
        textAlign: 'left',
    },
    infoIcon: {
        color: theme.palette.primary.light,
        height: theme.spacing(2),
        marginLeft: theme.spacing(1),
        width: theme.spacing(2),
    },
}));

const TranslationTooltip = (props: TranslationProps): JSX.Element | null => {
    const {
        children,
        icon = true,
        translationKey,
        overrideTranslations,
    } = props;
    const { classes } = useStyles();
    const [translations, , , error] = useTranslationValues(translationKey);

    if (error) {
        getLogger().error(error);
    }

    return (
        <LabelTooltip
            title={(
                <>
                    {error && (
                        <Typography color="error" variant="body1">
                            Cannot load localized values
                        </Typography>
                    )}
                    {!error && translationKey && (
                        <Table size="small">
                            <TableBody>
                                {translations && translations.allIds.map((currentTranslationKey) => {
                                    const translation = translations.byId.get(currentTranslationKey);

                                    return translation
                                        ? (
                                            <TableRow key={`row${translation.culture}`}>
                                                <TableCell align="center" className={classes.tooltipTableCell}>
                                                    {translation.culture}
                                                </TableCell>
                                                <TableCell align="center" className={classes.tooltipTableCell}>
                                                    {translation.value}
                                                </TableCell>
                                            </TableRow>
                                        )
                                        : null;
                                })}
                            </TableBody>
                        </Table>
                    )}
                    {overrideTranslations && (
                        <Table size="small">
                            <TableBody>
                                {overrideTranslations.allIds.map((currentTranslationKey) => {
                                    const translation = overrideTranslations.byId.get(currentTranslationKey);

                                    return translation
                                        ? (
                                            <TableRow key={`row${translation.culture}`}>
                                                <TableCell align="center" className={classes.tooltipTableCell}>
                                                    {translation.culture}
                                                </TableCell>
                                                <TableCell align="center" className={classes.tooltipTableCell}>
                                                    {translation.value}
                                                </TableCell>
                                            </TableRow>
                                        )
                                        : null;
                                })}
                            </TableBody>
                        </Table>
                    )}
                </>
            )}
        >
            <span>
                {children}
                {icon && (<InfoIcon className={classes.infoIcon} />)}
            </span>
        </LabelTooltip>
    );
};

export default TranslationTooltip;
