import {
    Grid,
    Typography,
    IconButton,
    Box,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

import capitalize from 'src/utils/capitalize';
import config from 'src/lib/config';
import LinkButton from 'src/components/common/inputs/LinkButton';
import { LogoIcon } from 'src/components/common/Logo';
import { SEARCH_GALLERIES } from 'src/utils/routes';

const useStyles = makeStyles()((theme: Theme) => ({
    logoButton: {
        margin: theme.spacing(1, 1),
        backgroundColor: theme.palette.common.white,
        padding: theme.spacing(1),
    },
    title: {
        color: theme.palette.common.white,
        paddingTop: theme.spacing(0.5),
    },
}));

const Title = (): JSX.Element => {
    const { classes } = useStyles();

    return (
        <Grid container wrap="nowrap">
            <Grid item>
                <LinkButton
                    to={SEARCH_GALLERIES}
                    component={IconButton}
                    buttonClassName={classes.logoButton}
                >
                    <LogoIcon size="sm" />
                </LinkButton>
            </Grid>
            <Grid item className={classes.title}>
                <Typography variant="subtitle1">
                    <Box component="span" fontWeight="bold">
                        {`Gallery ${capitalize(config.DEPLOYMENT)}`}
                    </Box>
                </Typography>
                <Typography variant="body2">
                    Management Tool
                </Typography>
            </Grid>
        </Grid>
    );
};

export default Title;
