import { useContext, ChangeEvent } from 'react';

import { SelectProps } from 'src/components/common/inputs/Select';
import ManageGalleryContext from 'src/contexts/manageGalleryContext';
import { updateRenderProperty } from 'src/store/galleryData/renderPropertiesStore';
import { getRenderProperty } from 'src/components/ManageGallery/GalleryRenderProperties/utils';
import TextField from 'src/components/common/inputs/TextField';
import GalleryGlobalValueContext from 'src/contexts/GalleryGlobalValueContext';

interface PropTypes extends SelectProps {
    editing?: boolean;
    propKey: string;
    experienceType: string;
}

const RenderPropertyInput = (props: PropTypes): JSX.Element => {
    const {
        editing,
        propKey,
        experienceType,
    } = props;

    const { galleryData, dispatch } = useContext(ManageGalleryContext);
    const { globalGalleryData: { defaultRenderProperties } } = useContext(GalleryGlobalValueContext);
    const { renderProperties } = galleryData;

    const updateGalleryRenderPropValue = (
        event: ChangeEvent<{ name?: string | undefined; value: unknown }>,
    ): void => {
        const renderProperty = getRenderProperty(defaultRenderProperties, renderProperties, propKey, experienceType);

        if (renderProperty) {
            const value = event.target.value as string;

            renderProperty.value = value;

            dispatch(updateRenderProperty(renderProperty));
        }
    };

    const renderProperty = getRenderProperty(defaultRenderProperties, renderProperties, propKey, experienceType);

    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
            {renderProperty && (
                <TextField
                    fullWidth
                    editing={editing}
                    value={renderProperty.value}
                    id="best-selling-count-input"
                    label="Image Cache Version"
                    type="number"
                    disabled={!renderProperty}
                    onChange={updateGalleryRenderPropValue}
                />
            )}
        </>
    );
};

export default RenderPropertyInput;
