import { ErrorPayload, ErrorInfo, IAppError } from 'src/lib/errors/types';
import * as utils from 'src/lib/errors/utils';

// TODO use a code for easy conditional handling of error
export default class AppError extends Error implements IAppError {
    guid: string;

    hint: string | undefined;

    info?: ErrorInfo | null;

    title: string | undefined;

    constructor(payload: ErrorPayload) {
        const message = utils.resolveMessage(payload);

        super(message);

        if (typeof Error.captureStackTrace === 'function') {
            Error.captureStackTrace(this, AppError);
        }

        this.guid = utils.getGuid();
        this.hint = utils.resolveHint(payload);
        this.info = utils.getInfo(payload as ErrorInfo);
        this.name = 'AppError';
        this.title = utils.resolveTitle(payload);
    }
}
