import { CancelToken } from 'axios';

import GalleryContentService from 'src/services/GalleryContentService';
import config from 'src/lib/config';

export async function getPreviewUrlRoot(
    gallery: string,
    productOptions: Services.Scenes.ProductOption[],
    cancelToken?: CancelToken,
): Promise<string | null> {
    try {
        const content = await GalleryContentService.getContent(
            gallery,
            config.defaultCulture,
            {
                selectedOptions: productOptions.reduce((memo, po) => {
                    if (po.optionName !== 'Stock') {
                        // eslint-disable-next-line no-param-reassign
                        memo[po.optionName] = po.optionValue;
                    }

                    return memo;
                }, {} as Services.GalleryContent.SelectedOptions),
                limit: 1,
            },
            cancelToken,
        );

        if (!(content.length > 0)) {
            return null;
        }

        const previewUrl = new URL(`https:${content[0].previewUrls.size2x}`);

        previewUrl.searchParams.delete('scene');

        return previewUrl.href;
    } catch {
        // We never want this function to throw an error, return null if any issues
        return null;
    }
}
