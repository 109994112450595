import isValidUrl from 'src/components/ManageScene/isValidUrl';
import TextField, { TextFieldProps } from 'src/components/common/inputs/TextField';

const SceneUriTextField = (props: TextFieldProps): JSX.Element => {
    const { value, ...rest } = props;
    const invalidSceneUri = value ? !isValidUrl(value) : false;

    return (
        <TextField
            error={invalidSceneUri}
            helperText={invalidSceneUri ? 'Scene URI must be a valid URI' : undefined}
            value={value}
            {...rest}
        />
    );
};

export default SceneUriTextField;
