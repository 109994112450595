import {
    Dispatch, SetStateAction, useRef, useState,
} from 'react';
import qs from 'qs';
import { useHistory, useLocation } from 'react-router-dom';
import {
    CircularProgress,
    Grid,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import AppBar from 'src/components/common/AppBar';
import Content from 'src/components/Content';
import Main from 'src/components/Main';
import ResultsList from 'src/components/SearchScenes/ResultsList';
import scenesService from 'src/services/ScenesService';
import SearchErrorMessage from 'src/components/common/SearchErrorMessage';
import SearchForm from 'src/components/SearchScenes/SearchForm';
import CreateActions from 'src/components/common/AppBar/CreateActions';
import { CREATE_SCENE_DEFINITION } from 'src/utils/routes';
import { AppError } from 'src/lib/errors';

type SearchState = {
    hasMore: boolean;
    search: Services.Scenes.Search | null;
    scenes: Services.Scenes.SceneDefinition[] | undefined;
};

const LIMIT = 50;

const useStyles = makeStyles()({
    centeredContent: {
        textAlign: 'center',
    },
    container: {
        height: '100%',
        maxHeight: '100%',
    },
    scrollTable: {
        maxHeight: '100%',
        overflow: 'auto',
        textAlign: 'center',
        '&.MuiGrid-item': {
            paddingTop: 0,
        },
    },
});

function createNewSearch(
    product: Services.Product.BasicProduct | null,
    productOptions: Services.Product.ProductOptionValuePair[] | null,
): Services.Scenes.Search {
    return {
        limit: LIMIT,
        productKey: product?.productKey || undefined,
        productOptions: productOptions
            ? productOptions.reduce((accum: Services.Scenes.ProductOptionSearch, option) => {
                // eslint-disable-next-line no-param-reassign
                accum[option.name] = option.value;

                return accum;
            }, {})
            : undefined,
    };
}

function createPager(
    searchState: SearchState,
    setSearchState: Dispatch<SetStateAction<SearchState>>,
    setError: (value: SetStateAction<AppError | null>) => void,
) {
    return async (nextPage: number): Promise<void> => {
        const {
            search,
            scenes = [],
        } = searchState;

        if (search) {
            try {
                const response = await scenesService.getScenes({
                    ...search,
                    offset: nextPage * LIMIT,
                });

                const updatedScenes = [...scenes];
                const newSearchState: SearchState = {
                    search,
                    hasMore: (response.offset + response.length) < response.total,
                    scenes: updatedScenes.concat(response.results),
                };

                setSearchState(newSearchState);
            } catch (e: any) {
                setError(new AppError(e));
            }
        }
    };
}

const SearchScenes = (): JSX.Element => {
    const history = useHistory();
    const location = useLocation();
    const [loading, isLoading] = useState(false);
    const [error, setError] = useState<AppError | null>(null);
    const [searchState, setSearchState] = useState<SearchState>({} as SearchState);
    const scrollTableRef = useRef<HTMLDivElement>(null);
    const { classes } = useStyles();

    const onSearch = async (selection: Services.Scenes.SearchTerm): Promise<void> => {
        history.push({
            ...location,
            search: qs.stringify(selection),
        });

        try {
            isLoading(true);

            const { product, productOptions } = selection;
            const newSearch = createNewSearch(product, productOptions);

            const response = await scenesService.getScenes(newSearch);

            const newSearchState: SearchState = {
                hasMore: (response.offset + response.length) < response.total,
                search: newSearch,
                scenes: response.results,
            };

            setSearchState(newSearchState);
        } catch (e: any) {
            setError(new AppError(e));
        } finally {
            isLoading(false);
        }
    };

    return (
        <Main
            AppBar={(
                <AppBar
                    title="Search Scenes"
                    actions={<CreateActions title="Create Scene" to={CREATE_SCENE_DEFINITION} />}
                />
            )}
        >
            <Content>
                <Grid
                    container
                    className={classes.container}
                    direction="column"
                    spacing={4}
                    wrap="nowrap"
                >
                    <Grid item>
                        <SearchForm onSubmit={onSearch} />
                    </Grid>
                    <Grid item className={classes.scrollTable} ref={scrollTableRef}>
                        {loading && (<CircularProgress color="primary" />)}
                        {(!loading && error) && (<SearchErrorMessage />)}
                        {(!loading && !error) && (
                            <ResultsList
                                results={searchState.scenes}
                                hasMore={searchState.hasMore}
                                productKey={searchState.search?.productKey}
                                scrollParent={(): HTMLDivElement | null => (scrollTableRef.current)}
                                onPage={createPager(searchState, setSearchState, setError)}
                            />
                        )}
                    </Grid>
                </Grid>
            </Content>
        </Main>
    );
};

export default SearchScenes;
