import { useContext, useMemo } from 'react';
import {
    Box,
    IconButton,
    ListItemSecondaryAction,
    Switch,
    Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { makeStyles } from 'tss-react/mui';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DeleteForever from '@mui/icons-material/Delete';

import { EditContext } from 'src/contexts/EditContext';

interface PropTypes {
    categoryRestrictions: Services.CategoryRestrictions.CategoryRestriction[];
    onCategoryRestrictionRemoved: (id: string) => void;
    onCategoryRestrictionUpdated: (id: string) => void;
}

const useStyles = makeStyles()({
    nonInteractive: {
        cursor: 'default',
    },
});

const CategoryRestrictionList = (props: PropTypes): JSX.Element => {
    const {
        categoryRestrictions,
        onCategoryRestrictionRemoved,
        onCategoryRestrictionUpdated,
    } = props;

    const { editing } = useContext(EditContext);

    const { classes } = useStyles();

    const sortedCategoryRestrictions = useMemo(() => [
        ...categoryRestrictions.sort((a, b) => (a.name < b.name ? -1 : 1)),
    ], [categoryRestrictions]);

    return (
        <>
            <Typography
                gutterBottom
                className={classes.nonInteractive}
                variant="overline"
            >
                <Box component="span" fontWeight="bold">
                    <Typography
                        display="inline"
                        variant="inherit"
                    >
                        Added Category Restrictions
                    </Typography>
                </Box>
            </Typography>
            <Grid xs={12}>
                <List dense>
                    {sortedCategoryRestrictions.map((value) => {
                        const itemId = `${value.name}_${value.categoryKey}`;
                        const labelId = `checkbox-list-secondary-label-${value}`;
                        const labelText = `${value.name} (${value.categoryKey})`;

                        return (
                            <ListItem key={itemId}>
                                <ListItemText id={labelId} primary={labelText} />
                                <ListItemSecondaryAction>
                                    <Grid container component="label" alignItems="center" spacing={1}>
                                        <Grid>Exclude</Grid>
                                        <Grid>
                                            <Switch
                                                disabled={!editing}
                                                color="primary"
                                                checked={value.include}
                                                inputProps={{ 'aria-labelledby': labelId }}
                                                onChange={(): void => onCategoryRestrictionUpdated(value.categoryKey)}
                                            />
                                        </Grid>
                                        <Grid>Include</Grid>
                                        <Grid xs={1}>
                                            <IconButton
                                                disabled={!editing}
                                                edge="end"
                                                aria-label="delete"
                                                onClick={(): void => onCategoryRestrictionRemoved(value.categoryKey)}
                                            >
                                                <DeleteForever />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </ListItemSecondaryAction>
                            </ListItem>
                        );
                    })}
                </List>
            </Grid>
        </>
    );
};

export default CategoryRestrictionList;
