import config from 'src/lib/config';
import AbstractService from 'src/services/AbstractService';
import { getLogger } from 'src/utils/logger';
import { ServiceError } from 'src/lib/errors';

const getProductsQuery = (searchInput: string): Services.ProductSearch.ProductQuery => {
    const wordQueries = searchInput.split(' ').map((word) => ({
        type: 'wildcard',
        fieldName: 'productName',
        value: `${word}*`,
    }));

    return {
        page: 1,
        pageSize: 250,
        query: {
            type: 'compound',
            operator: 'and',
            compoundQuery: [
                {
                    type: 'match',
                    fieldName: 'status',
                    value: 'ACTIVE',
                },
                {
                    type: 'compound',
                    operator: 'or',
                    compoundQuery: [
                        {
                            type: 'match',
                            fieldName: 'productId',
                            value: searchInput,
                        },
                        {
                            type: 'compound',
                            operator: 'and',
                            compoundQuery: [
                                ...wordQueries,
                            ],
                        },
                    ],
                },
            ],
        },
        scroll: false,
        shouldAggregate: true,
    };
};

class ProductSearchService extends AbstractService {
    constructor() {
        super(config.services.productSearch);
    }

    public async getProductsBy(bearerToken: string, searchInput: string): Promise<Services.Product.BasicProduct[]> {
        const url = '/v1/products/current:search';

        try {
            const response = await this.api.post<Services.ProductSearch.ProductSearchResponse>(
                url,
                getProductsQuery(searchInput),
                {
                    params: {
                        requestor: config.appName,
                    },
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                },
            );

            return response.data.result.map((r) => {
                const version = r.matching_versions.find((v) => v.isCurrent);

                return {
                    productKey: version?.productId ?? '',
                    name: version?.productName ?? '',
                };
            }).filter((v) => v.productKey !== '' && v.name !== '')
                .sort((a, b) => (a.name < b.name ? -1 : 1));
        } catch (e: any) {
            getLogger().error(e);
            throw new ServiceError(e);
        }
    }
}

export default new ProductSearchService();
