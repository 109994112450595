import { Theme, alpha } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import { TreeItem } from '@mui/x-tree-view';
import classnames from 'classnames';

import TransitionComponent from 'src/components/ManageGallery/GalleryCategoryRestrictions/CategoryRestrictionPicker/TransitionComponent';

export interface CategoryTreeItem {
    nodeId: string,
    label: string,
    children: CategoryTreeItem[]
}

export interface StyledTreeItemProps {
    root: CategoryTreeItem
}

const useStyles = makeStyles()((theme: Theme) => ({
    iconContainer: {
        '& .close': {
            opacity: 0.3,
        },
    },
    group: {
        marginLeft: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
    },
}));

const StyledTreeItem = (props: StyledTreeItemProps): JSX.Element => {
    const { classes } = useStyles();
    const { root } = props;

    return (
        <TreeItem
            itemId={root.nodeId}
            label={root.label}
            className={classnames(classes.group, classes.iconContainer)}
            slots={{ groupTransition: TransitionComponent }}
        >
            {!!root.children && root.children.map((nt) => (
                <StyledTreeItem root={nt} key={nt.nodeId} />
            ))}
        </TreeItem>
    );
};

export default StyledTreeItem;
