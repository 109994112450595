import { useContext } from 'react';
import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from '@mui/material';

import Card, {
    CardHeader,
    CardContent,
} from 'src/components/common/Card';
import ManageGalleryContext from 'src/contexts/manageGalleryContext';
import Header from 'src/components/common/Header';
import ValidationRow from 'src/components/ManageGallery/GalleryValidations/ValidationRow';

const GalleryValidations = (): JSX.Element => {
    const { galleryData } = useContext(ManageGalleryContext);
    const { validations } = galleryData;
    // If a validation doesn't have any messages, give it a fake one so it'll show up in the .map later
    const validationsWithMessages = validations.map((validation) => (validation.messages.length > 0 ? validation : ({
        ...validation,
        messages: [''],
    })));

    return (
        <Card>
            <CardHeader>
                Validation Results
            </CardHeader>
            <CardContent>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Header>Result</Header>
                            </TableCell>
                            <TableCell>
                                <Header>Name</Header>
                            </TableCell>
                            <TableCell>
                                <Header>Details</Header>
                            </TableCell>
                            <TableCell>
                                <Header>Documentation</Header>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {validationsWithMessages.sort((v) => v.position).map(
                            (validation) => validation.messages.map((message) => (
                                <ValidationRow
                                    validation={validation}
                                    message={message}
                                />
                            )),
                        )}
                    </TableBody>
                </Table>
            </CardContent>
        </Card>
    );
};

export default GalleryValidations;
