import { MouseEvent } from 'react';
import {
    Chip,
    Typography,
    Grid,
} from '@mui/material';

interface PropTypes {
    editing?: boolean;
    chipsData?: Services.NotificationChannels.NotificationChannel[];
    onDelete?: (galleryNotificationChannels: Services.NotificationChannels.NotificationChannel[],) => void;
}

const ChipsArray = (props: PropTypes): JSX.Element => {
    const { editing = false, chipsData, onDelete } = props;
    const handleDelete = (data: Services.NotificationChannels.NotificationChannel) => (
        event: MouseEvent<{}>,
    ): void => {
        event.preventDefault();
        const updatedArray = chipsData?.filter((nc) => nc.label !== data.label);

        if (onDelete && updatedArray) {
            onDelete(updatedArray);
        }
    };

    return (
        <Grid container spacing={2}>
            {!(chipsData?.length) && (
                <Grid item>
                    <Typography variant="body2">
                        No Notification Channels
                    </Typography>
                </Grid>
            )}
            {!!(chipsData?.length) && chipsData.map((data) => (
                <Grid item key={data.label}>
                    <Chip
                        key={data.label}
                        label={data.label}
                        onDelete={editing ? handleDelete(data) : undefined}
                    />
                </Grid>
            ))}
        </Grid>
    );
};

export default ChipsArray;
