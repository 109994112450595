import { HTMLProps, useContext, useState } from 'react';
import { Redirect } from 'react-router-dom';

import EditController from 'src/components/common/EditController';
import ManageSceneContext from 'src/contexts/ManageSceneContext';
import { SEARCH_SCENES } from 'src/utils/routes';

const CreateSceneDefinitionController = (props: HTMLProps<{}>): JSX.Element => {
    const { children } = props;
    const { saveScene, sceneData: { sceneDefinition } } = useContext(ManageSceneContext);
    const [sceneId, setSceneId] = useState<number | undefined>(undefined);
    const [cancel, setCancel] = useState<boolean>(false);

    const handleSave = async (): Promise<void> => {
        const newScene = await saveScene(sceneDefinition);

        setSceneId(newScene.id);
    };

    const handleCancel = async (): Promise<void> => {
        setCancel(true);
    };

    if (cancel) {
        return <Redirect to={SEARCH_SCENES} />;
    }
    if (sceneId) {
        return <Redirect to={`/scenes/${sceneId}`} />;
    }

    return (
        <EditController
            onCancel={handleCancel}
            onSave={handleSave}
        >
            {children}
        </EditController>
    );
};

export default CreateSceneDefinitionController;
