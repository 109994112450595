import { useEffect, useState, useContext } from 'react';

import rankingService from 'src/services/RankingStrategyService';
import { getLogger } from 'src/utils/logger';
import { AppError } from 'src/lib/errors';
import { IdentityContext } from 'src/contexts/IdentityContext';

type RankingStrategiesHook = [
    string[] | undefined,
    boolean,
    AppError | null,
];

const useRankingStrategies = (strategyType: string): RankingStrategiesHook => {
    const [error, setError] = useState<AppError | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [strategies, setStrategies] = useState<string[]>();
    const { accessToken } = useContext(IdentityContext);

    useEffect(() => {
        const runEffect = async (): Promise<void> => {
            if (accessToken) {
                try {
                    setLoading(true);
                    const rankingStrategies = await rankingService.getRankingStrategies(accessToken, strategyType);
                    const strategyNames = [...new Set(rankingStrategies.map((rs): string => rs.strategyName))];

                    setStrategies(strategyNames);
                } catch (e: any) {
                    getLogger().error(e);
                    setError(new AppError(e));
                } finally {
                    setLoading(false);
                }
            }
        };

        runEffect();
    }, [strategyType, accessToken]);

    return [strategies, loading, error];
};

export default useRankingStrategies;
