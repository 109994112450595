import {
    Chip,
    Grid,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

import CopyTextButton from 'src/components/common/CopyTextButton';
import ScenePreview from 'src/components/common/ScenePreview';
import {
    CardRow,
    CardCell,
} from 'src/components/common/CardTable';

interface PropTypes {
    galleryName: string | null;
    scene: Services.Scenes.SceneDefinition;
}

const useStyles = makeStyles()((theme: Theme) => ({
    copy: {
        left: theme.spacing(0.5),
        position: 'absolute',
        top: theme.spacing(0.5),
    },
    maxWidth: {
        maxWidth: theme.spacing(48),
    },
    previewCell: {
        position: 'relative',
        textAlign: 'center',
    },
    preview: {
        maxWidth: '100%',
        height: theme.spacing(15),
    },
}));

const ResultRow = (props: PropTypes): JSX.Element => {
    const { galleryName, scene } = props;
    const { classes } = useStyles();
    const { uri } = scene.scenes.gallery;

    return (
        <CardRow hover to={`/scenes/${scene.id}`}>
            <CardCell align="center" className={classes.previewCell}>
                <CopyTextButton
                    className={classes.copy}
                    text={uri}
                    title="Click to copy the Scene URI"
                />
                <ScenePreview
                    className={classes.preview}
                    galleryName={galleryName}
                    productOptions={scene.productOptions}
                    sceneUri={uri}
                />
            </CardCell>
            <CardCell align="center">
                {scene.productKey}
            </CardCell>
            <CardCell align="center">
                {scene.description}
            </CardCell>
            <CardCell align="center" className={classes.maxWidth}>
                <Grid container spacing={1}>
                    {scene.productOptions && scene.productOptions.map(
                        (productOption: Services.Scenes.ProductOption): JSX.Element => (
                            <Grid item key={`${productOption.optionName}: ${productOption.optionValue}`}>
                                <Chip label={`${productOption.optionName}: ${productOption.optionValue}`} />
                            </Grid>
                        ),
                    )}
                </Grid>
            </CardCell>
        </CardRow>
    );
};

export default ResultRow;
