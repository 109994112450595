import AbstractService from 'src/services/AbstractService';
import config from 'src/lib/config';
import { ServiceError } from 'src/lib/errors';
import { logServiceError } from 'src/utils/logger';

/**
 * Gets and sets attribute filters for a gallery
 */
class GalleryFiltersService extends AbstractService {
    constructor() {
        super(config.services.config);
    }

    public async getGalleryFilters(
        galleryId: number,
    ): Promise<Services.Filters.GalleryFilter[]> {
        const requestUrl = `api/v2/Galleries/${galleryId}/Config/Filters`;

        try {
            const response = await this.api.get<Services.Filters.GalleryFilter[]>(requestUrl, {
                params: {
                    requestor: config.appName,
                    noCache: true,
                },
            });

            return response.data;
        } catch (e: any) {
            const error = new ServiceError(e);

            logServiceError(error);
            throw error;
        }
    }

    /**
     * Updates gallery filter link
     *
     * @param filter
     * @param galleryId
     * @throws ServiceError
     */
    public async putGalleryFilter(
        filter: Services.Filters.GalleryFilter,
        galleryId: number,
        bearerToken: string,
    ): Promise<void> {
        const requestUrl = `api/v2/Galleries/${galleryId}/Config/Filters`;

        try {
            return await this.api.put(
                requestUrl,
                filter,
                {
                    params: {
                        requestor: config.appName,
                    },
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                },
            );
        } catch (e: any) {
            const error = new ServiceError(e);

            logServiceError(error);
            throw error;
        }
    }

    /**
     * deletes gallery filter link
     *
     * @param filter
     * @param galleryId
     * @throws ServiceError
     */
    public async deleteGalleryFilter(
        filter: Services.Filters.GalleryFilter,
        galleryId: number,
        bearerToken: string,
    ): Promise<void> {
        const requestUrl = `api/v2/Galleries/${galleryId}/Config/Filters/${filter.id}`;

        try {
            return await this.api.delete(
                requestUrl,
                {
                    params: {
                        requestor: config.appName,
                    },
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                },
            );
        } catch (e: any) {
            const error = new ServiceError(e);

            logServiceError(error);
            throw error;
        }
    }
}

export default new GalleryFiltersService();
