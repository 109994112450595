import { useContext, useState } from 'react';
import {
    Info,
    Settings,
    FilterList,
    RemoveCircleOutlined,
    History,
    Check,
    Error,
    Warning,
} from '@mui/icons-material';
import {
    Tabs,
    Tab,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { Theme } from '@mui/material/styles';
import {
    Link,
    Route,
    Switch,
    useRouteMatch,
} from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import Filters from 'src/components/ManageGallery/Filters';
import GalleryDetails from 'src/components/ManageGallery/GalleryDetails';
import GalleryRenderProperties from 'src/components/ManageGallery/GalleryRenderProperties';
import GalleryCategoryRestrictions from 'src/components/ManageGallery/GalleryCategoryRestrictions';
import GalleryAuditLog from 'src/components/ManageGallery/GalleryAuditLog';
import GalleryValidations from 'src/components/ManageGallery/GalleryValidations';
import ManageGalleryContext from 'src/contexts/manageGalleryContext';
import { VALIDATION_RESULT } from 'src/constants';

const useStyles = makeStyles()((theme: Theme) => ({
    container: {
        height: '100%',
        maxHeight: '100%',
    },
    underlined: {
        borderBottomWidth: 1,
        borderBottomColor: theme.palette.secondary.dark,
        borderBottomStyle: 'solid',
    },
    success: {
        color: theme.palette.success.main,
    },
    error: {
        color: theme.palette.error.main,
    },
    warning: {
        color: theme.palette.warning.main,
    },
}));

const ManageGallery = (): JSX.Element => {
    const { classes } = useStyles();
    const { path, url } = useRouteMatch();
    const [tabIndex, setTabIndex] = useState(0);
    const { galleryData } = useContext(ManageGalleryContext);
    const { validations } = galleryData;

    let validationIcon: JSX.Element;

    if (validations.some((v) => v.result === VALIDATION_RESULT.FAILED)) {
        validationIcon = (<Error className={classes.error} />);
    } else if (validations.some((v) => v.result === VALIDATION_RESULT.WARNING)) {
        validationIcon = (<Warning className={classes.warning} />);
    } else {
        validationIcon = (<Check className={classes.success} />);
    }

    return (
        <Grid
            container
            className={classes.container}
            direction="column"
            spacing={2}
            wrap="nowrap"
        >
            <Grid>
                <Tabs
                    className={classes.underlined}
                    value={tabIndex}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                >
                    <Tab icon={<Info />} label="DETAILS" component={Link} to={`${url}/details`} />
                    <Tab icon={<Settings />} label="RENDER PROPERTIES" component={Link} to={`${url}/renderProperties`} />
                    <Tab icon={<FilterList />} label="FILTERS" component={Link} to={`${url}/filters`} />
                    <Tab icon={<RemoveCircleOutlined />} label="CATEGORY RESTRICTIONS" component={Link} to={`${url}/categoryRestrictions`} />
                    <Tab icon={<History />} label="AUDIT LOG" component={Link} to={`${url}/auditLog`} />
                    <Tab icon={validationIcon} label="VALIDATIONS" component={Link} to={`${url}/validations`} />
                </Tabs>
            </Grid>
            <Grid className={classes.container}>
                <Switch>
                    <Route
                        exact
                        path={`${path}/details`}
                        render={(): JSX.Element | null => {
                            setTabIndex(0);
                            return tabIndex === 0 ? <GalleryDetails /> : null;
                        }}
                    />
                    <Route
                        exact
                        path={`${path}/renderProperties`}
                        render={(): JSX.Element | null => {
                            setTabIndex(1);
                            return tabIndex === 1 ? <GalleryRenderProperties /> : null;
                        }}
                    />
                    <Route
                        path={`${path}/filters`}
                        render={(): JSX.Element | null => {
                            setTabIndex(2);
                            return tabIndex === 2 ? <Filters /> : null;
                        }}
                    />
                    <Route
                        path={`${path}/categoryRestrictions`}
                        render={(): JSX.Element | null => {
                            setTabIndex(3);
                            return tabIndex === 3 ? <GalleryCategoryRestrictions /> : null;
                        }}
                    />
                    <Route
                        path={`${path}/auditLog`}
                        render={(): JSX.Element | null => {
                            setTabIndex(4);
                            return tabIndex === 4 ? <GalleryAuditLog /> : null;
                        }}
                    />
                    <Route
                        path={`${path}/validations`}
                        render={(): JSX.Element | null => {
                            setTabIndex(5);
                            return tabIndex === 5 ? <GalleryValidations /> : null;
                        }}
                    />
                    <Route
                        path={`${path}`}
                        render={(): JSX.Element | null => {
                            setTabIndex(0);
                            return tabIndex === 0 ? <GalleryDetails /> : null;
                        }}
                    />
                </Switch>
            </Grid>
        </Grid>
    );
};

export default ManageGallery;
