import {
    Dispatch, SetStateAction, useRef, useState,
} from 'react';
import qs from 'qs';
import { useHistory, useLocation } from 'react-router-dom';
import {
    CircularProgress,
    Grid,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import AppBar from 'src/components/common/AppBar';
import Content from 'src/components/Content';
import Main from 'src/components/Main';
import ResultsList from 'src/components/SearchScenesV2/ResultsList';
import SearchErrorMessage from 'src/components/common/SearchErrorMessage';
import SearchForm from 'src/components/SearchScenesV2/SearchForm';
import SearchScenesContext from 'src/contexts/SearchScenesContext';
import SearchScenesController from 'src/components/SearchScenesV2/SearchScenesController';
import useSearchedScenes from 'src/hooks/useSearchedScenes';
import Splash from 'src/components/common/Splash';
import { SEARCH_SCENES_TYPE } from 'src/constants';

const LIMIT = 50;

const useStyles = makeStyles()({
    centeredContent: {
        textAlign: 'center',
    },
    container: {
        height: '100%',
        maxHeight: '100%',
    },
    scrollTable: {
        maxHeight: '100%',
        overflow: 'auto',
        textAlign: 'center',
        '&.MuiGrid-item': {
            paddingTop: 0,
        },
    },
});

function createSearch(
    basicProduct: Services.Product.BasicProduct,
    productOptions: Services.Product.ProductOptionValuePair[],
): Services.Scenes.V2.Search {
    return {
        basicProduct,
        limit: LIMIT,
        productOptions: productOptions.reduce((accum: Services.Scenes.V2.ProductOptionSearch, option) => {
            // eslint-disable-next-line no-param-reassign
            accum[option.name] = option.value;

            return accum;
        }, {}),
        type: SEARCH_SCENES_TYPE.SEARCHING,
    };
}

function createPager(
    search: Services.Scenes.V2.Search | null,
    setSearch: Dispatch<SetStateAction<Services.Scenes.V2.Search | null>>,
) {
    return (nextPage: number): void => {
        if (search) {
            const newSearch = {
                ...search,
                offset: nextPage * LIMIT,
                type: SEARCH_SCENES_TYPE.PAGING,
            };

            setSearch(newSearch);
        }
    };
}

const SearchScenes = (): JSX.Element => {
    const history = useHistory();
    const location = useLocation();
    const { classes } = useStyles();
    const scrollTableRef = useRef<HTMLDivElement>(null);
    const [search, setSearch] = useState<Services.Scenes.V2.Search | null>(null);
    const [state, loading, error] = useSearchedScenes(search);

    const onSearch = async (selection: Services.Scenes.V2.SearchTerm): Promise<void> => {
        history.push({
            ...location,
            search: qs.stringify(selection),
        });

        const { basicProduct, productOptions } = selection;
        const newSearch = createSearch(basicProduct, productOptions);

        setSearch(newSearch);
    };

    return (
        <Main AppBar={(<AppBar title="Search Scenes" />)}>
            <Content>
                {!state && (<Splash />)}
                {state && (
                    <Grid
                        container
                        direction="column"
                        spacing={4}
                        wrap="nowrap"
                    >
                        <Grid item>
                            <SearchForm onSubmit={onSearch} />
                        </Grid>
                        <SearchScenesContext.Provider value={state}>
                            <SearchScenesController>
                                <Grid item className={classes.scrollTable} ref={scrollTableRef}>
                                    {loading && (<CircularProgress color="primary" />)}
                                    {!loading && error && (<SearchErrorMessage />)}
                                    {(!loading && !error) && (
                                        <ResultsList
                                            hasMore={state.searchState.hasMore}
                                            scrollParent={(): HTMLDivElement | null => (scrollTableRef.current)}
                                            onPage={createPager(search, setSearch)}
                                        />
                                    )}
                                </Grid>
                            </SearchScenesController>
                        </SearchScenesContext.Provider>
                    </Grid>
                )}
            </Content>
        </Main>
    );
};

export default SearchScenes;
