import { ChangeEvent, useContext } from 'react';

import Switch, { PropTypes as SwitchProps } from 'src/components/common/inputs/Switch';
import ManageGalleryContext from 'src/contexts/manageGalleryContext';
import { RenderPropertiesSchema } from 'src/constants';
import { addRenderProperty, removeRenderProperty } from 'src/store/galleryData/renderPropertiesStore';
import { getRenderProperty } from 'src/components/ManageGallery/GalleryRenderProperties/utils';
import GalleryGlobalValueContext from 'src/contexts/GalleryGlobalValueContext';

type RenderPropEvent = (event: ChangeEvent<HTMLInputElement>, value: boolean) => void;
interface PropTypes extends SwitchProps {
    editing?: boolean;
    propKey: string;
    experienceType: string;
}

const RenderPropertyEnable = (props: PropTypes): JSX.Element => {
    const {
        editing,
        propKey,
        experienceType,
        ...rest
    } = props;
    const { galleryData, dispatch } = useContext(ManageGalleryContext);
    const { renderProperties } = galleryData;
    const { globalGalleryData: { defaultRenderProperties } } = useContext(GalleryGlobalValueContext);
    const label = (RenderPropertiesSchema[propKey].hideFromList) ? ('Enabled') : null;

    const handleEnabledChange = (renderPropertyKey: string): RenderPropEvent => (
        event: ChangeEvent<HTMLInputElement>,
        value: boolean,
    ): void => {
        event.preventDefault();

        const renderPropValues = RenderPropertiesSchema[renderPropertyKey].values;

        const renderProperty = {
            key: renderPropertyKey,
            value: renderPropValues ? renderPropValues[0].dbValue : '',
            experienceType,
        };

        if (value) {
            dispatch(addRenderProperty(renderProperty));
        } else {
            dispatch(removeRenderProperty(renderProperty));
        }
    };

    const renderProperty = getRenderProperty(defaultRenderProperties, renderProperties, propKey, experienceType);

    return (
        <Switch
            {...rest}
            editing={editing}
            checked={renderProperty?.inheritedFrom === experienceType}
            label={label}
            onChange={handleEnabledChange(propKey)}
        />
    );
};

export default RenderPropertyEnable;
