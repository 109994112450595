import { SelectChangeEvent } from '@mui/material';

import Select, { SelectProps } from 'src/components/common/inputs/Select';
import { CULTURES } from 'src/constants';

interface PropTypes extends SelectProps {
    currentCulture: string;
    setCurrentCulture: (culture: string) => void;
    options?: string[];
}

const CultureSelect = (props: PropTypes): JSX.Element => {
    const {
        currentCulture,
        helperText,
        setCurrentCulture,
        label,
        options = CULTURES,
        ...rest
    } = props;

    const handleCultureChange = (event: SelectChangeEvent<unknown>): void => {
        event.preventDefault();

        setCurrentCulture(event.target.value as string);
    };

    return (
        <Select
            editing
            value={currentCulture}
            label={label}
            helperText={helperText}
            onChange={handleCultureChange}
            {...rest}
        >
            {!options.length && (<option value="" key="">None Available</option>)}
            {options.map((culture) => (
                <option value={culture} key={culture}>{culture}</option>
            ))}
        </Select>
    );
};

export default CultureSelect;
