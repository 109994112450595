import { HTMLProps, ReactNode } from 'react';
import {
    Typography,
    AppBar as MuiAppBar,
    Box,
    Alert,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

import capitalize from 'src/utils/capitalize';
import config from 'src/lib/config';

interface PropTypes extends HTMLProps<{}> {
    actions?: ReactNode;
    title: string;
}

const useStyles = makeStyles()((theme: Theme) => ({
    root: {
        backgroundColor: theme.palette.common.white,
        height: '100%',
    },
    gridRoot: {
        margin: '0',
        width: '100%',
        height: '100%',
    },
    exitButtonGrid: {
        textAlign: 'right',
    },
    logo: {
        display: 'inline',
        verticalAlign: 'middle',
    },
    logoText: {
        display: 'inline',
        verticalAlign: 'middle',
        paddingLeft: theme.spacing(1),
        fontSize: `min(max(${theme.typography.subtitle2.fontSize}, 2vw), ${theme.typography.h6.fontSize})`,
    },
    appName: {
        '&:hover': {
            backgroundColor: theme.palette.common.white,
            boxShadow: 'none',
        },
    },
}));

const AppBar = (props: PropTypes): JSX.Element => {
    const {
        actions,
        children,
        title,
    } = props;
    const { classes } = useStyles();

    return (
        <MuiAppBar className={classes.root} position="sticky" elevation={1}>
            <Grid
                container
                alignItems="center"
                className={classes.gridRoot}
                justifyContent="flex-end"
                spacing={3}
            >
                <Grid xs alignItems="flex-start">
                    <Typography className={classes.logoText} variant="h6" color="textSecondary">
                        {title}
                    </Typography>
                </Grid>
                {config.DEPLOYMENT !== 'production' && (
                    <Grid>
                        <Alert severity="warning">
                            <Box fontWeight="bold">
                                {`You are in the ${capitalize(config.DEPLOYMENT)} Environment`}
                            </Box>
                        </Alert>
                    </Grid>
                )}
                <Grid>
                    {children}
                </Grid>
                {actions && (
                    <Grid className={classes.exitButtonGrid}>
                        {actions}
                    </Grid>
                )}
            </Grid>
        </MuiAppBar>
    );
};

export default AppBar;
