import { ComponentProps, ReactNode, useRef } from 'react';
import {
    FormControl,
    Select as MuiSelect,
    FormHelperText,
    InputLabel,
} from '@mui/material';

export type SelectProps = (Omit<ComponentProps<typeof MuiSelect>, 'labelWidth'>) & {
    editing?: boolean;
    label?: string;
    required?: boolean;
    value?: ReactNode;
    helperText?: ReactNode;
    formMargin?: 'none' | 'dense' | 'normal';
    FormControlProps?: ComponentProps<typeof FormControl>;
};

function getRandomId(): string {
    return `input-select-${Math.floor(Math.random() * Math.floor(10000))}`;
}

const Select = (props: SelectProps): JSX.Element => {
    const {
        children,
        label,
        editing,
        required,
        value,
        helperText,
        id = getRandomId(),
        inputProps,
        formMargin,
        FormControlProps = {},
        ...rest
    } = props;
    const inputLabel = useRef<HTMLLabelElement>(null);

    return (
        <FormControl
            fullWidth
            required={required}
            variant="outlined"
            margin={formMargin}
            {...FormControlProps}
        >
            {label && (
                <InputLabel shrink htmlFor={id} ref={inputLabel}>
                    {label}
                </InputLabel>
            )}
            <MuiSelect
                native
                disabled={!editing}
                value={value}
                inputProps={{
                    ...inputProps,
                    id,
                }}
                label={label}
                {...rest}
            >
                {children}
            </MuiSelect>
            {helperText && (
                <FormHelperText>
                    {helperText}
                </FormHelperText>
            )}
        </FormControl>
    );
};

export default Select;
