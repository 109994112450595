import {
    Dispatch, SetStateAction, useContext, useState,
} from 'react';
import { Redirect } from 'react-router-dom';
import {
    Button,
    Grid,
    SelectChangeEvent,
    Typography,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

import BackBar from 'src/components/common/BackBar';
import ManageGalleryContext from 'src/contexts/manageGalleryContext';
import ZeroState from 'src/components/common/ZeroState';
import Card, { CardContent } from 'src/components/common/Card';
import Select, { SelectProps as MuiSelectProps } from 'src/components/common/inputs/Select';
import { EditContext } from 'src/contexts/EditContext';

interface PropTypes<T> {
    backTo: string;
    description?: string;
    backBarTitle: string;
    redirectTo: string;
    filter: string | undefined;
    setFilter: Dispatch<SetStateAction<string | undefined>>;
    filters: T[];
    addFilterAction: (id: string, globalFilter: T) => Actions.Action<unknown>;
    SelectProps?: MuiSelectProps;
}

const useStyles = makeStyles()((theme: Theme) => ({
    centered: {
        textAlign: 'center',
    },
    wideButton: {
        width: theme.spacing(24),
    },
}));

const CreateFilter = <T extends Services.Filters.IGlobalFilter, >(props: PropTypes<T>): JSX.Element => {
    const {
        backTo,
        description,
        redirectTo,
        backBarTitle,
        filter,
        setFilter,
        filters,
        addFilterAction,
        SelectProps = {},
    } = props;
    const { editing } = useContext(EditContext);
    const { dispatch } = useContext(ManageGalleryContext);
    const [saved, setSaved] = useState(false);

    const { classes } = useStyles();

    const handleFilterChange = (event: SelectChangeEvent<unknown>): void => {
        setFilter(event.target.value as string);
    };

    const handleCreateFilter = (): void => {
        if (filter) {
            const data = filters.find((f) => f.id === filter);

            if (data) {
                dispatch(addFilterAction(filter, data));
                setSaved(true);
            }
        }
    };

    if (saved) {
        return (<Redirect to={redirectTo} />);
    }

    if (!editing) {
        return (<Redirect to={backTo} />);
    }

    return (
        <>
            <BackBar to={backTo} title={backBarTitle} />
            {!filters.length && (
                <ZeroState text="All possible filters have already been added to this Gallery" />
            )}
            {!!filters.length && (
                <Card>
                    <CardContent>
                        <Grid container alignItems="center" justifyContent="center" spacing={4}>
                            {description && (
                                <Grid item xs={12}>
                                    <Typography variant="body2">
                                        {description}
                                    </Typography>
                                </Grid>
                            )}
                            <Grid item xs={6}>
                                <Select
                                    editing
                                    value={filter}
                                    onChange={handleFilterChange}
                                    {...SelectProps}
                                >
                                    <option value={undefined}>{' '}</option>
                                    {filters.map((f) => (f
                                        ? (
                                            <option value={f.id} key={f.id}>
                                                {`${f.name} (${f.id})`}
                                            </option>
                                        )
                                        : null))}
                                </Select>
                            </Grid>
                            <Grid item xs={6} className={classes.centered}>
                                <Button
                                    className={classes.wideButton}
                                    disabled={!filter}
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    onClick={handleCreateFilter}
                                >
                                    Create
                                </Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            )}
        </>
    );
};

export default CreateFilter;
