import { useContext, useState } from 'react';
import {
    Grid,
    Box,
    useTheme,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

import SubCategoryTree from 'src/components/ManageGallery/Filters/TaxonomyFilter/SubCategoryTree';
import Card, { CardContent } from 'src/components/common/Card';
import { CollapsableToggle, Collapsable } from 'src/components/common/Collapsable';
import { getMaxDepthFromType } from 'src/components/ManageGallery/Filters/TaxonomyFilter/utils';
import Select from 'src/components/common/inputs/Select';
import GalleryGlobalValueContext from 'src/contexts/GalleryGlobalValueContext';

interface PropTypes {
    taxonomyFilter: App.TaxonomyFilter;
}

const useStyles = makeStyles()((theme: Theme) => ({
    fullWidth: {
        width: '100%',
    },
    padded: {
        paddingTop: theme.spacing(2),
    },
}));

const TaxonomySelection = (props: PropTypes): JSX.Element => {
    const { globalGalleryData: { allTaxonomiesById } } = useContext(GalleryGlobalValueContext);
    const { taxonomyFilter } = props;
    const { classes } = useStyles();
    const theme = useTheme();
    const [expanded, setExpanded] = useState(true);

    const handleExpandClick = (): void => {
        setExpanded(!expanded);
    };

    return (
        <Card>
            <CardContent>
                <Grid
                    container
                    direction="column"
                    spacing={3}
                    justifyContent="space-between"
                >
                    <Grid item xs={12}>
                        <Select
                            editing={false}
                            label="Taxonomy"
                            helperText="Select the Taxonomy for this filter"
                            id="taxonomy-filter-name-select"
                            value={taxonomyFilter.globalFilter.id}
                            inputProps={{
                                name: 'name',
                            }}
                        >
                            {[...allTaxonomiesById.byId.values()].map((taxonomy) => (taxonomy
                                ? (
                                    <option key={taxonomy.id} value={taxonomy.id}>
                                        {taxonomy.name}
                                    </option>
                                )
                                : null))}
                        </Select>
                    </Grid>
                    <Grid item xs={12}>
                        <CollapsableToggle
                            className={classes.fullWidth}
                            expanded={expanded}
                            showLessText="Hide sub cateogries"
                            showMoreText="Show sub cateogries"
                            onClick={handleExpandClick}
                        />
                        <Collapsable className={classes.padded} expanded={expanded}>
                            <Box
                                bgcolor={theme.palette.grey[50]}
                                padding={parseInt(theme.spacing(1), 10) / 2}
                                marginBottom={parseInt(theme.spacing(1), 10) / 2}
                            >
                                <SubCategoryTree
                                    taxonomy={taxonomyFilter}
                                    maxDepth={getMaxDepthFromType(taxonomyFilter.filter.type)}
                                />
                            </Box>
                        </Collapsable>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default TaxonomySelection;
