import { ReactNode } from 'react';
import { Warning } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

interface PropTypes {
    title: ReactNode;
    subtitle?: ReactNode;
    message?: ReactNode;
}

const useStyles = makeStyles()((theme: Theme) => ({
    error: {
        paddingTop: theme.spacing(4),
        textAlign: 'center',
    },
}));

const ErrorState = (props: PropTypes): JSX.Element => {
    const { title, subtitle, message } = props;
    const { classes } = useStyles();

    return (
        <div className={classes.error}>
            <Warning color="error" fontSize="large" />
            <Typography
                gutterBottom
                align="center"
                variant="h6"
            >
                {title}
            </Typography>
            {subtitle && (
                <Typography
                    align="center"
                    variant="body1"
                >
                    {subtitle}
                </Typography>
            )}
            {message && (
                <Typography
                    align="center"
                    variant="body2"
                >
                    {message}
                </Typography>
            )}
        </div>
    );
};

export default ErrorState;
