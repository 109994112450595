import { MouseEvent } from 'react';
import {
    Chip,
    Typography,
    Box,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

interface PropTypes {
    filters: string[];
    onClick: (key: string) => void;
}

const TITLE = 'No compatible filters are available';
const SUBTITLE = 'Double check that you\'ve either added or properly configured filters for this Gallery';

const useStyles = makeStyles()((theme: Theme) => ({
    chips: {
        display: 'flex',
        justifyContent: 'left',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
}));

const AvailableFiltersList = (props: PropTypes): JSX.Element => {
    const { filters, onClick } = props;
    const { classes } = useStyles();

    const handleClick = (key: string) => (event: MouseEvent<HTMLDivElement>): void => {
        event.preventDefault();

        onClick(key);
    };

    // TODO make a common chips list component and replace everywhere
    return (
        <div className={classes.chips}>
            {!filters.length && (
                <>
                    <Typography paragraph variant="body2">
                        <Box fontWeight="bold">
                            {TITLE}
                        </Box>
                    </Typography>
                    <Typography variant="caption">{SUBTITLE}</Typography>
                </>
            )}
            {!!filters.length && filters.map((key) => (
                <Chip
                    key={key}
                    label={key}
                    color="primary"
                    onClick={handleClick(key)}
                />
            ))}
        </div>
    );
};

export default AvailableFiltersList;
