import classnames from 'classnames';
import { HTMLAttributes } from 'react';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

export interface PropTypes extends HTMLAttributes<HTMLSpanElement> {
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
}

interface SvgContainerPropTypes extends PropTypes {
    viewBoxWidth: string;
    viewBoxHeight: string;
}

const useStyles = makeStyles()((theme: Theme) => ({
    root: {
        display: 'block',
        textAlign: 'center',
    },
    xs: {
        height: theme.spacing(2),
        width: theme.spacing(2),
    },
    sm: {
        height: theme.spacing(3),
        width: theme.spacing(3),
    },
    md: {
        height: theme.spacing(5),
        width: theme.spacing(5),
    },
    lg: {
        height: theme.spacing(8),
        width: theme.spacing(8),
    },
    xl: {
        height: theme.spacing(13),
        width: theme.spacing(13),
    },
    xxl: {
        height: theme.spacing(21),
        width: theme.spacing(21),
    },
}));

const Icon = (): JSX.Element => (
    <g>
        <polygon fill="#006196" points="50.4,86.5 72.3,48.6 28.6,48.6" />
        <polygon fill="#2BA8E0" points="28.6,48.6 72.3,48.6 50.4,10.8" />
        <polygon fill="#6ECFF5" points="44.5,0.5 0.8,0.5 28.6,48.6 50.4,10.8" />
        <polygon fill="#006196" points="56.4,0.5 50.4,10.8 72.3,48.6 100.1,0.5" />
    </g>
);

const CompanyName = (): JSX.Element => (
    <>
        <g>
            <polygon
                fill="#38454F"
                points="136.7,27.6 125.3,51.3 113.9,27.6 106.9,27.6 125.3,66 143.7,27.6"
            />
            <path
                fill="#38454F"
                d="M168.5,55.2c2.5,3,5.2,5,9.6,5c3.9,0,8.2-1.7,8.2-5.8c0-4-4-5-8-5.9c-7.1-1.6-13.2-3.1-13.2-10.9
    c0-7.3,7-11,14-11c5.3,0,10.1,2,12.7,6.8l-5.2,3.4c-1.6-2.6-4.2-4.5-7.9-4.5c-3.4,0-7.3,1.7-7.3,5.2c0,3.1,4.5,4.5,9.4,5.4
    c6.6,1.3,12.2,3.7,12.2,11.2c0,8.5-7.5,11.7-14.9,11.7c-6.3,0-10.9-1.7-14.5-6.9L168.5,55.2z"
            />
            <path
                fill="#38454F"
                d="M221.7,32.9h-11v18.7c0,4.8,0.1,8.6,5.6,8.6c1.8,0,3.8-0.4,5.5-1.3l0.2,5.7c-2.2,1-5,1.4-7.3,1.4
    c-8.9,0-10.3-4.8-10.3-12.5V32.9h-8.1v-5.3h8.1V17h6.2v10.6h11V32.9z"
            />
            <path
                fill="#38454F"
                d="M427.5,32.9h-11v18.7c0,4.8,0.1,8.6,5.6,8.6c1.8,0,3.8-0.4,5.5-1.3l0.2,5.7c-2.2,1-5,1.4-7.3,1.4
    c-8.9,0-10.3-4.8-10.3-12.5V32.9h-8.1v-5.3h8.1V17h6.2v10.6h11V32.9z"
            />
            <path
                fill="#38454F"
                d="M274.5,27.6h6.2v5.4h0.2c3.4-4,8-6.4,13.4-6.4c11.7,0,19.6,8.3,19.6,19.6c0,11.2-8.2,19.6-18.7,19.6
    c-6.7,0-12.1-3.4-14.4-7.6h-0.2v20.9h-6.2V27.6z M294,60.2c8.1,0,13.3-6.1,13.3-13.9c0-7.8-5.2-13.9-13.3-13.9
    c-8.1,0-13.3,6.1-13.3,13.9C280.7,54.2,285.9,60.2,294,60.2"
            />
            <path
                fill="#38454F"
                d="M265.4,27.6h-6.2v5.4H259c-3.4-4-8-6.4-13.4-6.4c-11.7,0-19.6,8.3-19.6,19.6c0,11.2,8.2,19.6,18.7,19.6
    c6.7,0,12.1-3.4,14.4-7.6h0.2V65h6.2V27.6z M245.9,60.2c-8.1,0-13.3-6.1-13.3-13.9c0-7.8,5.2-13.9,13.3-13.9
    c8.1,0,13.3,6.1,13.3,13.9C259.2,54.2,254,60.2,245.9,60.2"
            />
            <path
                fill="#38454F"
                d="M320.3,36.5c0-3.4-0.3-6.4-0.3-8.9h5.9c0,2,0.2,4.1,0.2,6.2h0.2c1.7-3.7,6.3-7.1,12.5-7.1
    c0.8,0,1.7,0.1,2.6,0.4l-0.4,6.3c-1-0.3-2.2-0.5-3.4-0.5c-7.6,0-10.9,5.3-10.9,13.9V65h-6.2V36.5z"
            />
            <path
                fill="#38454F"
                d="M365,36.5c0-3.4-0.3-6.4-0.3-8.9h5.9c0,2,0.2,4.1,0.2,6.2h0.2c1.7-3.7,6.3-7.1,12.5-7.1
    c9.8,0,14.2,6.2,14.2,15.3V65h-6.2V42.6c0-6.2-2.7-10.1-8.5-10.1c-8.1,0-11.6,5.8-11.6,14.4V65H365V36.5z"
            />
            <rect x="150.8" y="27.6" fill="#38454F" width="6.2" height="37.4" />
            <path
                fill="#38454F"
                d="M157.6,20.3c0,2-1.6,3.7-3.7,3.7c-2,0-3.7-1.6-3.7-3.7c0-2,1.6-3.7,3.7-3.7
    C155.9,16.6,157.6,18.3,157.6,20.3z"
            />
            <rect x="348.5" y="27.6" fill="#38454F" width="6.2" height="37.4" />
            <path
                fill="#38454F"
                d="M355.3,20.3c0,2-1.6,3.7-3.7,3.7c-2,0-3.7-1.6-3.7-3.7c0-2,1.6-3.7,3.7-3.7
    C353.6,16.6,355.3,18.3,355.3,20.3z"
            />
        </g>
        <g>
            <path
                fill="#38454F"
                d="M442.1,21.5c0,3-2.5,5.5-5.6,5.5c-3.1,0-5.6-2.5-5.6-5.5c0-3,2.5-5.5,5.6-5.5
    C439.6,16,442.1,18.5,442.1,21.5z M441,21.5c0-2.5-2-4.5-4.5-4.5c-2.5,0-4.5,2-4.5,4.5c0,2.5,2,4.5,4.5,4.5
    C439,26,441,24,441,21.5z M436.8,18.8c1.3,0,2,0.6,2,1.6c0,0.9-0.6,1.5-1.7,1.6l1.8,2.1h-1.1l-1.7-2.1h-0.7v2.1h-0.9v-5.3H436.8z
    M435.4,19.6v1.7h1.4c0.7,0,1.1-0.3,1.1-0.8c0-0.5-0.4-0.8-1.1-0.8H435.4z"
            />
        </g>
    </>
);

const SvgContainer = (props: SvgContainerPropTypes): JSX.Element => {
    const {
        children,
        className,
        size = 'md',
        viewBoxHeight,
        viewBoxWidth,
        ...rest
    } = props;
    const { classes } = useStyles();

    return (
        <span className={classnames(classes.root, className)} {...rest}>
            <svg
                className={classes[size]}
                display="inherit"
                version="1.2"
                baseProfile="tiny"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
                xmlSpace="preserve"
            >
                {children}
            </svg>
        </span>
    );
};

const FullLogo = (props: PropTypes): JSX.Element => (
    <SvgContainer viewBoxHeight="87" viewBoxWidth="443" {...props}>
        <Icon />
        <CompanyName />
    </SvgContainer>
);

export const LogoIcon = (props: PropTypes): JSX.Element => (
    <SvgContainer viewBoxHeight="80" viewBoxWidth="100" {...props}>
        <Icon />
    </SvgContainer>
);

export default FullLogo;
