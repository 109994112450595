import classnames from 'classnames';

import {
    Divider,
    Typography,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import { HTMLProps } from 'react';

const useStyles = makeStyles()((theme: Theme) => ({
    header: {
        paddingBottom: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    title: {
        paddingLeft: theme.spacing(2),
        marginBottom: theme.spacing(2),
        fontWeight: 500,
    },
}));

const CardHeader = (props: HTMLProps<HTMLDivElement>): JSX.Element => {
    const {
        children,
        className = '',
        ...rest
    } = props;

    const { classes } = useStyles();

    return (
        <section className={classnames(classes.header, className)} {...rest}>
            <Typography component="div" className={classes.title} variant="subtitle1">
                {children}
            </Typography>
            <Divider />
        </section>
    );
};

export default CardHeader;
