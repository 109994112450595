import { HTMLProps, useContext, useState } from 'react';
import {
    Button,
    Grid,
    Dialog,
    DialogTitle,
    DialogContentText,
    DialogContent,
    DialogActions,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import { Redirect } from 'react-router-dom';
import { DeleteForever } from '@mui/icons-material';

import EditActions from 'src/components/common/AppBar/EditActions';
import ScenesService from 'src/services/ScenesService';
import { IdentityContext } from 'src/contexts/IdentityContext';
import { EditContext } from 'src/contexts/EditContext';
import { SEARCH_SCENES } from 'src/utils/routes';

interface PropTypes extends HTMLProps<{}> {
    sceneId: number;
}

const useStyles = makeStyles()((theme: Theme) => ({
    error: {
        backgroundColor: theme.palette.error.main,
        '&:hover': {
            backgroundColor: theme.palette.error.dark,
            boxShadow: 'none',
        },
        color: theme.palette.common.white,
    },
}));

const AppBarActions = (props: PropTypes): JSX.Element => {
    const { sceneId } = props;
    const { accessToken } = useContext(IdentityContext);
    const { setEditing } = useContext(EditContext);
    const [deleted, setDeleted] = useState<boolean>(false);
    const [deleteClicked, setDeleteClicked] = useState<boolean>(false);
    const { classes } = useStyles();

    const deleteSceneDefinition = async (): Promise<void> => {
        if (accessToken) {
            await ScenesService.deleteScene(sceneId, accessToken);
            // Exit edit mode so you don't get asked if you're okay being redirected
            setEditing(false);
            setDeleted(true);
        }
    };

    const deleteButtonClicked = (): void => {
        setDeleteClicked(true);
    };

    const dialogClosed = (): void => {
        setDeleteClicked(false);
    };

    if (deleted) {
        return <Redirect to={SEARCH_SCENES} />;
    }

    return (
        <Grid container spacing={1}>
            <Grid item>
                <Button
                    variant="contained"
                    className={classes.error}
                    startIcon={<DeleteForever />}
                    onClick={deleteButtonClicked}
                >
                    Delete Scene
                </Button>
                <Dialog open={deleteClicked} onClose={dialogClosed}>
                    <DialogTitle>{`Are you sure you want to delete scene ${sceneId}?`}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            This action cannot be undone
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="contained"
                            className={classes.error}
                            onClick={deleteSceneDefinition}
                        >
                            Yes
                        </Button>
                        <Button
                            variant="contained"
                            onClick={dialogClosed}
                        >
                            No
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
            <Grid item>
                <EditActions />
            </Grid>
        </Grid>
    );
};

export default AppBarActions;
