import { Dispatch, HTMLProps, SetStateAction } from 'react';
import {
    TableRow,
    TableCell,
    Button,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import AddIcon from '@mui/icons-material/Add';
import { TableCellProps } from '@mui/material/TableCell';

export interface AddToTableRenderProps<T> {
    row: T;
    index: number;
    updateRow: (index: number, row: T) => void;
    onDone: () => void;
    onDelete: () => void;
}

interface PropTypes<T> extends Omit<HTMLProps<{}>, 'children'> {
    addedRows: T[];
    setAddedRows: Dispatch<SetStateAction<T[]>>;
    createNewRow: () => void;
    saveRow: (row: T) => void;
    children: (props: AddToTableRenderProps<T>) => JSX.Element;
    addButtonText?: string;
    tableCellProps?: TableCellProps;
}

const useStyles = makeStyles()(() => ({
    addButton: {
        width: '100%',
        justifyContent: 'left',
    },
}));

const AddToTable = <T extends object>(props: PropTypes<T>): JSX.Element => {
    const {
        addedRows,
        setAddedRows,
        createNewRow,
        saveRow,
        addButtonText = 'Add Item',
        children,
        tableCellProps = {},
    } = props;
    const { classes } = useStyles();

    const deleteRow = (index: number): () => void => (): void => {
        // slice first to clone
        const cloneAddedRows = addedRows.slice();

        cloneAddedRows.splice(index, 1);

        setAddedRows(cloneAddedRows);
    };

    const updateRow = (index: number, row: T): void => {
        const cloneAddedRows = addedRows.slice();

        cloneAddedRows[index] = row;
        setAddedRows(cloneAddedRows);
    };

    const saveAndDeleteRow = (index: number, row: T): () => void => (): void => {
        saveRow(row);
        deleteRow(index)();
    };

    return (
        <>
            {addedRows.map((row: T, index: number): JSX.Element => (
                children({
                    row,
                    index,
                    updateRow,
                    onDone: saveAndDeleteRow(index, row),
                    onDelete: deleteRow(index),
                })
            ))}
            <TableRow>
                <TableCell colSpan={4} align="left" {...tableCellProps}>
                    <Button className={classes.addButton} size="small" startIcon={<AddIcon />} onClick={createNewRow}>
                        {addButtonText}
                    </Button>
                </TableCell>
            </TableRow>
        </>
    );
};

export default AddToTable;
