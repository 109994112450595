import { MouseEvent } from 'react';
import classnames from 'classnames';
import TableRow, { TableRowProps } from '@mui/material/TableRow';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import { useHistory } from 'react-router-dom';
import { DraggableProvided } from 'react-beautiful-dnd';

const useStyles = makeStyles()((theme: Theme) => ({
    card: {
        background: theme.palette.common.white,
        boxShadow: theme.shadows[1],
    },
    hover: {
        cursor: 'pointer',
    },
}));

interface PropTypes extends TableRowProps {
    to?: string;
    draggableProvided?: DraggableProvided;
}
const CardRow = (props: PropTypes): JSX.Element => {
    const {
        children,
        className = '',
        onClick,
        to,
        draggableProvided,
        ...rest
    } = props;
    const { classes } = useStyles();
    const history = useHistory();
    const navigate = (event: MouseEvent<{}>): void => {
        event.preventDefault();

        if (to != null) {
            history.push(to);
        }
    };

    return (
        <TableRow
            className={classnames(classes.card, className, {
                [classes.hover]: !!to || !!onClick,
            })}
            {...(draggableProvided && {
                ref: draggableProvided.innerRef,
                ...draggableProvided.draggableProps,
                ...draggableProvided.dragHandleProps,
            })}
            onClick={onClick || navigate}
            {...rest}
        >
            {children}
        </TableRow>
    );
};

export default CardRow;
