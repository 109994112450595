import translationService from 'src/services/TranslationService';

type ExistingTranslationOperations = {
    toDelete: Services.Translations.V2.Translation[];
    toUpdate: Services.Translations.V2.Translation[];
};

const saveTranslations = (bearerToken: string) => async (
    translationGroupId: number,
    translations: Services.Translations.V2.Translations,
): Promise<void> => {
    const existingTranslations = await translationService.getTranslations(translationGroupId);

    const { toDelete, toUpdate } = Object.keys(existingTranslations).reduce((accum, culture) => {
        const originalValue = existingTranslations[culture];

        if (!(culture in translations)) {
            accum.toDelete.push({
                culture,
                value: originalValue,
            });
        } else if (originalValue !== translations[culture]) {
            accum.toUpdate.push({
                culture,
                value: translations[culture],
            });
        }

        return accum;
    }, { toDelete: [], toUpdate: [] } as ExistingTranslationOperations);

    const toCreate = Object.keys(translations).reduce((accum, culture) => {
        if (!(culture in existingTranslations)) {
            accum.push({
                culture,
                value: translations[culture],
            });
        }

        return accum;
    }, [] as Services.Translations.V2.Translation[]);

    const createTranslationsTask = translationService.postTranslations(translationGroupId, toCreate, bearerToken);
    const deleteTranslationsTask = translationService.deleteTranslations(translationGroupId, toDelete, bearerToken);
    const updateTranslationsTask = translationService.putTranslations(translationGroupId, toUpdate, bearerToken);

    await Promise.all([
        ...createTranslationsTask,
        ...deleteTranslationsTask,
        ...updateTranslationsTask,
    ]);
};

export default saveTranslations;
