import { useContext } from 'react';
import ManageGalleryNotificationChannelsContext from 'src/contexts/ManageGalleryNotificationChannelContext';
import { saveGalleryNotificationChannels } from 'src/store/GalleryNotificationChannelData/utils';
import EditNotificationChannels from './EditNotificationChannels';
import ReadOnlyNotificationChannels from './ReadOnlyNotificationChannels';

interface PropTypes {
    editing: boolean;
    notificationChannels: Services.NotificationChannels.NotificationChannel[];
}

const ManageGalleryNotificationChannels = (props: PropTypes): JSX.Element => {
    const {
        editing,
        notificationChannels,
    } = props;

    const galleryNotificationChannelContext = useContext(ManageGalleryNotificationChannelsContext);
    const { galleryNotificationChannelData, dispatch } = galleryNotificationChannelContext;

    const handleUpdateNotificationChannels = (
        galleryNotificationChannels: Services.NotificationChannels.NotificationChannel[],
    ): void => {
        dispatch(saveGalleryNotificationChannels({
            ...galleryNotificationChannelData,
            galleryNotificationChannels,
        }));
    };

    return (
        <>
            {editing && (
                <EditNotificationChannels
                    notificationChannels={notificationChannels}
                    onAdd={handleUpdateNotificationChannels}
                    onDelete={handleUpdateNotificationChannels}
                />
            )}
            {!editing && (
                <ReadOnlyNotificationChannels
                    notificationChannels={notificationChannels}
                />
            )}
        </>
    );
};

export default ManageGalleryNotificationChannels;
