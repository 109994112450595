import { useContext, forwardRef } from 'react';
import { CircularProgress, TableBodyProps } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroller';
import { TableProps } from '@mui/material/Table';

import Header from 'src/components/common/Header';
import NoSearchResults from 'src/components/common/NoSearchResults';
import ResultRow from 'src/components/SearchScenesV2/ResultRow';
import SearchScenesContext from 'src/contexts/SearchScenesContext';
import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from 'src/components/common/CardTable';

interface PropTypes extends TableProps {
    hasMore: boolean;
    onPage: (nextPage: number) => void;
    scrollParent: () => HTMLElement | null;
}

const ResultsList = (props: PropTypes): JSX.Element => {
    const {
        hasMore,
        onPage,
        scrollParent,
        ...rest
    } = props;
    const {
        searchState: {
            galleryName,
            scenes,
        },
    } = useContext(SearchScenesContext);

    return (
        <Table stickyHeader {...rest}>
            <TableHead>
                <TableRow>
                    <TableCell align="center"><Header>Preview</Header></TableCell>
                    <TableCell align="center"><Header>Product Key</Header></TableCell>
                    <TableCell align="center"><Header>Description</Header></TableCell>
                    <TableCell align="center"><Header>Product Options</Header></TableCell>
                </TableRow>
            </TableHead>
            {/* TODO this lib hasn't been touched in 2 years and needs to be replaced ASAP */}
            <InfiniteScroll
                // @ts-ignore
                // eslint-disable-next-line react/no-unstable-nested-components
                element={forwardRef<HTMLTableSectionElement, TableBodyProps>(
                    // eslint-disable-next-line react/prop-types
                    ({ children, ...other }, ref): JSX.Element => (
                        <TableBody {...other} ref={ref}>
                            {children}
                        </TableBody>
                    ),
                )}
                hasMore={hasMore}
                initialLoad={false}
                loader={(
                    <TableRow key="loader">
                        <TableCell align="center" colSpan={6}>
                            <CircularProgress color="primary" />
                        </TableCell>
                    </TableRow>
                )}
                loadMore={onPage}
                pageStart={0}
                threshold={1}
                useWindow={false}
                getScrollParent={scrollParent}
            >
                {(scenes && !scenes.length) && (
                    <TableRow>
                        <TableCell align="center" colSpan={4}>
                            <NoSearchResults />
                        </TableCell>
                    </TableRow>
                )}
                {(scenes && !!scenes.length) && scenes.map((scene): JSX.Element => (
                    <ResultRow key={scene.id} galleryName={galleryName} scene={scene} />
                ))}
            </InfiniteScroll>
        </Table>
    );
};

export default ResultsList;
