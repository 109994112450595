import AbstractService from 'src/services/AbstractService';
import config from 'src/lib/config';
import { ServiceError } from 'src/lib/errors';
import { logServiceError } from 'src/utils/logger';

/**
 *
 */
class GalleryCategoryRestrictionsService extends AbstractService {
    constructor() {
        super(config.services.config);
    }

    public async getCategoryRestrictions(
        galleryId: number,
    ): Promise<Services.CategoryRestrictions.CategoryRestrictionDto[]> {
        const requestUrl = `api/v2/Galleries/${galleryId}/CategoryRestrictions`;

        try {
            const response = await this.api.get<Services.CategoryRestrictions.CategoryRestrictionDto[]>(requestUrl, {
                params: {
                    requestor: config.appName,
                    noCache: true,
                },
            });

            return response.data;
        } catch (e: any) {
            const error = new ServiceError(e);

            logServiceError(error);
            throw error;
        }
    }

    public async postCategoryRestriction(
        galleryId: number,
        categoryRestriction: Services.CategoryRestrictions.CategoryRestrictionDto,
        bearerToken: string,
    ): Promise<void> {
        const requestUrl = `api/v2/Galleries/${galleryId}/CategoryRestrictions`;

        try {
            await this.api.post(
                requestUrl,
                categoryRestriction,
                {
                    params: {
                        requestor: config.appName,
                    },
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                },
            );
        } catch (e: any) {
            const error = new ServiceError(e);

            logServiceError(error);
            throw error;
        }
    }

    public async putCategoryRestriction(
        galleryId: number,
        categoryRestriction: Services.CategoryRestrictions.CategoryRestrictionDto,
        bearerToken: string,
    ): Promise<void> {
        const requestUrl = `api/v2/Galleries/${galleryId}/CategoryRestrictions/${categoryRestriction.categoryKey}`;

        try {
            return await this.api.put(
                requestUrl,
                { include: categoryRestriction.include },
                {
                    params: {
                        requestor: config.appName,
                    },
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                },
            );
        } catch (e: any) {
            const error = new ServiceError(e);

            logServiceError(error);
            throw error;
        }
    }

    public async deleteCategoryRestriction(
        galleryId: number,
        categoryKey: number,
        bearerToken: string,
    ): Promise<void> {
        const requestUrl = `api/v2/Galleries/${galleryId}/CategoryRestrictions/${categoryKey}`;

        try {
            return await this.api.delete(
                requestUrl,
                {
                    params: {
                        requestor: config.appName,
                    },
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                },
            );
        } catch (e: any) {
            const error = new ServiceError(e);

            logServiceError(error);
            throw error;
        }
    }

    public updateCategoryRestrictions(
        galleryId: number,
        oldCategoryRestrictions: Services.CategoryRestrictions.CategoryRestrictionDto[],
        categoryRestrictions: Services.CategoryRestrictions.CategoryRestrictionDto[],
        bearerToken: string,
    ): Promise<void>[] {
        const categoriesToUpdate = categoryRestrictions
            .filter((cr) => oldCategoryRestrictions
                .find((ocr) => ocr.categoryKey === cr.categoryKey && ocr.include !== cr.include));
        const categoriesToPost = categoryRestrictions
            .filter((cr) => !oldCategoryRestrictions.find((ocr) => ocr.categoryKey === cr.categoryKey));
        const categoriesToDelete = oldCategoryRestrictions
            .filter((ocr) => !categoryRestrictions.find((cr) => cr.categoryKey === ocr.categoryKey));

        return [
            ...categoriesToUpdate.map((cr) => this.putCategoryRestriction(galleryId, cr, bearerToken)),
            ...categoriesToPost.map((cr) => this.postCategoryRestriction(galleryId, cr, bearerToken)),
            ...categoriesToDelete.map((cr) => this.deleteCategoryRestriction(galleryId, cr.categoryKey, bearerToken)),
        ];
    }
}

export default new GalleryCategoryRestrictionsService();
