import classnames from 'classnames';

import { TableProps } from '@mui/material/Table';
import {
    Table as MuiTable,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
    rowSpacing: {
        borderCollapse: 'separate',
        borderSpacing: `0 ${theme.spacing(1)}`,
    },
}));

const Table = (props: TableProps): JSX.Element => {
    const {
        children,
        className = '',
        ...rest
    } = props;
    const { classes } = useStyles();

    return (
        <MuiTable className={classnames(classes.rowSpacing, className)} {...rest}>
            {children}
        </MuiTable>
    );
};

export default Table;
