import clsx from 'clsx';
import { ComponentProps, useState, useEffect } from 'react';
import {
    Tooltip,
    Button,
    lighten,
    ToggleButton,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import { PowerSettingsNew } from '@mui/icons-material';

import { GALLERY_STATUS } from 'src/constants';

interface PropTypes extends Omit<ComponentProps<typeof ToggleButton>, 'value'>{
    editing: boolean;
    status: string;
    handleStatusChange: () => void;
}

const useStyles = makeStyles()((theme: Theme) => ({
    button: {
        padding: theme.spacing('6px', '16px'),
        color: theme.palette.common.black,
        width: theme.spacing(17),
        '&.Mui-disabled': {
            color: theme.palette.common.black,
        },
    },
    disabledButton: {
        borderColor: `${theme.palette.error.main} !important`,
        '&:hover': {
            backgroundColor: theme.palette.error.main,
            color: theme.palette.common.white,
            boxShadow: 'none',
        },
        backgroundColor: lighten(theme.palette.error.main, 0.8),
    },
    enabledButton: {
        borderColor: theme.palette.success.main,
        '&:hover': {
            backgroundColor: theme.palette.success.main,
            color: theme.palette.common.white,
            boxShadow: 'none',
        },
        backgroundColor: lighten(theme.palette.success.main, 0.8),
    },
    icon: {
        marginRight: theme.spacing(1),
    },
}));

const ManageGalleryStatus = (props: PropTypes): JSX.Element => {
    const {
        className,
        editing,
        status,
        handleStatusChange,
        ...rest
    } = props;
    const { classes } = useStyles();
    const [buttonText, setButtonText] = useState<string>();
    const [mouseOnButton, setMouseOnButton] = useState(false);
    const active = status === GALLERY_STATUS.ACTIVE;
    const actionHover = editing && mouseOnButton;

    useEffect(() => {
        let text = active ? 'Enabled' : 'Disabled';

        if (actionHover) {
            text = active ? 'Disable' : 'Enable';
        }
        setButtonText(text);
    }, [active, actionHover]);

    return (
        <Tooltip title={(
            <>
                <p>
                    Inactive galleries have working URLs
                    but are not discoverable by other parts of the site.
                </p>
                <p>
                    It is recommended to disable a Gallery while it is being worked
                    on or if it needs to be temporarily removed from the site for any reason.
                </p>
            </>
        )}
        >
            <ToggleButton
                {...rest}
                className={clsx(
                    classes.button,
                    { [classes.enabledButton]: (active && !actionHover) || (!active && actionHover) },
                    { [classes.disabledButton]: (!active && !actionHover) || (active && actionHover) },
                    className,
                )}
                color={active ? 'primary' : 'secondary'}
                component={Button}
                value="active"
                disabled={!editing}
                onChange={handleStatusChange}
                onMouseEnter={(): void => setMouseOnButton(true)}
                onMouseLeave={(): void => setMouseOnButton(false)}
            >
                <PowerSettingsNew className={classes.icon} />
                <span>{buttonText}</span>
            </ToggleButton>
        </Tooltip>
    );
};

export default ManageGalleryStatus;
