import axios, { AxiosInstance } from 'axios';

/**
 * Abstract (base) class for all Service implementations.
 */
export default class AbstractService {
    api: AxiosInstance;

    constructor(serviceConfig: App.ServiceConfig) {
        this.api = axios.create(serviceConfig);
    }
}
