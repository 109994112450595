import { ChangeEvent, useContext } from 'react';
import { Grid, SelectChangeEvent } from '@mui/material';

import config from 'src/lib/config';
import Checkbox from 'src/components/common/inputs/Checkbox';
import Select from 'src/components/common/inputs/Select';
import TextField from 'src/components/common/inputs/TextField';
import Card, { CardContent, CardHeader } from 'src/components/common/Card';
import { getMaxDepthFromType, typeToLevelsMap, getTypeFromMaxDepth } from 'src/components/ManageGallery/Filters/TaxonomyFilter/utils';
import { EditContext } from 'src/contexts/EditContext';
import ManageGalleryContext from 'src/contexts/manageGalleryContext';
import { updateGalleryFilter } from 'src/store/galleryData/filterStore';

interface PropTypes {
    taxonomyFilter: App.TaxonomyFilter;
    taxonomyId: string;
}

const FilterDetails = (props: PropTypes): JSX.Element => {
    const { taxonomyFilter, taxonomyId } = props;
    const { editing } = useContext(EditContext);
    const { dispatch } = useContext(ManageGalleryContext);

    const handleBestSellingCountChange = (
        event: ChangeEvent<HTMLTextAreaElement>,
    ): void => {
        event.preventDefault();

        const newBestSellingCount = parseInt(event.target.value, 10);

        dispatch(updateGalleryFilter({
            ...taxonomyFilter.filter,
            bestSellingCount: newBestSellingCount,
        }, taxonomyId));
    };

    const handleSubCategoryDepthChange = (event: SelectChangeEvent<unknown>): void => {
        event.preventDefault();

        dispatch(updateGalleryFilter({
            ...taxonomyFilter.filter,
            type: getTypeFromMaxDepth(parseInt(event.target.value as string, 10)),
        }, taxonomyId));
    };

    const handleCollapsedChange = (
        event: ChangeEvent<HTMLInputElement>,
        value: boolean,
    ): void => {
        event.preventDefault();

        dispatch(updateGalleryFilter({
            ...taxonomyFilter.filter,
            collapsed: value,
        }, taxonomyId));
    };

    return (
        <Card>
            <CardHeader>
                Filter Details
            </CardHeader>
            <CardContent>
                <Grid container direction="column" spacing={3} justifyContent="space-between">
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            value={taxonomyFilter.globalFilter.category.localizedTitle[config.defaultCulture]}
                            id="title-input"
                            label="Title"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            editing={editing}
                            value={taxonomyFilter.filter.bestSellingCount > -1
                                ? taxonomyFilter.filter.bestSellingCount
                                : ''}
                            id="best-selling-count-input"
                            label="Best Selling Count"
                            onChange={handleBestSellingCountChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Select
                            editing={editing}
                            label="Sub-Category Depth"
                            id="sub-category-levels-select"
                            helperText="Select the how many levels of sub-categories users can expand down"
                            value={getMaxDepthFromType(taxonomyFilter.filter.type)}
                            inputProps={{
                                name: 'type',
                            }}
                            onChange={handleSubCategoryDepthChange}
                        >
                            {Object.values(typeToLevelsMap).map((level): JSX.Element => (
                                <option key={level} value={level}>{level}</option>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={12}>
                        <Checkbox
                            editing={editing}
                            checked={taxonomyFilter.filter.collapsed}
                            id="collapsed-input"
                            label="Collapsed"
                            onChange={handleCollapsedChange}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default FilterDetails;
