import {
    ChangeEvent, ReactNode, useContext, useMemo, useState,
} from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { SimpleTreeView, SimpleTreeViewProps } from '@mui/x-tree-view';
import { ExpandMore, ChevronRight } from '@mui/icons-material';
import { createStyles } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

import GalleryGlobalValueContext from 'src/contexts/GalleryGlobalValueContext';
import { EditContext } from 'src/contexts/EditContext';
import StyledTreeItem, { CategoryTreeItem } from 'src/components/ManageGallery/GalleryCategoryRestrictions/CategoryRestrictionPicker/StyledTreeItem';

export interface Category {
    id: string;
    taxonomyId: string;
    categoryKey: string;
    name: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface PropTypes extends SimpleTreeViewProps<any> {
    taxonomyId: string;
    maxDepth: number;
    selected: string[];
    onRowSelected: (categoryTreeRow: Category[]) => void;
}

const useStyles = makeStyles()(
    createStyles({
        root: {
            flexGrow: 1,
            width: '100%',
        },
    }),
);

const EndIcon = (): ReactNode => <span />;

const CategoryTree = (props: PropTypes): JSX.Element | null => {
    const {
        taxonomyId, maxDepth, selected, onRowSelected,
    } = props;

    const { classes } = useStyles();

    const { editing } = useContext(EditContext);
    const { globalGalleryData: { allTaxonomiesById } } = useContext(GalleryGlobalValueContext);
    const taxonomy = allTaxonomiesById.byId.get(taxonomyId);

    const [expanded, setExpanded] = useState<string[]>([]);

    const flatTaxonomy = useMemo(() => {
        function flatten(categories: Services.Filters.Category[], parentId: string): Category[] {
            const flat: Category[] = [];

            categories.forEach((category) => {
                const id = `${parentId}_${category.categoryKey}`;

                flat.push({
                    id,
                    categoryKey: category.categoryKey.toString(),
                    taxonomyId,
                    name: category.name,
                });

                if (category.children.length > 0) {
                    flat.push(...flatten(category.children, id));
                }
            });

            return flat;
        }

        return typeof taxonomy !== 'undefined' ? flatten([taxonomy.category], '') : undefined;
    }, [taxonomyId, taxonomy]);

    const selectedNodeIds = flatTaxonomy?.filter(
        (ft) => selected.includes(ft.categoryKey.toString()),
    ).map((ft) => ft.id);

    const taxonomyTree = useMemo(() => {
        function potatos(): (
            item: Services.Filters.Category,
            depth: number,
            parentId: string,
        ) => CategoryTreeItem {
            return function potato(
                category: Services.Filters.Category,
                depth: number,
                parentId: string,
            ): CategoryTreeItem {
                const id = `${parentId}_${category.categoryKey}`;

                return {
                    nodeId: id,
                    label: `${category.name} (${category.categoryKey})`,
                    children: depth < maxDepth
                        ? category.children.map((child) => potato(child, depth + 1, id))
                        : [],
                };
            };
        }

        return typeof taxonomy !== 'undefined' ? potatos()(taxonomy.category, 0, '') : undefined;
    }, [taxonomy, maxDepth]);

    const handleOnNodeSelect = (_: ChangeEvent<{}>, nodeIds: string[]): void => {
        if (typeof flatTaxonomy !== 'undefined') {
            const categories = flatTaxonomy.filter((r) => nodeIds.includes(r.id));

            onRowSelected(categories.map((c) => ({
                id: c.id,
                name: c.name,
                taxonomyId: c.taxonomyId,
                categoryKey: c.categoryKey,
            })));
        }
    };

    const handleToggle = (_: ChangeEvent<{}>, nodeIds: string[]): void => {
        if (editing) {
            setExpanded(nodeIds);
        }
    };

    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
            {!!taxonomyTree && (
                <Grid container alignItems="center" direction="column">
                    <SimpleTreeView
                        multiSelect
                        disableSelection={!editing}
                        className={classes.root}
                        slots={{ collapseIcon: ExpandMore, expandIcon: ChevronRight, endIcon: EndIcon }}
                        expandedItems={expanded}
                        selectedItems={selectedNodeIds}
                        onExpandedItemsChange={handleToggle}
                        onSelectedItemsChange={handleOnNodeSelect}
                    >
                        <StyledTreeItem root={taxonomyTree} />
                    </SimpleTreeView>
                </Grid>
            )}
        </>
    );
};

export default CategoryTree;
