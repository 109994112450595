import { translationGroupReducer } from 'src/store/translationsData/translationGroup';
import { translationsReducer } from 'src/store/translationsData/translations';
import { INITIALIZE_TRANSLATIONS_DATA } from 'src/store/translationsData/constants';

export const initializeTranslationsDataAction = (
    translationsData: App.TranslationsData,
): Actions.TranslationsDataAction => ({
    type: INITIALIZE_TRANSLATIONS_DATA,
    payload: {
        translationsData,
    },
});

export const reducer = (state: App.TranslationsData | null, action: Actions.Action): App.TranslationsData | null => {
    switch (action.type) {
        case (INITIALIZE_TRANSLATIONS_DATA):
            return (action as Actions.TranslationsDataAction).payload.translationsData;
        default:
            if (!state) {
                return state;
            }
            return {
                ...state,
                translationGroup: translationGroupReducer(state.translationGroup, action),
                translations: translationsReducer(state.translations, action),
            };
    }
};
