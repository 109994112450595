import classnames from 'classnames';

import Typography, { TypographyProps } from '@mui/material/Typography';
import { Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
    description: {
        textTransform: 'uppercase',
    },
}));

const Header = (props: TypographyProps): JSX.Element => {
    const {
        children,
        className,
        ...rest
    } = props;
    const { classes } = useStyles();

    return (
        <Typography
            className={classnames(classes.description, className)}
            {...rest}
        >
            <Box component="span" fontWeight="fontWeightBold" fontSize={12}>
                {children}
            </Box>
        </Typography>
    );
};

export default Header;
