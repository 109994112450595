import ErrorState from 'src/components/common/ErrorState';

const SearchErrorMessage = (): JSX.Element => (
    <ErrorState
        title="Something went wrong with that search"
        subtitle="Double check what you typed and try again"
        message="If this keeps happening, please reach out to the #help-gallery slack channel for assistance"
    />
);

export default SearchErrorMessage;
