import AbstractService from 'src/services/AbstractService';
import config from 'src/lib/config';
import { ServiceError } from 'src/lib/errors';
import { logServiceError } from 'src/utils/logger';

class GalleryNotificationChannelService extends AbstractService {
    constructor() {
        super(config.services.config);
    }

    public async getPagableGalleryNotificationChannels(
        galleryNotificationChannelSearch: Services.NotificationChannels.GalleryNotificationChannelSearch,
    ): Promise<Services.NotificationChannels.NotificationChannel[]> {
        const requestUrl = `api/v3/Galleries/${galleryNotificationChannelSearch.galleryId}/NotificationChannels`;

        try {
            const response = await this.api.get<Services.NotificationChannels.NotificationChannel[]>(requestUrl, {
                params: {
                    notificationChannelLabel: galleryNotificationChannelSearch.notificationChannelLabel,
                    defaultFor: galleryNotificationChannelSearch.defaultFor,
                    offset: galleryNotificationChannelSearch.offset,
                    limit: galleryNotificationChannelSearch.limit ? galleryNotificationChannelSearch.limit : 10000,
                    requestor: config.appName,
                },
            });

            return response.data;
        } catch (e: any) {
            const error = new ServiceError(e);

            logServiceError(error);
            throw error;
        }
    }

    public async addGalleryNotificationChannel(
        galleryId: number,
        notificationChannel: Services.NotificationChannels.NotificationChannel,
        bearerToken: string,
    ): Promise<void> {
        const requestUrl = `api/v3/Galleries/${galleryId}/NotificationChannels`;

        try {
            const response = await this.api.post(
                requestUrl,
                null,
                {
                    params: {
                        requestor: config.appName,
                        notificationChannelId: notificationChannel.id,
                    },
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                },
            );

            return response.data;
        } catch (e: any) {
            const error = new ServiceError(e);

            logServiceError(error);
            throw error;
        }
    }

    public async addAllGalleryNotificationChannels(
        galleryId: number,
        notificationChannels: Services.NotificationChannels.NotificationChannel[],
        bearerToken: string,
    ): Promise<Promise<void>[]> {
        return notificationChannels.map(
            (notificationChannel) => this.addGalleryNotificationChannel(galleryId, notificationChannel, bearerToken),
        );
    }

    public async deleteGalleryNotificationChannel(
        galleryId: number,
        notificationChannel: Services.NotificationChannels.NotificationChannel,
        bearerToken: string,
    ): Promise<void> {
        const requestUrl = `api/v3/Galleries/${galleryId}/NotificationChannels`;

        try {
            return await this.api.delete(
                requestUrl,
                {
                    params: {
                        requestor: config.appName,
                        notificationChannelId: notificationChannel.id,
                    },
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                },
            );
        } catch (e: any) {
            const error = new ServiceError(e);

            logServiceError(error);
            throw error;
        }
    }

    public async deleteAllGalleryNotificationChannels(
        galleryId: number,
        notificationChannels: Services.NotificationChannels.NotificationChannel[],
        bearerToken: string,
    ): Promise<Promise<void>[]> {
        return notificationChannels.map(
            (notificationChannel) => this.deleteGalleryNotificationChannel(galleryId, notificationChannel, bearerToken),
        );
    }
}

export default new GalleryNotificationChannelService();
