import { useContext } from 'react';
import {
    Grid,
    Typography,
} from '@mui/material';

import Card, { CardHeader, CardContent } from 'src/components/common/Card';
import ManageGalleryContext from 'src/contexts/manageGalleryContext';
import { EditContext } from 'src/contexts/EditContext';
import RenderPropertyEnable from 'src/components/ManageGallery/GalleryRenderProperties/RenderPropertyEnable';
import RenderPropertySelect from 'src/components/ManageGallery/GalleryRenderProperties/RenderPropertySelect';
import { getRenderProperty } from 'src/components/ManageGallery/GalleryRenderProperties/utils';
import { BASE_EXPERIENCE_TYPE } from 'src/constants';
import GalleryGlobalValueContext from 'src/contexts/GalleryGlobalValueContext';

const BACKGROUND_COLOR_KEY = 'ContentBackgroundColor';

const ContentBackgroundColor = (): JSX.Element => {
    const { galleryData } = useContext(ManageGalleryContext);
    const { renderProperties } = galleryData;
    const { editing } = useContext(EditContext);
    const { globalGalleryData: { defaultRenderProperties } } = useContext(GalleryGlobalValueContext);
    const showBackgroundColor = !!getRenderProperty(
        defaultRenderProperties,
        renderProperties,
        BACKGROUND_COLOR_KEY,
        BASE_EXPERIENCE_TYPE,
    );

    return (
        <Card>
            <CardHeader>
                <Grid container spacing={3} justifyContent="space-between">
                    <Grid item>
                        Content Background Color
                    </Grid>
                    <Grid item>
                        <RenderPropertyEnable
                            editing={editing}
                            id="content-background-color-render-prop-enable"
                            propKey={BACKGROUND_COLOR_KEY}
                            experienceType={BASE_EXPERIENCE_TYPE}
                        />
                    </Grid>
                </Grid>
            </CardHeader>
            <CardContent>
                {showBackgroundColor && (
                    <Grid container spacing={3} justifyContent="space-between">
                        <Grid item xs={6}>
                            <Typography variant="subtitle2">
                                Set the tile color for the Content Preview in Gallery
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <RenderPropertySelect
                                editing={editing}
                                id="content-background-color-render-prop-select"
                                propKey={BACKGROUND_COLOR_KEY}
                                experienceType={BASE_EXPERIENCE_TYPE}
                            />
                        </Grid>
                    </Grid>
                )}
                {!showBackgroundColor && (
                    <Typography variant="subtitle2">
                        Enable to include and set the background color for the Content Preview in the Gallery.
                    </Typography>
                )}
            </CardContent>
        </Card>
    );
};

export default ContentBackgroundColor;
