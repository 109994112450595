import { Warning } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
    infoIcon: {
        marginRight: theme.spacing(1),
    },
}));

const MissingProductIcon = (): JSX.Element => {
    const { classes } = useStyles();

    return (
        <Tooltip
            arrow
            title="This product could not be found and may no longer be valid"
        >
            <Warning className={classes.infoIcon} color="error" />
        </Tooltip>
    );
};

export default MissingProductIcon;
