import {
    Dispatch, SetStateAction, useEffect, useState,
} from 'react';

import translationService from 'src/services/TranslationService';
import byId from 'src/utils/byId';
import { AppError } from 'src/lib/errors';

type UseValueTuple = [
    App.TranslationsByCulture | null | undefined,
    Dispatch<SetStateAction<App.AllowedTranslationValues>>,
    boolean,
    AppError | null,
];

export const fetchTranslations = async (key: string): Promise<App.TranslationsByCulture | undefined> => {
    const translations = await translationService.getTranslationValues(key);

    if (!translations) {
        return undefined;
    }
    const translationsById = byId<Services.Translations.TranslationByCulture>(translations, 'culture');

    translationsById.allIds = translationsById.allIds.sort();

    return translationsById;
};

const useTranslationValues = (
    keyOrValues: App.AllowedTranslationKey | App.AllowedTranslationValues,
): UseValueTuple => {
    const [values, setValues] = useState<App.AllowedTranslationValues>(null);
    const [error, setError] = useState<AppError | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        // Don't try to update options if component is unmounted
        let active = true;

        const fetchData = async (key: string): Promise<void> => {
            try {
                setLoading(true);
                const translationsById = await fetchTranslations(key);

                if (active) {
                    setValues(translationsById);
                }
            } catch (e: any) {
                setError(e);
            } finally {
                setLoading(false);
            }
        };

        if (!keyOrValues) {
            setValues(null);
        } else if (typeof keyOrValues === 'string') {
            fetchData(keyOrValues);
        } else {
            setValues(keyOrValues);
        }

        return (): void => {
            active = false;
        };
    }, [keyOrValues]);

    return [values, setValues, loading, error];
};

export default useTranslationValues;
