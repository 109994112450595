import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import AddTranslations from 'src/components/ManageTranslations/AddTranslations';
import Header from 'src/components/common/Header';
import TranslationsTableRow from 'src/components/ManageTranslations/TranslationsTableRow';
import ZeroState from 'src/components/common/ZeroState';
import Card, { CardContent } from 'src/components/common/Card';

interface PropTypes {
    editing: boolean;
    onChange: (culture: string, value: string) => void;
    onDelete: (culture: string) => void;
    translations: Services.Translations.V2.Translations;
}

const useStyles = makeStyles()({
    card: {
        paddingTop: 0,
    },
    noUnderline: {
        borderBottom: 'none',
    },
});

const ManageTranslationsTable = (props: PropTypes): JSX.Element => {
    const {
        editing,
        onChange,
        onDelete,
        translations,
    } = props;
    const { classes } = useStyles();
    const cultures = Object.keys(translations);

    const handleTranslationChange = (culture: string) => (value: string): void => {
        onChange(culture, value);
    };

    const handleDelete = (culture: string) => (): void => {
        onDelete(culture);
    };

    return (
        <Card className={classes.card}>
            <CardContent>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">
                                <Header>Culture</Header>
                            </TableCell>
                            <TableCell align="center">
                                <Header>Translation</Header>
                            </TableCell>
                            <TableCell align="right" />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(!editing && !cultures.length) && (
                            <TableRow>
                                <TableCell className={classes.noUnderline} colSpan={3}>
                                    <ZeroState text="Click Edit to add translations" />
                                </TableCell>
                            </TableRow>
                        )}
                        {!!cultures.length && cultures.map((culture) => (
                            <TranslationsTableRow
                                culture={culture}
                                editing={editing}
                                key={`translation-row-${culture}`}
                                value={translations[culture]}
                                onChange={handleTranslationChange(culture)}
                                onDelete={handleDelete(culture)}
                            />
                        ))}
                        {editing && (<AddTranslations />)}
                    </TableBody>
                </Table>
            </CardContent>
        </Card>
    );
};

export default ManageTranslationsTable;
