import { memo } from 'react';
import { Link } from 'react-router-dom';
import {
    Grid,
    Chip,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

import { MANAGE_GALLERIES } from 'src/utils/routes';

export interface PropTypes {
    galleryIds: number[];
}

const useStyles = makeStyles()((theme: Theme) => ({
    chip: {
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
            cursor: 'pointer',
        },
    },
    link: {
        textDecoration: 'none',
    },
}));

const LinkedGalleryIdList = (props: PropTypes): JSX.Element => {
    const { galleryIds } = props;
    const { classes } = useStyles();

    return (
        <>
            {galleryIds.map((id) => (
                <Grid item key={id}>
                    <Link
                        className={classes.link}
                        to={MANAGE_GALLERIES.replace(':galleryId', id.toString())}
                    >
                        <Chip className={classes.chip} label={`Gallery ${id}`} />
                    </Link>
                </Grid>
            ))}
        </>
    );
};

export default memo(LinkedGalleryIdList);
