import scenesReducer from 'src/store/searchedScenes/scenes';
import { SET_SEARCH_RESULTS } from 'src/store/searchedScenes/constants';

function reduceSearchResults(
    state: Services.Scenes.V2.SearchState,
    newSearchState: Services.Scenes.V2.SearchState,
): Services.Scenes.V2.SearchState {
    return {
        ...newSearchState,
        scenes: [...new Set([...state.scenes || [], ...newSearchState.scenes || []])],
    };
}

export const setSearchResults = (searchState: Services.Scenes.V2.SearchState): Actions.SetSearchResultsAction => ({
    type: SET_SEARCH_RESULTS,
    payload: {
        searchState,
    },
});

export const reducer = (state: Services.Scenes.V2.SearchState, action: Actions.Action): Services.Scenes.V2.SearchState => {
    switch (action.type) {
        case (SET_SEARCH_RESULTS):
            return reduceSearchResults(state, (action as Actions.SetSearchResultsAction).payload.searchState);
        default:
            return {
                ...state,
                scenes: scenesReducer(state.scenes, action),
            };
    }
};
