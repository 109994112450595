import {
    ChangeEvent, useEffect, useState, MouseEvent,
} from 'react';
import { CardProps } from '@mui/material/Card';
import { Cancel, Done } from '@mui/icons-material';
import {
    Card,
    CardContent,
    CardHeader,
    IconButton,
    Grid,
    TextField,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

import imageAspectRatioUpdate from 'src/components/ManageScene/imageAspectRatioUpdate';
import isValidUrl from 'src/components/ManageScene/isValidUrl';
import SceneUriTextField from 'src/components/ManageScene/SceneUriTextField';
import ScenePreview from 'src/components/common/ScenePreview';
import { SCENE_TYPES } from 'src/constants';

interface PropTypes extends CardProps {
    onDelete: () => void;
    onDone: (scene: App.SceneType) => void;
    galleryName: string | null;
    productOptions: Services.Scenes.ProductOption[];
}

const useStyles = makeStyles()((theme: Theme) => ({
    media: {
        height: theme.spacing(20),
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
        objectFit: 'contain',
    },
}));

const NewSceneTypeCard = (props: PropTypes): JSX.Element => {
    const {
        onDelete,
        onDone,
        galleryName,
        productOptions,
        ...rest
    } = props;
    const { classes } = useStyles();
    const [aspectRatio, setAspectRatio] = useState<number | null>(null);
    const [sceneType, setSceneType] = useState<string>(SCENE_TYPES.GALLERY);
    const [sceneUri, setSceneUri] = useState<string>('');

    useEffect(() => {
        if (sceneUri && isValidUrl(sceneUri)) {
            imageAspectRatioUpdate(encodeURIComponent(sceneUri)).then(setAspectRatio);
        } else {
            setAspectRatio(null);
        }
    }, [sceneUri]);

    const handleOnCancel = (event: MouseEvent<{}>): void => {
        event.preventDefault();

        onDelete();
    };

    const handleOnDone = (event: MouseEvent<{}>): void => {
        event.preventDefault();

        onDone({
            type: sceneType,
            uri: sceneUri,
        });

        onDelete();
    };

    const onSceneTypeChange = (event: ChangeEvent<HTMLInputElement>): void => {
        event.preventDefault();

        setSceneType(event.currentTarget.value);
    };

    const onSceneUriChange = (event: ChangeEvent<HTMLInputElement>): void => {
        event.preventDefault();

        setSceneUri(event.currentTarget.value);
    };

    return (
        <Card {...rest}>
            <CardHeader
                action={(
                    <>
                        <IconButton onClick={handleOnCancel}>
                            <Cancel />
                        </IconButton>
                        <IconButton color="primary" onClick={handleOnDone}>
                            <Done />
                        </IconButton>
                    </>
                )}
                title={`Scene Type: ${sceneType}`}
                subheader={`Aspect Ratio: ${aspectRatio || ''}`}
            />
            {(sceneUri && isValidUrl(sceneUri)) && (
                <ScenePreview
                    galleryName={galleryName}
                    sceneUri={sceneUri}
                    productOptions={productOptions}
                    className={classes.media}
                />
            )}
            <CardContent>
                <Grid
                    container
                    direction="column"
                    spacing={2}
                    wrap="nowrap"
                >
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            multiline
                            disabled
                            id="scene-type-input"
                            label="Scene Type"
                            value={sceneType}
                            variant="outlined"
                            onChange={onSceneTypeChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <SceneUriTextField
                            fullWidth
                            multiline
                            required
                            id="add-new-scene-uri-input"
                            label="Scene URI"
                            value={sceneUri}
                            variant="outlined"
                            onChange={onSceneUriChange}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default NewSceneTypeCard;
