import { ButtonProps } from '@mui/material/Button';

import LinkButton from 'src/components/common/inputs/LinkButton';

interface PropTypes extends ButtonProps {
    title: string;
    to: string;
}

const CreateActions = (props: PropTypes): JSX.Element => {
    const { title, to, ...rest } = props;

    return (
        <LinkButton
            to={to}
            variant="contained"
            color="primary"
            type="submit"
            {...rest}
        >
            {title}
        </LinkButton>
    );
};

export default CreateActions;
