import { createTheme } from '@mui/material/styles';

import base from 'src/lib/theme/base';

export default createTheme({
    ...base,
    palette: {
        error: {
            light: '#f30909',
            main: '#e00808',
            dark: '#a10524',
        },
        primary: {
            light: '#2ba8e0',
            main: '#0099e0',
            dark: '#006196',
        },
        secondary: {
            light: '#e6e6e6',
            main: '#c8cbcc',
            dark: '#919699',
            contrastText: '#00111a',
        },
    },
});
