import { ArrowBack, ArrowForward } from '@mui/icons-material';
import {
    Grid,
    IconButton,
    Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

interface PropTypes {
    page: number;
    pageSize: number;
    total: number;
    setPage: (page: number) => void;
}

const useStyles = makeStyles()({
    leftArrow: {
        textAlign: 'right',
    },
    text: {
        textAlign: 'center',
    },
});

const Paging = (props: PropTypes): JSX.Element => {
    const {
        page,
        pageSize,
        setPage,
        total,
    } = props;
    const { classes } = useStyles();

    return (
        <Grid container alignItems="center" justifyContent="center">
            <Grid item className={classes.leftArrow} xs={1}>
                <IconButton
                    disabled={page <= 0}
                    onClick={(): void => setPage(page - 1)}
                >
                    <ArrowBack />
                </IconButton>
            </Grid>
            <Grid item className={classes.text} xs={2}>
                {total > 0
                    ? (
                        <Typography>
                            {`${(page * pageSize) + 1} - ${Math.min((page + 1) * pageSize, total)}  of  ${total}`}
                        </Typography>
                    )
                    : (
                        <Typography>
                            0 - 0 of 0
                        </Typography>
                    )}
            </Grid>
            <Grid item xs={1}>
                <IconButton
                    disabled={(page + 1) >= total / pageSize}
                    onClick={(): void => setPage(page + 1)}
                >
                    <ArrowForward />
                </IconButton>
            </Grid>
        </Grid>
    );
};

export default Paging;
