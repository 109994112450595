import galleryNotificationChannelService from 'src/services/GalleryNotificationChannelService';

const compareNotificationChannels = (
    a: Services.NotificationChannels.NotificationChannel,
    b: Services.NotificationChannels.NotificationChannel,
): boolean => {
    if (a.id === b.id) {
        return true;
    }
    return false;
};

const saveGalleryNotificationChannels = (bearerToken: string) => async (
    galleryNotificationChannelData: App.GalleryNotificationChannelData,
): Promise<void> => {
    const { galleryId, galleryNotificationChannels } = galleryNotificationChannelData;
    const existingGalleryNotificationChannels = await galleryNotificationChannelService
        .getPagableGalleryNotificationChannels({ galleryId });

    const channelsToAdd = galleryNotificationChannels.filter((notificationChannel) => {
        const x = existingGalleryNotificationChannels
            .filter((existingChannel) => compareNotificationChannels(notificationChannel, existingChannel));

        return !!(x.length < 1);
    });

    const channelsToRemove = existingGalleryNotificationChannels.filter((existingChannel) => {
        const x = galleryNotificationChannels
            .filter((notificationChannel) => compareNotificationChannels(existingChannel, notificationChannel));

        return !!(x.length < 1);
    });

    const addAllTask = galleryNotificationChannelService.addAllGalleryNotificationChannels(
        galleryId,
        channelsToAdd,
        bearerToken,
    );
    const removeAllTask = galleryNotificationChannelService.deleteAllGalleryNotificationChannels(
        galleryId,
        channelsToRemove,
        bearerToken,
    );

    await Promise.all([
        ...await addAllTask,
        ...await removeAllTask,
    ]);
};

export default saveGalleryNotificationChannels;
