import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { Grid } from '@mui/material';

import { NOTIFICATION_CHANNEL_DEFAULT_PAGE_SIZE } from 'src/constants';
import useNotificationChannelSearch from 'src/hooks/useNotificationChannelSearch';
import Splash from 'src/components/common/Splash';
import Card, { CardContent } from 'src/components/common/Card';
import Description from 'src/components/common/Description';
import SearchForm from 'src/components/common/NotificationChannelSearch/SearchForm';
import NotificationChannelTable from 'src/components/common/NotificationChannelSearch/NotificationChannelTable';
import Paging from 'src/components/common/NotificationChannelSearch/Paging';

interface PropTypes {
    handleNotificationChannelChange?: (key: string) => () => void;
    pageSize?: number;
}

const NotificationChannelSearch = (props: PropTypes): JSX.Element => {
    const {
        handleNotificationChannelChange,
        pageSize = NOTIFICATION_CHANNEL_DEFAULT_PAGE_SIZE,
    } = props;

    const {
        searchedNotificationChannels,
        labelSearch,
        setLabelSearch,
        channelNameSearch,
        setChannelNameSearch,
        defaultForSearch,
        setDefaultForSearch,
        page,
        setPage,
        notificationChannelsLoading,
        notificationChannelsError,
    } = useNotificationChannelSearch();

    if (notificationChannelsError) {
        throw notificationChannelsError;
    }

    const handleFilterChange = (
        setState: Dispatch<SetStateAction<string>>,
    ) => (event: ChangeEvent<{ value: unknown }>): void => {
        setState(event.target.value as string);
        setPage(0);
    };

    const handleClear = (): void => {
        setLabelSearch('');
        setChannelNameSearch('');
        setDefaultForSearch('');
    };

    return (
        <>
            {notificationChannelsLoading && (
                // TODO better loading state
                <Splash />
            )}
            {!notificationChannelsLoading && (
                <Grid container direction="column" spacing={3}>

                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <Description>
                                    Notification Channels for Sending Validation Notifications
                                </Description>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <SearchForm
                            filterByLabel={handleFilterChange(setLabelSearch)}
                            filterByChannelName={handleFilterChange(setChannelNameSearch)}
                            filterByDefaultFor={handleFilterChange(setDefaultForSearch)}
                            labelSearch={labelSearch}
                            channelNameSearch={channelNameSearch}
                            defaultForSearch={defaultForSearch}
                            clearAll={handleClear}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <NotificationChannelTable
                                    handleNotificationChannelChange={handleNotificationChannelChange}
                                    page={page}
                                    pageSize={pageSize}
                                    notificationChannels={searchedNotificationChannels}
                                />
                                <Paging
                                    page={page}
                                    pageSize={pageSize}
                                    setPage={setPage}
                                    searchedNotificationChannels={searchedNotificationChannels}
                                />
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export default NotificationChannelSearch;
