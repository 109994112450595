import { Tooltip, TooltipProps } from '@mui/material';

import SaveButton, { SaveButtonPropTypes } from 'src/components/common/inputs/SaveButton';

export interface DisabledSaveButtonPropTypes extends SaveButtonPropTypes {
    tooltipProps: Omit<TooltipProps, 'children'>;
}

const DisabledSaveButton = (props: DisabledSaveButtonPropTypes): JSX.Element => {
    const { tooltipProps, ...rest } = props;

    return (
        <Tooltip
            placement="left"
            {...tooltipProps}
        >
            <span>
                <SaveButton disabled {...rest} />
            </span>
        </Tooltip>
    );
};

export default DisabledSaveButton;
