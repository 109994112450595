import { useContext, forwardRef } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { TableProps, CircularProgress } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import Header from 'src/components/common/Header';
import NoSearchResults from 'src/components/common/NoSearchResults';
import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    CardRow,
    CardCell,
} from 'src/components/common/CardTable';
import { MANAGE_GALLERIES } from 'src/utils/routes';
import { IdentityContext } from 'src/contexts/IdentityContext';
import { Lock } from '@mui/icons-material';
import { ContainsSecurityBoundary } from 'src/utils/securityBoundary';

interface PropTypes extends Omit<TableProps, 'onSelect' | 'results'> {
    hasMore: boolean;
    onPage: (nextPage: number) => void;
    results: Services.Galleries.Gallery[];
    scrollParent: () => HTMLElement | null;
}

const useStyles = makeStyles()({
    wrappable: {
        wordBreak: 'break-all',
    },
});

const ResultsList = (props: PropTypes): JSX.Element => {
    const {
        hasMore,
        onPage,
        results,
        scrollParent,
        ...rest
    } = props;
    const { classes } = useStyles();
    const { securityBoundaries } = useContext(IdentityContext);

    return (
        <Table stickyHeader {...rest}>
            <TableHead>
                <TableRow>
                    { /* Blank row for the "uneditable" Block icon */ }
                    <TableCell align="center" />
                    <TableCell align="center"><Header>ID</Header></TableCell>
                    <TableCell align="center"><Header>Product Key</Header></TableCell>
                    <TableCell align="center"><Header>MPV</Header></TableCell>
                    <TableCell align="center"><Header>Security Boundary</Header></TableCell>
                    <TableCell align="center"><Header>H1 Title Key</Header></TableCell>
                </TableRow>
            </TableHead>
            <InfiniteScroll
                // @ts-ignore
                // eslint-disable-next-line react/no-unstable-nested-components
                element={forwardRef<HTMLTableSectionElement, TableBodyProps>(
                    ({ children, ...other }, ref): JSX.Element => (
                        <TableBody {...other} ref={ref}>
                            {children}
                        </TableBody>
                    ),
                )}
                hasMore={hasMore}
                initialLoad={false}
                loader={(
                    <TableRow key="loader">
                        <TableCell align="center" colSpan={6}>
                            <CircularProgress color="primary" />
                        </TableCell>
                    </TableRow>
                )}
                loadMore={onPage}
                pageStart={0}
                threshold={1}
                useWindow={false}
                getScrollParent={scrollParent}
            >
                {(results && !results.length) && (
                    <TableRow key="no-results">
                        <TableCell colSpan={7}>
                            <NoSearchResults />
                        </TableCell>
                    </TableRow>
                )}
                {!!results.length && results.map((gallery): JSX.Element => (
                    <CardRow hover key={gallery.id} to={MANAGE_GALLERIES.replace(':galleryId', gallery.id.toString())}>
                        <CardCell align="center">
                            {!ContainsSecurityBoundary(securityBoundaries, gallery.securityBoundary)
                                ? <Lock />
                                : null}
                        </CardCell>
                        <CardCell align="center">
                            {gallery.id}
                        </CardCell>
                        <CardCell align="center">
                            {gallery.productKey}
                        </CardCell>
                        <CardCell align="center" className={classes.wrappable}>
                            {gallery.mpv}
                        </CardCell>
                        <CardCell align="center" className={classes.wrappable}>
                            {gallery.securityBoundary}
                        </CardCell>
                        <CardCell align="center" className={classes.wrappable}>
                            {gallery.h1Title}
                        </CardCell>
                    </CardRow>
                ))}
            </InfiniteScroll>
        </Table>
    );
};

export default ResultsList;
