import { useRef, useState } from 'react';
import { Visibility, ArrowDropDown } from '@mui/icons-material';
import {
    Button,
    ButtonGroup,
    ClickAwayListener,
    Grid,
    Grow,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Tooltip,
} from '@mui/material';

import config from 'src/lib/config';
import capitalize from 'src/utils/capitalize';
import generateUrl from 'src/utils/urlGenerator';

interface PropTypes {
    gallery: Services.Galleries.Gallery;
}

function getPreviewUrl(culture: string, name?: string): string | null {
    if (!name) {
        return null;
    }

    return `${culture}/g/${name}?noCache=true&debug=true`;
}

const PreviewGalleryButton = (props: PropTypes): JSX.Element => {
    const { gallery: { galleryNames } } = props;
    const { defaultCulture, galleryUrl, DEPLOYMENT } = config;
    const defaultGalleryName = galleryNames.find((galleryName) => galleryName.culture === defaultCulture);
    const [baseUrl, setBaseUrl] = useState<string>(galleryUrl);
    const anchorRef = useRef<HTMLDivElement>(null);
    const [open, setOpen] = useState<boolean>(false);
    const [
        selectedGalleryName,
        setSelectedGalleryName,
    ] = useState<Services.Galleries.GalleryUrl | undefined>(defaultGalleryName);
    const [
        previewUrl,
        setPreviewUrl,
    ] = useState<string | null>(getPreviewUrl(defaultCulture, defaultGalleryName?.name));

    const handleMenuItemClick = (
        event: React.MouseEvent<HTMLLIElement, MouseEvent>,
        culture: string,
        name: string,
    ): void => {
        event.preventDefault();
        const newGalleryName = galleryNames.find((galleryName) => (
            galleryName.culture === culture
            && galleryName.name === name
        ));

        setBaseUrl(generateUrl(culture, DEPLOYMENT));

        if (newGalleryName) {
            setSelectedGalleryName(newGalleryName);
            setPreviewUrl(getPreviewUrl(newGalleryName.culture, newGalleryName.name));
            setOpen(false);
        }
    };

    const handleToggle = (): void => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: MouseEvent | TouchEvent): void => {
        event.preventDefault();

        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setOpen(false);
    };

    return (
        <Tooltip placement="left" title={`Preview the Gallery in the ${capitalize(DEPLOYMENT)} Environment`}>
            <Grid container direction="column" alignItems="center">
                <Grid item xs={12}>
                    <ButtonGroup
                        variant="contained"
                        color="primary"
                        ref={anchorRef}
                        aria-label="split button"
                    >
                        <Button
                            color="primary"
                            component="a"
                            disabled={!previewUrl}
                            href={`${baseUrl}/${previewUrl}`}
                            target="_blank"
                            startIcon={(<Visibility />)}
                        >
                            {selectedGalleryName ? `Preview ${selectedGalleryName.culture}` : 'Please select a culture'}
                        </Button>
                        <Button
                            color="primary"
                            size="small"
                            aria-controls={open ? 'split-button-menu' : undefined}
                            aria-expanded={open ? 'true' : undefined}
                            aria-label="select a culture"
                            aria-haspopup="menu"
                            onClick={handleToggle}
                        >
                            <ArrowDropDown />
                        </Button>
                    </ButtonGroup>
                    <Popper
                        transition
                        disablePortal
                        open={open}
                        anchorEl={anchorRef.current}
                        role={undefined}
                    >
                        {({ TransitionProps, placement }): JSX.Element => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin: (placement === 'bottom') ? 'center top' : 'center bottom',
                                }}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList id="split-button-menu">
                                            {galleryNames.map(({ culture, name }) => (
                                                <MenuItem
                                                    key={`${culture}-${name}`}
                                                    selected={(
                                                        culture === selectedGalleryName?.culture
                                                        && name === selectedGalleryName?.name
                                                    )}
                                                    onClick={(event): void => handleMenuItemClick(event, culture, name)}
                                                >
                                                    {`${culture} - ${name}`}
                                                </MenuItem>
                                            ))}
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </Grid>
            </Grid>
        </Tooltip>
    );
};

export default PreviewGalleryButton;
