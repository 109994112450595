import { Component, ErrorInfo, ReactNode } from 'react';

import ErrorPage from 'src/pages/Error';
import { getLogger } from 'src/utils/logger';
import { AppError } from 'src/lib/errors';

interface PropTypes {
    children: ReactNode;
}

interface State {
    error: AppError | null;
}

/**
 * Our app relies on handling errors at a central location to ensure consistent
 * reporting and consumption of any error-based states.
 *
 * Any error-based state, such as a non-recoverable network error, validation
 * error, etc., should be thrown from a Component to be caught and handled
 * in the ErrorBoundary.
 */
export default class ErrorBoundary extends Component<PropTypes, State> {
    state = {
        error: null,
    };

    // TODO We should be using getDerivedStateFromError, but Enzyme currently
    // does not support this method.
    // When https://github.com/airbnb/enzyme/issues/1553 is closed, re-enable
    // static getDerivedStateFromError(error: Error | AppError) {
    //     return { error };
    // }

    componentDidCatch(thrownError: Error, info: ErrorInfo): void {
        const error = new AppError(thrownError);

        getLogger().error(error, info);

        // See comment @ getDerivedStateFromError
        this.setState({ error });
    }

    render(): ReactNode {
        const { children } = this.props;
        const { error } = this.state;

        // eslint-disable-next-line
        return error ? <ErrorPage error={error!} /> : children;
    }
}
