import { Button, ButtonProps } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { Lock } from '@mui/icons-material';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SaveButtonPropTypes extends ButtonProps {}

const SaveButton = (props: SaveButtonPropTypes): JSX.Element => {
    const {
        onClick,
        className,
        disabled,
        ...rest
    } = props;

    return (
        <Button
            {...rest}
            variant="contained"
            color="primary"
            type="submit"
            startIcon={disabled ? <Lock /> : <SaveIcon />}
            className={className}
            disabled={disabled}
            onClick={onClick}
        >
            Save
        </Button>
    );
};

export default SaveButton;
