export const UPDATE_GALLERY = 'UPDATE_GALLERY';

export const updateGallery = (gallery: Services.Galleries.Gallery): Actions.GalleryAction => ({
    type: UPDATE_GALLERY,
    payload: {
        gallery,
    },
});

export const galleryReducer = (
    gallery: Services.Galleries.Gallery,
    action: Actions.Action,
): Services.Galleries.Gallery => {
    switch (action.type) {
        case (UPDATE_GALLERY):
            return (action as Actions.GalleryAction).payload.gallery;
        default:
            return gallery;
    }
};
