import { MouseEvent } from 'react';
import classnames from 'classnames';

import TableCell, { TableCellProps } from '@mui/material/TableCell';
import { useHistory } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

interface PropTypes extends TableCellProps {
    to?: string;
}

const useStyles = makeStyles()({
    card: {
        border: 'none',
    },
    clickable: {
        cursor: 'pointer',
    },
    unstyledLink: {
        textDecoration: 'none',
        color: 'initial',
    },
});

const CardCell = (props: PropTypes): JSX.Element => {
    const {
        children,
        className = '',
        to,
        ...rest
    } = props;
    const { classes } = useStyles();
    const history = useHistory();

    const navigate = (event: MouseEvent<{}>): void => {
        event.preventDefault();
        if (to != null) {
            history.push(to);
        }
    };

    return (
        <TableCell
            className={classnames(classes.card, className, {
                [classes.clickable]: (to != null),
            })}
            onClick={navigate}
            {...rest}
        >
            {children}
        </TableCell>
    );
};

export default CardCell;
