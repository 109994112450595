import { ChangeEvent, useContext, useState } from 'react';
import { Button, SelectChangeEvent } from '@mui/material';
import { Redirect } from 'react-router-dom';
import Grid from '@mui/material/Unstable_Grid2';

import config from 'src/lib/config';
import CultureSelect from 'src/components/common/inputs/CultureSelect';
import galleriesService from 'src/services/GalleriesService';
import Select from 'src/components/common/inputs/Select';
import TextField from 'src/components/common/inputs/TextField';
import Card, { CardActionBar, CardContent, CardHeader } from 'src/components/common/Card';
import { EditContext } from 'src/contexts/EditContext';
import { IdentityContext } from 'src/contexts/IdentityContext';
import { AppError } from 'src/lib/errors';
import ErrorMessageBar from 'src/components/common/ErrorMessageBar';
import { getHintForResponseCode } from 'src/lib/errors/utils';
import ZeroState from 'src/components/common/ZeroState';
import renderPropertiesService from 'src/services/RenderPropertiesService';
import { GALLERY_STATUS, BUILD_TLP } from 'src/constants';

const defaultFullBleedUploadRenderProperty = {
    key: 'FullBleedUploadPopExperience',
    value: 'v2',
    experienceType: 'Base',
};

export type GalleryInitialization = {
    securityBoundary: string;
    culture: string;
    name?: string;
}

function createGallery(initializedGallery: GalleryInitialization): Services.Galleries.CreateGallery {
    return {
        securityBoundary: initializedGallery.securityBoundary,
        galleryNames: [
            {
                culture: initializedGallery.culture,
                name: initializedGallery.name || '',
                isDefault: true,
                buildTlp: BUILD_TLP.OnlyL0,
            },
        ],
        status: GALLERY_STATUS.INACTIVE,
    };
}

const CreateGallery = (): JSX.Element => {
    const { setEditing } = useContext(EditContext);
    const { accessToken, securityBoundaries } = useContext(IdentityContext);
    // We don't want galleries created with super user boundary
    const validSecurityBoundaries = securityBoundaries ? securityBoundaries.filter((sb) => sb.identifier !== '*') : [];
    const [initializedGallery, setInitializedGallery] = useState<GalleryInitialization>({
        culture: config.defaultCulture,
        securityBoundary: validSecurityBoundaries.length ? validSecurityBoundaries[0].identifier : '',
    });
    const [gallery, setGallery] = useState<Services.Galleries.Gallery | undefined>(undefined);
    const [errorData, setErrorData] = useState<AppError | null>(null);

    const handleChange = (
        property: string,
    ) => (event: SelectChangeEvent<unknown> | ChangeEvent<HTMLTextAreaElement | { value: unknown }>): void => {
        event.preventDefault();

        setInitializedGallery({
            ...initializedGallery,
            [property]: event.target.value,
        });
    };

    const handleCultureChange = (culture: string): void => {
        setInitializedGallery({
            ...initializedGallery,
            culture,
        });
    };

    const handleCreateGallery = async (): Promise<void> => {
        if (initializedGallery.name && accessToken) {
            try {
                const postGallery = createGallery(initializedGallery);
                const newGallery = await galleriesService.postGallery(postGallery, accessToken);

                await renderPropertiesService.putRenderProperty(
                    defaultFullBleedUploadRenderProperty,
                    newGallery.id,
                    accessToken,
                );

                setGallery(newGallery);
                setEditing(true);
            } catch (e: any) {
                e.title = 'Failed to create gallery';
                e.hint = getHintForResponseCode(e.status);

                setErrorData(e);
            }
        }
    };

    const closeErrorMessageBar = (): void => {
        setErrorData(null);
    };

    if (!validSecurityBoundaries.length) {
        return (<ZeroState text="You do not have access to create galleries, please go to #help-gallery for assistance" />);
    }

    if (gallery) {
        return <Redirect to={`/gallery/${gallery.id}/details`} />;
    }

    return (
        <Card>
            <CardHeader>
                Initialize Gallery Info
            </CardHeader>
            <CardContent>
                <Grid container spacing={3} direction="column">
                    <Grid container justifyContent="space-around" spacing={3}>
                        <Grid xs={6}>
                            <CultureSelect
                                required
                                currentCulture={initializedGallery.culture}
                                id="culture-select"
                                label="Select initial culture"
                                setCurrentCulture={handleCultureChange}
                            />
                        </Grid>
                        <Grid xs={6}>
                            <TextField
                                fullWidth
                                editing
                                required
                                id="name-input"
                                label="Gallery name/URL"
                                value={initializedGallery.name}
                                onChange={handleChange('name')}
                            />
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="space-around" spacing={3}>
                        <Grid xs={6}>
                            {validSecurityBoundaries.length === 1 && (
                                <TextField
                                    fullWidth
                                    editing={false}
                                    label="Security Boundary"
                                    value={initializedGallery.securityBoundary}
                                />
                            )}
                            {validSecurityBoundaries.length > 1 && (
                                <Select
                                    editing
                                    required
                                    id="security-boundary-select"
                                    label="Select a security boundary"
                                    value={initializedGallery.securityBoundary}
                                    onChange={handleChange('securityBoundary')}
                                >
                                    {validSecurityBoundaries.map((sb) => (
                                        <option value={sb.identifier} key={sb.identifier}>
                                            {sb.identifier}
                                        </option>
                                    ))}
                                </Select>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
            <CardActionBar>
                <Button
                    disabled={!initializedGallery.culture
                        || !initializedGallery.name
                        || !initializedGallery.securityBoundary}
                    variant="contained"
                    color="primary"
                    type="submit"
                    onClick={handleCreateGallery}
                >
                    Create
                </Button>
            </CardActionBar>
            {errorData && (
                <ErrorMessageBar
                    appError={errorData}
                    onClose={closeErrorMessageBar}
                />
            )}
        </Card>
    );
};

export default CreateGallery;
