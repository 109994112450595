import {
    ChangeEvent, FC, FormEvent, useEffect, useState,
} from 'react';
import qs from 'qs';
import {
    Button,
    Grid,
    PaperProps,
} from '@mui/material';
import { Search } from '@mui/icons-material';

import Description from 'src/components/common/Description';
import useProductOptionsValuePairs from 'src/hooks/useProductOptionsValuePairs';
import useSavedSearch from 'src/hooks/useSavedSearch';
import AsyncAutocomplete, { AsyncAutocompleteProps } from 'src/components/common/inputs/AsyncAutocomplete';
import Card, {
    CardContent,
    CardActionBar,
    CardHeader,
} from 'src/components/common/Card';
import ProductKeyAutocomplete from 'src/components/common/inputs/ProductKeyAutocomplete';
import { AppError } from 'src/lib/errors';

interface PropTypes extends Omit<PaperProps, 'onSubmit'> {
    onSubmit: (search: Services.Scenes.SearchTerm) => void;
}

const INITIAL_STATE = {
    product: null,
    productOptions: [] as Services.Product.ProductOptionValuePair[],
};

function getSavedSelection(search: string, initialState: Services.Scenes.SearchTerm): Services.Scenes.SearchTerm {
    const query = qs.parse(search.replace('?', ''));
    const updatedState = { ...initialState };

    updatedState.productOptions = (
        query.productOptions || initialState.productOptions
    ) as Services.Product.ProductOptionValuePair[];
    updatedState.product = (query.product || initialState.product) as Services.Product.BasicProduct;

    return updatedState;
}

const ResultsList = (props: PropTypes): JSX.Element => {
    const {
        onSubmit,
        ...rest
    } = props;
    const initialState = useSavedSearch(INITIAL_STATE, getSavedSelection);
    const [selection, updateSelection] = useState<Services.Scenes.SearchTerm>(initialState);
    const [pairs, pairsLoading, pairsError] = useProductOptionsValuePairs(selection.product?.productKey);

    // If a product exists, trigger the provided search callback
    // Right now we don't allow searches without a selected product
    const submitSearch = (): void => {
        const { product } = selection;

        if (product) {
            onSubmit(selection);
        }
    };

    // Trigger a search on component mount (submitSearch).
    // This has the effect of showing the "saved" search that was pulled from the URL query
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => submitSearch(), []);

    if (pairsError) {
        throw new AppError(pairsError);
    }

    const handleProductKeyChange = (event: ChangeEvent<{}>, value: unknown): void => {
        event.preventDefault();

        updateSelection({
            ...selection,
            product: !value ? null : (value as Services.Product.BasicProduct),
            productOptions: [],
        });
    };

    const handleProductOptionsValuePairsChange = (event: ChangeEvent<{}>, value: unknown): void => {
        event.preventDefault();

        updateSelection({
            ...selection,
            productOptions: !value ? [] : (value as Services.Product.ProductOptionValuePair[]),
        });
    };

    const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        submitSearch();
    };

    const ProductOptionValuesPairAutocomplete = AsyncAutocomplete as (
        FC<AsyncAutocompleteProps<Services.Product.ProductOptionValuePair, true, false, false>>
    );

    return (
        <form onSubmit={handleSubmit}>
            <Card {...rest}>
                <CardHeader>
                    <Description>
                        Scenes are the way customers will preview designs in gallery. A scene is selected based on
                        which has the most product options match the design with no contradicting options. If no scene
                        is found, a standard square preview is used.
                    </Description>
                </CardHeader>
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <ProductKeyAutocomplete
                                editing
                                productKey={selection.product?.productKey}
                                handleProductChange={handleProductKeyChange}
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <ProductOptionValuesPairAutocomplete
                                multiple
                                disabled={!selection.product}
                                helperText="Further refine your search by selecting product options"
                                label="Select a Product Option"
                                loading={pairsLoading}
                                options={pairs}
                                optionKey="label"
                                size="small"
                                value={selection.productOptions || []}
                                onChange={handleProductOptionsValuePairsChange}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
                <CardActionBar>
                    <Button
                        color="primary"
                        disabled={!selection.product}
                        type="submit"
                        variant="contained"
                        startIcon={(<Search />)}
                    >
                        Search
                    </Button>
                </CardActionBar>
            </Card>
        </form>
    );
};

export default ResultsList;
