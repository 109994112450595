import classnames from 'classnames';

import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import { HTMLProps } from 'react';

const useStyles = makeStyles()((theme: Theme) => ({
    actionBar: {
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
}));

const CardActionBar = (props: HTMLProps<HTMLDivElement>): JSX.Element => {
    const {
        children,
        className = '',
        ...rest
    } = props;

    const { classes } = useStyles();

    return (
        <section className={classnames(classes.actionBar, className)} {...rest}>
            {children}
        </section>
    );
};

export default CardActionBar;
