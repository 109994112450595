import {
    Dispatch, useContext, useEffect, useReducer, useState,
} from 'react';

import translationService from 'src/services/TranslationService';
import translationGroupService from 'src/services/TranslationGroupService';
import saveTranslationGroup from 'src/lib/saveTranslationGroup';
import saveTranslations from 'src/lib/saveTranslations';
import { reducer, initializeTranslationsDataAction } from 'src/store/translationsData';
import { IdentityContext } from 'src/contexts/IdentityContext';
import { AppError } from 'src/lib/errors';

type TranslationsTuple = [
    App.ManageTranslationsContext | null,
    boolean,
    AppError | null,
];

export const fetchTranslationsData = async (
    translationGroupId: number,
    accessToken: string | undefined,
): Promise<App.TranslationsData> => {
    const foundInTask = accessToken
        ? translationGroupService.getFoundIn(translationGroupId, accessToken)
        : Promise.resolve({} as Services.Translations.V2.FoundIn);
    const translationGroupTask = translationGroupService.getTranslationGroup(translationGroupId);
    const translationsTask = translationService.getTranslations(translationGroupId);

    const [foundIn, translationGroup, translations] = await Promise.all([
        foundInTask,
        translationGroupTask,
        translationsTask,
    ]);

    return {
        foundIn,
        translationGroup,
        translations,
    };
};

const useTranslationsData = (translationGroupId: number): TranslationsTuple => {
    const [error, setError] = useState<AppError | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const { accessToken } = useContext(IdentityContext);
    const [
        translationsData,
        dispatch,
    ] = useReducer(reducer, null) as [App.TranslationsData | null, Dispatch<Actions.Action>];

    useEffect(() => {
        const runEffect = async (): Promise<void> => {
            try {
                setLoading(true);
                const data = await fetchTranslationsData(translationGroupId, accessToken);

                if (!data) {
                    throw Error('Translations data could not be found');
                }

                dispatch(initializeTranslationsDataAction(data));
            } catch (e: any) {
                setError(new AppError(e));
            } finally {
                setLoading(false);
            }
        };

        runEffect();
    }, [accessToken, dispatch, translationGroupId]);

    const state = (translationsData && accessToken)
        ? {
            dispatch,
            translationsData,
            saveTranslationGroup: saveTranslationGroup(accessToken),
            saveTranslations: saveTranslations(accessToken),
        }
        : null;

    return [state, loading, error];
};

export default useTranslationsData;
