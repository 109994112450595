import {
    AccountTree,
    Public,
    List,
} from '@mui/icons-material';
import { Grid } from '@mui/material';

import LinkButton from 'src/components/common/inputs/LinkButton';

interface PropTypes {
    url: string;
}

const ACTIONS = [
    { icon: <AccountTree />, name: 'Add Category Filter', href: '/taxonomy/create' },
    { icon: <Public />, name: 'Add Content Filter', href: '/attribute/create' },
    { icon: <List />, name: 'Add Product Filter', href: '/product/create' },
];

const AddFiltersButtons = (props: PropTypes): JSX.Element => {
    const { url } = props;

    return (
        <Grid container justifyContent="flex-end" spacing={2}>
            {ACTIONS.map((action) => (
                <Grid item key={action.name}>
                    <LinkButton
                        color="primary"
                        startIcon={action.icon}
                        to={`${url}${action.href}`}
                        variant="contained"
                    >
                        {action.name}
                    </LinkButton>
                </Grid>
            ))}
        </Grid>
    );
};

export default AddFiltersButtons;
