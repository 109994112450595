import { INITIALIZE_GALLERY_NOTIFICATION_CHANNEL_DATA } from 'src/store/GalleryNotificationChannelData/constants';
import { galleryNotificationChannelReducer } from './utils';

export const initializeGalleryNotificationChannels = (
    galleryNotificationChannelData: App.GalleryNotificationChannelData,
): Actions.GalleryNotificationChannelsAction => ({
    type: INITIALIZE_GALLERY_NOTIFICATION_CHANNEL_DATA,
    payload: {
        galleryNotificationChannelData,
    },
});

export const reducer = (
    galleryNotificationChannelData: App.GalleryNotificationChannelData | null,
    action: Actions.Action,
): App.GalleryNotificationChannelData | null => {
    switch (action.type) {
        case (INITIALIZE_GALLERY_NOTIFICATION_CHANNEL_DATA):
            return (action as Actions.GalleryNotificationChannelsAction).payload.galleryNotificationChannelData;
        default:
            if (!galleryNotificationChannelData) {
                return galleryNotificationChannelData;
            }
            return {
                ...galleryNotificationChannelData,
                galleryNotificationChannels: galleryNotificationChannelReducer(galleryNotificationChannelData, action),
            };
    }
};
