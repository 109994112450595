import { useRouteMatch } from 'react-router-dom';

import AppBar from 'src/components/common/AppBar';
import EditActions from 'src/components/common/AppBar/EditActions';
import Splash from 'src/components/common/Splash';
import ZeroState from 'src/components/common/ZeroState';
import Content from 'src/components/Content';
import Main from 'src/components/Main';
import ManageNotificationChannels from 'src/components/ManageNotificationChannels';
import ManageNotificationChannelController from 'src/components/ManageNotificationChannels/ManageNotificationChannelController';
import ManageNotificationChannelsContext from 'src/contexts/ManageNotificationChannelsContext';
import useNotificationChannelData from 'src/hooks/useNotificationChannelData';

function getNotificationChannelLabel(notificationChannelData: App.NotificationChannelData | null | undefined): string {
    return (`Translation Key: ${notificationChannelData ? notificationChannelData?.label : 'Loading...'}`);
}

const ManageNotificationChannelsPage = (): JSX.Element => {
    const { params: { notificationChannelId } } = useRouteMatch<{ notificationChannelId: string }>();
    const [state, loading, error] = useNotificationChannelData(parseInt(notificationChannelId, 10));

    if (error) {
        // TODO better error handling?
        throw error;
    }

    return (
        <Main
            AppBar={(
                <AppBar
                    actions={<EditActions />}
                    title={getNotificationChannelLabel(state?.notificationChannelData)}
                />
            )}
        >
            <Content>
                {loading && (<Splash />)}
                {(!loading && !state) && (
                    <ZeroState text="Could not load the translations for that translation group" />
                )}
                {(!loading && state) && (
                    <ManageNotificationChannelsContext.Provider value={state}>
                        <ManageNotificationChannelController>
                            <ManageNotificationChannels />
                        </ManageNotificationChannelController>
                    </ManageNotificationChannelsContext.Provider>
                )}
            </Content>
        </Main>
    );
};

export default ManageNotificationChannelsPage;
