import { QueryFunctionContext } from 'react-query';

import { galleryAuditLogService } from 'src/services/GalleryAuditLogService';
import { DEFAULT_LIMIT } from 'src/constants';

export const QUERY_KEY = 'audit_logs';

export const queryAuditLogs = (
    context: QueryFunctionContext<App.AuditLogs.PagedQueryKey, App.AuditLogs.PageParam>,
): Promise<Models.PageResult<Services.AuditLogs.AuditLog>> => {
    const { queryKey } = context;
    const [, search] = queryKey;

    return galleryAuditLogService.getAuditLog(
        search.galleryId,
        search.offset,
        search.limit || DEFAULT_LIMIT,
        search.accessToken,
    );
};
