import classnames from 'classnames';
import { useContext } from 'react';
import {
    Drawer as MuiDrawer,
    List,
    ListItem,
    Grid,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import {
    Translate,
    AccountCircleOutlined,
    PhotoLibrary,
    Settings,
    Help,
    Notifications,
} from '@mui/icons-material';

import config from 'src/lib/config';
import ExpandButton from 'src/components/NavDrawer/ExpandButton';
import NavDrawerContext from 'src/contexts/NavDrawerContext';
import NavLink from 'src/components/NavDrawer/NavLink';
import Title from 'src/components/NavDrawer/Title';
import * as routes from 'src/utils/routes';
import { GALLERY_CONFLUENCE_GUIDES, NAV_DRAWER_OPEN } from 'src/constants';
import { getLogger } from 'src/utils/logger';

const CLOSE_MULTIPLIER = 7;
const OPEN_MULTIPLIER = 32;

const useStyles = makeStyles()((theme: Theme) => ({
    drawer: {
        width: theme.spacing(OPEN_MULTIPLIER),
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: theme.spacing(OPEN_MULTIPLIER),
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        backgroundColor: theme.palette.primary.dark,
        boxShadow: `inset -${theme.spacing(0.25)}px 0px ${theme.spacing(0.5)}px 0px rgba(0, 0, 0, 0.2)`,
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: theme.palette.primary.dark,
        overflowX: 'hidden',
        width: theme.spacing(CLOSE_MULTIPLIER),
    },
    drawerGrid: {
        height: '100%',
        margin: 0,
        width: '100%',
    },
    title: {
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
}));

const NavDrawer = (): JSX.Element => {
    const { classes } = useStyles();
    const { expanded, setExpanded } = useContext(NavDrawerContext);

    const toggleDrawer = (): void => {
        const currentlyExpanded = !expanded;

        setExpanded(currentlyExpanded);
        try {
            localStorage.setItem(NAV_DRAWER_OPEN, currentlyExpanded ? '1' : '');
        } catch (e: any) {
            getLogger().warning(e);
        }
    };

    return (
        <MuiDrawer
            variant="permanent"
            anchor="left"
            className={classnames(classes.drawer, {
                [classes.drawerOpen]: expanded,
                [classes.drawerClose]: !expanded,
            })}
            classes={{
                paper: classnames({
                    [classes.drawerOpen]: expanded,
                    [classes.drawerClose]: !expanded,
                }),
            }}
        >
            <Grid
                container
                className={classes.drawerGrid}
                direction="column"
                justifyContent="space-between"
            >
                {config.features.ExpandNavBar && (
                    <ExpandButton
                        closeOffset={CLOSE_MULTIPLIER}
                        open={expanded}
                        openOffset={OPEN_MULTIPLIER}
                        onClick={toggleDrawer}
                    />
                )}
                <Grid item className={classes.title}>
                    <Title />
                </Grid>
                <Grid item xs>
                    <List>
                        {config.features.Galleries && (
                            <ListItem button>
                                <NavLink
                                    icon={<Settings />}
                                    text="Manage Galleries"
                                    to={routes.SEARCH_GALLERIES}
                                    tooltip="Manage Galleries"
                                />
                            </ListItem>
                        )}
                        {config.features.Translations && (
                            <ListItem button>
                                <NavLink
                                    icon={<Translate />}
                                    text="Manage Translations"
                                    to={routes.SEARCH_TRANSLATIONS}
                                    tooltip="Manage Translations"
                                />
                            </ListItem>
                        )}
                        {config.features.Scenes && (
                            <ListItem button>
                                <NavLink
                                    icon={<PhotoLibrary />}
                                    text="Manage Scenes"
                                    to={routes.SEARCH_SCENES}
                                    tooltip="Manage Scenes"
                                />
                            </ListItem>
                        )}
                        {config.features.NotificationChannels && (
                            <ListItem button>
                                <NavLink
                                    icon={<Notifications />}
                                    text="Manage Notifications"
                                    to={routes.SEARCH_NOTIFICATION_CHANNELS}
                                    tooltip="Manage Notifications"
                                />
                            </ListItem>
                        )}
                    </List>
                </Grid>
                <Grid item>
                    <List>
                        <ListItem button>
                            <NavLink
                                href={GALLERY_CONFLUENCE_GUIDES}
                                icon={<Help />}
                                target="_blank"
                                text="Guides"
                                tooltip="Access support documentation & guides"
                            />
                        </ListItem>
                        {config.features.Logout && (
                            <ListItem button>
                                <NavLink
                                    icon={<AccountCircleOutlined />}
                                    text="Logout"
                                    to={routes.LOGOUT}
                                    tooltip="Logout"
                                />
                            </ListItem>
                        )}
                    </List>
                </Grid>
            </Grid>
        </MuiDrawer>
    );
};

export default NavDrawer;
