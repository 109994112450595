import { Grid } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

import { LogoIcon } from 'src/components/common/Logo';

const useStyles = makeStyles()((theme: Theme) => ({
    logo: {
        display: 'inline',
        verticalAlign: 'middle',
    },
    logoGrid: {
        height: '100%',
        textAlign: 'center',
    },
    splash: {
        height: '100',
        paddingTop: theme.spacing(30),
    },
}));

const Splash = (): JSX.Element => {
    const { classes } = useStyles();

    return (
        <Grid container item className={classes.splash} xs={12} justifyContent="center" alignItems="center">
            <Grid item className={classes.logoGrid}>
                <LogoIcon className={classes.logo} size="xxl" />
            </Grid>
        </Grid>
    );
};

export default Splash;
