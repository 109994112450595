import { UPDATE_TRANSLATION_GROUP_PURPOSE } from 'src/store/translationsData/constants';

export const updateTranslationGroupPurpose = (
    purpose: TRANSLATION_PURPOSE,
): Actions.UpdateTranslationGroupPurposeAction => ({
    type: UPDATE_TRANSLATION_GROUP_PURPOSE,
    payload: {
        purpose,
    },
});

export const translationGroupReducer = (
    translationGroup: Services.Translations.V2.TranslationGroup,
    action: Actions.Action,
): Services.Translations.V2.TranslationGroup => {
    switch (action.type) {
        case UPDATE_TRANSLATION_GROUP_PURPOSE:
            return {
                ...translationGroup,
                purpose: (action as Actions.UpdateTranslationGroupPurposeAction).payload.purpose,
            };
        default:
            return translationGroup;
    }
};
