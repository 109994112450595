import { UPDATE_NOTIFICATION_CHANNEL } from 'src/store/notificationChannelData/constants';

export const updateNotificationChannel = (
    notificationChannel: Services.NotificationChannels.NotificationChannel,
    id: number,
): Actions.UpdateNotificationChannelAction => ({
    type: UPDATE_NOTIFICATION_CHANNEL,
    payload: {
        id,
        notificationChannel,
    },
});

export const notificationChannelReducer = (
    notificationChannel: Services.NotificationChannels.NotificationChannel,
    action: Actions.Action,
): Services.NotificationChannels.NotificationChannel => {
    switch (action.type) {
        case UPDATE_NOTIFICATION_CHANNEL:
            return (action as Actions.UpdateNotificationChannelAction).payload.notificationChannel;
        default:
            return notificationChannel;
    }
};
