import {
    Typography,
    Grid,
    Card,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

import BackButton from 'src/components/common/inputs/BackButton';

const useStyles = makeStyles()((theme: Theme) => ({
    bold: {
        fontWeight: 500,
    },
    padded: {
        padding: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
}));

export interface BackBarProps {
    to: string;
    title: string;
}

const BackBar = (props: BackBarProps): JSX.Element => {
    const { to, title } = props;
    const { classes } = useStyles();

    return (
        <Card className={classes.padded}>
            <Grid container alignItems="center">
                <Grid item xs={3}>
                    <BackButton to={to} />
                </Grid>
                <Grid item xs={6}>
                    <Typography className={classes.bold} variant="subtitle1" align="center">
                        {title}
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    {/* Blank space for grid centering */}
                </Grid>
            </Grid>
        </Card>
    );
};

export default BackBar;
