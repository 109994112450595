import {
    CircularProgress,
    IconButton,
    InputAdornment,
    Tooltip,
} from '@mui/material';
import { Warning, Edit } from '@mui/icons-material';

interface PropTypes {
    editing?: boolean;
    error?: Error | null;
    onClick: () => void;
    loading?: boolean;
}

const Adornment = (props: PropTypes): JSX.Element => {
    const {
        editing,
        error,
        loading,
        onClick,
    } = props;

    return (
        <>
            {error && (
                <Tooltip title="An error occurred while loading translations">
                    <Warning color="error" />
                </Tooltip>
            )}
            {(loading) && (
                <Tooltip title="Loading translations...">
                    <CircularProgress />
                </Tooltip>
            )}
            {(editing && !error && !loading) && (
                <InputAdornment position="end">
                    <IconButton
                        aria-label="select translation key"
                        onClick={onClick}
                    >
                        <Edit />
                    </IconButton>
                </InputAdornment>
            )}
        </>
    );
};

export default Adornment;
