import { ChangeEvent, MouseEvent } from 'react';

import {
    TextField,
    Button,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import Card, { CardContent, CardActionBar } from 'src/components/common/Card';
import PurposeSelect from 'src/components/common/inputs/PurposeSelect';
import useCompatibleBreakpoint from 'src/hooks/useCompatibleBreakpoint';
import { Clear } from '@mui/icons-material';

interface PropTypes {
    filterByKey: (event: ChangeEvent<{ value: unknown }>) => void;
    filterByValue: (event: ChangeEvent<{ value: unknown }>) => void;
    keySearch: string;
    valueSearch: string;
    translationsPurpose?: TRANSLATION_PURPOSE;
    setTranslationsPurpose: (purpose: TRANSLATION_PURPOSE) => void;
    selectablePurpose: boolean;
    clearAll: (event: MouseEvent<HTMLButtonElement>) => void;
}

const SearchForm = (props: PropTypes): JSX.Element => {
    const {
        filterByKey,
        filterByValue,
        keySearch,
        valueSearch,
        translationsPurpose,
        setTranslationsPurpose,
        selectablePurpose,
        clearAll,
    } = props;
    const splitScreenProp = { [useCompatibleBreakpoint('md')]: 4 };

    return (
        <Card>
            <CardContent>
                <Grid container alignItems="center" spacing={3}>
                    <Grid xs={12} {...splitScreenProp}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Translation Example Text"
                            placeholder="Translation Example Text"
                            helperText="Filter translations by Example Text"
                            margin="normal"
                            value={valueSearch}
                            name="value"
                            onChange={filterByValue}
                        />
                    </Grid>
                    <Grid xs={12} {...splitScreenProp}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Translation Key"
                            placeholder="Translation Key"
                            helperText="Filter translations by key"
                            margin="normal"
                            value={keySearch}
                            name="key"
                            onChange={filterByKey}
                        />
                    </Grid>
                    <Grid xs={12} {...splitScreenProp}>
                        <PurposeSelect
                            currentPurpose={translationsPurpose}
                            editing={selectablePurpose}
                            helperText="View translation values in the selected culture"
                            label="Select a Purpose"
                            onChange={setTranslationsPurpose}
                        />
                    </Grid>
                </Grid>
            </CardContent>
            <CardActionBar>
                <Button
                    variant="contained"
                    color="secondary"
                    startIcon={(<Clear />)}
                    onClick={clearAll}
                >
                    Clear All
                </Button>
            </CardActionBar>
        </Card>
    );
};

export default SearchForm;
