import AbstractService from 'src/services/AbstractService';
import config from 'src/lib/config';
import { ServiceError } from 'src/lib/errors';
import { logServiceError } from 'src/utils/logger';

/**
 * Gets and sets global filters with values
 */
class GlobalFiltersService extends AbstractService {
    constructor() {
        super(config.services.config);
    }

    public async getGlobalFilter(
        filterId: string,
    ): Promise<Services.Filters.GlobalFilter> {
        const requestUrl = `api/v2/Filters/${filterId}`;

        try {
            const response = await this.api.get<Services.Filters.GlobalFilter>(requestUrl, {
                params: {
                    requestor: config.appName,
                    noCache: true,
                },
            });

            return response.data;
        } catch (e: any) {
            const error = new ServiceError(e);

            logServiceError(error);
            throw error;
        }
    }

    /**
     * Updates global filter
     *
     * @param filter
     * @throws ServiceError
     */
    public async putGlobalFilter(filter: Services.Filters.GlobalFilter, bearerToken: string): Promise<void> {
        const requestUrl = `api/v2/Filters/${filter.id}`;

        try {
            return await this.api.put(
                requestUrl,
                filter,
                {
                    params: {
                        requestor: config.appName,
                    },
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                },
            );
        } catch (e: any) {
            const error = new ServiceError(e);

            logServiceError(error);
            throw error;
        }
    }

    /**
     * Creates global filter
     *
     * @param filter
     * @throws ServiceError
     */
    public async postGlobalFilter(filter: Services.Filters.GlobalFilter, bearerToken: string): Promise<void> {
        const requestUrl = 'api/v2/Filters';

        try {
            return await this.api.post(
                requestUrl,
                filter,
                {
                    params: {
                        requestor: config.appName,
                    },
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                },
            );
        } catch (e: any) {
            const error = new ServiceError(e);

            logServiceError(error);
            throw error;
        }
    }
}

export default new GlobalFiltersService();
