import canonicalizedPurpose from 'src/utils/canonicalizePurpose';
import Select, { SelectProps } from 'src/components/common/inputs/Select';
import { TRANSLATION_PURPOSE } from 'src/constants';
import { SelectChangeEvent } from '@mui/material';

interface PurposeSelectProps extends Omit<SelectProps, 'onChange'> {
    currentPurpose?: TRANSLATION_PURPOSE;
    editing: boolean;
    helperText?: string;
    label?: string;
    options?: TRANSLATION_PURPOSE[];
    onChange: (purpose: TRANSLATION_PURPOSE) => void;
}

const DEFAULT_OPTIONS = Object.values(TRANSLATION_PURPOSE).filter((purpose) => purpose !== TRANSLATION_PURPOSE.Unknown);

const PurposeSelect = (props: PurposeSelectProps): JSX.Element => {
    const {
        currentPurpose,
        editing = true,
        helperText,
        label,
        options = DEFAULT_OPTIONS,
        onChange,
        ...rest
    } = props;

    const canonicalizedCurrentPurpose = currentPurpose ? canonicalizedPurpose(currentPurpose) : undefined;
    const handlePurposeChange = (event: SelectChangeEvent<unknown>): void => {
        event.preventDefault();

        onChange(event.target.value as TRANSLATION_PURPOSE);
    };

    return (
        <Select
            editing={editing}
            variant="outlined"
            formMargin="normal"
            value={canonicalizedCurrentPurpose}
            label={label}
            helperText={helperText}
            onChange={handlePurposeChange}
            {...rest}
        >
            {options.map((purpose) => (
                <option value={purpose} key={purpose}>{purpose}</option>
            ))}
        </Select>
    );
};

export default PurposeSelect;
