import notificationChannelService from 'src/services/NotificationChannelService';

const saveNotificationChannel = (bearerToken: string) => async (
    notificationChannel: Services.NotificationChannels.NotificationChannel,
): Promise<void> => {
    await notificationChannelService.updateNotificationChannel(
        notificationChannel.id,
        notificationChannel,
        bearerToken,
    );
};

export default saveNotificationChannel;
