import qs from 'qs';

import AbstractService from 'src/services/AbstractService';
import config from 'src/lib/config';
import { ServiceError } from 'src/lib/errors';
import { logServiceError } from 'src/utils/logger';

class ScenesService extends AbstractService {
    constructor() {
        super(config.services.config);
    }

    public async getScene(
        id: number,
    ): Promise<Services.Scenes.SceneDefinition> {
        const requestUrl = `api/v2/Scenes/${id}`;

        try {
            const response = await this.api.get<Services.Scenes.SceneDefinition>(requestUrl, {
                params: {
                    requestor: config.appName,
                    noCache: true,
                },
            });

            return response.data;
        } catch (e: any) {
            const error = new ServiceError(e);

            logServiceError(error);
            throw error;
        }
    }

    public async getScenes(
        input: Services.Scenes.Search,
    ): Promise<Services.Scenes.Response> {
        const requestUrl = 'api/v2/Scenes';

        try {
            const response = await this.api.get<Services.Scenes.Response>(requestUrl, {
                params: {
                    ...input,
                    requestor: config.appName,
                    noCache: true,
                },
                paramsSerializer: (params) => qs.stringify(params),
            });

            return response.data;
        } catch (e: any) {
            const error = new ServiceError(e);

            logServiceError(error);
            throw error;
        }
    }

    public async putScene(
        scene: Services.Scenes.SceneDefinition,
        id: number,
        bearerToken: string,
    ): Promise<void> {
        const requestUrl = `api/v2/Scenes/${id}`;

        try {
            return await this.api.put(
                requestUrl,
                scene,
                {
                    params: {
                        requestor: config.appName,
                    },
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                },
            );
        } catch (e: any) {
            const error = new ServiceError(e);

            logServiceError(error);
            throw error;
        }
    }

    public async postScene(
        scene: Services.Scenes.SceneDefinition,
        bearerToken: string,
    ): Promise<Services.Scenes.SceneDefinition> {
        const requestUrl = 'api/v2/Scenes/';

        try {
            const response = await this.api.post<Services.Scenes.SceneDefinition>(
                requestUrl,
                scene,
                {
                    params: {
                        requestor: config.appName,
                    },
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                },
            );

            return response.data;
        } catch (e: any) {
            const error = new ServiceError(e);

            logServiceError(error);
            throw error;
        }
    }

    public async deleteScene(
        id: number,
        bearerToken: string,
    ): Promise<void> {
        const requestUrl = `api/v2/Scenes/${id}`;

        try {
            return await this.api.delete(
                requestUrl,
                {
                    params: {
                        requestor: config.appName,
                    },
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                },
            );
        } catch (e: any) {
            const error = new ServiceError(e);

            logServiceError(error);
            throw error;
        }
    }
}

export default new ScenesService();
