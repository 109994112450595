import AbstractService from 'src/services/AbstractService';
import config from 'src/lib/config';
import { ServiceError } from 'src/lib/errors';
import { logServiceError } from 'src/utils/logger';

/**
 * Gets taxonomy values
 */
class TaxonomyService extends AbstractService {
    constructor() {
        super(config.services.contentStore);
    }

    public async getTaxonomies(bearerToken: string): Promise<Services.Filters.Taxonomy[]> {
        const requestUrl = 'api/v3/taxonomies';

        try {
            const response = await this.api.get<Services.Taxonomies.Response<Services.Taxonomies.Taxonomy>>(
                requestUrl,
                {
                    params: {
                        requestor: config.appName,
                        noCache: true,
                        maxDepth: 2,
                        limit: 200,
                    },
                    headers: {
                        Authorization: `Bearer ${bearerToken}`,
                    },
                },
            );

            return response.data.results.map((t) => {
                const taxonomy: Services.Filters.Taxonomy = {
                    // eslint-disable-next-line no-underscore-dangle
                    id: t.href.split('/').slice(-1)[0],
                    name: t.taxonomyName,
                    category: {
                        // eslint-disable-next-line no-underscore-dangle
                        name: t.taxonomyNode._embedded.category.internalName,
                        categoryKey: t.taxonomyNode.categoryKey,
                        // eslint-disable-next-line no-underscore-dangle
                        localizedTitle: t.taxonomyNode._embedded.category.translations,
                        children: this.mapTaxonomies(t.taxonomyNode.children),
                    },
                };

                return taxonomy;
            });
        } catch (e: any) {
            const error = new ServiceError(e);

            logServiceError(error);
            throw error;
        }
    }

    mapTaxonomies(taxonomyNodes: Services.Taxonomies.TaxonomyNode[]): Services.Filters.Category[] {
        return taxonomyNodes.map((taxonomyNode) => ({
            // eslint-disable-next-line no-underscore-dangle
            id: taxonomyNode._embedded.category.href.split('/').slice(-1)[0],
            // eslint-disable-next-line no-underscore-dangle
            name: taxonomyNode._embedded.category.internalName,
            categoryKey: taxonomyNode.categoryKey,
            // eslint-disable-next-line no-underscore-dangle
            localizedTitle: taxonomyNode._embedded.category.translations,
            children: this.mapTaxonomies(taxonomyNode.children),
        }));
    }
}

export default new TaxonomyService();
