import { MouseEvent } from 'react';
import classnames from 'classnames';
import { ExpandMore } from '@mui/icons-material';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

interface PropTypes extends Omit<IconButtonProps, 'onClick'> {
    edge?: 'start' | 'end';
    expanded: boolean;
    Icon?: typeof SvgIcon;
    IconProps?: SvgIconProps;
    onClick: (event: MouseEvent<HTMLButtonElement>, isExpanded: boolean) => void;
    showLessText?: string;
    showMoreText?: string;
}

const useStyles = makeStyles()((theme: Theme) => ({
    expand: {
        borderRadius: 0,
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandText: {
        paddingRight: theme.spacing(1),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    hidden: {
        display: 'none',
    },
}));

const CollapsableToggle = (props: PropTypes): JSX.Element => {
    const {
        className = '',
        edge = 'start',
        expanded,
        Icon = ExpandMore,
        IconProps = {},
        onClick,
        showLessText,
        showMoreText,
        ...rest
    } = props;
    const { classes } = useStyles();

    const handleOnClick = (event: MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();

        if (onClick) {
            onClick(event, !expanded);
        }
    };

    return (
        <IconButton
            className={classnames(classes.expand, className)}
            aria-expanded={expanded}
            aria-label="show more"
            onClick={handleOnClick}
            {...rest}
        >
            {(edge === 'start') && (
                <Icon
                    className={classnames({
                        [classes.expandOpen]: expanded,
                    })}
                    {...IconProps}
                />
            )}
            {showMoreText && (
                <Typography
                    className={classnames(classes.expandText, {
                        [classes.hidden]: expanded,
                    })}
                    variant="caption"
                >
                    {showMoreText}
                </Typography>
            )}
            {showLessText && (
                <Typography
                    className={classnames(classes.expandText, {
                        [classes.hidden]: !expanded,
                    })}
                    variant="caption"
                >
                    {showLessText}
                </Typography>
            )}
            {(edge === 'end') && (
                <Icon
                    className={classnames({
                        [classes.expandOpen]: expanded,
                    })}
                    {...IconProps}
                />
            )}
        </IconButton>
    );
};

export default CollapsableToggle;
