import { useContext } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Grid } from '@mui/material';

import FilterDetails from 'src/components/ManageGallery/Filters/TaxonomyFilter/FilterDetails';
import TaxonomySelection from 'src/components/ManageGallery/Filters/TaxonomyFilter/TaxonomySelection';
import ManageGalleryContext from 'src/contexts/manageGalleryContext';
import ZeroState from 'src/components/common/ZeroState';
import BackBar from 'src/components/common/BackBar';

interface PropTypes {
    backTo: string;
}

const ManageTaxonomy = (props: PropTypes): JSX.Element => {
    const { params: { taxonomyId } } = useRouteMatch<{ taxonomyId: string }>();
    const { backTo } = props;
    const { galleryData: { allFiltersById } } = useContext(ManageGalleryContext);

    const taxonomyFilter = allFiltersById.byId.get(taxonomyId) as App.TaxonomyFilter;

    if (taxonomyFilter == null) {
        return (
            <>
                <BackBar to={backTo} title="" />
                <ZeroState text="That filter does not exist in this Gallery" />
            </>
        );
    }

    return (
        <>
            <BackBar to={backTo} title="View Category Filter" />
            <Grid container spacing={4}>
                <Grid item xs={6}>
                    <FilterDetails taxonomyFilter={taxonomyFilter} taxonomyId={taxonomyId} />
                </Grid>
                <Grid item xs={6}>
                    <TaxonomySelection taxonomyFilter={taxonomyFilter} />
                </Grid>
            </Grid>
        </>
    );
};

export default ManageTaxonomy;
