import AbstractService from 'src/services/AbstractService';
import config from 'src/lib/config';
import { ServiceError } from 'src/lib/errors';
import { logServiceError } from 'src/utils/logger';

/**
 * Gets all render properties configured for a gallery
 */
class GalleryAuditLogService extends AbstractService {
    constructor() {
        super(config.services.config);
    }

    public async getAuditLog(
        galleryId: number,
        offset?: number,
        limit?: number,
        accessToken?: string | undefined,
    ): Promise<Models.PageResult<Services.AuditLogs.AuditLog>> {
        const requestUrl = `api/v2/Galleries/${galleryId}/AuditLog`;

        try {
            const response = await this.api.get<Models.PageResult<Services.AuditLogs.AuditLog>>(requestUrl, {
                params: {
                    offset,
                    limit,
                    requestor: config.appName,
                    noCache: true,
                },
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            return response.data;
        } catch (e: any) {
            const error = new ServiceError(e);

            logServiceError(error);
            throw error;
        }
    }
}

export const galleryAuditLogService = new GalleryAuditLogService();
