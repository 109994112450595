import { ChangeEvent, useContext } from 'react';
import { makeStyles } from 'tss-react/mui';

import Card, {
    CardHeader,
    CardContent,
} from 'src/components/common/Card';
import ManageSceneContext from 'src/contexts/ManageSceneContext';
import TextField from 'src/components/common/inputs/TextField';
import { EditContext } from 'src/contexts/EditContext';
import { setSceneDescriptionAction } from 'src/store/sceneData/description';

const useStyles = makeStyles()(() => ({
    descriptionText: {
        width: '100%',
    },
}));

const ManageSceneDescription = (): JSX.Element => {
    const { dispatch, sceneData: { sceneDefinition } } = useContext(ManageSceneContext);
    const { editing } = useContext(EditContext);
    const { classes } = useStyles();

    const handleDescriptionChange = (event: ChangeEvent<HTMLTextAreaElement>): void => {
        dispatch(setSceneDescriptionAction(event.target.value));
    };

    return (
        <Card>
            <CardHeader>
                Description
            </CardHeader>
            <CardContent>
                <TextField
                    multiline
                    fullWidth
                    className={classes.descriptionText}
                    editing={editing}
                    placeholder="Enter a description..."
                    underlined={false}
                    value={sceneDefinition.description || (editing ? '' : 'No Description Provided')}
                    onChange={handleDescriptionChange}
                />
            </CardContent>
        </Card>
    );
};

export default ManageSceneDescription;
