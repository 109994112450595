import {
    Typography,
} from '@mui/material';

import ReadOnlyField from 'src/components/common/inputs/ReadOnlyField';
import NotificationChannelChipsArray from 'src/components/common/inputs/ManageNotificationChannels/NotificationChannelChipsArray';

interface PropTypes {
    notificationChannels: Services.NotificationChannels.NotificationChannel[];
}

const ReadOnlyNotificationChannels = (props: PropTypes): JSX.Element => {
    const {
        notificationChannels,
    } = props;

    return (
        <ReadOnlyField
            underlined={false}
            label={(
                <Typography
                    display="inline"
                    variant="inherit"
                >
                    Notification Channels
                </Typography>
            )}

        >
            <NotificationChannelChipsArray
                editing={false}
                chipsData={notificationChannels}
            />
        </ReadOnlyField>
    );
};

export default ReadOnlyNotificationChannels;
