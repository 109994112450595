import Grid from '@mui/material/Unstable_Grid2';

import ConfigDetails from 'src/components/ManageGallery/GalleryDetails/ConfigDetails';
import GalleryUrls from 'src/components/ManageGallery/GalleryDetails/GalleryUrls';

const GalleryDetails = (): JSX.Element => (
    <Grid container spacing={3} direction="column">
        <Grid xs={12}>
            <ConfigDetails />
        </Grid>
        <Grid xs={12}>
            <GalleryUrls />
        </Grid>
    </Grid>
);

export default GalleryDetails;
