import { HTMLProps } from 'react';
import classnames from 'classnames';
import { ArrowBack } from '@mui/icons-material';
import { Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Link } from 'react-router-dom';

const useStyles = makeStyles()({
    unstyledLink: {
        color: 'initial',
        textDecoration: 'none',
    },
});

export interface BackButtonProps extends HTMLProps<HTMLButtonElement> {
    to: string;
}

const BackButton = (props: BackButtonProps): JSX.Element => {
    const { to } = props;
    const { classes } = useStyles();

    return (
        <Button
            className={classnames(classes.unstyledLink)}
            component={Link}
            startIcon={<ArrowBack />}
            to={to}
        >
            Back
        </Button>
    );
};

export default BackButton;
