import GalleriesService from 'src/services/GalleriesService';

export default async function getGalleryName(productKey: string): Promise<string | null> {
    const galleries = await GalleriesService.getGalleries({
        productKey,
        limit: 1,
    });

    if (galleries.length === 0) {
        return null;
    }

    return galleries[0].name;
}
