export const UPDATE_RENDER_PROPERTY = 'UPDATE_RENDER_PROPERTY';
export const REMOVE_RENDER_PROPERTY = 'REMOVE_RENDER_PROPERTY';
export const ADD_RENDER_PROPERTY = 'ADD_RENDER_PROPERTY';

export const updateRenderProperty = (renderProperty: Services.RenderProperties.RenderProperty): Actions.RenderPropertyAction => ({
    type: UPDATE_RENDER_PROPERTY,
    payload: {
        renderProperty,
    },
});

export const updateSingleRenderProperty = (
    renderProperties: App.RenderPropertiesByExperienceType,
    renderProperty: Services.RenderProperties.RenderProperty,
): App.RenderPropertiesByExperienceType => {
    const { experienceType, key } = renderProperty;

    if (renderProperties[experienceType] && renderProperties[experienceType][key]) {
        return {
            ...renderProperties,
            [experienceType]: {
                ...renderProperties[experienceType],
                [key]: renderProperty,
            },
        };
    }
    return renderProperties;
};

export const addRenderProperty = (renderProperty: Services.RenderProperties.RenderProperty): Actions.RenderPropertyAction => ({
    type: ADD_RENDER_PROPERTY,
    payload: {
        renderProperty,
    },
});

export const addSingleRenderProperty = (
    renderProperties: App.RenderPropertiesByExperienceType,
    renderProperty: Services.RenderProperties.RenderProperty,
): App.RenderPropertiesByExperienceType => {
    const { experienceType, key } = renderProperty;

    return {
        ...renderProperties,
        [experienceType]: {
            ...renderProperties[experienceType],
            [key]: renderProperty,
        },
    };
};

export const removeRenderProperty = (renderProperty: Services.RenderProperties.RenderProperty): Actions.RenderPropertyAction => ({
    type: REMOVE_RENDER_PROPERTY,
    payload: {
        renderProperty,
    },
});

export const removeSingleRenderProperty = (
    renderProperties: App.RenderPropertiesByExperienceType,
    renderProperty: Services.RenderProperties.RenderProperty,
): App.RenderPropertiesByExperienceType => {
    const { experienceType, key } = renderProperty;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [key]: toRemove, ...rest } = renderProperties[experienceType];
    const newRenderProperties = {
        ...renderProperties,
        [experienceType]: {
            ...rest,
        },
    };

    return newRenderProperties;
};

export const renderPropertiesReducer = (
    renderProperties: App.RenderPropertiesByExperienceType,
    action: Actions.Action,
): App.RenderPropertiesByExperienceType => {
    const { renderProperty } = (action as Actions.RenderPropertyAction).payload;

    switch (action.type) {
        case UPDATE_RENDER_PROPERTY:
            return updateSingleRenderProperty(renderProperties, renderProperty);
        case REMOVE_RENDER_PROPERTY:
            return removeSingleRenderProperty(renderProperties, renderProperty);
        case ADD_RENDER_PROPERTY:
            return addSingleRenderProperty(renderProperties, renderProperty);
        default:
            return renderProperties;
    }
};
