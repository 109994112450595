import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import ScenePreview, { PropTypes } from 'src/components/common/ScenePreview';

const useStyles = makeStyles()((theme: Theme) => ({
    preview: {
        maxWidth: '100%',
        height: theme.spacing(15),
        objectFit: 'contain',
    },
}));

const SearchScenePreview = (props: PropTypes): JSX.Element => {
    const { classes } = useStyles();

    return (<ScenePreview className={classes.preview} {...props} />);
};

export default SearchScenePreview;
