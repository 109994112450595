import { FunctionComponent, HTMLProps, useContext } from 'react';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

import DefaultCancelButton, { CancelButtonPropTypes } from 'src/components/common/inputs/CancelButton';
import DefaultEditButton, { EditButtonPropTypes } from 'src/components/common/inputs/EditButton';
import DefaultSaveButton, { SaveButtonPropTypes } from 'src/components/common/inputs/SaveButton';
import { DeleteButtonPropTypes } from 'src/components/common/inputs/DeleteButton';
import { EditContext } from 'src/contexts/EditContext';
import { SAVE_CLICKED_EVENT, CANCEL_CLICKED_EVENT, DELETE_CLICKED_EVENT } from 'src/constants';

const useStyles = makeStyles()((theme: Theme) => ({
    actionButton: {
        marginRight: theme.spacing(1),
    },
}));

interface PropTypes extends HTMLProps<{}> {
    DeleteButton?: FunctionComponent<DeleteButtonPropTypes> | false | null;
    EditButton?: FunctionComponent<EditButtonPropTypes> | false | null;
    SaveButton?: FunctionComponent<SaveButtonPropTypes> | false | null;
    CancelButton?: FunctionComponent<CancelButtonPropTypes> | false | null;
}

const AppBarAction = (props: PropTypes): JSX.Element => {
    const {
        DeleteButton,
        EditButton = DefaultEditButton,
        SaveButton = DefaultSaveButton,
        CancelButton = DefaultCancelButton,
    } = props;
    const { editing, setEditing } = useContext(EditContext);
    const { classes } = useStyles();

    const deleteOnClick = (): void => {
        const deleteEvent = new Event(DELETE_CLICKED_EVENT);

        window.dispatchEvent(deleteEvent);
    };

    const saveOnClick = (): void => {
        const saveEvent = new Event(SAVE_CLICKED_EVENT);

        window.dispatchEvent(saveEvent);
    };
    const cancelOnClick = (): void => {
        const cancelEvent = new Event(CANCEL_CLICKED_EVENT);

        window.dispatchEvent(cancelEvent);
    };

    const DeleteButtonComponent = DeleteButton || undefined;

    const CancelButtonComponent = CancelButton || DefaultCancelButton;

    const EditButtonComponent = EditButton || DefaultEditButton;

    const SaveButtonComponent = SaveButton || DefaultSaveButton;

    return (
        <>
            {!editing && (
                <EditButtonComponent
                    className={classes.actionButton}
                    onClick={(): void => setEditing(true)}
                />
            )}
            {editing && (
                <>
                    <CancelButtonComponent
                        className={classes.actionButton}
                        onClick={cancelOnClick}
                    />
                    <SaveButtonComponent
                        className={classes.actionButton}
                        onClick={saveOnClick}
                    />
                    {DeleteButtonComponent && (
                        <DeleteButtonComponent
                            className={classes.actionButton}
                            onClick={deleteOnClick}
                        />
                    )}
                </>
            )}
        </>
    );
};

export default AppBarAction;
