import {
    ChangeEvent, FC, useContext, useEffect, useMemo, useState, MouseEvent,
} from 'react';
import {
    Grid,
    Tooltip,
    useMediaQuery,
    useTheme,
    IconButton,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import { Info, Edit } from '@mui/icons-material';
import { useDebouncedCallback } from 'use-debounce';

import TextField, { TextFieldProps } from 'src/components/common/inputs/TextField';
import AsyncAutocomplete, { AsyncAutocompleteProps } from 'src/components/common/inputs/AsyncAutocomplete';
import { IdentityContext } from 'src/contexts/IdentityContext';
import useProducts from 'src/hooks/useProducts';

type PropTypes = Omit<Partial<AsyncAutocompleteProps<Services.Product.BasicProduct>>, 'renderInput'> & TextFieldProps & {
    productKey: string | undefined;
    handleProductChange: (event: ChangeEvent<{}>, value: unknown) => void;
    helperText?: string;
    lockOnSelect?: boolean;
};

const useStyles = makeStyles()((theme: Theme) => ({
    infoIcon: {
        color: theme.palette.primary.light,
        height: theme.spacing(2),
        marginLeft: theme.spacing(1),
        width: theme.spacing(2),
    },
    key: {
        marginRight: theme.spacing(2),
        whiteSpace: 'nowrap',
    },
}));

const ProductKeysAutocomplete = AsyncAutocomplete as (
    FC<AsyncAutocompleteProps<Services.Product.BasicProduct>>
);

const DEFAULT_HELPER_TEXT = 'Select a Product Key to search for all scenes mapped to a product';
const DEFAULT_LABEL = 'Select a Product Key';

const ProductKeyAutocomplete = (props: PropTypes): JSX.Element => {
    const {
        editing,
        handleProductChange,
        helperText = DEFAULT_HELPER_TEXT,
        label = DEFAULT_LABEL,
        lockOnSelect = false,
        productKey,
        ...rest
    } = props;
    const { accessToken } = useContext(IdentityContext);
    const [locked, setLocked] = useState<boolean>(false);

    const { classes } = useStyles();
    const theme = useTheme();
    const lgUp = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true });

    const [searchInput, setSearchInput] = useState<string>('');
    const [isDebouncing, setIsDebouncing] = useState(false);
    const [products, productsLoading] = useProducts(accessToken, searchInput);
    const product = useMemo(
        () => products.find((p) => p.productKey === productKey) || null,
        [productKey, products],
    );

    const debounced = useDebouncedCallback((newValue) => {
        setSearchInput(newValue);
        setIsDebouncing(false);
    }, 750);

    useEffect(() => {
        if (lockOnSelect) {
            setLocked(!!product);
        }
    }, [lockOnSelect, product, setLocked]);

    const handleUnlock = (event: MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();

        setLocked(false);
    };

    const handleInputChange = (event: ChangeEvent<{}>, value: string): void => {
        event.preventDefault();

        setIsDebouncing(true);
        debounced(value);
    };

    const loading = (isDebouncing || productsLoading);

    return (
        <>
            {(!editing || locked) && (
                <TextField
                    disabled
                    label={label}
                    helperText={helperText}
                    value={productKey}
                    InputProps={{
                        endAdornment: (
                            <Tooltip title="change selection">
                                <IconButton onClick={handleUnlock}>
                                    <Edit />
                                </IconButton>
                            </Tooltip>
                        ),
                    }}
                    {...rest}
                />
            )}
            {(editing && !locked) && (
                <ProductKeysAutocomplete
                    virtualize
                    helperText={helperText}
                    label={label}
                    {...rest}
                    loading={loading}
                    options={products}
                    optionKey="productKey"
                    filterOptions={(x: any): Services.Product.BasicProduct[] => x}
                    value={product}
                    disabled={!products}
                    renderOption={(optionProps, option: Services.Product.BasicProduct): JSX.Element => (
                        <li {...optionProps}>
                            <Grid container justifyContent="space-between" wrap="nowrap">
                                <Grid item xs={3} className={classes.key}>{option.productKey}</Grid>
                                <Grid item xs={lgUp}>
                                    {lgUp && (option.name)}
                                    {!lgUp && (
                                        <Tooltip title={option.name}>
                                            <Info className={classes.infoIcon} />
                                        </Tooltip>
                                    )}
                                </Grid>
                            </Grid>
                        </li>
                    )}
                    onChange={handleProductChange}
                    onInputChange={handleInputChange}
                />
            )}
        </>
    );
};

export default ProductKeyAutocomplete;
