import { useContext } from 'react';
import { RenderPropertiesSchema } from 'src/constants';
import RenderPropertySelect from 'src/components/ManageGallery/GalleryRenderProperties/RenderPropertySelect';
import RenderPropertyInput from 'src/components/ManageGallery/GalleryRenderProperties/RenderPropertyInput';
import ManageGalleryContext from 'src/contexts/manageGalleryContext';
import GalleryGlobalValueContext from 'src/contexts/GalleryGlobalValueContext';
import { getRenderProperty } from 'src/components/ManageGallery/GalleryRenderProperties/utils';

type PropTypes = {
    experienceType: string;
    propKey: string;
    editing: boolean;
}

export const RenderPropertyValue = (props: PropTypes): JSX.Element => {
    const { experienceType, editing, propKey } = props;
    const { galleryData } = useContext(ManageGalleryContext);
    const { renderProperties } = galleryData;
    const { globalGalleryData: { defaultRenderProperties } } = useContext(GalleryGlobalValueContext);
    const renderProperty = getRenderProperty(defaultRenderProperties, renderProperties, propKey, experienceType);

    const enabled = editing && renderProperty?.inheritedFrom === experienceType;

    return (
        <>
            {(RenderPropertiesSchema[propKey].values) && (
                <RenderPropertySelect
                    editing={enabled}
                    id={`${propKey}-${experienceType}-render-prop-select`}
                    propKey={propKey}
                    experienceType={experienceType}
                />
            )}
            {(!RenderPropertiesSchema[propKey].values) && (
                <RenderPropertyInput
                    editing={enabled}
                    id={`${propKey}-${experienceType}-render-prop-input`}
                    propKey={propKey}
                    experienceType={experienceType}
                />
            )}
        </>
    );
};
