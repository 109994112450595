import Rollbar from 'rollbar';
import ServiceError from 'src/lib/errors/ServiceError';

declare const ENVIRONMENT: App.AllowedEnvironments;

declare const DEPLOYMENT: string;

let rollbarLogger: Rollbar;

export const initialize = (accessToken: string): void => {
    if (!rollbarLogger) {
        rollbarLogger = new Rollbar({
            accessToken,
            captureUncaught: true,
            captureUnhandledRejections: true,
            payload: {
                environment: ENVIRONMENT,
                deployment: DEPLOYMENT,
            },
        });
    }
};

export const getLogger = (): Rollbar => {
    if (!rollbarLogger) {
        throw new Error('You must initialize the logger first');
    }

    return rollbarLogger;
};

export const registerUser = (email: string): void => {
    const logger = getLogger();

    logger.configure({
        payload: {
            person: {
                id: email,
                username: email,
                email,
            },
        },
    });
};

export function logServiceError(error: ServiceError): void {
    const logger = getLogger();
    const { payload, ...context } = error;
    const extraData = {
        responseMessage: context.response?.data,
        requestUrl: context.response?.config?.baseURL,
        requestParams: context.response?.config?.params,
        requestBody: context.response?.config?.data,
        ...context,
    };

    if (context.status >= 400 && context.status < 500) {
        logger.warn(payload, extraData);
    } else {
        logger.error(payload, extraData);
    }
}
