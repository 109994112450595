import {
    Dispatch, SetStateAction, useEffect, useState,
} from 'react';

import useTranslationGroups from 'src/hooks/useTranslationGroups';
import { AppError } from 'src/lib/errors';

type useTranslationGroupTuple = {
    page: number;
    setPage: Dispatch<SetStateAction<number>>;
    searchedTranslations: Services.Translations.V2.TranslationGroup[];
    keySearch: string;
    setKeySearch: Dispatch<SetStateAction<string>>;
    valueSearch: string;
    setValueSearch: Dispatch<SetStateAction<string>>;
    translationsLoading: boolean;
    translationsError: AppError | null;
};

const useTranslationGroupSearch = (
    purpose?: TRANSLATION_PURPOSE,
): useTranslationGroupTuple => {
    const {
        translations,
        translationsLoading,
        translationsError,
    } = useTranslationGroups(purpose);

    const [page, setPage] = useState(0);
    const [keySearch, setKeySearch] = useState<string>('');
    const [valueSearch, setValueSearch] = useState<string>('');
    const [searchedTranslations, setSearchedTranslations] = useState(translations);

    useEffect(() => {
        const keyRegex = new RegExp(keySearch, 'i');
        const valueRegex = new RegExp(valueSearch, 'i');

        setSearchedTranslations(translations.filter(
            (option) => keyRegex.test(option.key) && valueRegex.test(option.display),
        ));
    }, [keySearch, valueSearch, translations]);

    return {
        searchedTranslations,
        valueSearch,
        setValueSearch,
        keySearch,
        setKeySearch,
        page,
        setPage,
        translationsLoading,
        translationsError,
    };
};

export default useTranslationGroupSearch;
