import {
    HTMLProps, createContext, useEffect, useState,
} from 'react';
import { centralizedAuth } from '@cimpress/simple-auth-wrapper';

import config from 'src/lib/config';
import Splash from 'src/components/common/Splash';
import CoamService from 'src/services/CoamService';
import { registerUser } from 'src/utils/logger';

const zeroState = {
    auth: undefined,
    accessToken: undefined,
    profile: undefined,
    isLoggedIn: false,
    securityBoundaries: undefined,
};

const IdentityContext = createContext<App.AuthContext>(zeroState);

const IdentityProvider = (props: HTMLProps<{}>): JSX.Element => {
    const { children } = props;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [auth, setAuthState] = useState<any>(null);
    const [accessToken, setAccessToken] = useState();
    const [profile, setProfile] = useState();
    const [securityBoundaries, setSecurityBoundaries] = useState<Services.Coam.Resource[] | undefined>(undefined);
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

    useEffect(() => {
        // eslint-disable-next-line new-cap
        const authLibrary = new centralizedAuth({
            clientID: config.clientID,
        });

        setAuthState(authLibrary);
    }, []);

    useEffect(() => {
        const fetchNewAccessToken = async (): Promise<void> => {
            if (auth) {
                await auth.login({ nextUri: window.location.pathname, forceLogin: true, prompt: 'none' });
                setAccessToken(auth.getAccessToken());
            }
        };
        const runEffect = async (): Promise<void> => {
            if (auth) {
                // Actually runs 30 seconds before token expires
                auth.on('tokenExpired', fetchNewAccessToken);

                if (!isLoggedIn) {
                    await auth.login();
                }

                const loggedInProfile = auth.getProfile();
                const token = auth.getAccessToken();
                const securityBoundariesTask = CoamService.getSecurityBoundaries(loggedInProfile['https://claims.cimpress.io/canonical_id'], token);

                setIsLoggedIn(auth.isLoggedIn());
                setAccessToken(token);
                setProfile(loggedInProfile);
                registerUser(loggedInProfile['https://claims.cimpress.io/email']);
                setSecurityBoundaries(await securityBoundariesTask);
            }
        };

        runEffect();
    }, [auth, isLoggedIn]);

    return (
        // eslint-disable-next-line react/jsx-no-constructed-context-values
        <IdentityContext.Provider value={{
            auth,
            accessToken,
            profile,
            isLoggedIn,
            securityBoundaries,
        }}
        >
            { isLoggedIn && children }
            { !isLoggedIn && (
                <Splash />
            )}
        </IdentityContext.Provider>
    );
};

export { IdentityContext, IdentityProvider };
