import { FILTER_TYPE } from 'src/constants';
import {
    setGalleryFilter,
    setGlobalFilter,
    deleteGalleryFilter,
    setGalleryFilterOrder,
    setProductFilterOptionsOrder,
    addTaxonomyFilterToFilters,
    addGalleryAttributeFilter,
    addFilterOption,
    removeFilterOption,
    setFilterOption,
} from 'src/store/galleryData/utils';

export const UPDATE_GALLERY_FILTER = 'UPDATE_GALLERY_FILTER';
export const UPDATE_GLOBAL_FILTER = 'UPDATE_GLOBAL_FILTER';
export const REMOVE_GALLERY_FILTER = 'REMOVE_GALLERY_FILTER';
export const UPDATE_GALLERY_FILTER_ORDER = 'UPDATE_GALLERY_FILTER_ORDER';
export const UPDATE_PRODUCT_FILTER_OPTIONS_ORDER = 'UPDATE_PRODUCT_FILTER_OPTIONS_ORDER';
export const ADD_CATEGORY_FILTER = 'ADD_CATEGORY_FILTER';
export const ADD_CONTENT_FILTER = 'ADD_CONTENT_FILTER';
export const ADD_PRODUCT_FILTER = 'ADD_PRODUCT_FILTER';
export const ADD_FILTER_OPTION = 'INITIALIZE_FILTER_OPTION';
export const UPDATE_FILTER_OPTION = 'UPDATE_FILTER_OPTION';
export const REMOVE_FILTER_OPTION = 'REMOVE_FILTER_OPTION';

export const updateGalleryFilter = (
    galleryFilter: Services.Filters.GalleryFilter | Services.Filters.GalleryTaxonomy,
    id: string,
): Actions.GalleryFilterAction => ({
    type: UPDATE_GALLERY_FILTER,
    payload: {
        galleryFilter,
        id,
    },
});

export const updateGlobalFilter = (
    globalFilter: Services.Filters.GlobalFilter,
    id: string,
): Actions.GlobalFilterAction => ({
    type: UPDATE_GLOBAL_FILTER,
    payload: {
        globalFilter,
        id,
    },
});

export const removeGalleryFilter = (
    id: string,
): Actions.RemoveGalleryFilterAction => ({
    type: REMOVE_GALLERY_FILTER,
    payload: {
        id,
    },
});

export const updateGalleryFilterOrder = (
    sourceIndex: number,
    destinationIndex: number,

): Actions.GalleryFilterReorderAction => ({
    type: UPDATE_GALLERY_FILTER_ORDER,
    payload: {
        sourceIndex,
        destinationIndex,
    },
});

export const updateProductFilterOptionOrder = (
    filterId: string,
    sourceIndex: number,
    destinationIndex: number,
): Actions.ProductFilterOptionReorderAction => ({
    type: UPDATE_PRODUCT_FILTER_OPTIONS_ORDER,
    payload: {
        filterId,
        sourceIndex,
        destinationIndex,
    },
});

export const addContentFilter = (
    id: string,
    globalFilter: Services.Filters.GlobalFilter,
): Actions.AddAttributeFilterAction => ({
    type: ADD_CONTENT_FILTER,
    payload: {
        id,
        globalFilter,
    },
});

export const addTaxonomyFilter = (
    id: string,
    taxonomy: Services.Filters.Taxonomy,
): Actions.AddTaxonomyFilterAction => ({
    type: ADD_CATEGORY_FILTER,
    payload: {
        id,
        taxonomy,
    },
});

export const addProductFilter = (
    id: string,
    globalFilter: Services.Filters.GlobalFilter,
): Actions.AddAttributeFilterAction => ({
    type: ADD_PRODUCT_FILTER,
    payload: {
        id,
        globalFilter,
    },
});

export const removeAttributeFilterOption = (
    id: string,
    globalFilterOption: Services.Filters.GlobalFilterOption,
): Actions.GlobalFilterOptionAction => ({
    type: REMOVE_FILTER_OPTION,
    payload: {
        id,
        globalFilterOption,
    },
});

export const addAttributeFilterOption = (
    id: string,
    globalFilterOption: Services.Filters.GlobalFilterOption,
): Actions.GlobalFilterOptionAction => ({
    type: ADD_FILTER_OPTION,
    payload: {
        id,
        globalFilterOption,
    },
});

export const updateAttributeFilterOption = (
    id: string,
    globalFilterOption: Services.Filters.GlobalFilterOption,
): Actions.GlobalFilterOptionAction => ({
    type: UPDATE_FILTER_OPTION,
    payload: {
        id,
        globalFilterOption,
    },
});

export const filterReducer = (allFiltersById: App.FiltersById, action: Actions.Action): App.FiltersById => {
    switch (action.type) {
        case (UPDATE_GALLERY_FILTER):
            return setGalleryFilter(allFiltersById, action as Actions.GalleryFilterAction);
        case (UPDATE_GLOBAL_FILTER):
            return setGlobalFilter(allFiltersById, action as Actions.GlobalFilterAction);
        case (REMOVE_GALLERY_FILTER):
            return deleteGalleryFilter(allFiltersById, action as Actions.RemoveGalleryFilterAction);
        case (UPDATE_GALLERY_FILTER_ORDER):
            return setGalleryFilterOrder(allFiltersById, action as Actions.GalleryFilterReorderAction);
        case (UPDATE_PRODUCT_FILTER_OPTIONS_ORDER):
            return setProductFilterOptionsOrder(allFiltersById, action as Actions.ProductFilterOptionReorderAction);
        case (ADD_CATEGORY_FILTER):
            return addTaxonomyFilterToFilters(allFiltersById, action as Actions.AddTaxonomyFilterAction);
        case (ADD_CONTENT_FILTER):
            return addGalleryAttributeFilter(
                allFiltersById,
                action as Actions.AddAttributeFilterAction,
                FILTER_TYPE.global,
            );
        case (ADD_PRODUCT_FILTER):
            return addGalleryAttributeFilter(
                allFiltersById,
                action as Actions.AddAttributeFilterAction,
                FILTER_TYPE.private,
            );
        case (ADD_FILTER_OPTION):
            return addFilterOption(allFiltersById, action as Actions.GlobalFilterOptionAction);
        case (REMOVE_FILTER_OPTION):
            return removeFilterOption(allFiltersById, action as Actions.GlobalFilterOptionAction);
        case (UPDATE_FILTER_OPTION):
            return setFilterOption(allFiltersById, action as Actions.GlobalFilterOptionAction);
        default:
            return allFiltersById;
    }
};
