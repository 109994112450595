import {
    Dispatch, SetStateAction, useEffect, useState,
} from 'react';

import useNotificationChannels from 'src/hooks/useNotificationChannels';
import { AppError } from 'src/lib/errors';

type useNotificationChannelTuple = {
    page: number;
    setPage: Dispatch<SetStateAction<number>>;
    searchedNotificationChannels: Services.NotificationChannels.NotificationChannel[];
    labelSearch: string;
    setLabelSearch: Dispatch<SetStateAction<string>>;
    channelNameSearch: string;
    setChannelNameSearch: Dispatch<SetStateAction<string>>;
    defaultForSearch: string;
    setDefaultForSearch: Dispatch<SetStateAction<string>>;
    notificationChannelsLoading: boolean;
    notificationChannelsError: AppError | null;
};

const useNotificationChannelSearch = (
    defaultFor?: string,
): useNotificationChannelTuple => {
    const {
        notificationChannels,
        notificationChannelsLoading,
        notificationChannelsError,
    } = useNotificationChannels(defaultFor);

    const [page, setPage] = useState(0);
    const [labelSearch, setLabelSearch] = useState<string>('');
    const [channelNameSearch, setChannelNameSearch] = useState<string>('');
    const [defaultForSearch, setDefaultForSearch] = useState<string>('');
    const [searchedNotificationChannels, setSearchedNotificationChannels] = useState(notificationChannels);

    useEffect(() => {
        const labelRegex = new RegExp(labelSearch, 'i');
        const channelNameRegex = new RegExp(channelNameSearch, 'i');
        const defaultForRegex = new RegExp(defaultForSearch, 'i');

        setSearchedNotificationChannels(notificationChannels.filter(
            (option) => labelRegex.test(option.label)
            && defaultForRegex.test(option.defaultFor)
            && channelNameRegex.test(option.channelName),
        ));
    }, [labelSearch, channelNameSearch, defaultForSearch, notificationChannels]);

    return {
        searchedNotificationChannels,
        labelSearch,
        setLabelSearch,
        channelNameSearch,
        setChannelNameSearch,
        defaultForSearch,
        setDefaultForSearch,
        page,
        setPage,
        notificationChannelsLoading,
        notificationChannelsError,
    };
};

export default useNotificationChannelSearch;
