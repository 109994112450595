export const deleteFromTranslations = (
    culture: string,
    translations: Services.Translations.V2.Translations,
): Services.Translations.V2.Translations => {
    const updatedTranslations = { ...translations };

    delete updatedTranslations[culture];

    return updatedTranslations;
};

export const updateTranslationValue = (
    culture: string,
    value: string,
    translations: Services.Translations.V2.Translations,
): Services.Translations.V2.Translations => {
    const updatedTranslations = { ...translations };

    updatedTranslations[culture] = value;

    return updatedTranslations;
};
