import { SelectChangeEvent, TableCell, TableRow } from '@mui/material';

import Checkbox from 'src/components/common/inputs/Checkbox';
import CultureSelect from 'src/components/common/inputs/CultureSelect';
import TextField from 'src/components/common/inputs/TextField';
import AddActionButtons, { AddActionButtonsProps } from 'src/components/common/AddToTable/AddActionButtons';
import { AddToTableRenderProps } from 'src/components/common/AddToTable';
import { ChangeEvent } from 'react';
import BuildTlpSelect from 'src/components/ManageGallery/GalleryDetails/GalleryNames/BuildTlpSelect';
import { BUILD_TLP } from 'src/constants';

interface PropTypes extends AddActionButtonsProps, Omit<AddToTableRenderProps<Services.Galleries.GalleryUrl>, 'row'> {
    url: Services.Galleries.GalleryUrl;
    index: number;
    updateRow: (index: number, newRow: Services.Galleries.GalleryUrl) => void;
    onDone: () => void;
    onDelete: () => void;
    doneButtonDisabled?: boolean;
}

const AddGalleryUrlRow = (props: PropTypes): JSX.Element => {
    const {
        url,
        index,
        updateRow,
        onDone,
        onDelete,
        doneButtonDisabled,
    } = props;

    const handleCultureChange = (culture: string): void => {
        const newGalleryUrl = {
            ...url,
            culture,
        };

        updateRow(index, newGalleryUrl);
    };

    const handleNameChange = (event: ChangeEvent<HTMLTextAreaElement>): void => {
        event.preventDefault();

        const newGalleryUrl = {
            ...url,
            name: event.target.value,
        };

        updateRow(index, newGalleryUrl);
    };

    const handleIsDefaultChange = (
        event: ChangeEvent<HTMLInputElement>,
        value: boolean,
    ): void => {
        event.preventDefault();

        const newGalleryUrl = {
            ...url,
            isDefault: value,
        };

        updateRow(index, newGalleryUrl);
    };

    const handleBuildTlpChange = (
        event: SelectChangeEvent<unknown>,
        value: BUILD_TLP,
    ): void => {
        event.preventDefault();

        const newGalleryUrl = {
            ...url,
            buildTlp: value,
        };

        updateRow(index, newGalleryUrl);
    };

    return (
        <TableRow>
            <TableCell>
                <Checkbox
                    editing
                    checked={url.isDefault}
                    name={url.name}
                    onChange={handleIsDefaultChange}
                />
            </TableCell>
            <TableCell>
                <CultureSelect
                    required
                    currentCulture={url.culture}
                    id={`${index}-culture-change`}
                    label="Select a Culture"
                    setCurrentCulture={handleCultureChange}
                />
            </TableCell>
            <TableCell>
                <BuildTlpSelect
                    buildTlp={url.buildTlp}
                    onBuildTlpChange={handleBuildTlpChange}
                />
            </TableCell>
            <TableCell>
                <TextField
                    fullWidth
                    editing
                    required
                    id="url-name-input"
                    value={url.name}
                    onChange={handleNameChange}
                />
            </TableCell>
            <TableCell align="right">
                <AddActionButtons
                    doneButtonDisabled={doneButtonDisabled}
                    onDone={onDone}
                    onDelete={onDelete}
                />
            </TableCell>
        </TableRow>
    );
};

export default AddGalleryUrlRow;
