import qs from 'query-string';

import config from 'src/lib/config';
import AbstractService from 'src/services/AbstractService';
import { ServiceError } from 'src/lib/errors';
import { logServiceError } from 'src/utils/logger';

/**
 * Gets all the existing gallery attributes and attribute values for design groups
 */
class GalleryContentAttributeService extends AbstractService {
    constructor() {
        super(config.services.content);
    }

    public async getContentAttributes(
        culture: string,
    ): Promise<Services.GalleryContentAttribute.GalleryContentAttribute> {
        const requestUrl = `api/v2/Galleries/Culture/${culture}/Content/Attribute`;

        try {
            const response = await this.api.get<Services.GalleryContentAttribute.GalleryContentAttribute>(requestUrl, {
                params: {
                    requestor: config.appName,
                    noCache: true,
                },
                paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'none' }),
            });

            return response.data;
        } catch (e: any) {
            const error = new ServiceError(e);

            logServiceError(error);
            throw error;
        }
    }
}

export default new GalleryContentAttributeService();
