export const UPDATE_CATEGORY_RESTRICTIONS = 'UPDATE_CATEGORY_RESTRICTIONS';

export const updateCategoryRestrictions = (categoryRestrictions: Services.CategoryRestrictions.CategoryRestriction[]): Actions.CategoryRestrictionAction => ({
    type: UPDATE_CATEGORY_RESTRICTIONS,
    payload: {
        categoryRestrictions,
    },
});

export const categoryRestrictionsReducer = (
    categoryRestrictions: Services.CategoryRestrictions.CategoryRestriction[],
    action: Actions.Action,
): Services.CategoryRestrictions.CategoryRestriction[] => {
    switch (action.type) {
        case (UPDATE_CATEGORY_RESTRICTIONS):
            return (action as Actions.CategoryRestrictionAction).payload.categoryRestrictions;
        default:
            return categoryRestrictions;
    }
};
