import { Add } from '@mui/icons-material';
import { useRouteMatch } from 'react-router-dom';

import AppBar from 'src/components/common/AppBar';
import Content from 'src/components/Content';
import Main from 'src/components/Main';
import TranslationKeySearch from 'src/components/common/TranslationKeySearch';
import LinkButton from 'src/components/common/inputs/LinkButton';

const SearchTranslations = (): JSX.Element => {
    const { path } = useRouteMatch();

    return (
        <Main
            AppBar={(
                <AppBar
                    title="Search Translations"
                    actions={(
                        <LinkButton
                            to={`${path}/create`}
                            variant="contained"
                            color="primary"
                            startIcon={(<Add />)}
                        >
                            Create Translation Group
                        </LinkButton>
                    )}
                />
            )}
        >
            <Content>
                <TranslationKeySearch />
            </Content>
        </Main>
    );
};

export default SearchTranslations;
