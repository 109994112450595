import {
    HTMLProps, useContext, useEffect, useState,
} from 'react';
import { Grid } from '@mui/material';
import { useRouteMatch } from 'react-router-dom';

import ErrorState from 'src/components/common/ErrorState';
import ProductFilterOptionsList from 'src/components/ManageGallery/Filters/ProductFilter/ProductFilterOptionsList';
import BackBar from 'src/components/common/BackBar';
import FilterForm from 'src/components/ManageGallery/Filters/ProductFilter/FilterForm';
import ManageGalleryContext from 'src/contexts/manageGalleryContext';
import Splash from 'src/components/common/Splash';
import ZeroState from 'src/components/common/ZeroState';
import { FILTER_TYPE } from 'src/constants';

export interface PropTypes extends HTMLProps<HTMLDivElement> {
    backTo: string;
}

const ProductFilter = (props: PropTypes): JSX.Element => {
    const { params: { filterId } } = useRouteMatch<{ filterId: string }>();
    const { backTo } = props;
    const { galleryData } = useContext(ManageGalleryContext);

    const [attributeFilter, setAttributeFilter] = useState<App.AttributeFilter | null>(null);
    const [notFound, setNotFound] = useState<boolean>(false);

    useEffect(() => {
        if (galleryData) {
            const attributeFilterValue = galleryData.allFiltersById.byId.get(filterId) as App.AttributeFilter;

            if (attributeFilterValue && attributeFilterValue.type !== FILTER_TYPE.global) {
                setAttributeFilter(attributeFilterValue);
            } else {
                setNotFound(true);
            }
        }
    }, [filterId, galleryData]);

    if (!galleryData) {
        // TODO better loading state
        return (<Splash />);
    }

    return (
        <>
            <BackBar
                to={backTo}
                title={attributeFilter ? `Product Filter - ${attributeFilter.id}` : ''}
            />
            {notFound && (<ZeroState text="Filter not found" />)}
            {(!notFound && !galleryData.coreProduct) && (
                <ErrorState
                    title="The Product for this product option is invalid"
                    subtitle="Double check the configured Product Key"
                    message="If the Product Key is correct and this error persists, check that the Product exists"
                />
            )}
            {(!notFound && galleryData.coreProduct) && (
                <Grid container direction="column" spacing={3}>
                    <Grid item xs={12}>
                        <FilterForm filterId={filterId} />
                    </Grid>
                    <Grid item xs={12}>
                        <ProductFilterOptionsList filterId={filterId} />
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export default ProductFilter;
