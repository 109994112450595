import {
    MouseEvent, useEffect, useRef, useState,
} from 'react';
import {
    Checkbox,
    Collapse,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemText,
    TableCell,
    TableRow,
    Typography,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { create, formatters } from 'jsondiffpatch';

interface PropTypes {
    auditLog: Services.AuditLogs.AuditLog;
}

const useStyles = makeStyles()((theme: Theme) => ({
    cell: {
        padding: theme.spacing(0, 4),
    },
    container: {
        paddingTop: theme.spacing(2),
    },
    description: {
        fontWeight: 'bold',
    },
    diff: {
        marginBottom: theme.spacing(2),
    },
    form: {
        marginBottom: theme.spacing(2),
    },
    list: {
        paddingBottom: 0,
    },
    root: {
        cursor: 'pointer',
        '& > *': {
            borderBottom: 'unset',
        },
        '&:hover': {
            background: theme.palette.action.hover,
        },
    },
}));

const AuditTableRow = (props: PropTypes): JSX.Element => {
    const { auditLog } = props;
    const ref = useRef<HTMLPreElement>(null);
    const [open, setOpen] = useState<boolean>(false);
    const [showUnchanged, setShowUnchanged] = useState<boolean>(false);
    const { classes } = useStyles();

    const toggleCollapse = (event: MouseEvent<HTMLElement>): void => {
        event.preventDefault();
        setOpen(!open);
    };

    const toggleUnchanged = (): void => {
        setShowUnchanged(!showUnchanged);
    };

    const left = JSON.parse(auditLog.initialState);
    const right = JSON.parse(auditLog.finalState);
    const delta = create().diff(left, right);

    useEffect(() => {
        if (ref.current) {
            formatters.html.showUnchanged(showUnchanged, ref.current.children[0]);
        }
    }, [ref, showUnchanged]);

    return (
        <>
            <TableRow className={classes.root} onClick={toggleCollapse}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={toggleCollapse}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell align="center">{new Date(auditLog.createdAt).toLocaleDateString()}</TableCell>
                <TableCell align="center">
                    {auditLog.method === 'DELETE' && 'Deleted'}
                    {auditLog.method === 'PUT' && 'Modified'}
                    {auditLog.method === 'POST' && 'Created'}
                </TableCell>
                <TableCell align="center">{auditLog.userCanonicalId}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell className={classes.cell} colSpan={6}>
                    <Collapse in={open} timeout="auto">
                        <Grid container className={classes.container} spacing={2}>
                            <Grid item xs>
                                <Typography className={classes.description} variant="body2">
                                    Description of changes
                                </Typography>
                                <List className={classes.list}>
                                    {auditLog.messages.map((message) => (
                                        <ListItem key={message}>
                                            <ListItemText>{message}</ListItemText>
                                        </ListItem>
                                    ))}
                                </List>
                            </Grid>
                            {delta && (
                                <Grid item xs>
                                    <FormControl component="fieldset" onChange={toggleUnchanged}>
                                        <FormGroup row aria-label="position" className={classes.form}>
                                            <FormControlLabel
                                                value="end"
                                                control={<Checkbox />}
                                                label="Show unchanged"
                                                labelPlacement="end"
                                            />
                                        </FormGroup>
                                    </FormControl>
                                    <pre
                                        className={classes.diff}
                                        // eslint-disable-next-line react/no-danger
                                        dangerouslySetInnerHTML={{ __html: formatters.html.format(delta, left) }}
                                        ref={ref}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

export default AuditTableRow;
