import { useContext } from 'react';
import { Breakpoint } from '@mui/material';

import NavDrawerContext from 'src/contexts/NavDrawerContext';

const breakpointStepsUp: { [key in Breakpoint]: Breakpoint} = {
    xs: 'sm',
    sm: 'md',
    md: 'lg',
    lg: 'xl',
    xl: 'xl',
};

const breakpointStepsDown: { [key in Breakpoint]: Breakpoint } = {
    xs: 'xs',
    sm: 'xs',
    md: 'sm',
    lg: 'md',
    xl: 'lg',
};

const useCompatibleBreakpoint = (desiredBreakpoint: Breakpoint, direction: 'up' | 'down' = 'up'): Breakpoint => {
    const { expanded } = useContext(NavDrawerContext);

    // If the nav drawer is expanded, then step the desiredBreakpoint up or down based on direction;
    // otherwise use the desiredBreakpoint
    return expanded
        ? (direction === 'up' ? breakpointStepsUp : breakpointStepsDown)[desiredBreakpoint]
        : desiredBreakpoint;
};

export default useCompatibleBreakpoint;
