import { useContext } from 'react';
import { Grid } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

import ManageTranslationsContext from 'src/contexts/ManageTranslationsContext';
import ManageTranslationGroup from 'src/components/ManageTranslations/ManageTranslationGroup';
import ManageTranslationsTable from 'src/components/ManageTranslations/ManageTranslationsTable';
import { EditContext } from 'src/contexts/EditContext';
import { updateTranslationGroupPurpose } from 'src/store/translationsData/translationGroup';
import { updateTranslation, deleteTranslation } from 'src/store/translationsData/translations';

const useStyles = makeStyles()((theme: Theme) => ({
    container: {
        height: '100%',
        maxHeight: '100%',
    },
    scrollTable: {
        height: '100%',
        maxHeight: '100%',
        overflow: 'auto',
        '&.MuiGrid-item': {
            paddingTop: 0,
        },
    },
    translationGroupSpacing: {
        marginBottom: theme.spacing(2),
    },
}));

const ManageTranslations = (): JSX.Element => {
    const { translationsData, dispatch } = useContext(ManageTranslationsContext);
    const { editing } = useContext(EditContext);
    const { classes } = useStyles();

    const handleTranslationChange = (culture: string, value: string): void => {
        dispatch(updateTranslation(culture, value));
    };

    const handleDelete = (culture: string): void => {
        dispatch(deleteTranslation(culture));
    };

    const handlePurposeChange = (newPurpose: TRANSLATION_PURPOSE): void => {
        dispatch(updateTranslationGroupPurpose(newPurpose));
    };

    return (
        <Grid
            container
            className={classes.container}
            direction="column"
            spacing={2}
            wrap="nowrap"
        >
            <Grid item>
                <ManageTranslationGroup
                    className={classes.translationGroupSpacing}
                    editing={editing}
                    foundIn={translationsData.foundIn}
                    translationGroup={translationsData.translationGroup}
                    onPurposeChange={handlePurposeChange}
                />
            </Grid>
            <Grid item className={classes.scrollTable}>
                <ManageTranslationsTable
                    editing={editing}
                    translations={translationsData.translations}
                    onChange={handleTranslationChange}
                    onDelete={handleDelete}
                />
            </Grid>
        </Grid>
    );
};

export default ManageTranslations;
