import { useEffect, useState } from 'react';

import useRankingStrategies from 'src/hooks/useRankingStrategies';
import ManageRankingStrategy from 'src/components/common/ManageRankingStrategy';

interface PropTypes {
    editing: boolean;
    currentStrategy: string | null;
    setStrategy: (strategyName: string) => void;
}

const STRATEGY_TYPE = 'Natural';

const ManageNaturalRankingStrategy = (props: PropTypes): JSX.Element => {
    const {
        editing,
        currentStrategy,
        setStrategy,
    } = props;

    const [rankingStrategies, loading, error] = useRankingStrategies(STRATEGY_TYPE);
    const [strategies, setStrategies] = useState(rankingStrategies);

    useEffect(() => (): void => {
        if (rankingStrategies && rankingStrategies.length > 0) {
            rankingStrategies.sort();

            // Don't allow using deprecated Monolith strategy. This can be deleted when the strategy is deleted.
            if (rankingStrategies.includes('MonolithSourced')) {
                rankingStrategies.splice(rankingStrategies.indexOf('MonolithSourced'), 1);
            }

            setStrategies(rankingStrategies);
        }
    }, [rankingStrategies, loading]);

    return (
        <ManageRankingStrategy
            editing={editing}
            strategyType={STRATEGY_TYPE}
            currentStrategy={currentStrategy}
            setStrategy={setStrategy}
            strategies={strategies}
            loading={loading}
            error={error}
        />
    );
};

export default ManageNaturalRankingStrategy;
