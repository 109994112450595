import { Fragment } from 'react';
import { TableBody } from '@mui/material';

import canonicalizedPurpose from 'src/utils/canonicalizePurpose';
import Header from 'src/components/common/Header';
import TranslationTooltip from 'src/components/common/TranslationTooltip';
import {
    Table,
    TableRow,
    TableCell,
    TableHead,
    CardCell,
    CardRow,
} from 'src/components/common/CardTable';

interface PropTypes {
    handleTranslationKeyChange?: (key: string) => () => void;
    page: number;
    pageSize: number;
    translations: Services.Translations.V2.TranslationGroup[];
}

const TranslationKeyTable = (props: PropTypes): JSX.Element => {
    const {
        handleTranslationKeyChange,
        page,
        pageSize,
        translations,
    } = props;

    return (
        <Table size="medium">
            <TableHead>
                <TableRow>
                    <TableCell>
                        <Header>Example Text</Header>
                    </TableCell>
                    <TableCell>
                        <Header>Key</Header>
                    </TableCell>
                    <TableCell>
                        <Header>Purpose</Header>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {translations.slice(page * pageSize, (page * pageSize) + pageSize).map((option) => (
                    <Fragment key={option.key}>
                        {!handleTranslationKeyChange && (
                            <CardRow hover to={`/translations/${option.id}`}>
                                <CardCell>
                                    {option.display}
                                </CardCell>
                                <CardCell>
                                    <TranslationTooltip translationKey={option.key}>
                                        <span>{option.key}</span>
                                    </TranslationTooltip>
                                </CardCell>
                                <CardCell>
                                    {canonicalizedPurpose(option.purpose)}
                                </CardCell>
                            </CardRow>
                        )}
                        {handleTranslationKeyChange && (
                            <CardRow hover onClick={handleTranslationKeyChange(option.key)}>
                                <CardCell>
                                    {option.display}
                                </CardCell>
                                <CardCell>
                                    <TranslationTooltip translationKey={option.key}>
                                        <span>{option.key}</span>
                                    </TranslationTooltip>
                                </CardCell>
                                <CardCell>
                                    {canonicalizedPurpose(option.purpose)}
                                </CardCell>
                            </CardRow>
                        )}
                    </Fragment>
                ))}
            </TableBody>
        </Table>
    );
};

export default TranslationKeyTable;
