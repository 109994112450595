type TypeToLevelsMap = {
    [key: string]: number;
    'flattaxonomy': number;
    'facetedtaxonomy': number;
};

export const typeToLevelsMap: TypeToLevelsMap = {
    flattaxonomy: 1,
    facetedtaxonomy: 2,
};

export const levelsToTypeMap = [
    'flattaxonomy',
    'facetedtaxonomy',
];

export const getMaxDepthFromType = (type: string): number => typeToLevelsMap[type.toLowerCase()];

export const getTypeFromMaxDepth = (maxDepth: number): string => levelsToTypeMap[maxDepth - 1];
