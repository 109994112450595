import { useContext } from 'react';
import { Grid } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

import { EditContext } from 'src/contexts/EditContext';
import ManageNotificationChannel from 'src/components/ManageNotificationChannels/ManageNotificationChannel';

const useStyles = makeStyles()((theme: Theme) => ({
    container: {
        height: '100%',
        maxHeight: '100%',
    },
    scrollTable: {
        height: '100%',
        maxHeight: '100%',
        overflow: 'auto',
        '&.MuiGrid-item': {
            paddingTop: 0,
        },
    },
    notificationChannelSpacing: {
        marginBottom: theme.spacing(2),
    },
}));

const ManageTranslations = (): JSX.Element => {
    const { editing } = useContext(EditContext);
    const { classes } = useStyles();

    return (
        <Grid
            container
            className={classes.container}
            direction="column"
            spacing={2}
            wrap="nowrap"
        >
            <Grid item>
                <ManageNotificationChannel
                    className={classes.notificationChannelSpacing}
                    editing={editing}
                />
            </Grid>
        </Grid>
    );
};

export default ManageTranslations;
